import { styled } from "@mui/material/styles";
import Tooltip from "@mui/material/Tooltip";

export const TooltipCustom = styled(
    ({ title, delay, children, placement, className }) => (
        <Tooltip
            delay={delay}
            title={title}
            placement={placement}
            classes={{ popper: className }}
        >
            {children}
        </Tooltip>
    )
)(({ theme, delay, className }) => ({
    [`&.${className}`]: {
        "& div": {
            borderRadius: "4px",
            background: "#EFEFEF",
            display: "flex",
            border: "1px solid #D9D9D9",
            color: theme.colors.grey[700],
            fontSize: 13,
            opacity: 0,
            fontWeight: 500,
            transform: "none !important",
            transition: `all 600ms ease-in-out ${delay}ms !important`
        }
    }
}));
