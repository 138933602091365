import { styled } from "@mui/material/styles";

const classes = {
    container: `container`,
    top: `top`,
    title: `title`,
    sectionClose: `sectionClose`,
    content: `content`,
    paddingRight: `paddingRight`,
    footer: `footer`,
    footerGenerateContainer: `footerGenerateContainer`,
    descriptionInfo: `descriptionInfo`,
    textField: `textField`,
    multiline: `multiline`,
    descriptionItem: `descriptionItem`,
    saveContainer: `saveContainer`,
    select: `select`,
    selectRow: `selectRow`
};
const Root = styled("div")(({ theme }) => ({
    [`&.${classes.container}`]: {
        overflowX: "hidden",
        overflow: "auto",
        padding: "0",
        margin: 0,
        minHeight: 227,
        width: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between"
    },
    [`& .${classes.top}`]: {
        height: 53,
        width: "100%",
        borderBottom: "1px solid #D9D9D9",
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        overflow: "hidden"
    },
    [`& .${classes.title}`]: {
        fontFamily: "Roboto",
        fontSize: 16,
        fontWeight: 500,
        lineHeight: "22px",
        margin: 0,
        padding: 0,
        letterSpacing: 0.15,
        marginLeft: 25,
        marginTop: 5
    },
    [`& .${classes.sectionClose}`]: {
        width: 30,
        height: 30,
        borderRadius: "50%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        marginRight: 16,
        cursor: "pointer",
        transition: "background-color 0.2s ease-in-out",
        "&:hover": {
            backgroundColor: "#F5F5F5"
        },
        "&:active": {
            backgroundColor: "#E0E0E0"
        }
    },
    [`& .${classes.content}`]: {
        height: "100%",
        width: "100%",
        padding: "0 25px 0 25px",
        margin: "25px 0"
    },
    [`& .${classes.footer}`]: {
        width: "100%",
        borderTop: "1px solid #D9D9D9",
        overflow: "hidden",
        padding: "0 25px"
    },
    [`& .${classes.footerGenerateContainer}`]: {
        padding: "12px 0",
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "center"
    },
    [`& .${classes.saveContainer}`]: {
        padding: "16px 0",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        "& .retry_item_button": {
            minWidth: 80
        },
        "& .save_item_button": {
            marginLeft: 10,
            minWidth: 80,
            "& >span:empty": {
                display: "none"
            }
        }
    },
    [`& .${classes.descriptionInfo}`]: {
        margin: "12px 0 0 0",
        fontSize: "11px",
        fontStyle: "italic",
        color: theme.colors.grey[400],
        display: "flex",
        alignItems: "center",
        "& svg": {
            width: 12,
            marginRight: 6,
            height: 12
        },
        "& path": {
            fill: theme.colors.grey[400]
        }
    },
    [`& .${classes.textField}`]: {
        "& div.Mui-focused": {
            "& fieldset": {
                borderColor: `${theme.colors.orange[700]} !important`
            }
        },
        "& label.Mui-focused": {
            color: `${theme.colors.orange[700]} !important`
        },
        "& fieldset": {
            borderRadius: "50px",
            "& legend": {
                margin: "4px 0px 4px 2px"
            }
        },
        "& input": {
            padding: "12px 18px !important"
        },
        "& input, div": {
            fontSize: 14
        },
        "& label": {
            transition: "all 100ms linear !important"
        },
        "& label[data-shrink=true].Mui-focused": {
            color: `${theme.colors.orange[700]} !important`
        },
        "& label[data-shrink=true]": {
            padding: `4px${theme.browser.isSafari ? " 10px" : " 8px"}`,
            color: `${theme.colors.grey[900]} !important`,
            background: "white",
            fontWeight: 500,
            fontSize: "16px",
            top: -3,
            "& .MuiFormLabel-asterisk": {
                color: theme.colors.orange[700]
            }
        },
        "& label[data-shrink=false]": {
            paddingLeft: 7
        }
    },
    [`& .${classes.multiline}`]: {
        "& fieldset": {
            borderRadius: "20px !important"
        },
        "& .MuiInputBase-multiline": {
            paddingLeft: 20,
            alignItems: "flex-start",
            minHeight: 110
        }
    },
    [`& .${classes.descriptionItem}`]: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        background:
            "linear-gradient(0deg, #FDECDF, #FDECDF), linear-gradient(0deg, #FAD3B8, #FAD3B8)",
        borderRadius: "8px",
        margin: 0,
        padding: "12px 16px 12px 20px",
        border: "1px solid #FAD3B8",
        "& .MuiFormControlLabel-label": {
            fontSize: 14,
            fontWeight: 500,
            color: theme.colors.orange[800],
            display: "flex",
            alignItems: "center",
            "& svg": {
                width: 24,
                height: 24,
                marginRight: 16,
                "& path": {
                    fill: theme.colors.orange[700]
                }
            }
        },
        "& .MuiRadio-root": {
            color: "#CC580380",
            "&.Mui-checked": {
                color: theme.colors.primary
            },
            "&:hover": {
                backgroundColor: "rgba(204, 88, 3, 0.04)"
            }
        }
    },
    [`& .${classes.select}`]: {
        maxWidth: "200px",
        width: "100%",
        "& .MuiSelect-select": {
            padding: "9px 25px 9px 16px !important"
        },
        "& .MuiSelect-nativeInput": {
            padding: "9px 25px 9px 20px !important",
            border: "none",
            opacity: "1 !important",
            width: "100%",
            height: "100%",
            zIndex: -1,
            opacity: 0
        }
    }
}));

const selectRow = (theme) => ({
    borderRadius: "20px !important",
    boxShadow:
        "0px 5px 5px -3px rgba(0,0,0,0.05), 0px 8px 10px 1px rgba(0,0,0,0.03), 0px 3px 14px 2px rgba(0,0,0,0.1) !important",
    border: "1px solid #d9d9d9 !important",
    "& li": {
        height: 40,
        width: "100%",
        display: "flex !important",
        alignItems: "center !important",
        padding: "0 16px !important",
        background: "white !important",
        fontSize: 13,
        fontWeight: 400,
        "&:hover": {
            background: `${theme.colors.orange[50]} !important`,
            color: `${theme.colors.orange[900]} !important`
        }
    },
    "& li[aria-selected='true']": {
        background: `${theme.colors.orange[50]} !important`,
        color: `${theme.colors.orange[900]} !important`
    }
});

export { Root, classes, selectRow };
