import {
    Checkbox,
    CircularProgress,
    FormControlLabel,
    Switch,
    Table,
    TableBody,
    TableRow,
    TextField
} from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
    Root,
    classes
} from "../../assets/styles/pages/FormCreateCollection.js";
import NoResults from "../../components/NoResults.js";
import BoxLayout from "../../components/UI/BoxLayout.js";
import Button from "../../components/UI/Button.js";
import Icon from "../../components/UI/Icon.js";
import SearchBar from "../../components/UI/SearchBar.js";
import {
    getMenus,
    runPublishCompiler,
    searchActiveMenus
} from "../../utils/Menus/menu-helper.js";
import AuthGlobal from "../../context/store/global";
import {
    SortableContainer,
    SortableElement,
    SortableHandle
} from "react-sortable-hoc";
import CustomTooltip from "../../components/UI/CustomTooltip.js";
import { arrayMoveImmutable } from "array-move";
import {
    createCollection,
    updateCollection
} from "../../utils/Collections/collection-helper.js";
import { useAlert } from "../../hooks/useAlert.js";
import { saveImage } from "../../utils/mediaHelper.js";
import ConfirmAction from "../../components/modals/ConfirmAction.js";
import { refreshRestaurant } from "../../utils/Restaurants/restaurant-helper.js";
import Alert from "../../components/UI/Alert.js";
import PublishHeader from "../../components/UI/PublishHeader.js";
import { usePrompt } from "../../utils/navigationBlocker.js";
import InfoMessage from "../../components/message/InfoMessage.js";
import ExitInfoMessage from "../../components/message/ExitInfoMessage.js";

const DragHandle = SortableHandle(() => {
    // const classes = useStyles();
    return (
        <CustomTooltip title="Drag to Order">
            <span className={classes.dragIconContainer}>
                <Icon name={"drag"} />{" "}
            </span>
        </CustomTooltip>
    );
});
const TableBodySortable = SortableContainer(({ children }) => (
    <TableBody className="sortable-tbody-create-cl">{children}</TableBody>
));

const Row = SortableElement(({ data }) => {
    // const classes = useStyles();
    return (
        <TableRow
            className={`${classes.menuListTableRow} ${
                data.index % 2 === 0 ? classes.color1 : classes.color2
            }`}
        >
            <td>
                {data.internal_name}{" "}
                {data.belongsTo?.length > 0 && (
                    <span className={classes.collectionsList}>
                        {"("}
                        {data.belongsTo.map(([id, collectionName], index) => (
                            <span key={index}>
                                {index > 0 && ", "}
                                {collectionName}
                            </span>
                        ))}
                        {")"}
                    </span>
                )}
            </td>
            <td className={classes.rowActions}>
                <CustomTooltip title="Remove Menu">
                    <span className={classes.iconContainer}>
                        <Icon
                            onClick={() => data.removeMenu(data._id)}
                            name="remove"
                        />
                    </span>
                </CustomTooltip>
            </td>
            {data.draggable && (
                <td className={classes.dragSection}>
                    <DragHandle />
                </td>
            )}
        </TableRow>
    );
});

const FormCreateCollection = ({ mode = "create", collection = {} }) => {
    // const classes = useStyles();

    const {
        dispatch,
        globalState: {
            current_restaurant: { identifier },
            current_restaurant
        }
    } = useContext(AuthGlobal);

    const navigate = useNavigate();

    const [alert, handleAlert] = useAlert();
    const [search, setSearch] = useState("");
    const [name, setName] = useState("");
    const [active, setActive] = useState(true);
    const [message, setMessage] = useState("");
    const [logo, setLogo] = useState("");
    const [file, setFile] = useState(null);
    const [allMenus, setAllMenus] = useState([]);
    const [selectedMenus, setSelectedMenus] = useState([]);
    const [firstLoad, setFirstLoad] = useState(true);
    const [errorMessage, setErrorMessage] = useState("");
    const [imageError, setImageError] = useState("");
    const [loadingMenus, setLoadingMenus] = useState(true);
    const [showConfirmExitDialog, setShowConfirmExitDialog] = useState(false);
    const [hasChanged, setHasChanged] = useState(false);
    const [deleteImage, setDeleteImage] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [disableBtn, setDisableBtn] = useState(false);

    const handleBlockExecution = (currentIsBlocked) => {
        if (currentIsBlocked) setShowConfirmExitDialog(true);
    };

    const { targetLocation } = usePrompt(hasChanged, handleBlockExecution);

    useEffect(() => {
        if (!firstLoad) {
            setHasChanged(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [name, file, selectedMenus, message, active]);

    const handleSubmit = async (
        targetLocation = "",
        saveChanges = false,
        saveAndLeave = false
    ) => {
        setDisableBtn(true);

        if (!name) {
            setErrorMessage("* This field is required");
            handleAlert({
                severity: "error",
                title: "Error",
                message: "<b>Service Period Name</b> field is required to save."
            });
            setDisableBtn(false);

            return false;
        }
        let success,
            response,
            successUpload = true,
            logo_image = "";
        if (file) {
            [successUpload, logo_image] = await saveImage(
                file,
                identifier,
                "service-periods"
            );
        } else if (!deleteImage) {
            logo_image = collection?.logo_image || "";
        }
        if (!successUpload) {
            logo_image = "";
            handleAlert({
                severity: "error",
                title: "Error",
                message:
                    "There was a problem while trying to save your service period logo, please try again in a few seconds"
            });
            setDisableBtn(false);

            return false;
        }
        const body = {
            internal_name: name.trim(),
            display_name: name.trim(),
            schedule_landing_message: message?.trim() || "",
            status: active ? "active" : "draft",
            menu_links: selectedMenus.map((menu, index) => ({
                menu_id: menu._id,
                sort_order: index + 1
            })),
            logo_image
        };
        if (mode === "create") {
            ({ success, response } = await createCollection(body, identifier));
        } else if (mode === "update") {
            ({ success, response } = await updateCollection(
                collection._id,
                body,
                identifier
            ));
        }
        if (success && response) {
            if (saveChanges) {
                handleAlert({
                    severity: "success",
                    title: "Success",
                    message: `Your changes have been <b>saved.</b>`
                });
                setShowModal(false);
                setHasChanged(false);
            }
            if (saveAndLeave) {
                setShowConfirmExitDialog(false);
                setHasChanged(false);
                setTimeout(() => {
                    if (targetLocation !== "") {
                        navigate(targetLocation);
                    } else {
                        navigate("/service-periods");
                    }
                }, 2000);
            }
            await refreshRestaurant(dispatch, identifier, current_restaurant);
            setDisableBtn(false);

            return true;
        } else {
            handleAlert({
                severity: "error",
                title: "Error",
                message: response.message
            });
            setDisableBtn(false);
            setShowModal(false);
            setShowConfirmExitDialog(false);

            return false;
        }
    };

    const handleSave = () => {
        if (hasChanged) {
            setShowModal(true);
        }
    };

    useEffect(() => {
        if (firstLoad && mode === "update") {
            if (collection?.internal_name) {
                setName(collection.internal_name);
                setActive(collection.status === "active");
                setMessage(collection.schedule_landing_message);
                setLogo(collection.logo_image);
                if (allMenus.length > 0) {
                    setSelectedMenus(
                        collection.menu_links
                            .map(({ menu_id }) =>
                                allMenus.find((x) => x._id === menu_id)
                            )
                            .filter((x) => x)
                    );
                }
                setTimeout(() => setFirstLoad(false), 1500);
            }
        } else if (firstLoad) {
            setFirstLoad(false);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [allMenus, collection, mode]);

    useEffect(() => {
        const searchTimeout = setTimeout(() => {
            (async () => {
                let success, response;
                if (search.length >= 2) {
                    ({ success, response } = await searchActiveMenus(
                        search,
                        identifier
                    ));
                } else {
                    ({ success, response } = await getMenus(identifier));
                }
                if (success && response) {
                    setAllMenus(response);
                    setLoadingMenus(false);
                }
            })();
        }, 200);

        return () => clearTimeout(searchTimeout);
    }, [identifier, search]);

    const handleImageChange = (e) => {
        if (e.target.files[0]?.size) {
            if (e.target.files[0].size > 5000000) {
                setImageError(
                    "*Your file was too large to upload. The maximum file size is 5 MB."
                );
                setFile(null);
                setLogo("");
                e.target.value = null;
                return;
            }
            if (
                ![
                    "image/png",
                    "image/jpg",
                    "image/jpeg",
                    "image/svg+xml"
                ].includes(e.target.files[0].type)
            ) {
                setImageError(
                    "*Unsupported file type. Please upload only a JPG, PNG, or SVG file."
                );
                setFile(null);
                setLogo("");
                e.target.value = null;
                return;
            }
            const url = URL.createObjectURL(e.target.files[0]);
            setFile(e.target.files[0]);
            setLogo(url);
            e.target.value = null;
        }
    };

    const toggleMenu = (id) => {
        if (selectedMenus.find((x) => x._id === id)) {
            setSelectedMenus((prev) => prev.filter((x) => x._id !== id));
        } else {
            setSelectedMenus((prev) => [
                ...prev,
                allMenus.find((x) => x._id === id)
            ]);
        }
    };

    const onSortEnd = (arr) => {
        setSelectedMenus((prev) =>
            arrayMoveImmutable(prev, arr.oldIndex, arr.newIndex)
        );
    };

    const publish = async () => {
        setDisableBtn(true);
        const { success, response } = await runPublishCompiler(identifier);
        if (success && response) {
            await refreshRestaurant(dispatch, identifier, current_restaurant);
            handleAlert({
                severity: "success",
                title: "Success",
                message:
                    "Your changes have been <b>saved</b> and <b>published.</b>"
            });
        } else {
            handleAlert({
                severity: "error",
                title: "Error",
                message: response.message
            });
        }
        setDisableBtn(false);
        setShowModal(false);
        setHasChanged(false);
    };

    const handleCombinedFunction = async () => {
        const isSuccessful = await handleSubmit();
        if (isSuccessful) {
            publish();
        }
    };

    return (
        <>
            <Alert {...alert} />
            <BoxLayout style={{ display: "flex" }}>
                <Root className={classes.container}>
                    <PublishHeader />
                    <div className={classes.headline}>
                        <CustomTooltip title="Back">
                            <span
                                className={classes.iconCircle}
                                onClick={() => {
                                    if (hasChanged) {
                                        setShowConfirmExitDialog(true);
                                    } else {
                                        navigate("/service-periods");
                                    }
                                }}
                            >
                                <Icon name="back" />
                            </span>
                        </CustomTooltip>
                        <span className={classes.title}>
                            {mode === "create" && "Create New Service Period"}
                            {mode === "update" &&
                                `Edit ${collection?.internal_name}`}
                        </span>
                        <span className={classes.actions}>
                            <Button
                                disableBtn={!hasChanged}
                                type="primary"
                                color="dark"
                                innerText="Save"
                                onClick={handleSave}
                            />
                        </span>
                    </div>
                    <div className={classes.columnsContainer}>
                        <div className={classes.column}>
                            <TextField
                                label="Service Period Name"
                                required
                                value={name}
                                error={errorMessage.length > 0}
                                helperText={errorMessage}
                                onChange={(e) => {
                                    setErrorMessage("");
                                    setName(e.target.value);
                                }}
                                placeholder="Enter Service Period Name"
                                InputLabelProps={{ shrink: true }}
                                className={classes.textField}
                                style={{
                                    marginBottom:
                                        errorMessage.length === 0 ? 32 : 9
                                }}
                            />
                            <TextField
                                multiline
                                className={`${classes.textField} ${classes.multiline}`}
                                label="Service Period Message"
                                value={message}
                                onChange={(e) => setMessage(e.target.value)}
                                placeholder="Example: Served 2pm - 5pm"
                                InputLabelProps={{ shrink: true }}
                            />
                        </div>
                        <div className={classes.column}>
                            <p className={classes.toggleLabel}>
                                Service Period Status
                            </p>
                            <FormControlLabel
                                control={
                                    <Switch
                                        checked={active}
                                        classes={{
                                            switchBase: classes.switchDisabled
                                        }}
                                        className={active ? classes.switch : ""}
                                    />
                                }
                                label={active ? "Active" : "Inactive"}
                                onChange={() => setActive((val) => !val)}
                                className={classes.switchLabel}
                            />
                            <span className={classes.centered}>
                                Service Period logo
                            </span>
                            <div className={classes.imageContainer}>
                                <input
                                    type="file"
                                    title=""
                                    className={classes.fileInput}
                                    onChange={handleImageChange}
                                />
                                <Icon
                                    name="remove"
                                    className={classes.deleteImage}
                                    onClick={() => {
                                        setLogo("");
                                        setDeleteImage(true);
                                        setFile(null);
                                    }}
                                />
                                {logo && (
                                    <img
                                        className={classes.image}
                                        src={logo}
                                        alt="Service Period logo"
                                    />
                                )}
                                {!logo && (
                                    <>
                                        <Icon name="noImage" />
                                        <p className={classes.noImageText}>
                                            Upload or Drag Image. JPG, PNG or
                                            SVG - 5 MB max.
                                        </p>
                                    </>
                                )}
                            </div>
                            <span className={classes.imageError}>
                                {imageError}
                            </span>
                        </div>
                    </div>
                    <div className={classes.menuSelector}>
                        <div className={classes.listContainer}>
                            <p className={classes.selectorTitle}>
                                Menu Selector
                            </p>
                            <h5 className={classes.infoMessage}>
                                <Icon name="info" />
                                Only Active Menus will be displayed here.
                            </h5>
                            <div className={classes.inlineSearchBar}>
                                <SearchBar
                                    placeholder={"Search Menus"}
                                    clearSearchText={() => setSearch("")}
                                    searchText={search}
                                    setSearchText={setSearch}
                                />
                            </div>
                            <div className={classes.menuList}>
                                {loadingMenus && (
                                    <CircularProgress
                                        style={{
                                            color: "#CC5803",
                                            display: "flex",
                                            height: "100%",
                                            alignSelf: "center"
                                        }}
                                    />
                                )}
                                {!loadingMenus &&
                                    allMenus.map((menu, index) => {
                                        const selected = !!selectedMenus.find(
                                            (x) => x._id === menu._id
                                        );
                                        return (
                                            <div
                                                key={index}
                                                className={classes.selectRow}
                                                onClick={() =>
                                                    toggleMenu(menu._id)
                                                }
                                            >
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            classes={
                                                                !selected
                                                                    ? {
                                                                          root: classes.switchDisabled
                                                                      }
                                                                    : {}
                                                            }
                                                            checked={selected}
                                                            icon={
                                                                <Icon name="checkboxEmpty" />
                                                            }
                                                            checkedIcon={
                                                                <Icon name="checkboxActive" />
                                                            }
                                                            className={`${
                                                                classes.checkbox
                                                            } ${
                                                                selected
                                                                    ? classes.checkboxActive
                                                                    : ""
                                                            }`}
                                                            style={{
                                                                pointerEvents:
                                                                    "auto"
                                                            }}
                                                        />
                                                    }
                                                    label={
                                                        menu.internal_name +
                                                        (menu.belongsTo
                                                            ?.length > 0
                                                            ? ` (${menu.belongsTo
                                                                  .map(
                                                                      ([
                                                                          ,
                                                                          name
                                                                      ]) => name
                                                                  )
                                                                  .join(", ")})`
                                                            : "")
                                                    }
                                                    style={{
                                                        pointerEvents: "none"
                                                    }}
                                                />
                                            </div>
                                        );
                                    })}
                                {!loadingMenus && allMenus.length === 0 && (
                                    <NoResults text="No Menus Found" />
                                )}
                            </div>
                        </div>
                        <div className={classes.menusOrder}>
                            <p className={classes.orderTitle}>Menus Order</p>
                            {!loadingMenus && selectedMenus.length === 0 && (
                                <NoResults text="No Menus Found" />
                            )}
                            {loadingMenus && (
                                <CircularProgress
                                    style={{
                                        color: "#CC5803",
                                        display: "flex",
                                        height: "100%",
                                        alignSelf: "center"
                                    }}
                                />
                            )}

                            <Table>
                                <TableBodySortable
                                    onSortEnd={onSortEnd}
                                    useDragHandle
                                    helperClass={classes.sorting}
                                    lockAxis="y"
                                    helperContainer={() =>
                                        document.querySelector(
                                            ".sortable-tbody-create-cl"
                                        )
                                    }
                                >
                                    {!loadingMenus &&
                                        selectedMenus.map((menu, index) => {
                                            return (
                                                <Row
                                                    index={index}
                                                    key={index}
                                                    data={{
                                                        ...menu,
                                                        index,
                                                        draggable:
                                                            selectedMenus.length >
                                                            1,
                                                        removeMenu: toggleMenu
                                                    }}
                                                ></Row>
                                            );
                                        })}
                                </TableBodySortable>
                            </Table>
                        </div>
                    </div>
                </Root>
                <ConfirmAction
                    button1Text="Save Changes"
                    button2Text="Leave Page"
                    open={showConfirmExitDialog}
                    actionConfirm={async () => {
                        handleSubmit(targetLocation || "", false, true);
                    }}
                    actionSecondButton={() => {
                        setHasChanged(false);
                        setShowConfirmExitDialog(false);
                        setTimeout(() => {
                            if (targetLocation !== "") {
                                navigate(targetLocation);
                            } else {
                                navigate("/service-periods");
                            }
                        }, 200);
                    }}
                    actionCancel={() => {
                        setShowConfirmExitDialog(false);
                    }}
                    icon="error"
                    dialogText="You have unsaved changes. Do you want to save before you exiting?"
                    note={ExitInfoMessage()}
                    disableBtn={disableBtn}
                />
                <ConfirmAction
                    button1Text="Save & Publish Changes"
                    button2Text="Continue with Save"
                    open={showModal}
                    actionConfirm={handleCombinedFunction}
                    actionSecondButton={async () => {
                        handleSubmit("", true);
                    }}
                    actionCancel={() => setShowModal(false)}
                    icon="publish"
                    dialogText={`Would you like to Publish your changes?`}
                    note={InfoMessage()}
                    disableBtn={disableBtn}
                />
            </BoxLayout>
        </>
    );
};

export default FormCreateCollection;
