import { styled } from "@mui/material/styles";

const classes = {
    table: `table`,
    tabsRow: `tabsRow`,
    sortHandler: `sortHandler`,
    tabsContainer: `tabsContainer`,
    labelText: `labelText`,
    loader: `loader`
};

const Root = styled("div")(({ theme }) => ({
    [`&.${classes.table}`]: {
        margin: "0 3rem 3rem"
    },
    [`& .${classes.tabsRow}`]: {
        display: "flex",
        justifyContent: "space-between",
        borderBottom: "0.3px solid #9D9D9D"
    },
    [`& .${classes.sortHandler}`]: {
        cursor: "pointer",
        display: "flex",
        alignItems: "center",
        minWidth: "90px",
        justifyContent: "flex-end",
        "& svg": {
            width: 18,
            height: 18,
            marginLeft: 12
        },
        "&:hover": {
            color: theme.colors.orange[700],
            "& svg > path": {
                fill: theme.colors.orange[700]
            }
        }
    },
    [`& .${classes.tabsContainer}`]: {
        minHeight: "46px !important",
        "& .MuiTabs-indicator": {
            borderBottom: "2px solid #CC5803"
        },
        "& button": {
            padding: 2,
            marginRight: 38
        },
        "& .Mui-selected > span": {
            fontWeight: 500
        },
        "& .MuiTabs-scroller": {
            overflowX: "auto !important"
        },
        "& .MuiTabs-scroller::-webkit-scrollbar": {
            height: "3px"
        }
    },

    [`& .${classes.labelText}`]: {
        color: "black",
        "&:hover": {
            color: theme.colors.orange[700]
        },
        fontSize: "18px",
        fontWeight: 400,
        textTransform: "none"
    },
    [`& .${classes.loader}`]: {
        height: 200,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        "& span": {
            width: "50px !important",
            height: "50px !important",
            color: theme.colors.orange[700]
        }
    }
    // formContainer: {
    //     height: '100vh',
    //     background: 'white',
    //     transform: 'translateX(50%)'
    // }
}));

export { Root, classes };
