import { fetchRequest } from "../index";

export const getAllNotations = (identifier) => {
    return fetchRequest(`/notation/${identifier}`, { method: "GET" });
};

export const createNotation = (identifier, name, type) => {
    return fetchRequest(`/notation/${identifier}`, {
        method: "POST",
        body: JSON.stringify({ name, type })
    });
};
