import { Grid } from "@mui/material";
import AlertCard from "./AlertCard";
import NoResults from "../../components/NoResults";

const AlertsList = ({ alerts = [], archived = false }) => {
    return (
        <Grid container spacing="32px" style={{ marginTop: 0 }}>
            {alerts.map((alert, index) => (
                <Grid item xs={4} component="div" key={index}>
                    <AlertCard
                        alert={alert}
                        key={alert._id}
                        archived={archived}
                    />
                </Grid>
            ))}
            {alerts.length === 0 && <NoResults text="No alerts found" />}
        </Grid>
    );
};

export default AlertsList;
