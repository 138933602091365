import { TextField } from "@mui/material";
import { styled } from "@mui/material/styles";

const classes = {
    textField: `textField`,
    invisibleColorPicker: `invisibleColorPicker`
};

const Root = styled(TextField)(({ theme }) => ({
    [`&.${classes.textField}`]: {
        lineHeight: "44px !important",
        // border: "1px solid #B4B3B3",
        "& div.Mui-focused": {
            "& fieldset": {
                borderColor: `${theme.colors.orange[700]} !important`
            }
        },
        "& label.Mui-focused": {
            color: `${theme.colors.orange[700]} !important`
        },
        "& fieldset": {
            borderRadius: "50px",
            "& legend": {
                margin: "4px 0px 4px 2px"
            }
        },
        "& input:not([type='color']):not([type='file'])": {
            padding: "12px 18px !important"
        },
        "& input, div": {
            fontSize: 14
        },
        "& label": {
            transition: "all 100ms linear !important"
        },
        "& label[data-shrink=true].Mui-focused": {
            color: `${theme.colors.orange[700]} !important`
        },
        "& label[data-shrink=true]": {
            padding: `4px${theme.browser.isSafari ? " 10px" : " 8px"}`,
            color: `${theme.colors.grey[900]} !important`,
            background: "white",
            fontWeight: 500,
            fontSize: "16px",
            top: -3,
            "& .MuiFormLabel-asterisk": {
                color: theme.colors.orange[700]
            }
        },
        "& label[data-shrink=false]": {
            paddingLeft: 7
        }
    },
    [`& .${classes.invisibleColorPicker}`]: {
        opacity: 0,
        cursor: "pointer",
        padding: "0px !important",
        width: "20px",
        height: "20px",
        "&::-webkit-file-upload-button": {
            cursor: "pointer"
        }
    }
}));

const ColorInput = ({ value, setValue, label }) => {
    let colorChangeTimeout;

    return (
        <Root
            label={label}
            value={value}
            type="text"
            InputLabelProps={{ shrink: true }}
            className={classes.textField}
            onChange={(e) => setValue(e.target.value)}
            fullWidth
            InputProps={{
                endAdornment: (
                    <div
                        style={{
                            width: "22px",
                            height: "22px",
                            borderRadius: "50%",
                            border: "1px solid #B4B3B3",
                            background: value
                        }}
                    >
                        <input
                            className={classes.invisibleColorPicker}
                            style={{
                                padding: "0 !important"
                            }}
                            type="color"
                            onChange={(e) => {
                                clearTimeout(colorChangeTimeout);
                                colorChangeTimeout = setTimeout(() => {
                                    setValue(e.target.value);
                                }, 100);
                            }}
                        />
                    </div>
                )
            }}
        />
    );
};

export default ColorInput;
