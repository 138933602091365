import { styled } from "@mui/material/styles";

const classes = {
    container: `container`,
    sectionClose: `sectionClose`,
    closeButtonContainer: `closeButtonContainer`,
    closeButton: `closeButton`,
    title: `title`,
    top: `top`,
    form: `form`,
    actions: `actions`,
    toggleLabel: `toggleLabel`,
    switchLabel: `switchLabel`,
    switch: `switch`,
    switchDisabled: `switchDisabled`,
    textField: `textField`,
    multiline: `multiline`,
    info: `info`
};

const Root = styled("div")(({ theme }) => ({
    [`&.${classes.container}`]: {
        position: "absolute",
        display: "flex",
        flexDirection: "column",
        right: 0,
        width: 550,
        overflowX: "hidden",
        height: "100vh",
        overflow: "auto",
        background: "white",
        borderRadius: "20px 0 0 20px",
        padding: "0",
        boxShadow: "0px 10px 30px rgba(31, 19, 0, 0.3)"
    },
    [`& .${classes.sectionClose}`]: {
        width: "100%",
        display: "flex",
        flexDirection: "row-reverse"
    },
    [`& .${classes.closeButtonContainer}`]: {
        marginTop: 6,
        height: 26,
        width: 26,
        borderRadius: "16px",
        transform: "translate(16px, 12px)",
        display: "flex",
        background: theme.colors.orange[700],
        alignItems: "center",
        cursor: "pointer",
        justifyContent: "center",
        "&:hover": {
            boxShadow: `0px 10px 25px ${theme.colors.orange[700]}59`
        }
    },
    [`& .${classes.closeButton}`]: {
        "& path": {
            fill: "white"
        },
        display: "flex",
        alignItems: "center"
    },
    [`& .${classes.title}`]: {
        fontSize: 20,
        fontWeight: 700,
        color: theme.colors.grey[900],
        margin: "0 0 0.5em"
    },
    [`& .${classes.top}`]: {
        top: 0,
        padding: "0 32px",
        zIndex: 2,
        position: "sticky",
        background: "white",
        borderBottom: "1px solid #D9D9D9",
        filter: "drop-shadow(0px 1px 4px rgba(37, 37, 37, 0.05))"
    },
    [`& .${classes.form}`]: {
        paddingLeft: 32,
        paddingRight: 32,
        minHeight: 150,
        overflowY: "auto",
        flexGrow: 1,
        display: "flex",
        flexDirection: "column",
        paddingBottom: 32
    },
    [`& .${classes.actions}`]: {
        gap: 10,
        display: "flex",
        padding: "16px 32px",
        position: "sticky",
        borderTop: "1px solid #D9D9D9",
        flexDirection: "row-reverse",
        background: "white",
        filter: "drop-shadow(0px -1px 4px rgba(37, 37, 37, 0.05))"
    },
    [`& .${classes.toggleLabel}`]: {
        padding: "20px 0 0 20px",
        fontWeight: 500,
        margin: 0,
        fontSize: 12
    },
    [`& .${classes.switchLabel}`]: {
        marginLeft: "6px !important",
        "& .MuiFormControlLabel-label": {
            fontSize: 14,
            lineHeight: "16px",
            color: theme.colors.grey[500]
        },
        "&:hover .MuiFormControlLabel-label": {
            color: theme.colors.grey[900]
        }
    },
    [`& .${classes.switch}`]: {
        "& .MuiSwitch-switchBase": {
            color: `${theme.colors.orange[700]} !important`,
            "&.Mui-checked:hover": {
                backgroundColor: "rgba(250, 211, 184, 0.15) !important"
            }
        },
        "& .MuiSwitch-track": {
            backgroundColor: `${theme.colors.orange[200]} !important`
        }
    },
    [`& .${classes.switchDisabled}`]: {
        "&:hover": {
            backgroundColor: "rgba(250, 250, 250, 0.35)"
        }
    },
    [`& .${classes.textField}`]: {
        marginTop: "28px !important",
        lineHeight: "44px !important",
        // border: "1px solid #B4B3B3",
        "& div.Mui-focused": {
            "& fieldset": {
                borderColor: `${theme.colors.orange[700]} !important`
            }
        },
        "& label.Mui-focused": {
            color: `${theme.colors.orange[700]} !important`
        },
        "& fieldset": {
            borderRadius: "50px",
            "& legend": {
                margin: "4px 0px 4px 2px"
            }
        },
        "& input": {
            padding: "12px 18px !important"
        },
        "& input, div": {
            fontSize: 14
        },
        "& label": {
            transition: "all 100ms linear !important"
        },
        "& label[data-shrink=true].Mui-focused": {
            color: `${theme.colors.orange[700]} !important`
        },
        "& label[data-shrink=true]": {
            padding: `4px${theme.browser.isSafari ? " 10px" : " 8px"}`,
            color: `${theme.colors.grey[900]} !important`,
            background: "white",
            fontWeight: 500,
            fontSize: "16px",
            top: -3,
            "& .MuiFormLabel-asterisk": {
                color: theme.colors.orange[700]
            }
        },
        "& label[data-shrink=false]": {
            paddingLeft: 7
        }
    },
    [`& .${classes.multiline}`]: {
        "& fieldset": {
            borderRadius: "20px !important"
        },
        "& .MuiInputBase-multiline": {
            paddingLeft: 20,
            alignItems: "flex-start",
            minHeight: 110
        }
    },
    [`& .${classes.info}`]: {
        margin: "0 20px",
        display: "flex",
        alignItems: "center",
        color: "rgba(34, 34, 34, 0.75)",
        fontSize: "11px",
        fontStyle: "italic",
        lineHeight: "15px",
        "& svg": {
            height: 12,
            marginRight: 8,
            "& path": {
                fill: "rgba(34, 34, 34, 0.75)"
            }
        }
    }
}));

export { Root, classes };
