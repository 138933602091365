import { styled } from "@mui/material/styles";

const classes = {
    modal: `modal`,
    iconCircle: `iconCircle`,
    icon: `icon`,
    closeButtonContainer: `closeButtonContainer`,
    closeButton: `closeButton`,
    sectionClose: `sectionClose`,
    message: `message`,
    note: `note`,
    hr: `hr`,
    actions: `actions`,
    dialogForm: `dialogForm`,
    textField: `textField`,
    fieldDescription: `fieldDescription`,
    conactInfo: `conactInfo`
};

const Root = styled("div")(({ theme }) => ({
    [`&.${classes.modal}`]: {
        position: "absolute",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: 550,
        background: "white",
        borderRadius: 20,
        boxShadow: "0px 10px 30px rgba(31, 19, 0, 0.3)"
    },
    [`& .${classes.iconCircle}`]: {
        width: 60,
        height: 60,
        borderRadius: "50%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        background: theme.colors.orange[100]
    },
    [`& .${classes.icon}`]: {
        width: 28,
        height: 28,
        "& path": {
            fill: theme.colors.orange[700]
        }
    },
    [`& .${classes.closeButtonContainer}`]: {
        marginTop: 20,
        marginRight: 20,
        height: 12,
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
    },
    [`& .${classes.closeButton}`]: {
        cursor: "pointer",
        "& path": {
            fill: theme.colors.grey[900]
        },
        "&:hover path": {
            fill: theme.colors.orange[700]
        },
        display: "flex",
        alignItems: "center"
    },
    [`& .${classes.sectionClose}`]: {
        width: "100%",
        display: "flex",
        flexDirection: "row-reverse"
    },
    [`& .${classes.message}`]: {
        width: "100%",
        marginTop: 24,
        marginBottom: 8,
        padding: "0 32px",
        fontSize: 20,
        fontWeight: 500,
        lineHeight: "28px",
        textAlign: "center"
    },
    [`& .${classes.note}`]: {
        marginBottom: 16,
        width: 486,
        fontSize: 11,
        lineHeight: "13px",
        textAlign: "center",
        fontStyle: "italic"
    },
    [`& .${classes.hr}`]: {
        width: 486
    },
    [`& .${classes.actions}`]: {
        marginTop: 20,
        marginBottom: 32,
        display: "flex",
        justifyContent: "space-between",
        flexDirection: "row",
        gap: 15
    },
    [`& .${classes.dialogForm}`]: {
        width: 486,
        paddingTop: 16,
        paddingBottom: 8,
        display: "flex",
        flexDirection: "column"
    },
    [`& .${classes.textField}`]: {
        // border: "1px solid #B4B3B3",
        "& div.Mui-focused": {
            "& fieldset": {
                borderColor: `${theme.colors.orange[700]} !important`
            }
        },
        "& label.Mui-focused": {
            color: `${theme.colors.orange[700]} !important`
        },
        "& input": {
            paddingLeft: 20
        },
        "& fieldset": {
            borderRadius: "50px",
            "& legend": {
                margin: "4px 0px 4px 10px"
            }
        },
        "& label": {
            transition: "all 100ms linear !important"
        },
        "& label[data-shrink=true].Mui-focused": {
            color: `${theme.colors.orange[700]} !important`
        },
        "& label[data-shrink=true]": {
            padding: `4px${theme.browser.isSafari ? " 10px" : " 8px"}`,
            color: `${theme.colors.grey[900]} !important`,
            background: "white",
            fontWeight: 500,
            fontSize: "16px",
            top: -3
        },
        "& label[data-shrink=false]": {
            paddingLeft: 10
        }
    },
    [`& .${classes.conactInfo}`]: {
        margin: "24px 0",
        "& p": {
            textAlign: "center",
            fontSize: 14,
            lineHeight: "20px",
            fontStyle: "italic",
            fontWeight: 700,
            color: "#000",
            "& a": {
                textDecoration: "underline",
                fontWeight: "400 !important",
                color: "#000"
            },
            "& + p": {
                marginTop: 5
            }
        }
    },
    [`& .${classes.fieldDescription}`]: {
        fontWeight: 400,
        fontSize: 14,
        lineHeight: "16px",
        textAlign: "center",
        fontStyle: "italic"
    }
}));

export { Root, classes };
