import { styled } from "@mui/material/styles";
import { Table } from "@mui/material";

const classes = {
    menuListTableRow: `menuListTableRow`,
    color1: `color1`,
    color2: `color2`,
    actions: `actions`,
    dragSection: `dragSection`,
    clockButton: `clockButton`,
    iconContainer: `iconContainer`,
    dragIconContainer: `dragIconContainer`,
    collections: `collections`,
    sorting: `sorting`,
    itemName: `itemName`,
    prices: `prices`,
    timeInfo: `timeInfo`,
    defaultIndicator: `defaultIndicator`,
    loader: `loader`,
    itemImage: `itemImage`,
    deletedOn: `deletedOn`,
    pdfIcon: `pdfIcon`,
    recoverMenu: `recoverMenu`,
    disablePrint: `disablePrint`,
    infoText: `infoText`
};

const Root = styled(Table)(({ theme }) => ({
    [`& .${classes.menuListTableRow}`]: {
        display: "table-row",
        width: "100%",
        borderRadius: "6px",
        "& td": {
            paddingLeft: 20,
            paddingRight: 10,
            fontWeight: 500,
            fontSize: 18,
            height: 70,
            width: "100%"
        }
    },
    [`& .${classes.color1}`]: {
        background: "#FFFFFF"
    },
    [`& .${classes.color2}`]: { background: "#FAFAFA" },
    [`& .${classes.actions}`]: {
        display: "flex",
        height: 70,
        alignItems: "center",
        "& svg": {
            cursor: "pointer",
            margin: "0 11.5px",
            width: 19,
            height: 19,
            "& path": {
                fill: "#9E9E9E"
            }
        },
        "& svg:hover": {
            "& path": {
                fill: theme.colors.orange[700]
            }
        },
        [`& .${classes.recoverMenu}`]: {
            whiteSpace: "nowrap",
            marginLeft: 13,
            minWidth: 93,
            background: "#727272",
            outlineColor: "#727272",
            outlineOffset: -1,
            border: "none",
            "&:hover": {
                background: "#3a3a3a"
            },
            "& svg": {
                margin: 0,
                width: 14,
                height: 14,
                "& path": {
                    fill: "#fff !important"
                }
            },
            [`&.${classes.disablePrint}`]: {
                background: "transparent !important",
                boxShadow: "none !important",
                color: "#d3d3d3",
                border: "1px solid #eaeaea",
                outline: "none",
                "&:hover": {
                    color: "#9e9e9e",
                    "& svg": {
                        "& path": {
                            fill: "#9e9e9e !important"
                        }
                    }
                },
                "& svg": {
                    "& path": {
                        transition: "fill 0.3s",
                        fill: "#d3d3d3 !important"
                    }
                }
            }
        }
    },
    [`& .${classes.dragSection}`]: {
        width: "5%"
    },
    [`& .${classes.clockButton}`]: {
        paddingLeft: "0 !important",
        "& svg": {
            cursor: "pointer",
            margin: "0 11.5px",
            width: 19,
            height: 19,
            "& path": {
                fill: "#9E9E9E"
            },
            transform: "translateY(2px)"
        },
        "& svg:hover": {
            "& path": {
                fill: theme.colors.orange[700]
            }
        }
    },
    [`& .${classes.iconContainer}`]: {
        transform: "translateY(2px)"
    },
    [`& .${classes.dragIconContainer}`]: {
        cursor: "grab",
        "&:hover": {
            "& > svg > path": {
                fill: theme.colors.grey[900]
            }
        },
        "& svg": {
            transform: "translateY(2px)"
        }
    },
    [`& .${classes.collections}`]: {
        fontWeight: 300,
        "& a": {
            // color: 'black !important',
            color: "black",
            textDecoration: "underline"
        },
        " > span": {
            border: "1px solid #d3d3d3",
            borderRadius: 16,
            display: "inline-flex",
            alignItems: "flex-end",
            justifyContent: "center",
            padding: "6px 10px",
            fontSize: 11,
            fontWeight: 500,
            marginLeft: 16,
            color: "#727272",
            "& > svg": {
                width: 12,
                height: 12,
                marginRight: 4,
                "& path": {
                    fill: "#727272"
                }
            }
        }
    },
    [`& .${classes.sorting}`]: {
        border: `1px solid ${theme.colors.grey[400]}`,
        boxShadow: "0px 5px 12px rgba(34, 34, 34, 0.15)",
        display: "inline-table !important"
    },
    [`& .${classes.itemName}`]: {
        fontSize: 18,
        fontWeight: 500
    },
    [`& .${classes.prices}`]: {
        fontSize: 16,
        fontWeight: 300,
        marginLeft: 16
    },
    [`& .${classes.timeInfo}`]: {
        fontFamily: "Roboto",
        fontSize: 16,
        width: 150,
        fontWeight: 300,
        lineHeight: "19px"
    },
    [`& .${classes.defaultIndicator}`]: {
        fontWeight: 300,
        fontSize: 18,
        lineHeight: "19px",
        marginLeft: 8
    },
    [`& .${classes.loader}`]: {
        height: 200,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        "& span": {
            width: "50px !important",
            height: "50px !important",
            color: theme.colors.orange[700]
        }
    },
    [`& .${classes.itemImage}`]: {
        maxHeight: 42,
        width: 84,
        borderRadius: 6,
        objectFit: "contain"
    },
    [`& .${classes.deletedOn}`]: {
        fontSize: 16,
        lineHeight: 1.17,
        fontWeight: 300,
        fontStyle: "italic",
        marginLeft: 5
    },
    [`& .${classes.pdfIcon}`]: {
        margin: "0 16px -3px 0"
    }
}));

const InfoText = styled("p")(({ theme }) => ({
    width: 486,
    fontSize: 14,
    lineHeight: "20px",
    textAlign: "center",
    fontStyle: "italic",
    display: "flex",
    flexDirection: "row",
    gap: 8,
    color: "#000",
    marginBottom: 16,
    "& svg": {
        "& path": {
            fill: "#000"
        }
    }
}));

export { Root, classes, InfoText };
