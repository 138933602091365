import { useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Logo from "../../assets/images/logo.svg";
import AuthGlobal from "../../context/store/global";
import { logoutUser } from "../../context/actions";
import useLocalStorage from "../../hooks/useLocalStorage";
import NavigationButton from "./NavigationButton";
import { Root, classes } from "../../assets/styles/Sidebar";
import { AlertsContext } from "../../context/store/Alerts";
import PublishButton from "../PublishButton";
import { Box } from "@mui/material";

const Sidebar = () => {
    const { pathname } = useLocation();
    const navigate = useNavigate();
    const { counter } = useContext(AlertsContext);
    const { removeItem } = useLocalStorage();
    const {
        dispatch,
        globalState: {
            current_restaurant: { all_day_menu, has_alerts },
            user_type
        }
    } = useContext(AuthGlobal);

    const getNavLink = (paths) => {
        return paths.some((path) => {
            if (path === pathname) return "active";
            if (path.includes(":")) {
                const pathComponents = path.split("/");
                const currentPathComponents = pathname.split("/");
                if (pathComponents.length !== currentPathComponents.length)
                    return "";
                if (
                    pathComponents
                        .map(
                            (x, i) =>
                                x[0] === ":" || x === currentPathComponents[i]
                        )
                        .every((x) => x)
                )
                    return "active";
                return "";
            }
            return "";
        });
    };

    const AlertsCounter = () => {
        return (
            counter > 0 && (
                <span
                    style={{
                        marginLeft: 8,
                        padding: "2px 7.5px",
                        color: "#FFFFFF",
                        fontWeight: 700,
                        fontSize: 14,
                        borderRadius: "50px",
                        background: "#D8000C",
                        marginRight: 10
                    }}
                >
                    {counter > 99 ? "+99" : counter}
                </span>
            )
        );
    };

    const logout = async () => {
        await logoutUser(dispatch);
        removeItem("sm_a_i");
        navigate(0);
    };

    const Buttons = [
        {
            targetPath: "/",
            icon: "menu",
            label: "Menus",
            paths: ["/", "/menu/create"],
            onClick: () => {}
        },
        user_type !== "view_only" && {
            targetPath: "/alerts",
            icon: "bell",
            label: "Alerts",
            hidden: !has_alerts,
            paths: ["/alerts"],
            onClick: () => {}
        },
        user_type !== "view_only" && {
            targetPath: "/items",
            icon: "item",
            label: "Items",
            paths: ["/items"],
            onClick: () => {}
        },
        user_type !== "view_only" && {
            targetPath: "/service-periods",
            icon: "clock",
            hidden: all_day_menu,
            label: "Service Periods",
            paths: [
                "/service-periods",
                "/service-periods/new",
                "/service-periods/:idCollection/update"
            ],
            onClick: () => {}
        },
        {
            targetPath: "/settings",
            icon: "settings",
            label: "Settings",
            paths: ["/settings"],
            onClick: () => {}
        },
        {
            targetPath: "",
            icon: "help",
            label: "Help",
            paths: [],
            onClick: () => {
                window.open("https://help.stellarmenus.com", "_blank").focus();
            }
        },
        {
            targetPath: "",
            icon: "logout",
            label: "Logout",
            paths: [],
            onClick: (e) => logout()
        }
    ].filter(Boolean); // This will filter out any false or undefined values

    return (
        <Root
            className={`${classes.sidebar} ${
                all_day_menu ? "no-collections" : "with-collections"
            } ${user_type === "view_only" ? "view_only" : ""}`}
        >
            <div className="img-container">
                <img src={Logo} className="logo-img" alt="logo" />
            </div>
            <ul>
                {Buttons.map((props, index) => (
                    <NavigationButton
                        key={index}
                        to={props.targetPath}
                        icon={props.icon}
                        isActive={getNavLink(props.paths)}
                        hidden={props.hidden}
                        label={props.label}
                        onClick={props.onClick}
                        decorator={
                            props.targetPath === "/alerts" && <AlertsCounter />
                        }
                    />
                ))}
            </ul>
            {user_type !== "view_only" && (
                <Box className={classes.publishWrapper}>
                    <PublishButton />
                </Box>
            )}
        </Root>
    );
};

export default Sidebar;
