import { fetchRequest } from "../index";

export const getTags = () => {
    return fetchRequest("/tag", { method: "GET" });
};

export const createTag = (tag) => {
    return fetchRequest("/tag", {
        method: "POST",
        body: JSON.stringify({
            ...tag
        })
    });
};

export const updateTag = (tag) => {
    return fetchRequest("/tag", {
        method: "PUT",
        body: JSON.stringify({
            ...tag
        })
    });
};
