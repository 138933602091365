import { Grid } from "@mui/material";
import { styled } from "@mui/material/styles";

const classes = {
    container: `container`,
    columnsContainer: `columnsContainer`,
    column: `column`,
    textField: `textField`,
    centered: `centered`,
    imageContainer: `imageContainer`,
    image: `image`,
    deleteImage: `deleteImage`,
    fileInput: `fileInput`,
    noImageText: `noImageText`,
    select: `select`,
    selectRow: `selectRow`,
    dropdown: `dropdown`,
    switchLabel: `switchLabel`,
    switch: `switch`,
    switchDisabled: `switchDisabled`,
    toggleLabel: `toggleLabel`,
    title: `title`,
    popper: `popper`,
    infoMessage: `infoMessage`,
    imageError: `imageError`,
    multiSelectRow: `multiSelectRow`,
    optionSelected: `optionSelected`,
    checkbox: `checkbox`,
    checkboxActive: `checkboxActive`,
    labelRow: `labelRow`,
    phoneWrapper: `phoneWrapper`
};

const RootExport = styled(Grid)(({ theme }) => ({
    [`& .${classes.textField}`]: {
        lineHeight: "44px !important",
        // border: "1px solid #B4B3B3",
        "& div.Mui-focused": {
            "& fieldset": {
                borderColor: `${theme.colors.orange[700]} !important`
            }
        },
        "& label.Mui-focused": {
            color: `${theme.colors.orange[700]} !important`
        },
        "& fieldset": {
            borderRadius: "50px",
            "& legend": {
                margin: "4px 0px 4px 2px"
            }
        },
        "& input": {
            padding: "12px 18px !important"
        },
        "& input, div": {
            fontSize: 14
        },
        "& label": {
            transition: "all 100ms linear !important"
        },
        "& label[data-shrink=true].Mui-focused": {
            color: `${theme.colors.orange[700]} !important`
        },
        "& label[data-shrink=true]": {
            padding: `4px${theme.browser.isSafari ? " 10px" : " 8px"}`,
            color: `${theme.colors.grey[900]} !important`,
            background: "white",
            fontWeight: 500,
            fontSize: "16px",
            top: -3,
            "& .MuiFormLabel-asterisk": {
                color: theme.colors.orange[700]
            }
        },
        "& label[data-shrink=false]": {
            paddingLeft: 7
        },
        "& .Mui-disabled+.MuiAutocomplete-inputRoot": {
            opacity: 0.4
        }
    },
    [`& .${classes.select}`]: {
        "& .MuiAutocomplete-input": {
            padding: "3px 8px !important"
        },
        "& .MuiAutocomplete-inputRoot": {
            padding: "10px 12px 8px 12px !important"
        }
    },
    [`& .${classes.selectRow}`]: {
        borderRadius: "20px !important",
        "& li": {
            height: 48,
            width: "100%",
            display: "flex !important",
            alignItems: "center !important",
            padding: "0 16px !important",
            background: "white !important",
            "&:hover": {
                background: `${theme.colors.orange[50]} !important`
            }
        },
        "& li[aria-selected='true']": {
            background: `${theme.colors.orange[50]} !important`,
            color: theme.colors.orange[900]
        },
        "& li.dropdown-selected": {
            background: `${theme.colors.orange[50]} !important`,
            color: `${theme.colors.orange[900]} !important`
        }
    }
}));
const Root = styled("div")(({ theme }) => ({
    [`&.${classes.container}`]: {
        padding: "30px 0",
        display: "flex",
        flexDirection: "column",
        width: "100%",
        position: "relative"
    },
    [`& .${classes.phoneWrapper}`]: {
        "& .textField": {
            width: "100%",
            "& input": {
                paddingLeft: "0 !important"
            }
        }
    },
    [`& .${classes.columnsContainer}`]: {
        display: "flex",
        flexDirection: "row",
        columnGap: 32,
        marginBottom: 32
    },
    [`& .${classes.column}`]: {
        display: "flex",
        flexDirection: "column",
        width: "100%"
    },
    [`& .${classes.textField}`]: {
        lineHeight: "44px !important",
        // border: "1px solid #B4B3B3",
        "& div.Mui-focused": {
            "& fieldset": {
                borderColor: `${theme.colors.orange[700]} !important`
            }
        },
        "& label.Mui-focused": {
            color: `${theme.colors.orange[700]} !important`
        },
        "& fieldset": {
            borderRadius: "50px",
            "& legend": {
                margin: "4px 0px 4px 2px"
            }
        },
        "& input": {
            padding: "12px 18px !important"
        },
        "& input, div": {
            fontSize: 14
        },
        "& label": {
            transition: "all 100ms linear !important"
        },
        "& label[data-shrink=true].Mui-focused": {
            color: `${theme.colors.orange[700]} !important`
        },
        "& label[data-shrink=true]": {
            padding: `4px${theme.browser.isSafari ? " 10px" : " 8px"}`,
            color: `${theme.colors.grey[900]} !important`,
            background: "white",
            fontWeight: 500,
            fontSize: "16px",
            top: -3,
            "& .MuiFormLabel-asterisk": {
                color: theme.colors.orange[700]
            }
        },
        "& label[data-shrink=false]": {
            paddingLeft: 7
        },
        "& .Mui-disabled+.MuiAutocomplete-inputRoot": {
            opacity: 0.4
        }
    },
    [`& .${classes.centered}`]: {
        textAlign: "center",
        fontWeight: 500,
        fontSize: 12
    },
    [`& .${classes.imageContainer}`]: {
        marginTop: 4,
        height: 150,
        border: "1px dashed rgba(180, 179, 179, 0.5)",
        borderRadius: 20,
        padding: 32,
        position: "relative",
        textAlign: "center",
        "&:hover": {
            "& svg:nth-of-type(2)": {
                "& path": {
                    fill: theme.colors.grey[700]
                }
            },
            "& p": {
                opacity: 1
            }
        }
    },
    [`& .${classes.image}`]: {
        maxWidth: "100%",
        height: "100%",
        objectFit: "scale-down"
    },
    [`& .${classes.deleteImage}`]: {
        position: "absolute",
        top: 16,
        right: 18,
        width: 16,
        cursor: "pointer",
        "& path": {
            fill: theme.colors.grey[500]
        },
        "&:hover": {
            "& path": {
                fill: theme.colors.grey[700]
            }
        }
    },
    [`& .${classes.fileInput}`]: {
        opacity: 0,
        position: "absolute",
        top: 0,
        left: 0,
        cursor: "pointer",
        height: "100%",
        width: "100%"
    },
    [`& .${classes.noImageText}`]: {
        fontWeight: 600,
        fontSize: 12,
        lineHeight: "14px",
        marginTop: 8,
        marginBottom: 0,
        opacity: 0.4,
        color: `${theme.colors.grey[900]}a6`
    },
    [`& .${classes.select}`]: {
        "& .MuiAutocomplete-input": {
            padding: "3px 8px !important"
        },
        "& .MuiAutocomplete-inputRoot": {
            padding: "10px 12px 8px 12px !important"
        }
    },
    [`& .${classes.selectRow}`]: {
        borderRadius: "20px !important",
        "& li": {
            height: 48,
            width: "100%",
            display: "flex !important",
            alignItems: "center !important",
            padding: "0 16px !important",
            background: "white !important",
            "&:hover": {
                background: `${theme.colors.orange[50]} !important`
            }
        },
        "& li[aria-selected='true']": {
            background: `${theme.colors.orange[50]} !important`,
            color: theme.colors.orange[900]
        },
        "& li.dropdown-selected": {
            background: `${theme.colors.orange[50]} !important`,
            color: `${theme.colors.orange[900]} !important`
        }
    },
    [`& .${classes.dropdown}`]: {
        "& .MuiSelect-select": {
            padding: "12px 0px 12px 16px !important"
        },
        "& .MuiSelect-nativeInput": {
            padding: "10px 12px 8px 12px !important",
            border: "none",
            opacity: "1 !important",
            transform: "translate(6px,-5px) "
        },
        lineHeight: "44px !important",
        border: "1px solid #B4B3B3",
        "& div.Mui-focused": {
            "& fieldset": {
                borderColor: `${theme.colors.orange[700]} !important`
            }
        },
        "& label.Mui-focused": {
            color: `${theme.colors.orange[700]} !important`
        },
        "& fieldset": {
            borderRadius: "50px",
            "& legend": {
                margin: "4px 0px 4px 2px"
            }
        },
        "& input": {
            padding: "12px 18px !important"
        },
        "& input, div": {
            fontSize: 14
        },
        "& label": {
            transition: "all 100ms linear !important"
        },
        "& label[data-shrink=true].Mui-focused": {
            color: `${theme.colors.orange[700]} !important`
        },
        "& label[data-shrink=true]": {
            padding: `4px${theme.browser.isSafari ? " 10px" : " 8px"}`,
            color: `${theme.colors.grey[900]} !important`,
            background: "white",
            fontWeight: 500,
            fontSize: "16px",
            top: -3,
            "& .MuiFormLabel-asterisk": {
                color: theme.colors.orange[700]
            }
        },
        "& label[data-shrink=false]": {
            paddingLeft: 7
        }
    },
    [`& .${classes.switchLabel}`]: {
        width: "fit-content !important",
        "& .MuiFormControlLabel-label": {
            fontSize: 14,
            lineHeight: "16px",
            color: theme.colors.grey[500]
        },
        "&:hover .MuiFormControlLabel-label": {
            color: theme.colors.grey[900]
        },
        "& > * > .Mui-disabled": {
            color: "#EAEAEA !important"
        }
    },
    [`& .${classes.switch}`]: {
        "& .MuiSwitch-switchBase:not(.Mui-disabled)": {
            color: `${theme.colors.orange[700]} !important`,
            "&.Mui-checked:hover": {
                backgroundColor: "rgba(250, 211, 184, 0.15) !important"
            }
        },
        "& .MuiSwitch-switchBase.Mui-disabled": {
            color: "#EAEAEA !important"
        },
        "& .MuiSwitch-track": {
            backgroundColor: `${theme.colors.orange[200]} !important`
        },
        "& .Mui-disabled+.MuiSwitch-track": {
            backgroundColor: `#EAEAEA !important`,
            opacity: "1 !important"
        }
    },
    [`& .${classes.switchDisabled}`]: {
        "&:hover": {
            backgroundColor: "rgba(250, 250, 250, 0.35) !important"
        }
    },
    [`& .${classes.toggleLabel}`]: {
        padding: "0",
        margin: 0,
        fontSize: 12,
        fontWeight: 500,
        lineHeight: "14px"
    },
    [`& .${classes.title}`]: {
        fontSize: "16px",
        fontWeight: 500,
        margin: 0,
        paddingBottom: 12,
        borderBottom: "1px solid rgba(180, 179, 179, 0.5)"
    },
    [`& .${classes.popper}`]: {
        border: `1px solid ${theme.colors.grey[100]}`,
        boxShadow: "0px 4px 12px rgba(34, 34, 34, 0.12)",
        borderRadius: "20px"
    },
    [`& .${classes.infoMessage}`]: {
        width: 486,
        fontSize: 11,
        display: "flex",
        flexDirection: "row",
        gap: 8,
        color: "rgba(34,34,34,0.75)",
        "& svg": {
            "& path": {
                fill: "rgba(34,34,34,0.75)"
            }
        }
    },
    [`& .${classes.imageError}`]: {
        color: "#D8000C",
        fontStyle: "italic",
        fontSize: 12,
        marginTop: 8
    },
    [`& .${classes.multiSelectRow}`]: {
        padding: "0 24px",
        cursor: "pointer",
        "&:hover": {
            background: theme.colors.grey[50]
        }
    },
    [`& .${classes.optionSelected}`]: {
        background: theme.colors.orange[50]
    },
    [`& .${classes.checkbox}`]: {
        "& path": {
            borderRadius: 4
        }
    },
    [`& .${classes.checkboxActive}`]: {
        color: `${theme.colors.orange[700]} !important`,
        "&.Mui-checked:hover": {
            backgroundColor: "rgba(250, 211, 184, 0.3)"
        }
    },
    [`& .${classes.labelRow}`]: {
        pointerEvents: "none",
        padding: "12px 0"
    }
}));

export { Root, classes, RootExport };
