import React, { useContext, useEffect, useState } from "react";
import {
    TextField,
    FormControlLabel,
    Switch,
    Collapse,
    CircularProgress,
    Table,
    TableBody,
    Box,
    FormLabel,
    RadioGroup,
    Radio
} from "@mui/material";
import { Root, classes } from "../../assets/styles/settings/PresenterSettings";
import _ from "lodash";
import AuthGlobal from "../../context/store/global";
import {
    getPresenterTemplate,
    refreshRestaurant,
    updatePresenterTemplate
} from "../../utils/Restaurants/restaurant-helper.js";
import { useAlert } from "../../hooks/useAlert.js";
import Icon from "../../components/UI/Icon.js";
import Alert from "../../components/UI/Alert.js";
import {
    CollapseRoot,
    classes as collapseClass
} from "../../assets/styles/CollapsibleTable";
import { useTheme } from "@mui/material";
import { useNavigate } from "react-router-dom";
import Button from "../../components/UI/Button";
import InfoMessage from "../../components/message/InfoMessage";
import ExitInfoMessage from "../../components/message/ExitInfoMessage";
import { usePrompt } from "../../utils/navigationBlocker";
import ConfirmAction from "../../components/modals/ConfirmAction";
import { runPublishCompiler } from "../../utils/Menus/menu-helper";

const MobileMenu = ({
    hasChildChanged,
    setHasChildChanged,
    setParentUpdate,
    onTabChange,
    newTabVal
}) => {
    const {
        dispatch,
        globalState: {
            current_restaurant: {
                identifier,
                social_media,
                landing_message,
                landing_button_text,
                landing_button_link,
                button_one_active,
                button_one_position,
                landing_button_two_text,
                landing_button_two_link,
                button_two_active,
                button_two_position,
                landing_button_three_text,
                landing_button_three_link,
                button_three_active,
                button_three_position
            },
            current_restaurant
        }
    } = useContext(AuthGlobal);
    let colorChangeTimeout;
    const [formData, setFormData] = useState({
        template_id: "1",
        landing: {
            message: "",
            buttonText: "",
            buttonLink: "",
            buttonOneActive: false,
            buttonOnePosition: "top",
            buttonTwoText: "",
            buttonTwoLink: "",
            buttonTwoActive: false,
            buttonTwoPosition: "top",
            buttonThreeText: "",
            buttonThreeLink: "",
            buttonThreeActive: false,
            buttonThreePosition: "top"
        },
        social: {
            bgColor: "",
            facebook: {
                active: false,
                color: "#3B5998",
                background: "#FFFFFF",
                link: ""
            },
            instagram: {
                active: false,
                color: "#E1306C",
                background: "#FFFFFF",
                link: ""
            },
            twitter: {
                active: false,
                color: "#1DA1F2",
                background: "#FFFFFF",
                link: ""
            },
            pinterest: {
                active: false,
                color: "#BD081C",
                background: "#FFFFFF",
                link: ""
            },
            yelp: {
                active: false,
                color: "#D32323",
                background: "#FFFFFF",
                link: ""
            },
            tiktok: {
                active: false,
                color: "#000000",
                background: "#FFFFFF",
                link: ""
            }
        },
        language_selector: {
            background_color: "",
            text_color: "",
            drawer_text_color: "",
            border_color: "",
            radio_color: ""
        },
        item_styles: {
            item_button: {
                background_color: "",
                border_color: "",
                text_color: "",
                border_width: "1px",
                border_radius: "6px"
            }
        }
    });

    const [errors, setErrors] = useState({
        template_id: "",
        "social.facebook.color": "",
        "social.instagram.color": "",
        "social.twitter.color": "",
        "social.pinterest.color": "",
        "social.yelp.color": "",
        "social.tiktok.color": "",
        "social.facebook.background": "",
        "social.instagram.background": "",
        "social.twitter.background": "",
        "social.pinterest.background": "",
        "social.yelp.background": "",
        "social.tiktok.background": ""
    });

    const [loading, setLoading] = useState(true);
    const [showFormError, setShowFormError] = useState(false);
    const [alert, handleAlert] = useAlert();
    const [hasChangesToSave, setHasChangesToSave] = useState(false);
    const [hasChanged, setHasChanged] = useState(false);
    const [showConfirmExitDialog, setShowConfirmExitDialog] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [targetLoc, setTargetLoc] = useState("");
    const navigate = useNavigate();
    const [disableBtn, setDisableBtn] = useState(false);

    const handleBlockExecution = (currentIsBlocked) => {
        if (currentIsBlocked) setShowConfirmExitDialog(true);
    };

    let { targetLocation } = usePrompt(hasChanged, handleBlockExecution);

    useEffect(() => {
        if (hasChildChanged) {
            setShowConfirmExitDialog(true);
        }
    }, [hasChildChanged]);

    useEffect(() => {
        setTargetLoc(targetLocation);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [targetLocation]);

    const handleChange = (target, event, type = "text") => {
        let newData = { ...formData };
        if (type !== "image") {
            newData = _.set(newData, target, event.target.value);
            setErrors((prev) => {
                return _.set(errors, target, "");
            });
            setShowFormError(false);
            setFormData(newData);
            setHasChangesToSave(true);
        }
    };

    useEffect(() => {
        if (hasChangesToSave) {
            setHasChanged(true);
            setParentUpdate(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formData]);

    useEffect(() => {
        (async () => {
            const { success, response } = await getPresenterTemplate(
                identifier
            );
            if (success && response) {
                const data = response.template;
                const bgColor =
                    data.social_media?.facebook?.background || "#FFFFFF";
                data.landing = {
                    message: landing_message,
                    buttonText: landing_button_text,
                    buttonLink: landing_button_link,
                    buttonOneActive: button_one_active,
                    buttonOnePosition: button_one_position,
                    buttonTwoText: landing_button_two_text,
                    buttonTwoLink: landing_button_two_link,
                    buttonTwoActive: button_two_active,
                    buttonTwoPosition: button_two_position,
                    buttonThreeText: landing_button_three_text,
                    buttonThreeLink: landing_button_three_link,
                    buttonThreeActive: button_three_active,
                    buttonThreePosition: button_three_position
                };
                data.social = {
                    facebook: {},
                    instagram: {},
                    twitter: {},
                    pinterest: {},
                    yelp: {},
                    tiktok: {}
                };
                data.social.bgColor = bgColor;
                if (social_media) {
                    for (const key of Object.keys(social_media)) {
                        data.social[key].link = social_media[key];
                        if (data.social_media) {
                            try {
                                data.social[key].color =
                                    data.social_media[key]?.color || "";
                                data.social[key].background =
                                    data.social_media[key]?.background ||
                                    "#FFFFFF";
                                data.social[key].active =
                                    data.social_media[key]?.show_social_media ||
                                    false;
                            } catch (e) {
                                console.log(e);
                            }
                        }
                    }
                }
                setFormData((prev) => _.merge(prev, data));
                setLoading(false);
            }
        })();
    }, [
        identifier,
        social_media,
        landing_button_link,
        landing_button_text,
        landing_message,
        button_one_active,
        button_one_position,
        landing_button_two_link,
        landing_button_two_text,
        button_two_active,
        button_two_position,
        landing_button_three_link,
        landing_button_three_text,
        button_three_active,
        button_three_position
    ]);

    const isValidColor = (val) => {
        if (typeof val !== "string") {
            return false;
        }
        const isHex = val.match("^#[0-9a-fA-F]+$");
        const isValidLength = [4, 7, 9].includes(val.length);
        return isHex && isValidLength;
    };

    const formInput = (label, name, { type = "text", multiline = false }) => {
        let value = _.get(formData, name);
        const errorState = _.get(errors, name)?.length > 0;
        return (
            <>
                <TextField
                    label={label}
                    value={value}
                    type={type !== "color" && type !== "image" ? type : "text"}
                    placeholder={type === "text" ? label : undefined}
                    InputLabelProps={{ shrink: true }}
                    className={`${classes.textField} ${
                        multiline ? classes.lessRadioBorder : ""
                    }`}
                    error={errorState}
                    fullWidth
                    helperText={_.get(errors, name)}
                    onChange={(e) => handleChange(name, e, type)}
                    style={{ margin: "15px 0" + (errorState ? " -7px" : "") }}
                    multiline={multiline}
                    rows={multiline ? 4 : 1}
                    InputProps={
                        type === "color"
                            ? {
                                  endAdornment: (
                                      <div
                                          style={{
                                              width: "22px",
                                              height: "22px",
                                              borderRadius: "50%",
                                              border: "1px solid #B4B3B3",
                                              background: value
                                          }}
                                      >
                                          <input
                                              className={
                                                  classes.invisibleColorPicker
                                              }
                                              style={{
                                                  padding: "0 !important"
                                              }}
                                              type="color"
                                              onChange={(e) => {
                                                  clearTimeout(
                                                      colorChangeTimeout
                                                  );
                                                  colorChangeTimeout =
                                                      setTimeout(() => {
                                                          handleChange(name, e);
                                                      }, 100);
                                              }}
                                          />
                                      </div>
                                  )
                              }
                            : type === "image"
                            ? {
                                  endAdornment: (
                                      <span className={classes.uploadButton}>
                                          <Icon name="publish" />
                                          <input
                                              type="file"
                                              className={
                                                  classes.invisibleColorPicker
                                              }
                                              onChange={(e) =>
                                                  handleChange(name, e, type)
                                              }
                                              style={{
                                                  position: "absolute",
                                                  padding: "0 !important"
                                              }}
                                          />
                                      </span>
                                  )
                              }
                            : {}
                    }
                />
            </>
        );
    };

    const toggleSwitch = (target) => {
        let newData = { ...formData };
        newData = _.set(newData, target, !_.get(newData, target));
        setFormData(newData);

        const buttonMappings = {
            "landing.buttonOneActive": "landing.buttonText",
            "landing.buttonTwoActive": "landing.buttonTwoText",
            "landing.buttonThreeActive": "landing.buttonThreeText"
        };
        const correspondingTextPath = buttonMappings[target];
        if (
            correspondingTextPath &&
            _.get(newData, correspondingTextPath) === ""
        ) {
            newData = _.set(newData, correspondingTextPath, "Button Text");
            setFormData(newData);
        }

        setHasChangesToSave(true);
    };

    const buttonPosition = (target, event) => {
        let newData = { ...formData };
        newData = _.set(newData, target, event.target.value);
        // setErrors((prev) => {
        //     return _.set(errors, target, "");
        // });
        // setShowFormError(false);
        setFormData(newData);
        setHasChangesToSave(true);
    };

    const handleSubmit = async (
        targetLoc = "",
        saveChanges = false,
        saveAndLeave = false
    ) => {
        setDisableBtn(true);
        // Validate fields
        const newErrors = {};
        let valid = true;
        Object.keys(errors).forEach((v) => {
            if (v === "template_id") {
                if (!["1", "2"].includes(_.get(formData, "template_id"))) {
                    newErrors["template_id"] =
                        "Invalid template, currently allowed values are '1' and '2'";
                    valid = false;
                }
                return false;
            }
            const color = _.get(formData, v);
            if (color?.length > 0 && !isValidColor(color)) {
                newErrors[v] = "Invalid color format";
                valid = false;
                return false;
            } else {
                newErrors[v] = "";
            }
        });
        setErrors(newErrors);
        if (!valid) {
            console.log(newErrors);
            setShowFormError(true);
            window.scrollTo({ top: 200 });
            return false;
        }
        // ---- End Validate ----
        // Process images
        let payload = {
            ...formData,
            // If the show logo is set to no, then remove the logo
            controls: {
                ...formData.controls
            }
        };
        // ---- End Image Processing
        // Call API to update
        const { success, response } = await updatePresenterTemplate(
            identifier,
            payload
        );
        if (success && response) {
            await refreshRestaurant(dispatch, identifier, current_restaurant);

            if (saveChanges) {
                handleAlert({
                    severity: "success",
                    title: "Success",
                    message: "Restaurant updated successfully"
                });
                await refreshRestaurant(
                    dispatch,
                    identifier,
                    current_restaurant
                );
                setShowModal(false);
                setTimeout(() => {
                    setHasChanged(false);
                    setParentUpdate(false);
                }, 500);
            }
            if (saveAndLeave) {
                setShowConfirmExitDialog(false);
                setTimeout(() => {
                    setHasChanged(false);
                    setParentUpdate(false);
                }, 200);
                if (hasChildChanged) {
                    setHasChildChanged(false);
                    setTimeout(() => {
                        onTabChange(newTabVal);
                    }, 500);
                } else {
                    setTimeout(() => {
                        if (targetLoc !== "") {
                            navigate(targetLoc);
                        } else {
                            navigate("/");
                        }
                    }, 500);
                }
            }
            setDisableBtn(false);
            setHasChangesToSave(false);
            return true;
        } else {
            handleAlert({
                severity: "error",
                title: "Error",
                message: "Something went wrong, please try again"
            });
            setDisableBtn(false);
            setShowModal(false);
            setShowConfirmExitDialog(false);

            return false;
        }
    };

    const publish = async () => {
        setDisableBtn(true);
        const { success, response } = await runPublishCompiler(identifier);
        if (success && response) {
            setHasChanged(false);
            setParentUpdate(false);
            await refreshRestaurant(dispatch, identifier, current_restaurant);
            handleAlert({
                severity: "success",
                title: "Success",
                message:
                    "Your changes have been <b>saved</b> and <b>published.</b>"
            });
            setDisableBtn(false);
            setShowModal(false);
        } else {
            handleAlert({
                severity: "error",
                title: "Error",
                message: response.message
            });
            setDisableBtn(false);
            setShowModal(false);
        }
    };

    const handleCombinedFunction = async () => {
        const isSuccessful = await handleSubmit();
        if (isSuccessful) {
            publish();
        }
    };

    const formSwitch = (label, name) => {
        const value = _.get(formData, name);
        return (
            <div style={{ height: 44, margin: "15px 0 0" }}>
                <p className={classes.toggleLabel}>{label}</p>
                <FormControlLabel
                    control={
                        <Switch
                            checked={value}
                            classes={{
                                switchBase: classes.switchDisabled
                            }}
                            className={value ? classes.switch : ""}
                        />
                    }
                    name={name}
                    onChange={() => toggleSwitch(name)}
                    label={value ? "Active" : "Inactive"}
                    className={classes.switchLabel}
                />
            </div>
        );
    };

    const formRadio = (label, name) => {
        const value = _.get(formData, name) || "top";
        return (
            <Box>
                <FormLabel
                    className={classes.toggleLabel}
                    sx={{
                        color: "inherit"
                    }}
                >
                    {label}
                </FormLabel>
                <RadioGroup
                    row
                    value={value}
                    onChange={(e) => buttonPosition(name, e)}
                >
                    <FormControlLabel
                        value="top"
                        control={
                            <Radio
                                sx={{
                                    color: "rgba(0, 0, 0, 0.6)",
                                    "&.Mui-checked": {
                                        color: theme.colors.primary
                                    },
                                    "&:hover": {
                                        backgroundColor:
                                            "rgba(204, 88, 3, 0.04)"
                                    }
                                }}
                            />
                        }
                        label="Top"
                        sx={{
                            "& .MuiFormControlLabel-label": {
                                fontSize: 14,
                                lineHeight: 1.2,
                                color: "#000"
                            }
                        }}
                    />
                    <FormControlLabel
                        value="bottom"
                        control={
                            <Radio
                                sx={{
                                    color: "rgba(0, 0, 0, 0.6)",
                                    "&.Mui-checked": {
                                        color: theme.colors.primary
                                    },
                                    "&:hover": {
                                        backgroundColor:
                                            "rgba(204, 88, 3, 0.04)"
                                    }
                                }}
                            />
                        }
                        label="Bottom"
                        sx={{
                            "& .MuiFormControlLabel-label": {
                                fontSize: 14,
                                lineHeight: 1.2,
                                color: "#000"
                            }
                        }}
                    />
                </RadioGroup>
            </Box>
        );
    };

    const theme = useTheme();

    const mobileMenuRows = [
        { name: "Landing Page Message", dataId: "message" },
        { name: "Button #1", dataId: "button-1" },
        { name: "Button #2", dataId: "button-2" },
        { name: "Button #3", dataId: "button-3" },
        { name: "Social Media Links", dataId: "links" }
    ];

    const [expanded, setExpanded] = useState(false);

    const toggleRow = React.useCallback((index) => {
        setExpanded((prevState) => ({
            ...prevState,
            [index]: !prevState[index]
        }));
    }, []);

    return !loading ? (
        <Root>
            <Alert {...alert} />
            <Collapse in={showFormError}>
                <div
                    style={{
                        fontSize: 14,
                        background: "#FAD3B8",
                        justifyContent: "center",
                        alignItems: "center",
                        display: "flex",
                        height: 40
                    }}
                >
                    Some errors were found in the form, please fix them and try
                    again
                </div>
            </Collapse>
            <Box
                component="h2"
                sx={{
                    fontSize: "18px",
                    margin: "32px 0 16px"
                }}
            >
                Restaurant Landing Page
            </Box>
            <div className="table-container">
                <Table>
                    <TableBody>
                        {mobileMenuRows.map((menuRows, index) => {
                            const isExpanded = expanded[index];
                            return (
                                <CollapseRoot
                                    key={index}
                                    className={`${collapseClass.container} ${
                                        isExpanded ? "active" : ""
                                    }`}
                                >
                                    <td
                                        style={{
                                            height: 75,
                                            display: "flex",
                                            alignItems: "center",
                                            width: "100%"
                                        }}
                                    >
                                        <span
                                            onClick={() => toggleRow(index)}
                                            className="expand_section"
                                            style={{
                                                height: "50px",
                                                display: "flex",
                                                alignItems: "center"
                                            }}
                                        >
                                            {isExpanded ? (
                                                <Icon
                                                    name={"arrowDown"}
                                                    className={
                                                        collapseClass.arrowDownButton
                                                    }
                                                />
                                            ) : (
                                                <Icon
                                                    name={"arrowRight"}
                                                    className={
                                                        collapseClass.arrowRightButton
                                                    }
                                                />
                                            )}
                                        </span>
                                        <span
                                            style={{
                                                fontSize: "16px",
                                                lineHeight: "1.2",
                                                flexGrow: 1,
                                                fontWeight: 400
                                            }}
                                        >
                                            {menuRows.name}
                                        </span>
                                    </td>
                                    <Collapse
                                        in={isExpanded}
                                        component="td"
                                        style={{ padding: 0 }}
                                    >
                                        <div
                                            style={{
                                                borderTop: "1px solid #eaeaea",
                                                margin: "0 20px 24px",
                                                padding: "24px 0 0"
                                            }}
                                        >
                                            {menuRows.dataId === "message" ? (
                                                formInput(
                                                    "Restaurant Page Message",
                                                    "landing.message",
                                                    {
                                                        multiline: true,
                                                        type: "text"
                                                    }
                                                )
                                            ) : menuRows.dataId ===
                                              "button-1" ? (
                                                <div
                                                    className={`${classes.columnsContainer} ${collapseClass.buttonContainer}`}
                                                >
                                                    <div
                                                        className={
                                                            classes.column
                                                        }
                                                        style={{ width: "14%" }}
                                                    >
                                                        {formSwitch(
                                                            "Button Display",
                                                            "landing.buttonOneActive"
                                                        )}
                                                    </div>
                                                    <div
                                                        className={
                                                            classes.column
                                                        }
                                                        style={{
                                                            lineHeight: 0,
                                                            width: "20%"
                                                        }}
                                                    >
                                                        {formRadio(
                                                            "Button Position",
                                                            "landing.buttonOnePosition"
                                                        )}
                                                    </div>
                                                    <div
                                                        className={
                                                            classes.column
                                                        }
                                                        style={{
                                                            alignSelf: "center",
                                                            width: "33%"
                                                        }}
                                                    >
                                                        {formInput(
                                                            "Button Text",
                                                            "landing.buttonText",
                                                            {
                                                                type: "text",
                                                                multiline: false
                                                            }
                                                        )}
                                                    </div>
                                                    <div
                                                        className={
                                                            classes.column
                                                        }
                                                        style={{
                                                            alignSelf: "center",
                                                            width: "33%"
                                                        }}
                                                    >
                                                        {formInput(
                                                            "Button Link",
                                                            "landing.buttonLink",
                                                            {
                                                                type: "text"
                                                            }
                                                        )}
                                                    </div>
                                                </div>
                                            ) : menuRows.dataId ===
                                              "button-2" ? (
                                                <div
                                                    className={`${classes.columnsContainer} ${collapseClass.buttonContainer}`}
                                                >
                                                    <div
                                                        className={
                                                            classes.column
                                                        }
                                                        style={{ width: "14%" }}
                                                    >
                                                        {formSwitch(
                                                            "Button Display",
                                                            "landing.buttonTwoActive"
                                                        )}
                                                    </div>
                                                    <div
                                                        className={
                                                            classes.column
                                                        }
                                                        style={{
                                                            lineHeight: 0,
                                                            width: "20%"
                                                        }}
                                                    >
                                                        {formRadio(
                                                            "Button Position",
                                                            "landing.buttonTwoPosition"
                                                        )}
                                                    </div>
                                                    <div
                                                        className={
                                                            classes.column
                                                        }
                                                        style={{
                                                            alignSelf: "center",
                                                            width: "33%"
                                                        }}
                                                    >
                                                        {formInput(
                                                            "Button Text",
                                                            "landing.buttonTwoText",
                                                            {
                                                                type: "text",
                                                                multiline: false
                                                            }
                                                        )}
                                                    </div>
                                                    <div
                                                        className={
                                                            classes.column
                                                        }
                                                        style={{
                                                            alignSelf: "center",
                                                            width: "33%"
                                                        }}
                                                    >
                                                        {formInput(
                                                            "Button Link",
                                                            "landing.buttonTwoLink",
                                                            {
                                                                type: "text"
                                                            }
                                                        )}
                                                    </div>
                                                </div>
                                            ) : menuRows.dataId ===
                                              "button-3" ? (
                                                <div
                                                    className={`${classes.columnsContainer} ${collapseClass.buttonContainer}`}
                                                >
                                                    <div
                                                        className={
                                                            classes.column
                                                        }
                                                        style={{ width: "14%" }}
                                                    >
                                                        {formSwitch(
                                                            "Button Display",
                                                            "landing.buttonThreeActive"
                                                        )}
                                                    </div>
                                                    <div
                                                        className={
                                                            classes.column
                                                        }
                                                        style={{
                                                            lineHeight: 0,
                                                            width: "20%"
                                                        }}
                                                    >
                                                        {formRadio(
                                                            "Button Position",
                                                            "landing.buttonThreePosition"
                                                        )}
                                                    </div>
                                                    <div
                                                        className={
                                                            classes.column
                                                        }
                                                        style={{
                                                            alignSelf: "center",
                                                            width: "33%"
                                                        }}
                                                    >
                                                        {formInput(
                                                            "Button Text",
                                                            "landing.buttonThreeText",
                                                            {
                                                                type: "text",
                                                                multiline: false
                                                            }
                                                        )}
                                                    </div>
                                                    <div
                                                        className={
                                                            classes.column
                                                        }
                                                        style={{
                                                            alignSelf: "center",
                                                            width: "33%"
                                                        }}
                                                    >
                                                        {formInput(
                                                            "Button Link",
                                                            "landing.buttonThreeLink",
                                                            {
                                                                type: "text"
                                                            }
                                                        )}
                                                    </div>
                                                </div>
                                            ) : menuRows.dataId === "links" ? (
                                                <div
                                                    className={
                                                        classes.columnsContainer
                                                    }
                                                >
                                                    <div
                                                        className={
                                                            classes.column
                                                        }
                                                        style={{
                                                            width: "50%",
                                                            gap: 15
                                                        }}
                                                    >
                                                        {formSwitch(
                                                            "Facebook Social Button",
                                                            "social.facebook.active"
                                                        )}
                                                        {formSwitch(
                                                            "Instagram Social Button",
                                                            "social.instagram.active"
                                                        )}
                                                        {formSwitch(
                                                            "Twitter Social Button",
                                                            "social.twitter.active"
                                                        )}
                                                        {formSwitch(
                                                            "Pinterest Social Button",
                                                            "social.pinterest.active"
                                                        )}
                                                        {formSwitch(
                                                            "Yelp Social Button",
                                                            "social.yelp.active"
                                                        )}
                                                        {formSwitch(
                                                            "TikTok Social Button",
                                                            "social.tiktok.active"
                                                        )}
                                                    </div>
                                                    <div
                                                        className={
                                                            classes.column
                                                        }
                                                        style={{ width: "70%" }}
                                                    >
                                                        {formInput(
                                                            "Facebook Icon Color",
                                                            "social.facebook.color",
                                                            {
                                                                type: "color"
                                                            }
                                                        )}
                                                        {formInput(
                                                            "Instagram Icon Color",
                                                            "social.instagram.color",
                                                            { type: "color" }
                                                        )}
                                                        {formInput(
                                                            "Twitter Icon Color",
                                                            "social.twitter.color",
                                                            {
                                                                type: "color"
                                                            }
                                                        )}
                                                        {formInput(
                                                            "Pinterest Icon Color",
                                                            "social.pinterest.color",
                                                            { type: "color" }
                                                        )}
                                                        {formInput(
                                                            "Yelp Icon Color",
                                                            "social.yelp.color",
                                                            {
                                                                type: "color"
                                                            }
                                                        )}
                                                        {formInput(
                                                            "TikTok Icon Color",
                                                            "social.tiktok.color",
                                                            {
                                                                type: "color"
                                                            }
                                                        )}
                                                    </div>
                                                    <div
                                                        className={
                                                            classes.column
                                                        }
                                                        style={{ width: "70%" }}
                                                    >
                                                        {formInput(
                                                            "Facebook Icon Background Color",
                                                            "social.facebook.background",
                                                            {
                                                                type: "color"
                                                            }
                                                        )}
                                                        {formInput(
                                                            "Instagram Icon Background Color",
                                                            "social.instagram.background",
                                                            { type: "color" }
                                                        )}
                                                        {formInput(
                                                            "Twitter Icon Background Color",
                                                            "social.twitter.background",
                                                            {
                                                                type: "color"
                                                            }
                                                        )}
                                                        {formInput(
                                                            "Pinterest Icon Background Color",
                                                            "social.pinterest.background",
                                                            { type: "color" }
                                                        )}
                                                        {formInput(
                                                            "Yelp Icon Background Color",
                                                            "social.yelp.background",
                                                            {
                                                                type: "color"
                                                            }
                                                        )}
                                                        {formInput(
                                                            "TikTok Icon Background Color",
                                                            "social.tiktok.background",
                                                            {
                                                                type: "color"
                                                            }
                                                        )}
                                                    </div>
                                                    <div
                                                        className={
                                                            classes.column
                                                        }
                                                    >
                                                        {formInput(
                                                            "Facebook Social Button Link",
                                                            "social.facebook.link",
                                                            { type: "text" }
                                                        )}
                                                        {formInput(
                                                            "Instagram Social Button Link",
                                                            "social.instagram.link",
                                                            { type: "text" }
                                                        )}
                                                        {formInput(
                                                            "Twitter Social Button Link",
                                                            "social.twitter.link",
                                                            { type: "text" }
                                                        )}
                                                        {formInput(
                                                            "Pinterest Social Button Link",
                                                            "social.pinterest.link",
                                                            { type: "text" }
                                                        )}
                                                        {formInput(
                                                            "Yelp Social Button Link",
                                                            "social.yelp.link",
                                                            {
                                                                type: "text"
                                                            }
                                                        )}
                                                        {formInput(
                                                            "TikTok Social Button Link",
                                                            "social.tiktok.link",
                                                            { type: "text" }
                                                        )}
                                                    </div>
                                                </div>
                                            ) : (
                                                ""
                                            )}
                                        </div>
                                    </Collapse>
                                </CollapseRoot>
                            );
                        })}
                    </TableBody>
                </Table>
            </div>
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "flex-end",
                    mt: 2
                }}
            >
                <Button
                    disableBtn={!hasChanged}
                    innerText="Save"
                    type="primary"
                    color="dark"
                    className="mobile_menu_save"
                    onClick={() => {
                        if (hasChanged) {
                            setShowModal(true);
                        }
                    }}
                />
            </Box>
            <ConfirmAction
                button1Text="Save Changes"
                button2Text="Leave Page"
                open={showConfirmExitDialog}
                actionConfirm={async () => {
                    handleSubmit(targetLoc || "", false, true);
                }}
                actionSecondButton={async () => {
                    setHasChanged(false);
                    setShowConfirmExitDialog(false);
                    if (hasChildChanged) {
                        setParentUpdate(false);
                        setHasChildChanged(false);
                        onTabChange(newTabVal);
                    } else {
                        setTimeout(() => {
                            if (targetLoc !== "") {
                                navigate(targetLoc);
                            } else {
                                navigate("/");
                            }
                        }, 200);
                    }
                }}
                actionCancel={() => {
                    setShowConfirmExitDialog(false);
                    setHasChildChanged(false);
                }}
                icon="error"
                dialogText="Unsaved changes. Do you want to Save?"
                note={ExitInfoMessage()}
                disableBtn={disableBtn}
            />
            <ConfirmAction
                button1Text="Save & Publish Changes"
                button2Text="Continue with Save"
                open={showModal}
                actionConfirm={handleCombinedFunction}
                actionSecondButton={() => {
                    handleSubmit("", true);
                }}
                actionCancel={() => setShowModal(false)}
                icon="publish"
                dialogText={`Would you like to Publish your changes?`}
                note={InfoMessage()}
                disableBtn={disableBtn}
            />
        </Root>
    ) : (
        <div
            style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: 500
            }}
        >
            <CircularProgress
                style={{ color: "#CC5803", width: 60, height: 60 }}
            />
        </div>
    );
};

export default MobileMenu;
