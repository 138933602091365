import { Chip } from "@mui/material";
import { styled } from "@mui/material/styles";

const classes = {
    tagBox: `tagBox`,
    tagDelete: `tagDelete`,
    buttonDelete: `buttonDelete`
};

const Root = styled(Chip)(({ theme, props }) => ({
    [`&.${classes.tagBox}`]: {
        "&.MuiChip-root": {
            backgroundColor: "#FAFAFA",
            fontWeight: 500,
            border: "1px solid #D3D3D3",
            marginRight: props.noMargin ? 0 : 10,
            marginBottom: props.noMargin ? 0 : 12,
            height: "auto",
            padding: "7px 0",
            fontSize: 11,
            "&:hover": {
                backgroundColor: "rgba(0, 0, 0, 0.04)"
            }
        },
        "&.Mui-disabled": {
            border: "none",
            backgroundColor: theme.colors.grey[100]
        }
    },
    [`&.${classes.tagDelete}`]: {
        "&.MuiChip-root": {
            backgroundColor: "#FDECDF",
            fontWeight: 500,
            border: "1px solid #FAD3B8",
            marginRight: 10,
            marginBottom: 12
        }
    },
    [`& .${classes.buttonDelete}`]: {
        display: "flex",
        paddingRight: 7,
        margin: "0 5px 0 -2px !important"
    }
}));

/**
 * Wrapper component for the MUI Chip component, intended to be used as a building block for
 * all the tags on the menu manager app as it includes the defined styles for the app
 * @param {string} label Text to display inside the tag
 * @param {function} onDelete Callback that runs when the delete icon is clicked
 * @param {function} onClick Callback on tag click
 * @param {JSX.Element} deleteIcon Optional parameter to customize the icon shown as a the delete button of the tag
 */
const Tag = (props) => {
    const {
        label,
        onDelete = null,
        deleteIcon,
        onClick,
        disabled = false,
        noMargin = false
    } = props;
    const data = { noMargin };

    const className =
        typeof onDelete === "function" ? classes.tagDelete : classes.tagBox;

    return (
        <Root
            props={data}
            label={label}
            component="span"
            clickable={typeof onClick === "function"}
            variant="outlined"
            disabled={disabled}
            onClick={onClick}
            onDelete={onDelete}
            deleteIcon={
                <span className={classes.buttonDelete}>{deleteIcon}</span>
            }
            className={className}
        />
    );
};

export default Tag;
