import Cookie from "js-cookie";
import { client } from "./apiClient";

const refreshSession = async () => {
    const token = Cookie.get("sm_c_t");
    const refreshToken = Cookie.get("sm_r_t");
    if (!token) {
        if (!refreshToken) {
            window.localStorage.removeItem("sm_a_i");
            return window.location.replace(`${process.env.REACT_APP_LOGIN}`);
        } else {
            return (async () => {
                const { success, response } = await client(
                    "user/refresh-token",
                    null,
                    "GET",
                    {
                        Authorization: `${refreshToken}`
                    }
                );
                if (success && response) {
                    const oneHour = new Date(
                        new Date().getTime() + 1 * 60 * 60 * 1000
                    );

                    Cookie.set(
                        "sm_c_t",
                        response.AuthenticationResult?.AccessToken,
                        {
                            domain: process.env.REACT_APP_COOKIE_DOMAIN,
                            expires: oneHour
                        }
                    );
                    return response.AuthenticationResult?.AccessToken;
                } else {
                    window.localStorage.removeItem("sm_a_i");
                    return window.location.replace(
                        `${process.env.REACT_APP_LOGIN}`
                    );
                }
            })();
        }
    }
};

const fetchRequest = async (
    requestPath,
    requestParams = {},
    contentType = "application/json"
) => {
    const token = Cookie.get("sm_c_t");
    try {
        const response = await fetch(
            `${process.env.REACT_APP_API_MM_URL}${requestPath}`,
            {
                mode: "cors",
                credentials: "same-origin",
                headers: {
                    "Content-Type": contentType,
                    Authorization: token
                },
                ...requestParams
            }
        );

        let returnResponse = response;
        if (
            contentType === "application/json" ||
            requestParams.method !== "GET"
        ) {
            returnResponse = await response.json();
            // If the response is 401, refresh the session and try again
            if (response.status === 401) {
                await refreshSession();
                try {
                    const token2 = Cookie.get("sm_c_t");
                    const response2 = await fetch(
                        `${process.env.REACT_APP_API_MM_URL}${requestPath}`,
                        {
                            mode: "cors",
                            credentials: "same-origin",
                            headers: {
                                "Content-Type": contentType,
                                Authorization: token2
                            },
                            ...requestParams
                        }
                    );

                    let returnResponse2 = response2;
                    if (
                        contentType === "application/json" ||
                        requestParams.method !== "GET"
                    ) {
                        returnResponse2 = await response2.json();
                    } else {
                        returnResponse2 = await response2.blob();
                    }

                    return {
                        success: response2.ok,
                        response: returnResponse2
                    };
                } catch (error) {
                    console.error(error);
                    return {
                        success: false,
                        error
                    };
                }
            }
        } else {
            returnResponse = await response.blob();
        }

        return {
            success: response.ok,
            response: returnResponse
        };
    } catch (error) {
        console.error(error);
        return {
            success: false,
            error
        };
    }
};

export default fetchRequest;
