export function formatItemPrice(
    {
        price_value,
        leading_text,
        trailing_text
    }: {
        price_value: number;
        leading_text: string;
        trailing_text: string;
    },
    currencyPosition: string,
    currencySymbol: string
): string {
    if (currencyPosition === "before") {
        return `${Boolean(leading_text) ? leading_text + " " : ""}${
            Boolean(price_value) && currencySymbol ? currencySymbol : ""
        }${Boolean(price_value) ? price_value : ""}${
            Boolean(trailing_text) ? " " + trailing_text : ""
        }`;
    }
    return `${Boolean(leading_text) ? leading_text + " " : ""}${
        Boolean(price_value) ? price_value : ""
    } ${Boolean(price_value) && currencySymbol ? currencySymbol : ""}${
        Boolean(trailing_text) ? " " + trailing_text : ""
    }`;
}
