import BoxLayout from "../../components/UI/BoxLayout";
import Button from "../../components/UI/Button";
import {
    Root,
    classes,
    BoxLabel,
    SectionsContainer
} from "../../assets/styles/pages/FormCreateMenu";
import { useNavigate, useParams } from "react-router-dom";
import {
    FormControlLabel,
    Switch,
    Table,
    TableBody,
    TextField
} from "@mui/material";
import Icon from "../../components/UI/Icon";
import MenuSettingsDrawer from "./MenuSettingsDrawer";
import { useContext, useEffect, useRef, useState } from "react";
import FormCreateSection from "../sections/FormCreateSection";
import SectionRow from "../sections/SectionRow";
import { SortableContainer, SortableElement } from "react-sortable-hoc";
import { arrayMoveImmutable } from "array-move";
import ConfirmAction from "../../components/modals/ConfirmAction";
import { useAlert } from "../../hooks/useAlert";
import FormUpdateItem from "../../components/items/FormUpdateItem";
import FormAddItems from "../../components/items/FormAddItems";
import {
    deleteSection,
    updateSection
} from "../../utils/Sections/section-helper";
import AuthGlobal from "../../context/store/global";
import {
    createMenu,
    refreshMenu,
    runPublishCompiler,
    updateMenuById
} from "../../utils/Menus/menu-helper";
import { refreshRestaurant } from "../../utils/Restaurants/restaurant-helper";
import Alert from "../../components/UI/Alert";
import CustomTooltip from "../../components/UI/CustomTooltip";
import PublishHeader from "../../components/UI/PublishHeader";
import { usePrompt } from "../../utils/navigationBlocker";
import {
    markAsActiveItem,
    markAsInactiveItem,
    updateItem
} from "../../utils/Items/item-helper";
import ExitInfoMessage from "../../components/message/ExitInfoMessage";
import InfoMessage from "../../components/message/InfoMessage";

const TableBodySortable = SortableContainer(({ children }) => (
    <TableBody
        style={{ width: "100%", display: "table" }}
        className="sortable-tbody"
    >
        {children}
    </TableBody>
));

const Row = SortableElement(({ data }) => {
    return (
        <SectionRow
            key={data.index}
            section={data.section}
            expanded={data.index === data.expandedIndex}
            setExpanded={data.setExpandedIndex}
            index={data.index}
            handleAddItemsForm={data.handleAddItemsForm}
            handleOpenEdit={data.handleOpenEdit}
            handleOpenDelete={data.handleOpenDelete}
            handleOpenTitleForm={data.handleOpenTitleForm}
            refreshList={data.refreshList}
            setSelectedItem={data.setSelectedItem}
            showEditItemForm={data.showEditItemForm}
            setShowEditItemForm={data.setShowEditItemForm}
            loading={data.loading}
            onUpdatedSection={data.onUpdatedSection}
            onSortSection={data.onSortSection}
            setNewItemsList={data.setNewItemsList}
            newItemsList={data.newItemsList}
            onRemoveItem={data.onRemoveItem}
            onToggleItem={data.onToggleItem}
            handleFindItemsById={data.handleFindItemsById}
            setHandleFindItemsById={data.setHandleFindItemsById}
            preveItemRefresh={data.preveItemRefresh}
            setPreveItemRefresh={data.setPreveItemRefresh}
        />
    );
});

const FormCreateMenu = ({ mode = "create", menu = {}, setMenu = () => {} }) => {
    const {
        dispatch,
        globalState: {
            current_restaurant: { identifier },
            current_restaurant
        }
    } = useContext(AuthGlobal);

    const { idMenu } = useParams();
    const navigate = useNavigate();
    const [selectedSection, setSelectedSection] = useState();
    const [active, setActive] = useState(true);
    const [name, setName] = useState("");
    const [showSettings, setShowSettings] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [showCreateSectionModal, setShowCreateSectionModal] = useState(false);
    const [showAddItemsModal, setShowAddItemsModal] = useState(false);
    const [sections, setSections] = useState([]);
    const [expandedIndex, setExpandedIndex] = useState(-1);
    const [editedIndex, setEditedIndex] = useState(-1);
    const [showEditItemForm, setShowEditItemForm] = useState(false);
    const [selectedItem, setSelectedItem] = useState({});
    const [itemsToAdd, setItemsToAdd] = useState([]);
    const [loading, setLoading] = useState(false);
    const [menuId, setMenuId] = useState("");
    const [firstLoad, setFirstLoad] = useState(true);
    const [handleCreateMenu, setHandleCreateMenu] = useState(true);
    const [type, setType] = useState("item");
    const [hasChanged, setHasChanged] = useState(false);
    const [showConfirmExitDialog, setShowConfirmExitDialog] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [disableBtn, setDisableBtn] = useState(false);
    const [targetLoc, setTargetLoc] = useState("");
    const [updatedSections, setUpdatedSections] = useState([]);
    const [unsavedSections, setUnsavedSections] = useState([]);
    const [updatedSectionStatus, setUpdatedSectionStatus] = useState([]);
    // const [sortSectionItems, setSortSectionItems] = useState([]);
    // const [removedSectionItems, setRemovedSectionItems] = useState([]);
    const [toggleSectionItems, setToggleSectionItems] = useState([]);
    const [deletedSection, setDeletedSection] = useState([]);
    const [newItemsList, setNewItemsList] = useState([]);
    // const [appendItemsList, setAppendItemsList] = useState([]);
    const [updatedItemsList, setUpdatedItemsList] = useState([]);
    const [handleFindItemsById, setHandleFindItemsById] = useState(false);
    const [preveItemRefresh, setPreveItemRefresh] = useState(false);

    const [alert, handleAlert] = useAlert();

    // ref object for New Menu input
    const newMenu = useRef();

    const handleBlockExecution = (currentIsBlocked) => {
        if (currentIsBlocked) setShowConfirmExitDialog(true);
    };

    let { targetLocation } = usePrompt(hasChanged, handleBlockExecution);

    useEffect(() => {
        setTargetLoc(targetLocation);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [targetLocation]);

    const onSortEnd = (arr) => {
        setExpandedIndex(-1);
        setSections((prev) =>
            arrayMoveImmutable(prev, arr.oldIndex, arr.newIndex)
        );
    };

    const [menuSettings, setMenuSettings] = useState({
        show_notations: false,
        show_logo: false,
        header_text: "",
        footer_text: ""
    });

    const handleOpenEditSectionModal = (section, index) => {
        setSelectedSection(section);
        setEditedIndex(index);
        setShowCreateSectionModal(true);
    };

    const handleOpenDeleteConfirmationModal = (index) => {
        setEditedIndex(index);
        setShowDeleteModal(true);
    };

    const handleOpenTitleForm = (index) => {
        setEditedIndex(index);
        setType("title");
        setShowAddItemsModal(true);
    };

    const handleAddItemsForm = (index) => {
        setEditedIndex(index);
        setType("item");
        setShowAddItemsModal(true);
    };

    const handleAppendItems = () => {
        appendItemsToSection(itemsToAdd.map(({ item }) => item));
        setItemsToAdd([]);
        setEditedIndex(-1);
        setShowAddItemsModal(false);
    };

    useEffect(() => {
        if (firstLoad) {
            if (mode === "create" && !menu._id) {
                setFirstLoad(false);
                newMenu.current.focus();
            } else {
                newMenu.current.focus();
                setMenuId(menu._id);
                setMenuSettings({
                    show_notations: menu.show_notations,
                    show_logo: menu.show_logo,
                    header_text: menu.header_text,
                    footer_text: menu.footer_text
                });
                setActive(menu.status === "active");
                setName(menu.internal_name);
                setSections(menu.section_links || []);
                setTimeout(() => {
                    setFirstLoad(false);
                }, 1000);
            }
        }
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [menu]);

    useEffect(() => {
        if (!firstLoad) {
            setHasChanged(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sections, menuSettings, name, active, identifier, menuId]);

    const appendItemsToSection = (items) => {
        setLoading(true);
        const section = sections[editedIndex];
        const item_links = section.item_links;
        let idx = item_links.at(-1)?.sort_order || 0;
        for (const item of items) {
            idx += 1;
            item_links.push({ item, sort_order: idx });
        }

        section.item_links = item_links;
        const appendItemsData = {
            sectionId: section._id,
            item_links: item_links.map((item, index) => ({
                item_id: item.item._id,
                sort_order: index + 1
            }))
        };
        // setAppendItemsList((prev) => [...prev, appendItemsData]);
        setNewItemsList((prev) => {
            const updatedList = [...prev]; // Create a copy of the previous array
            const sectionIndex = updatedList.findIndex(
                (item) => item.sectionId === appendItemsData.sectionId
            ); // Find the index of the object with the same sectionId

            if (sectionIndex !== -1) {
                // If sectionId exists, update the item_links array
                const existingItemLinks = updatedList[sectionIndex].item_links;
                const newItemLinks = appendItemsData.item_links.filter(
                    (newLink) =>
                        !existingItemLinks.some(
                            (existingLink) =>
                                existingLink.item_id === newLink.item_id
                        )
                );

                const updatedItemLinks = [
                    ...existingItemLinks,
                    ...newItemLinks
                ];

                const updatedOrderList = updatedItemLinks.map(
                    (link, index) => ({
                        ...link,
                        sort_order: index + 1
                    })
                );

                updatedList[sectionIndex].item_links = updatedOrderList;
            } else {
                // If sectionId is new, create a new object and add it to the array
                updatedList.push(appendItemsData);
            }
            return updatedList;
        });
        handleRefresh(section, editedIndex);
        setTimeout(() => {
            setLoading(false);
        }, 300);
    };

    const handleDeleteSection = async (index) => {
        const section = sections[index];
        setDisableBtn(true);
        setDeletedSection((prev) => [...prev, section._id]);
        const newSections = [...sections].filter((x, idx) => idx !== index);
        setSections(newSections);
        setShowDeleteModal(false);
        setDisableBtn(false);
    };

    const removeSections = async (target) => {
        let success, response;
        for (let { sectionId } of unsavedSections) {
            setDisableBtn(true);
            ({ success, response } = await deleteSection(
                sectionId,
                identifier
            ));
            if (success && response) {
                setDisableBtn(false);
                setHasChanged(false);
                setShowConfirmExitDialog(false);
                setTimeout(() => {
                    if (target !== "") {
                        navigate(target);
                    } else {
                        navigate("/");
                    }
                }, 200);
            } else {
                handleAlert({
                    severity: "error",
                    title: "Error",
                    message: "Couldn't delete the section, please try again."
                });
            }
        }
    };

    const handleRefresh = (obj, idx = -1) => {
        if (idx === -1) {
            setSections((prev) => [...prev, obj]);
        } else {
            const newSections = [...sections];
            newSections[idx] = obj;
            setSections(newSections);
        }
    };

    const handleUpdatedSectionRow = (rowData, rowId) => {
        // Check if rowId is present in updatedSections
        const statusEntry = updatedSections?.findIndex(
            (item) => item?.sectionId === rowId
        );

        if (
            statusEntry !== -1 &&
            updatedSections[statusEntry]?.sectionData?.status !==
                rowData?.status
        ) {
            // Get the status value from rowData and modify it in the updatedSections array
            setUpdatedSections((prevRow) => {
                const updatedRow = prevRow.map((row, index) => {
                    if (index !== statusEntry) return row; // Return unchanged if not the target row

                    return {
                        ...row,
                        sectionData: {
                            ...row?.sectionData,
                            status: rowData?.status
                        }
                    };
                });
                return updatedRow;
            });
        }

        setUpdatedSectionStatus((prevRow) => {
            const newObj = { rowData, rowId };
            if (prevRow) {
                const updatedList = [...prevRow]; // Create a copy of the previous array
                const sectionIndex = updatedList?.findIndex(
                    (item) => item.rowId === rowId
                ); // Find the index of the object with the same sectionId

                if (sectionIndex !== -1) {
                    // If sectionId exists, update the status

                    updatedList[sectionIndex].rowData = rowData;
                } else {
                    // If sectionId is new, create a new object and add it to the array
                    updatedList.push(newObj);
                }
                return updatedList;
            }
        });
    };

    const handleSortSectionItems = (sortData, sectionID) => {
        // setSortSectionItems((prevRow) => [...prevRow, { sortData, sectionID }]);
    };

    const handleRemoveSectionItem = (removedItem, sectionID) => {
        const removedItemsData = {
            sectionId: sectionID,
            ...removedItem
        };

        setNewItemsList((prev) => {
            const updatedList = [...prev]; // Create a copy of the previous array
            const sectionIndex = updatedList.findIndex(
                (item) => item.sectionId === removedItemsData.sectionId
            ); // Find the index of the object with the same sectionId

            if (sectionIndex !== -1) {
                // If sectionId exists, update the item_links array
                const existingItemLinks = updatedList[sectionIndex].item_links;
                const itemLinksToRemove = removedItemsData.item_links.map(
                    (link) => link.item_id
                );
                const updatedItemLinks = existingItemLinks
                    .filter((link) => itemLinksToRemove.includes(link.item_id))
                    .map((link, index) => ({ ...link, sort_order: index + 1 }));

                updatedList[sectionIndex].item_links = updatedItemLinks;
            } else {
                // If sectionId is new, create a new object and add it to the array
                updatedList.push(removedItemsData);
            }
            return updatedList;
        });

        // setRemovedSectionItems((prevRow) => [
        //     ...prevRow,
        //     { removedItem, sectionID }
        // ]);
    };

    const handleToggleSectionItem = (toggleItem, status) => {
        setToggleSectionItems((prevRow) => {
            const newObj = { toggleItem, status };
            const updatedList = [...prevRow]; // Create a copy of the previous array
            const itemIndex = updatedList.findIndex(
                (item) => item.toggleItem === toggleItem
            ); // Find the index of the object with the same itemId

            if (itemIndex !== -1) {
                // If itemId exists, update the status

                updatedList[itemIndex].status = status;
            } else {
                // If itemId is new, create a new object and add it to the array
                updatedList.push(newObj);
            }
            return updatedList;
        });
        setHasChanged(true);
    };

    const handleUpdatedSection = (sectionData, sectionId) => {
        // Check if sectionId is present in updatedSectionStatus
        const statusEntry = updatedSectionStatus?.findIndex(
            (item) => item?.rowId === sectionId
        );
        if (
            statusEntry !== -1 &&
            updatedSectionStatus[statusEntry]?.rowData?.status !==
                sectionData?.status
        ) {
            // Get the status value from sectionData and modify it in the updatedSectionStatus array
            setUpdatedSectionStatus((prevRow) => {
                const updatedRow = prevRow.map((row, index) => {
                    if (index !== statusEntry) return row; // Return unchanged if not the target row

                    return {
                        ...row,
                        rowData: {
                            ...row?.rowData,
                            status: sectionData?.status
                        }
                    };
                });
                return updatedRow;
            });
        }

        setUpdatedSections((prevRow) => {
            const newObj = { sectionData, sectionId };
            const updatedList = [...prevRow]; // Create a copy of the previous array
            const sectionIndex = updatedList.findIndex(
                (item) => item.sectionId === sectionId
            ); // Find the index of the object with the same sectionId

            if (sectionIndex !== -1) {
                // If sectionId exists, update the status

                updatedList[sectionIndex].sectionData = sectionData;
            } else {
                // If sectionId is new, create a new object and add it to the array
                updatedList.push(newObj);
            }
            return updatedList;
        });
    };

    const handleUnsavedSection = (sectionId) => {
        setUnsavedSections((prevSections) => [...prevSections, { sectionId }]);
    };

    const handleUpdateItems = async (itemData, itemId) => {
        setUpdatedItemsList((prevSections) => [
            ...prevSections,
            { itemData, itemId }
        ]);
        setHasChanged(true);
        // Append itemId to itemData
        itemData._id = itemId;

        const section = sections[expandedIndex];
        let item_links = section.item_links;
        item_links = item_links.map((itemLink) =>
            itemLink.item._id === itemId
                ? { ...itemLink, item: itemData }
                : itemLink
        );
        section.item_links = item_links;
        setPreveItemRefresh(false);
        handleRefresh(section, expandedIndex);
    };

    const handleSubmit = async (
        targetLoc = "",
        saveChanges = false,
        saveAndLeave = false
    ) => {
        setDisableBtn(true);
        let success, response, menuID;
        if (mode === "create" && !menu._id && handleCreateMenu) {
            if (!name) {
                setErrorMessage("* This field is required");
                handleAlert({
                    severity: "error",
                    title: "Error",
                    message: "<b>Menu Name</b> is required to save."
                });
                setDisableBtn(false);
                setShowModal(false);
                return false;
            }
            const body = {
                internal_name: name || "New Menu",
                display_name: name || "New Menu",
                show_notations: false,
                show_logo: false,
                status: "active",
                header_text: "",
                footer_text: ""
            };
            ({ success, response } = await createMenu(body, identifier));
            await refreshRestaurant(dispatch, identifier, current_restaurant);
            if (success && response) {
                setMenuId(response.menu._id);
                menuID = response.menu._id;
                setActive(true);
                setHandleCreateMenu(false);
            } else {
                handleAlert({
                    severity: "error",
                    title: "Error",
                    message: "Couldn't Create Menu, please try again"
                });
                setDisableBtn(false);
                setShowModal(false);
                setShowConfirmExitDialog(false);
                return false;
            }
        }

        // Handle Update Section
        if (updatedSections.length !== 0) {
            for (let { sectionData, sectionId } of updatedSections) {
                ({ success, response } = await updateSection(
                    sectionId,
                    sectionData,
                    identifier
                ));
                // Handele success && response
                if (success && response) {
                    delete response.section.__v;
                } else {
                    handleAlert({
                        severity: "error",
                        title: "Error",
                        message: "Couldn't save section, please try again"
                    });
                    setDisableBtn(false);
                    setShowModal(false);
                    setShowConfirmExitDialog(false);
                    return false;
                }
            }
            // Clear updatedSections after saving
            setUpdatedSections([]);
        }

        // Handle Update Section Status
        if (updatedSectionStatus.length !== 0) {
            for (let { rowData, rowId } of updatedSectionStatus) {
                ({ success, response } = await updateSection(
                    rowId,
                    rowData,
                    identifier
                ));
                if (success && response) {
                } else {
                    handleAlert({
                        severity: "error",
                        title: "Error",
                        message:
                            "Couldn't update the section status, please try again."
                    });
                    setDisableBtn(false);
                    setShowModal(false);
                    setShowConfirmExitDialog(false);
                    return false;
                }
            }
            // Clear updatedSectionsStatus after saving
            setUpdatedSectionStatus([]);
        }

        // Handle Toggle Section Item
        if (toggleSectionItems.length !== 0) {
            for (let { toggleItem, status } of toggleSectionItems) {
                ({ success, response } =
                    status === "active"
                        ? await markAsInactiveItem(toggleItem, identifier)
                        : await markAsActiveItem(toggleItem, identifier));

                if (success && response) {
                } else {
                    handleAlert({
                        severity: "error",
                        title: "Error",
                        message:
                            "Couldn't toggle the section items, please try again."
                    });
                    setDisableBtn(false);
                    setShowModal(false);
                    setShowConfirmExitDialog(false);
                    return false;
                }
            }
            // Clear toggleSectionItems after saving
            setToggleSectionItems([]);
        }

        // Handle Append/Remove/Sorting Items/Title
        if (newItemsList.length !== 0) {
            setHandleFindItemsById(true);
            for (let { sectionId, item_links } of newItemsList) {
                ({ success, response } = await updateSection(
                    sectionId,
                    { item_links: item_links },
                    identifier
                ));
                if (success && response) {
                } else {
                    handleAlert({
                        severity: "error",
                        title: "Error",
                        message: "Couldn't Add Items, please try again."
                    });
                    setDisableBtn(false);
                    setShowModal(false);
                    setShowConfirmExitDialog(false);
                    return false;
                }
            }
            // Clear newItemsList after saving
            setNewItemsList([]);
        }

        // Handle Update Items/Title
        if (updatedItemsList.length !== 0) {
            for (let { itemData, itemId } of updatedItemsList) {
                ({ success, response } = await updateItem(
                    itemData,
                    identifier,
                    itemId
                ));
                if (success && response) {
                } else {
                    handleAlert({
                        severity: "error",
                        title: "Error",
                        message: "Couldn't Update Items, please try again."
                    });
                    setDisableBtn(false);
                    setShowModal(false);
                    setShowConfirmExitDialog(false);
                    return false;
                }
            }
            // Clear updatedItemsList after saving
            setUpdatedItemsList([]);
        }

        // Handle Delete Sections
        if (deletedSection.length !== 0) {
            for (let sectionId of deletedSection) {
                ({ success, response } = await deleteSection(
                    sectionId,
                    identifier
                ));
                if (success && response) {
                } else {
                    handleAlert({
                        severity: "error",
                        title: "Error",
                        message:
                            "Couldn't delete the section, please try again."
                    });
                    setDisableBtn(false);
                    setShowModal(false);
                    setShowConfirmExitDialog(false);
                    return false;
                }
            }
            // Clear deletedSection after saving
            setDeletedSection([]);
        }

        // Update the Menu
        if (menuId || menuID) {
            ({ success, response } = await updateMenuById(
                menuId || menuID,
                {
                    internal_name: name || "New Menu",
                    display_name: name || "New Menu",
                    ...menuSettings,
                    status: active ? "active" : "draft",
                    section_links: sections.map((section, index) => ({
                        section_id: section._id,
                        sort_order: index + 1
                    }))
                },
                identifier
            ));
        }

        await refreshRestaurant(dispatch, identifier, current_restaurant);
        const refreshData = await refreshMenu(idMenu, identifier);
        if (refreshData) {
            setFirstLoad(true);
            setMenu && setMenu(refreshData);
        }
        if (success && response) {
            if (saveChanges) {
                handleAlert({
                    severity: "success",
                    title: "Success",
                    message: `Your changes have been <b>saved.</b>`
                });
                setShowModal(false);
                setTimeout(() => {
                    setHasChanged(false);
                }, 500);
            }
            if (saveAndLeave) {
                setShowConfirmExitDialog(false);
                setTimeout(() => {
                    setHasChanged(false);
                }, 500);
                setTimeout(() => {
                    if (targetLoc !== "") {
                        navigate(targetLoc);
                    } else {
                        navigate("/");
                    }
                }, 1500);
            }
            setDisableBtn(false);
            return true;
        } else {
            if (response?.error) {
                handleAlert({
                    severity: "error",
                    title: "Error",
                    message: response.error
                });
            }
            setDisableBtn(false);
            setShowModal(false);
            setShowConfirmExitDialog(false);

            return false;
        }
    };

    const publish = async () => {
        setDisableBtn(true);
        const { success, response } = await runPublishCompiler(identifier);
        if (success && response) {
            setHasChanged(false);
            await refreshRestaurant(dispatch, identifier, current_restaurant);
            handleAlert({
                severity: "success",
                title: "Success",
                message:
                    "Your changes have been <b>saved</b> and <b>published.</b>"
            });
            setDisableBtn(false);
            setShowModal(false);
        } else {
            handleAlert({
                severity: "error",
                title: "Error",
                message: response.message
            });
            setDisableBtn(false);
            setShowModal(false);
        }
    };

    const handleCombinedFunction = async () => {
        const isSuccessful = await handleSubmit();
        if (isSuccessful) {
            publish();
        }
    };

    return (
        <>
            <Alert {...alert} />
            <BoxLayout style={{ display: "flex", flexDirection: "column" }}>
                <Root className={classes.container}>
                    <PublishHeader />
                    <div className={classes.header}>
                        <CustomTooltip title="Back">
                            <span
                                className={classes.iconCircle}
                                onClick={() => {
                                    setTargetLoc("");
                                    if (hasChanged) {
                                        setShowConfirmExitDialog(true);
                                    } else {
                                        navigate("/");
                                    }
                                }}
                            >
                                <Icon name="back" />
                            </span>
                        </CustomTooltip>
                        <span className={classes.title}>
                            {mode === "create"
                                ? "Create New Menu"
                                : `Edit ${menu.internal_name}`}
                        </span>
                        <div>
                            <span>
                                <Button
                                    innerText="Menu Settings"
                                    type="secondary"
                                    color="dark"
                                    icon="settings2"
                                    onClick={() => setShowSettings(true)}
                                />
                            </span>
                            <span>
                                <Button
                                    // itemLoading={saveAddLoad}
                                    disableBtn={!hasChanged}
                                    innerText="Save"
                                    type="primary"
                                    color="dark"
                                    className="menu_editor_save"
                                    onClick={() => {
                                        if (hasChanged) {
                                            setShowModal(true);
                                        }
                                    }}
                                />
                            </span>
                        </div>
                    </div>
                </Root>
                <Root style={{ margin: "0 48px", display: "flex" }}>
                    <div style={{ width: "50%" }}>
                        <TextField
                            className={"menu_name " + classes.textField}
                            label="Menu Name"
                            value={name}
                            fullWidth
                            required
                            InputLabelProps={{ shrink: true }}
                            placeholder="New Menu"
                            inputRef={newMenu}
                            error={errorMessage.length > 0}
                            helperText={errorMessage}
                            onChange={(e) => {
                                setErrorMessage("");
                                setName(e.target.value);
                            }}
                            style={{
                                marginBottom: errorMessage.length === 0 ? 32 : 9
                            }}
                        />
                    </div>
                    <div style={{ marginLeft: 32 }}>
                        <p className={classes.toggleLabel}>Menu Status</p>
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={active}
                                    classes={{
                                        switchBase: classes.switchDisabled
                                    }}
                                    className={active ? classes.switch : ""}
                                />
                            }
                            label={active ? "Active" : "Inactive"}
                            onChange={() => setActive((val) => !val)}
                            className={classes.switchLabel}
                        />
                    </div>
                </Root>
                <BoxLabel className={classes.boxLabel}>Menu Structure</BoxLabel>
                <SectionsContainer className={classes.sectionsContainer}>
                    <Table className="sortable-tbody-create-menu">
                        <TableBodySortable
                            onSortEnd={onSortEnd}
                            useDragHandle
                            helperClass={classes.sorting}
                            lockAxis="y"
                            helperContainer={() =>
                                document.querySelector(
                                    ".sortable-tbody-create-menu"
                                )
                            }
                        >
                            {sections.map((section, index) => {
                                return (
                                    <Row
                                        key={index}
                                        index={index}
                                        data={{
                                            section,
                                            index,
                                            refreshList: (obj) =>
                                                handleRefresh(obj, index),
                                            expandedIndex: expandedIndex,
                                            setExpandedIndex: setExpandedIndex,
                                            handleOpenEdit:
                                                handleOpenEditSectionModal,
                                            handleOpenDelete:
                                                handleOpenDeleteConfirmationModal,
                                            handleOpenTitleForm,
                                            showEditItemForm,
                                            setShowEditItemForm,
                                            setSelectedItem,
                                            handleAddItemsForm,
                                            loading,
                                            onUpdatedSection:
                                                handleUpdatedSectionRow,
                                            onSortSection:
                                                handleSortSectionItems,
                                            onRemoveItem:
                                                handleRemoveSectionItem,
                                            onToggleItem:
                                                handleToggleSectionItem,
                                            handleFindItemsById,
                                            setHandleFindItemsById,
                                            preveItemRefresh,
                                            setPreveItemRefresh,
                                            setNewItemsList,
                                            newItemsList
                                        }}
                                    />
                                );
                            })}
                        </TableBodySortable>
                    </Table>
                    <div
                        className={"add_section " + classes.addSectionButton}
                        onClick={() => {
                            setSelectedSection({});
                            setEditedIndex(-1);
                            setShowCreateSectionModal(true);
                        }}
                    >
                        <Icon name="plus" />
                        Add Section
                    </div>
                </SectionsContainer>
                <MenuSettingsDrawer
                    open={showSettings}
                    values={menuSettings}
                    setValues={setMenuSettings}
                    handleClose={() => setShowSettings(false)}
                />
                {showCreateSectionModal && (
                    <FormCreateSection
                        editedIndex={editedIndex}
                        section={selectedSection}
                        open={showCreateSectionModal}
                        handleClose={() => {
                            setShowCreateSectionModal(false);
                        }}
                        refreshList={handleRefresh}
                        onUpdatedSection={handleUpdatedSection}
                        onNewSection={handleUnsavedSection}
                    />
                )}
                <ConfirmAction
                    actionConfirm={() => handleDeleteSection(editedIndex)}
                    actionCancel={() => setShowDeleteModal(false)}
                    dialogText="Are you sure you want to delete this section? It will be gone for good."
                    icon="trash"
                    open={showDeleteModal}
                    disableBtn={disableBtn}
                />
                <FormUpdateItem
                    show={showEditItemForm}
                    item={selectedItem}
                    handleItemSave={false}
                    onUpdateItems={handleUpdateItems}
                    onClose={(_, alertData, _newItem) => {
                        setShowEditItemForm(false);
                        handleAlert(alertData);
                    }}
                />
                <FormAddItems
                    handleClose={() => {
                        setItemsToAdd([]);
                        setShowAddItemsModal(false);
                    }}
                    handleSave={handleAppendItems}
                    type={type}
                    open={showAddItemsModal}
                    itemsToAdd={itemsToAdd}
                    setItemsToAdd={setItemsToAdd}
                    currentItems={sections
                        .at(editedIndex)
                        ?.item_links?.map((x) => x.item._id)}
                />
                <ConfirmAction
                    button1Text="Save Changes"
                    button2Text="Leave Page"
                    open={showConfirmExitDialog}
                    actionConfirm={async () => {
                        handleSubmit(targetLoc || "", false, true);
                    }}
                    actionSecondButton={async () => {
                        if (unsavedSections.length !== 0) {
                            removeSections(targetLoc);
                        } else {
                            setHasChanged(false);
                            setShowConfirmExitDialog(false);
                            setTimeout(() => {
                                if (targetLoc !== "") {
                                    navigate(targetLoc);
                                } else {
                                    navigate("/");
                                }
                            }, 200);
                        }
                    }}
                    actionCancel={() => {
                        setShowConfirmExitDialog(false);
                    }}
                    icon="error"
                    dialogText="Unsaved changes. Do you want to Save?"
                    note={ExitInfoMessage()}
                    disableBtn={disableBtn}
                />
                <ConfirmAction
                    button1Text="Save & Publish Changes"
                    button2Text="Continue with Save"
                    open={showModal}
                    actionConfirm={handleCombinedFunction}
                    actionSecondButton={() => {
                        handleSubmit("", true);
                    }}
                    actionCancel={() => setShowModal(false)}
                    icon="publish"
                    dialogText={`Would you like to Publish your changes?`}
                    note={InfoMessage()}
                    disableBtn={disableBtn}
                />
            </BoxLayout>
        </>
    );
};

export default FormCreateMenu;
