import { useContext, useState, useEffect } from "react";
import AuthGlobal from "../../../context/store/global";
import { saveImage } from "../../../utils/mediaHelper";
import {
    refreshRestaurant,
    updateRestaurant
} from "../../../utils/Restaurants/restaurant-helper";
import {
    Root,
    classes
} from "../../../assets/styles/pages/settings/tabs/Restaurant.js";
import {
    Autocomplete,
    Box,
    Checkbox,
    FormControlLabel,
    Switch,
    TextField
} from "@mui/material";
import Icon from "../../../components/UI/Icon";
import { getCollections } from "../../../utils/Collections/collection-helper";
import timezones from "../../../utils/timezones";
import { useAlert } from "../../../hooks/useAlert";
import _ from "lodash";
import ConfirmAction from "../../../components/modals/ConfirmAction";
import Alert from "../../../components/UI/Alert";
import { CURRENCY_PLACEMENT, CURRENCY_SYMBOLS } from "../../../utils/currency";
import Button from "../../../components/UI/Button";
import ExitInfoMessage from "../../../components/message/ExitInfoMessage";
import InfoMessage from "../../../components/message/InfoMessage";
import { useNavigate } from "react-router-dom";
import { usePrompt } from "../../../utils/navigationBlocker";
import { runPublishCompiler } from "../../../utils/Menus/menu-helper";
import { MuiTelInput, matchIsValidTel } from "mui-tel-input";

const languages = {
    es: {
        name: "Spanish",
        endonym: "Español"
    },
    en: {
        name: "English",
        endonym: "English"
    }
};

const POS_PLATFORMS = [
    {
        value: "chowly",
        label: "Chowly"
    },
    {
        value: "stream",
        label: "Stream"
    }
];

const defaultPOSPlatform = POS_PLATFORMS[1];
const POS = [
    {
        value: "toast",
        label: "Toast"
    },
    {
        value: "clover",
        label: "Clover"
    },
    {
        value: "square",
        label: "Square"
    }
];
const defaultPOS = POS[0];
const RestaurantTab = ({
    firstLoad,
    hasChildChanged,
    setHasChildChanged,
    setParentUpdate,
    onTabChange,
    newTabVal
}) => {
    const {
        dispatch,
        globalState: {
            current_restaurant: { identifier },
            current_restaurant,
            user
        }
    } = useContext(AuthGlobal);
    const [displayName, setDisplayName] = useState("");
    const [logo, setLogo] = useState("");
    const [timezone, setTimezone] = useState(null);
    const [currency, setCurrency] = useState(null);
    const [currencyPlacement, setCurrencyPlacement] = useState(null);
    const [defaultCollection, setDefaultCollection] = useState(null);
    const [enableCollections, setEnableCollections] = useState(false);
    const [enablePOS, setEnablePOS] = useState(false);
    const [enablePOSSync, setEnablePOSSync] = useState(false);
    const [posPlatform, setPosPlatform] = useState(defaultPOS);
    const [posName, setPosName] = useState(defaultPOSPlatform);
    const [posIntegrationId, setPosIntegrationId] = useState("");
    const [address1, setAddress1] = useState("");
    const [address2, setAddress2] = useState("");
    const [city, setCity] = useState("");
    const [state, setState] = useState("");
    const [zipCode, setZipCode] = useState("");
    const [country, setCountry] = useState("");
    const [enableAutoSchedule, setEnableAutoSchedule] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [phoneErrorMessage, setPhoneErrorMessage] = useState("");
    const [file, setFile] = useState(null);
    const [imageError, setImageError] = useState("");
    const [deleteImage, setDeleteImage] = useState(false);
    const [activeCollections, setActiveCollections] = useState([]);
    const [showConfirmDefault, setShowConfirmDefault] = useState(false);
    const [auxCollection, setAuxCollection] = useState(null);
    const [auxTimezone, setAuxTimezone] = useState(null);
    const [noCollectionError, setNoCollectionError] = useState("");
    const [noTimezoneError, setNoTimezoneError] = useState("");
    const [confirmEnableAutoSchedule, setConfirmEnableAutoSchedule] =
        useState(false);
    const [confirmDisableAutoSchedule, setConfirmDisableAutoSchedule] =
        useState(false);
    const [defaultLanguage, setDefaultLanguage] = useState(null);
    const [availableLanguages, setAvailableLanguages] = useState([]);
    const [hasAlerts, setHasAlerts] = useState(false);
    const [hasPrint, setHasPrint] = useState(false);
    const [hasChanged, setHasChanged] = useState(false);
    const [showConfirmExitDialog, setShowConfirmExitDialog] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [targetLoc, setTargetLoc] = useState("");
    const navigate = useNavigate();
    const [disableBtn, setDisableBtn] = useState(false);
    const [payload, setPayload] = useState({});

    const [phoneNumber, setPhoneNumber] = useState("");

    const [alert, handleAlert] = useAlert();
    const handleBlockExecution = (currentIsBlocked) => {
        if (currentIsBlocked) setShowConfirmExitDialog(true);
    };

    useEffect(() => {
        if (hasChildChanged) {
            setShowConfirmExitDialog(true);
        }
    }, [hasChildChanged]);

    let { targetLocation } = usePrompt(hasChanged, handleBlockExecution);

    useEffect(() => {
        setTargetLoc(targetLocation);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [targetLocation]);

    useEffect(() => {
        if (defaultLanguage && !availableLanguages?.includes(defaultLanguage)) {
            setAvailableLanguages((prev) => [...prev, defaultLanguage]);
        }
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [defaultLanguage, availableLanguages]);

    useEffect(() => {
        setDisplayName(current_restaurant.display_name);
        setPhoneNumber(current_restaurant.ohwaiter_phone_number);
        setLogo(current_restaurant.logo_image);

        current_restaurant.timezone &&
            setTimezone(
                timezones.find(
                    (tz) => current_restaurant.timezone === tz.tzName
                )
            );
        if (current_restaurant.currency_symbol) {
            setCurrency(
                CURRENCY_SYMBOLS.find(
                    (c) => c.currency === current_restaurant.currency_symbol
                ) || CURRENCY_SYMBOLS[0]
            );
        } else {
            setCurrency(CURRENCY_SYMBOLS[0]);
        }

        if (current_restaurant.currency_placement) {
            setCurrencyPlacement(
                CURRENCY_PLACEMENT.find(
                    (c) => c.placement === current_restaurant.currency_placement
                ) || CURRENCY_PLACEMENT[0]
            );
        } else {
            setCurrencyPlacement(CURRENCY_PLACEMENT[0]);
        }

        if (
            activeCollections.length > 0 &&
            current_restaurant.default_collection
        ) {
            setDefaultCollection(
                activeCollections.find(
                    (c) => c.value === current_restaurant.default_collection
                ) || null
            );
        }
        setEnableCollections(!current_restaurant.all_day_menu);
        setEnableAutoSchedule(current_restaurant.use_schedules);
        setAvailableLanguages(current_restaurant.available_languages || []);
        setDefaultLanguage(current_restaurant.source_language || "en");
        setEnablePOS(Boolean(current_restaurant.pos_integration));
        setPosPlatform(
            POS_PLATFORMS.find(
                (platform) =>
                    platform.value === current_restaurant.integration_platform
            ) || defaultPOSPlatform
        );
        setEnablePOSSync(Boolean(current_restaurant.pos_sync));
        setPosName(
            POS.find(
                (platform) => platform.value === current_restaurant.pos_name
            ) || defaultPOS
        );
        setPosIntegrationId(current_restaurant.integration_id || "");
        setHasAlerts(Boolean(current_restaurant.has_alerts));
        setHasPrint(Boolean(current_restaurant.has_prints));
        setAddress1(current_restaurant?.address?.address_line_1 || "");
        setAddress2(current_restaurant?.address?.address_line_2 || "");
        setCity(current_restaurant?.address?.city || "");
        setState(current_restaurant?.address?.state || "");
        setZipCode(current_restaurant?.address?.postal_code || "");
        setCountry(current_restaurant?.address?.country || "");
        // eslint-disable-next-line
    }, [current_restaurant, activeCollections, identifier]);

    const handleSubmit = async (
        targetLoc = "",
        saveChanges = false,
        saveAndLeave = false
    ) => {
        setDisableBtn(true);
        if (displayName.length <= 0) {
            if (displayName.length <= 0) {
                setErrorMessage("* Please, enter the restaurant name");
            }
            handleAlert({
                severity: "error",
                title: "Error",
                message: "Something went wrong, please try again"
            });
            setDisableBtn(false);
            setShowModal(false);
            setShowConfirmExitDialog(false);
            return false;
        }

        const { success, response } = await updateRestaurant(
            identifier,
            payload
        );
        if (success && response) {
            if (saveChanges) {
                handleAlert({
                    severity: "success",
                    title: "Success",
                    message: "Restaurant updated successfully"
                });
                await refreshRestaurant(
                    dispatch,
                    identifier,
                    current_restaurant
                );
                setShowModal(false);
                setTimeout(() => {
                    setHasChanged(false);
                    setParentUpdate(false);
                }, 500);
            }
            if (saveAndLeave) {
                setShowConfirmExitDialog(false);
                setTimeout(() => {
                    setHasChanged(false);
                    setParentUpdate(false);
                }, 200);
                if (hasChildChanged) {
                    setHasChildChanged(false);
                    setTimeout(() => {
                        onTabChange(newTabVal);
                    }, 500);
                } else {
                    setTimeout(() => {
                        if (targetLoc !== "") {
                            navigate(targetLoc);
                        } else {
                            navigate("/");
                        }
                    }, 500);
                }
            }
            setDisableBtn(false);
            return true;
        } else {
            handleAlert({
                severity: "error",
                title: "Error",
                message: "Something went wrong, please try again"
            });
            setDisableBtn(false);
            setShowModal(false);
            setShowConfirmExitDialog(false);

            return false;
        }
    };

    const publish = async () => {
        setDisableBtn(true);
        const { success, response } = await runPublishCompiler(identifier);
        if (success && response) {
            setHasChanged(false);
            setParentUpdate(false);
            await refreshRestaurant(dispatch, identifier, current_restaurant);
            handleAlert({
                severity: "success",
                title: "Success",
                message:
                    "Your changes have been <b>saved</b> and <b>published.</b>"
            });
            setDisableBtn(false);
            setShowModal(false);
        } else {
            handleAlert({
                severity: "error",
                title: "Error",
                message: response.message
            });
            setDisableBtn(false);
            setShowModal(false);
        }
    };

    const handleCombinedFunction = async () => {
        const isSuccessful = await handleSubmit();
        if (isSuccessful) {
            publish();
        }
    };

    useEffect(() => {
        let successUpload = true,
            logo_url = "";

        const fetchData = async () => {
            try {
                if (file) {
                    [successUpload, logo_url] = await saveImage(
                        file,
                        identifier,
                        "logos"
                    );
                } else if (!deleteImage) {
                    logo_url = current_restaurant.logo_image || "";
                }
                if (!successUpload) {
                    logo_url = "";
                    handleAlert({
                        severity: "error",
                        title: "Error",
                        message:
                            "There was a problem while trying to save your restaurant logo, please try again in a few seconds"
                    });
                    return;
                } else {
                    const currents = _.pick(current_restaurant, [
                        "display_name",
                        "ohwaiter_phone_number",
                        "logo_image",
                        "timezone",
                        "currency_symbol",
                        "currency_placement",
                        "default_collection",
                        "all_day_menu",
                        "use_schedules",
                        "source_language",
                        "available_languages",
                        "pos_integration",
                        "integration_platform",
                        "integration_id",
                        "has_alerts",
                        "has_prints",
                        "address",
                        "pos_name",
                        "pos_sync"
                    ]);
                    const payloadData = {
                        display_name: displayName,
                        ohwaiter_phone_number: phoneNumber,
                        logo_image: logo_url,
                        timezone: timezone?.tzName || "",
                        currency_symbol: currency?.currency || "",
                        currency_placement: currencyPlacement?.placement || "",
                        default_collection:
                            defaultCollection?.value ||
                            current_restaurant.default_collection,
                        all_day_menu: !enableCollections,
                        use_schedules: enableAutoSchedule,
                        source_language: defaultLanguage || "en",
                        available_languages: availableLanguages,
                        pos_integration: enablePOS,
                        pos_sync: enablePOSSync,
                        pos_name: posName?.value || "",
                        integration_platform: posPlatform?.value || "",
                        integration_id: posIntegrationId || "",
                        has_alerts: hasAlerts,
                        has_prints: hasPrint,
                        address: {
                            address_line_1: address1,
                            address_line_2: address2,
                            city: city,
                            state: state,
                            postal_code: zipCode,
                            country: country
                        }
                    };
                    setPayload(payloadData);

                    if (
                        Object.keys(payloadData).some(
                            (key) => !_.isEqual(payloadData[key], currents[key])
                        )
                    ) {
                        setHasChanged(true);
                        setParentUpdate(true);
                    } else {
                        setHasChanged(false);
                        setParentUpdate(false);
                    }
                }
            } catch (error) {
                console.error("Error in fetchData:", error);
            }
        };
        if (!firstLoad) {
            fetchData();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        phoneNumber,
        displayName,
        logo,
        timezone,
        currency,
        currencyPlacement,
        defaultCollection,
        enableCollections,
        enableAutoSchedule,
        identifier,
        defaultLanguage,
        availableLanguages,
        enablePOS,
        posPlatform,
        posIntegrationId,
        hasAlerts,
        hasPrint,
        address1,
        address2,
        city,
        state,
        zipCode,
        country,
        posName,
        enablePOSSync
    ]);

    useEffect(() => {
        getCollections(identifier, "A-Z").then(({ success, response }) => {
            if (success && response) {
                setActiveCollections(
                    response.map((c) => ({
                        label: c.internal_name,
                        value: c._id
                    }))
                );
            }
        });
    }, [identifier]);

    const handleImageChange = (e) => {
        if (e.target.files[0]?.size) {
            if (e.target.files[0].size > 5000000) {
                setImageError(
                    "*Your file was too large to upload. The maximum file size is 5 MB."
                );
                setFile(null);
                setLogo("");
                e.target.value = null;
                return;
            }
            if (
                ![
                    "image/png",
                    "image/jpg",
                    "image/jpeg",
                    "image/svg+xml"
                ].includes(e.target.files[0].type)
            ) {
                setImageError(
                    "*Unsupported file type. Please upload only a JPG, PNG, or SVG file."
                );
                setFile(null);
                setLogo("");
                e.target.value = null;
                return;
            }
            const url = URL.createObjectURL(e.target.files[0]);
            setFile(e.target.files[0]);
            setLogo(url);
            e.target.value = null;
        }
    };

    const confirmServicePeriodToggle = () => {
        if (!auxCollection) {
            setNoCollectionError("* Please, select a default collection");
        } else {
            setNoCollectionError("");
            setEnableCollections(false);
            setDefaultCollection(auxCollection);
            setShowConfirmDefault(false);
        }
    };

    const toggleLanguage = (lang) => {
        let newLangs = [...availableLanguages];
        if (newLangs.includes(lang)) {
            newLangs = newLangs.filter((x) => x !== lang);
        } else {
            newLangs.push(lang);
        }
        setAvailableLanguages(newLangs);
    };

    const cancelServicePeriodToggle = () => {
        setEnableCollections(true);
        setShowConfirmDefault(false);
        setNoCollectionError("");
    };

    const handleConfirmAutoSchedule = () => {
        if (!auxCollection || !auxTimezone) {
            !auxCollection &&
                setNoCollectionError("* Please, select a default collection");
            !auxTimezone && setNoTimezoneError("* Please, select a timezone");
            return;
        }
        setNoCollectionError("");
        setNoTimezoneError("");
        setDefaultCollection(auxCollection);
        setTimezone(auxTimezone);
        setEnableAutoSchedule(true);
        setConfirmEnableAutoSchedule(false);
    };

    const handleCancelAutoSchedule = () => {
        setConfirmEnableAutoSchedule(false);
        setNoCollectionError("");
        setNoTimezoneError("");
    };

    const handleNumberChange = (newVal) => {
        const valid = matchIsValidTel(newVal);
        setPhoneNumber(newVal);
        if (newVal.length > 0 && !valid) {
            setPhoneErrorMessage("Phone number is not valid!");
        } else {
            setPhoneErrorMessage("");
        }
    };

    const user_role =
        user.user_permissions.find(
            (x) => x.identifier.identifier === identifier
        )?.user_role || "user";

    return (
        <>
            <Alert {...alert} />
            <Root className={classes.container}>
                <div className={classes.columnsContainer}>
                    <div className={classes.column}>
                        <TextField
                            label="Restaurant Name"
                            required
                            value={displayName}
                            error={errorMessage.length > 0}
                            helperText={errorMessage}
                            onChange={(e) => {
                                setErrorMessage("");
                                setDisplayName(e.target.value);
                            }}
                            placeholder="Enter Restaurant Name"
                            InputLabelProps={{ shrink: true }}
                            className={classes.textField}
                            style={{
                                marginBottom: errorMessage.length === 0 ? 32 : 9
                            }}
                        />
                        <span className={classes.centered}>
                            Restaurant logo
                        </span>
                        <div className={classes.imageContainer}>
                            <input
                                type="file"
                                title=""
                                className={classes.fileInput}
                                onChange={handleImageChange}
                            />

                            <Icon
                                name="remove"
                                className={classes.deleteImage}
                                style={logo ? {} : { display: "none" }}
                                onClick={() => {
                                    setLogo("");
                                    setDeleteImage(true);
                                    setFile(null);
                                }}
                            />
                            {logo && (
                                <img
                                    className={classes.image}
                                    src={logo}
                                    alt="Restaurant logo"
                                />
                            )}
                            {!logo && (
                                <>
                                    <Icon name="noImage" />
                                    <p className={classes.noImageText}>
                                        Upload or Drag Image. JPG, PNG or SVG -
                                        5 MB max.
                                    </p>
                                </>
                            )}
                        </div>
                        <span className={classes.imageError}>{imageError}</span>
                    </div>
                    <div className={classes.column}>
                        <Autocomplete
                            fullWidth
                            disablePortal
                            value={timezone}
                            onChange={(_e, v) => setTimezone(v)}
                            options={timezones}
                            disableClearable={true}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Restaurant Time Zone"
                                    placeholder="Choose Your Time Zone"
                                    InputLabelProps={{ shrink: true }}
                                    className={`${classes.select} ${classes.textField}`}
                                />
                            )}
                            componentsProps={{
                                popper: {
                                    className: classes.popper
                                },
                                paper: {
                                    className: classes.selectRow
                                }
                            }}
                            isOptionEqualToValue={(option, value) =>
                                option.label === value.label
                            }
                            style={{ marginBottom: 32 }}
                        />
                        <Autocomplete
                            fullWidth
                            disablePortal
                            value={currency}
                            onChange={(_e, v) => setCurrency(v)}
                            options={CURRENCY_SYMBOLS}
                            disableClearable={true}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Currency Symbol"
                                    placeholder="Choose Your Currency Symbol"
                                    InputLabelProps={{ shrink: true }}
                                    className={`${classes.select} ${classes.textField}`}
                                />
                            )}
                            componentsProps={{
                                popper: {
                                    className: classes.popper
                                },
                                paper: {
                                    className: classes.selectRow
                                }
                            }}
                            isOptionEqualToValue={(option, value) =>
                                option.label === value.label
                            }
                            style={{ marginBottom: 32 }}
                        />
                        <Autocomplete
                            fullWidth
                            disablePortal
                            value={currencyPlacement}
                            onChange={(_e, v) => setCurrencyPlacement(v)}
                            options={CURRENCY_PLACEMENT}
                            disableClearable={true}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Currency Placement"
                                    placeholder="Choose Your Currency Placement"
                                    InputLabelProps={{ shrink: true }}
                                    className={`${classes.select} ${classes.textField}`}
                                />
                            )}
                            componentsProps={{
                                popper: {
                                    className: classes.popper
                                },
                                paper: {
                                    className: classes.selectRow
                                }
                            }}
                            isOptionEqualToValue={(option, value) =>
                                option.label === value.label
                            }
                            style={{ marginBottom: 32 }}
                        />
                        <Autocomplete
                            fullWidth
                            value={defaultCollection}
                            onChange={(_e, v) => setDefaultCollection(v)}
                            disablePortal
                            disableClearable={true}
                            disabled={!enableCollections}
                            options={activeCollections}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Default Service Period"
                                    placeholder="Select Service Period"
                                    InputLabelProps={{ shrink: true }}
                                    className={`${classes.select} ${classes.textField}`}
                                />
                            )}
                            componentsProps={{
                                popper: {
                                    className: classes.popper
                                },
                                paper: {
                                    className: classes.selectRow
                                }
                            }}
                            isOptionEqualToValue={(option, value) =>
                                option.label === value.label
                            }
                            style={{ marginBottom: 32, marginTop: 10 }}
                        />
                        <p className={classes.toggleLabel}>Service Periods</p>
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={enableCollections}
                                    classes={{
                                        switchBase: classes.switchDisabled
                                    }}
                                    className={
                                        enableCollections ? classes.switch : ""
                                    }
                                />
                            }
                            label={enableCollections ? "Active" : "Inactive"}
                            onChange={() => {
                                if (!enableCollections) {
                                    setEnableCollections(true);
                                } else {
                                    setAuxCollection(defaultCollection);
                                    setShowConfirmDefault(true);
                                }
                            }}
                            className={classes.switchLabel}
                        />
                    </div>
                </div>
                {enableCollections && (
                    <>
                        <p className={classes.title}>Service Period Settings</p>
                        <div style={{ margin: "28px 0" }}>
                            <p className={classes.toggleLabel}>Auto-Schedule</p>
                            <FormControlLabel
                                control={
                                    <Switch
                                        checked={enableAutoSchedule}
                                        classes={{
                                            switchBase: classes.switchDisabled
                                        }}
                                        className={
                                            enableAutoSchedule
                                                ? classes.switch
                                                : ""
                                        }
                                        disabled={!enableCollections}
                                    />
                                }
                                label={
                                    enableAutoSchedule ? "Active" : "Inactive"
                                }
                                onChange={() => {
                                    if (enableAutoSchedule) {
                                        setConfirmDisableAutoSchedule(true);
                                    } else {
                                        setAuxTimezone(timezone);
                                        setAuxCollection(defaultCollection);
                                        setConfirmEnableAutoSchedule(true);
                                    }
                                }}
                                className={classes.switchLabel}
                            />
                        </div>
                    </>
                )}
                {(user.role === "super_admin" ||
                    user_role === "customer_support") && (
                    <>
                        <p className={classes.title}>Language Settings</p>
                        <div style={{ marginTop: 28 }}>
                            <div
                                className={classes.columnsContainer}
                                style={{ margin: 0 }}
                            >
                                <div className={classes.column}>
                                    <Autocomplete
                                        fullWidth
                                        disablePortal
                                        value={defaultLanguage}
                                        onChange={(_e, v) => {
                                            setDefaultLanguage(v);
                                        }}
                                        options={Object.keys(languages)}
                                        disableClearable={true}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="Default Language"
                                                placeholder="English - (English)"
                                                InputLabelProps={{
                                                    shrink: true
                                                }}
                                                className={`${classes.select} ${classes.textField}`}
                                            />
                                        )}
                                        renderOption={(_props, option) => {
                                            _props.getAttribute = (attr) =>
                                                _props["data-option-index"];
                                            return (
                                                <div
                                                    key={option}
                                                    onClick={() =>
                                                        _props.onClick({
                                                            currentTarget:
                                                                _props
                                                        })
                                                    }
                                                    className={`${
                                                        classes.multiSelectRow
                                                    } ${
                                                        option ===
                                                        defaultLanguage
                                                            ? classes.optionSelected
                                                            : ""
                                                    }`}
                                                    style={{ padding: "12px" }}
                                                >
                                                    {languages[option].name} -{" "}
                                                    <i>
                                                        (
                                                        {
                                                            languages[option]
                                                                .endonym
                                                        }
                                                        )
                                                    </i>
                                                </div>
                                            );
                                        }}
                                        getOptionLabel={(option) =>
                                            `${languages[option]?.name} - (${languages[option]?.endonym})`
                                        }
                                        componentsProps={{
                                            popper: {
                                                className: classes.popper
                                            },
                                            paper: {
                                                className: classes.selectRow
                                            }
                                        }}
                                        isOptionEqualToValue={(option, value) =>
                                            option === value
                                        }
                                        style={{ marginBottom: 32 }}
                                    />
                                </div>
                                <div className={classes.column}>
                                    <Autocomplete
                                        fullWidth
                                        disablePortal
                                        value={availableLanguages}
                                        onChange={(_e, v) => {
                                            setAvailableLanguages(v);
                                        }}
                                        options={Object.keys(languages)}
                                        disableClearable={true}
                                        multiple
                                        renderTags={(value) => {
                                            return (
                                                <span style={{ marginLeft: 8 }}>
                                                    {value
                                                        .map(
                                                            (x) =>
                                                                languages[x]
                                                                    .name
                                                        )
                                                        .join(", ")}
                                                </span>
                                            );
                                        }}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="Menu Translations"
                                                placeholder={
                                                    availableLanguages.length ===
                                                    0
                                                        ? "Select Languages"
                                                        : ""
                                                }
                                                InputLabelProps={{
                                                    shrink: true
                                                }}
                                                className={`${classes.select} ${classes.textField}`}
                                            />
                                        )}
                                        getOptionLabel={(option) =>
                                            `${languages[option]?.name} - (${languages[option]?.endonym})`
                                        }
                                        renderOption={(_props, option) => {
                                            const active =
                                                availableLanguages.includes(
                                                    option
                                                );
                                            return (
                                                <div
                                                    key={option}
                                                    onClick={() => {
                                                        if (
                                                            option !==
                                                            defaultLanguage
                                                        ) {
                                                            toggleLanguage(
                                                                option
                                                            );
                                                        } else {
                                                            handleAlert({
                                                                severity:
                                                                    "info",
                                                                title: "You can't remove this option!",
                                                                message: `You can't remove ${languages[option]?.name} from your menu tranlations because it is set as your default language`
                                                            });
                                                        }
                                                    }}
                                                    className={`${
                                                        classes.multiSelectRow
                                                    } ${
                                                        active
                                                            ? classes.optionSelected
                                                            : ""
                                                    }`}
                                                >
                                                    <FormControlLabel
                                                        control={
                                                            <Checkbox
                                                                checked={active}
                                                                icon={
                                                                    <Icon name="checkboxEmpty" />
                                                                }
                                                                checkedIcon={
                                                                    <Icon name="checkboxActive" />
                                                                }
                                                                className={`${
                                                                    classes.checkbox
                                                                } ${
                                                                    active
                                                                        ? classes.checkboxActive
                                                                        : ""
                                                                }`}
                                                                style={{
                                                                    pointerEvents:
                                                                        "auto"
                                                                }}
                                                            />
                                                        }
                                                        label={
                                                            <span>
                                                                {
                                                                    languages[
                                                                        option
                                                                    ]?.name
                                                                }{" "}
                                                                -{" "}
                                                                <i>
                                                                    (
                                                                    {
                                                                        languages[
                                                                            option
                                                                        ]
                                                                            ?.endonym
                                                                    }
                                                                    )
                                                                </i>
                                                            </span>
                                                        }
                                                        className={
                                                            classes.labelRow
                                                        }
                                                    />
                                                </div>
                                            );
                                        }}
                                        componentsProps={{
                                            popper: {
                                                className: classes.popper
                                            },
                                            paper: {
                                                className: classes.selectRow
                                            }
                                        }}
                                        isOptionEqualToValue={(option, value) =>
                                            option === value
                                        }
                                        style={{ marginBottom: 32 }}
                                    />
                                </div>
                            </div>
                        </div>
                    </>
                )}
                {(user.role === "super_admin" ||
                    user_role === "customer_support") && (
                    <div>
                        <p className={classes.title}>Integration Settings</p>
                        <div style={{ marginTop: 28 }}>
                            <div
                                className={classes.columnsContainer}
                                style={{ margin: "0 0 20px 0" }}
                            >
                                <div className={classes.column}>
                                    <p className={classes.toggleLabel}>
                                        POS Integration
                                    </p>
                                    <FormControlLabel
                                        control={
                                            <Switch
                                                checked={enablePOS}
                                                classes={{
                                                    switchBase:
                                                        classes.switchDisabled
                                                }}
                                                className={
                                                    enablePOS
                                                        ? classes.switch
                                                        : ""
                                                }
                                            />
                                        }
                                        label={
                                            enablePOS ? "Active" : "Inactive"
                                        }
                                        onChange={() =>
                                            setEnablePOS(!enablePOS)
                                        }
                                        className={classes.switchLabel}
                                    />
                                </div>
                                <div className={classes.column}>
                                    <p className={classes.toggleLabel}>
                                        POS Sync
                                    </p>
                                    <FormControlLabel
                                        control={
                                            <Switch
                                                checked={enablePOSSync}
                                                disabled={!enablePOS}
                                                classes={{
                                                    switchBase:
                                                        classes.switchDisabled
                                                }}
                                                className={
                                                    enablePOSSync
                                                        ? classes.switch
                                                        : ""
                                                }
                                            />
                                        }
                                        label={
                                            enablePOSSync
                                                ? "Active"
                                                : "Inactive"
                                        }
                                        onChange={() =>
                                            setEnablePOSSync(!enablePOSSync)
                                        }
                                        className={classes.switchLabel}
                                    />
                                </div>
                                <div className={classes.column}>
                                    <Autocomplete
                                        fullWidth
                                        value={posName}
                                        onChange={(_e, v) => setPosName(v)}
                                        options={POS}
                                        disableClearable={true}
                                        disablePortal
                                        disabled={!enablePOS}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="POS"
                                                placeholder="Select POS"
                                                InputLabelProps={{
                                                    shrink: true
                                                }}
                                                className={`${classes.select} ${classes.textField}`}
                                            />
                                        )}
                                        componentsProps={{
                                            paper: {
                                                className: classes.selectRow
                                            }
                                        }}
                                        isOptionEqualToValue={(option, value) =>
                                            option.label === value.label
                                        }
                                    />
                                </div>
                            </div>

                            <div
                                className={classes.columnsContainer}
                                style={{ margin: "0 0 32px 0" }}
                            >
                                <div className={classes.column}>
                                    <Autocomplete
                                        fullWidth
                                        value={posPlatform}
                                        onChange={(_e, v) => setPosPlatform(v)}
                                        options={POS_PLATFORMS}
                                        disableClearable={true}
                                        disablePortal
                                        disabled={!enablePOS}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="POS Platform"
                                                placeholder="Select POS Platform"
                                                InputLabelProps={{
                                                    shrink: true
                                                }}
                                                className={`${classes.select} ${classes.textField}`}
                                            />
                                        )}
                                        componentsProps={{
                                            paper: {
                                                className: classes.selectRow
                                            }
                                        }}
                                        isOptionEqualToValue={(option, value) =>
                                            option.label === value.label
                                        }
                                    />
                                </div>
                                <div className={classes.column}>
                                    <TextField
                                        disabled={!enablePOS}
                                        label="Restaurant Integration ID"
                                        value={posIntegrationId}
                                        onChange={(e) => {
                                            setPosIntegrationId(e.target.value);
                                        }}
                                        placeholder="Enter The Restaurant Integration ID"
                                        InputLabelProps={{ shrink: true }}
                                        className={classes.textField}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className={classes.columnsContainer}>
                            <div className={classes.column}>
                                <div className={classes.phoneWrapper}>
                                    <MuiTelInput
                                        value={phoneNumber}
                                        error={phoneErrorMessage.length > 0}
                                        helperText={phoneErrorMessage}
                                        label="OhWaiter Phone Number"
                                        placeholder="Enter OhWaiter Phone Number"
                                        InputLabelProps={{ shrink: true }}
                                        className={classes.textField}
                                        onChange={handleNumberChange}
                                    />
                                </div>
                            </div>
                            <div className={classes.column}></div>
                        </div>
                        <div
                            className={classes.columnsContainer}
                            style={{ margin: "0 0 32px 0" }}
                        >
                            <div className={classes.column}>
                                <TextField
                                    label="Address 1"
                                    value={address1}
                                    onChange={(e) => {
                                        setAddress1(e.target.value);
                                    }}
                                    placeholder="Enter Address 1"
                                    InputLabelProps={{ shrink: true }}
                                    className={classes.textField}
                                />
                            </div>
                            <div className={classes.column}>
                                <TextField
                                    label="Address 2"
                                    value={address2}
                                    onChange={(e) => {
                                        setAddress2(e.target.value);
                                    }}
                                    placeholder="Enter Address 2"
                                    InputLabelProps={{ shrink: true }}
                                    className={classes.textField}
                                />
                            </div>
                        </div>
                        <div
                            className={classes.columnsContainer}
                            style={{ margin: "0 0 32px 0" }}
                        >
                            <div className={classes.column}>
                                <TextField
                                    label="City"
                                    value={city}
                                    onChange={(e) => {
                                        setCity(e.target.value);
                                    }}
                                    placeholder="Enter City"
                                    InputLabelProps={{ shrink: true }}
                                    className={classes.textField}
                                />
                            </div>
                            <div className={classes.column}>
                                <TextField
                                    label="State"
                                    value={state}
                                    onChange={(e) => {
                                        setState(e.target.value);
                                    }}
                                    placeholder="Enter State"
                                    InputLabelProps={{ shrink: true }}
                                    className={classes.textField}
                                />
                            </div>
                        </div>
                        <div
                            className={classes.columnsContainer}
                            style={{ margin: "0 0 32px 0" }}
                        >
                            <div className={classes.column}>
                                <TextField
                                    label="Zip Code"
                                    value={zipCode}
                                    onChange={(e) => {
                                        setZipCode(e.target.value);
                                    }}
                                    placeholder="Enter Zip Code"
                                    InputLabelProps={{ shrink: true }}
                                    className={classes.textField}
                                />
                            </div>
                            <div className={classes.column}>
                                <TextField
                                    label="Country"
                                    value={country}
                                    onChange={(e) => {
                                        setCountry(e.target.value);
                                    }}
                                    placeholder="Enter Country"
                                    InputLabelProps={{ shrink: true }}
                                    className={classes.textField}
                                />
                            </div>
                        </div>

                        <p className={classes.title}>Feature Configurations</p>
                        <div style={{ marginTop: 28 }}>
                            <div
                                className={classes.columnsContainer}
                                style={{ margin: "0" }}
                            >
                                <div>
                                    <p className={classes.toggleLabel}>
                                        Menu Alerts
                                    </p>
                                    <FormControlLabel
                                        control={
                                            <Switch
                                                checked={hasAlerts}
                                                classes={{
                                                    switchBase:
                                                        classes.switchDisabled
                                                }}
                                                className={
                                                    hasAlerts
                                                        ? classes.switch
                                                        : ""
                                                }
                                            />
                                        }
                                        label={
                                            hasAlerts ? "Active" : "Inactive"
                                        }
                                        onChange={() => setHasAlerts((n) => !n)}
                                        className={classes.switchLabel}
                                    />
                                </div>
                                <div>
                                    <p className={classes.toggleLabel}>
                                        Menu Printer
                                    </p>
                                    <FormControlLabel
                                        control={
                                            <Switch
                                                checked={hasPrint}
                                                classes={{
                                                    switchBase:
                                                        classes.switchDisabled
                                                }}
                                                className={
                                                    hasPrint
                                                        ? classes.switch
                                                        : ""
                                                }
                                            />
                                        }
                                        label={hasPrint ? "Active" : "Inactive"}
                                        onChange={() => setHasPrint((n) => !n)}
                                        className={classes.switchLabel}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                )}

                <Box
                    sx={{
                        position: "absolute",
                        right: "0",
                        bottom: "0"
                    }}
                >
                    <Button
                        disableBtn={!hasChanged}
                        innerText="Save"
                        type="primary"
                        color="dark"
                        className="restaurant_save"
                        onClick={() => {
                            if (hasChanged) {
                                setShowModal(true);
                            }
                        }}
                    />
                </Box>
                <ConfirmAction
                    open={showConfirmDefault}
                    actionCancel={cancelServicePeriodToggle}
                    actionConfirm={confirmServicePeriodToggle}
                    icon={defaultCollection ? "check" : "pointer"}
                    dialogText={
                        defaultCollection
                            ? "Please confirm your default Service Period."
                            : "Please select a Default Service Period."
                    }
                    // note="The menus within the Default Service Period are what will be viewed when inactive"
                    note={[
                        <span key={0} className={classes.infoMessage}>
                            <Icon name="info" />
                            <p>
                                The menus linked to your default service period
                                are the only menus that will be visible in the
                                menu manager when service periods is toggled to
                                inactive.{" "}
                            </p>
                        </span>,
                        <span key={1} className={classes.infoMessage}>
                            <Icon name="info" />
                            <p>
                                Any menu not linked to your default will
                                disappear from the menu manager. These menus are
                                not permanently gone, to see them just toggle
                                service periods to active.{" "}
                            </p>
                        </span>
                    ]}
                >
                    <div
                        style={{
                            marginTop: 8,
                            display: "flex",
                            flexDirection: "column",
                            gap: 32
                        }}
                    >
                        <Autocomplete
                            fullWidth
                            value={auxCollection}
                            onChange={(_e, v) => setAuxCollection(v)}
                            options={activeCollections}
                            disableClearable={true}
                            disablePortal
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Default Service Period"
                                    required
                                    helperText={noCollectionError}
                                    error={noCollectionError.length > 0}
                                    placeholder="Select Service Period"
                                    InputLabelProps={{ shrink: true }}
                                    className={`${classes.select} ${classes.textField}`}
                                />
                            )}
                            componentsProps={{
                                paper: {
                                    className: classes.selectRow
                                }
                            }}
                            isOptionEqualToValue={(option, value) =>
                                option.label === value.label
                            }
                        />
                    </div>
                </ConfirmAction>
                <ConfirmAction
                    open={confirmDisableAutoSchedule}
                    actionCancel={() => setConfirmDisableAutoSchedule(false)}
                    actionConfirm={() => {
                        setEnableAutoSchedule(false);
                        setConfirmDisableAutoSchedule(false);
                    }}
                    icon="clock"
                    dialogText="Are you sure want to turn off Auto-Schedule?"
                />
                <ConfirmAction
                    open={confirmEnableAutoSchedule}
                    actionCancel={handleCancelAutoSchedule}
                    actionConfirm={handleConfirmAutoSchedule}
                    icon="pointer"
                    dialogText="Select a Time Zone and Default Service Period."
                    note="The Default Service Period will be displayed on your live menus if nothing is scheduled."
                >
                    <div
                        style={{
                            marginTop: 8,
                            display: "flex",
                            flexDirection: "column",
                            gap: 32
                        }}
                    >
                        <Autocomplete
                            fullWidth
                            disablePortal
                            value={auxTimezone}
                            onChange={(_e, v) => setAuxTimezone(v)}
                            disableClearable={true}
                            options={timezones}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Restaurant Time Zone"
                                    required
                                    helperText={noTimezoneError}
                                    error={noTimezoneError.length > 0}
                                    placeholder="Choose Your Time Zone"
                                    InputLabelProps={{ shrink: true }}
                                    className={`${classes.select} ${classes.textField}`}
                                />
                            )}
                            componentsProps={{
                                paper: {
                                    className: classes.selectRow
                                }
                            }}
                            isOptionEqualToValue={(option, value) =>
                                option.label === value.label
                            }
                        />
                        <Autocomplete
                            fullWidth
                            value={auxCollection}
                            onChange={(_e, v) => setAuxCollection(v)}
                            disablePortal
                            disableClearable={true}
                            options={activeCollections}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    required
                                    label="Default Service Period"
                                    helperText={noCollectionError}
                                    error={noCollectionError.length > 0}
                                    placeholder="Select Service Period"
                                    InputLabelProps={{ shrink: true }}
                                    className={`${classes.select} ${classes.textField}`}
                                />
                            )}
                            componentsProps={{
                                paper: {
                                    className: classes.selectRow
                                }
                            }}
                            isOptionEqualToValue={(option, value) =>
                                option.label === value.label
                            }
                        />
                    </div>
                </ConfirmAction>
                <ConfirmAction
                    button1Text="Save Changes"
                    button2Text="Leave Page"
                    open={showConfirmExitDialog}
                    actionConfirm={async () => {
                        handleSubmit(targetLoc || "", false, true);
                    }}
                    actionSecondButton={async () => {
                        setHasChanged(false);
                        setShowConfirmExitDialog(false);
                        if (hasChildChanged) {
                            setHasChildChanged(false);
                            setParentUpdate(false);
                            onTabChange(newTabVal);
                        } else {
                            setTimeout(() => {
                                if (targetLoc !== "") {
                                    navigate(targetLoc);
                                } else {
                                    navigate("/");
                                }
                            }, 200);
                        }
                    }}
                    actionCancel={() => {
                        setShowConfirmExitDialog(false);
                        setHasChildChanged(false);
                    }}
                    icon="error"
                    dialogText="Unsaved changes. Do you want to Save?"
                    note={ExitInfoMessage()}
                    disableBtn={disableBtn}
                />
                <ConfirmAction
                    button1Text="Save & Publish Changes"
                    button2Text="Continue with Save"
                    open={showModal}
                    actionConfirm={handleCombinedFunction}
                    actionSecondButton={() => {
                        handleSubmit("", true);
                    }}
                    actionCancel={() => setShowModal(false)}
                    icon="publish"
                    dialogText={`Would you like to Publish your changes?`}
                    note={InfoMessage()}
                    disableBtn={disableBtn}
                />
            </Root>
        </>
    );
};

export default RestaurantTab;
