import { useContext, useEffect, useCallback, useState } from "react";
import { UNSAFE_NavigationContext as NavigationContext } from "react-router-dom";

// A custom hook to block navigation based on certain conditions
export function useBlocker(blocker, when = true, onBlockExecuted) {
    const { navigator } = useContext(NavigationContext);

    useEffect(() => {
        if (!when) {
            return;
        }

        const unblock = navigator.block((tx) => {
            if (typeof onBlockExecuted === "function") {
                onBlockExecuted(true); // We are now passing true directly
            }

            const autoUnblockingTx = {
                ...tx,
                retry() {
                    return true;
                }
            };
            blocker(autoUnblockingTx);
        });

        return unblock;
    }, [navigator, blocker, when, onBlockExecuted]);
}

export function usePrompt(when = true, onBlockExecuted) {
    const [targetLocation, setTargetLocation] = useState("");

    useBlocker(
        useCallback(
            (tx) => {
                if (when) {
                    setTargetLocation(tx?.location?.pathname || "");
                    tx.retry(when);
                } else {
                    tx.retry();
                }
            },
            [when, setTargetLocation]
        ),
        when,
        onBlockExecuted
    );

    return { targetLocation };
}
