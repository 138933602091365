import {
    FormControlLabel,
    Modal,
    Slide,
    Switch,
    TextField
} from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { Root, classes } from "../../assets/styles/items/FormCreateTitle.js";
import { createItem } from "../../utils/Items/item-helper.js";
import Button from "../UI/Button.js";
import AuthGlobal from "../../context/store/global";
import Icon from "../UI/Icon.js";
import { useAlert } from "../../hooks/useAlert.js";
import Alert from "../UI/Alert.js";
import { useIntercom } from "react-use-intercom";

const FormCreateTitle = ({ open, handleClose, mode = "create", item = {} }) => {
    const {
        globalState: {
            current_restaurant: { identifier }
        }
    } = useContext(AuthGlobal);
    const [active, setActive] = useState(true);
    const [errorText, setErrorText] = useState("");
    const [name, setName] = useState("");
    const [alert, handleAlert] = useAlert();
    const [saveLoad, setSaveLoad] = useState(false);
    const [disableBtn, setDisableBtn] = useState(false);

    const { update } = useIntercom();
    useEffect(() => {
        update({
            alignment: open ? "left" : "right"
        });
    }, [open, update]);

    useEffect(() => {
        setErrorText("");
    }, [name]);

    const onClose = (alertData = { open: false }, newTitle) => {
        setName("");
        setActive(true);
        handleClose(alertData, newTitle);
    };

    const handleSubmit = async () => {
        if (name === "") {
            setErrorText("* Please, enter the title name");
            return;
        }
        setSaveLoad(true);
        setDisableBtn(true);
        const { success, response } = await createItem(
            {
                type: "title",
                internal_name: name,
                display_name: name,
                status: active ? "active" : "inactive",
                notation_links: [],
                price_variations: []
            },
            identifier
        );
        if (success && response) {
            onClose(
                {
                    severity: "success",
                    title: "Success",
                    message: response.message
                },
                response.item
            );
        } else {
            handleAlert({
                severity: "Error",
                title: "Error",
                message: response.message
            });
        }
        setSaveLoad(false);
        setDisableBtn(false);
    };

    return (
        <>
            <Alert {...alert} />
            <Modal open={open} onClose={onClose} disablePortal>
                <Slide in={open} direction="left">
                    <Root className={classes.container}>
                        <div className={classes.top}>
                            <span className={classes.sectionClose}>
                                <span
                                    className={classes.closeButtonContainer}
                                    onClick={() => onClose()}
                                >
                                    <Icon
                                        name="clear"
                                        className={classes.closeButton}
                                    />
                                </span>
                            </span>
                            <h1 className={classes.title}>
                                {mode === "create"
                                    ? "Create New Title"
                                    : `Edit ${item.internal_name}`}
                            </h1>
                        </div>
                        <div className={classes.form}>
                            <p className={classes.toggleLabel}>Title Status</p>
                            <FormControlLabel
                                control={
                                    <Switch
                                        checked={active}
                                        classes={{
                                            switchBase: classes.switchDisabled
                                        }}
                                        className={active ? classes.switch : ""}
                                    />
                                }
                                label={active ? "Active" : "Inactive"}
                                onChange={() => setActive((val) => !val)}
                                className={classes.switchLabel}
                            />
                            <TextField
                                required
                                fullWidth
                                error={errorText.length > 0}
                                helperText={errorText}
                                placeholder="Enter Title Name"
                                className={classes.textField}
                                label="Title Name"
                                value={name}
                                InputLabelProps={{ shrink: true }}
                                onChange={(event) =>
                                    setName(event.target.value)
                                }
                            />
                        </div>
                        <div className={classes.actions}>
                            <Button
                                type="primary"
                                color="orange"
                                innerText="Save"
                                itemLoading={saveLoad}
                                disableBtn={disableBtn}
                                onClick={() => handleSubmit()}
                            />
                            <Button
                                type="secondary"
                                color="dark"
                                innerText="Cancel"
                                disableBtn={disableBtn}
                                onClick={() => onClose()}
                            />
                        </div>
                    </Root>
                </Slide>
            </Modal>
        </>
    );
};

export default FormCreateTitle;
