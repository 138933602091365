export const format24hto12h = (time24h) => {
    try {
        let [hours, minutes] = time24h.split(":");
        const ampm = parseInt(hours) >= 12 ? "pm" : "am";
        if (hours > 12) hours -= 12;
        return `${hours}:${minutes} ${ampm}`;
    } catch (e) {
        return "";
    }
};
