import { styled } from "@mui/material/styles";

const classes = {
    container: `container`,
    headline: `headline`,
    columnsContainer: `columnsContainer`,
    column: `column`,
    title: `title`,
    actions: `actions`,
    textField: `textField`,
    multiline: `multiline`,
    switchLabel: `switchLabel`,
    switch: `switch`,
    switchDisabled: `switchDisabled`,
    toggleLabel: `toggleLabel`,
    centered: `centered`,
    imageContainer: `imageContainer`,
    image: `image`,
    deleteImage: `deleteImage`,
    menuSelector: `menuSelector`,
    listContainer: `listContainer`,
    selectorTitle: `selectorTitle`,
    infoMessage: `infoMessage`,
    inlineSearchBar: `inlineSearchBar`,
    menuList: `menuList`,
    menusOrder: `menusOrder`,
    orderTitle: `orderTitle`,
    fileInput: `fileInput`,
    noImageText: `noImageText`,
    checkbox: `checkbox`,
    checkboxActive: `checkboxActive`,
    selectRow: `selectRow`,
    sorting: `sorting`,
    menuListTableRow: `menuListTableRow`,
    collectionsList: `collectionsList`,
    color1: `color1`,
    color2: `color2`,
    dragSection: `dragSection`,
    dragIconContainer: `dragIconContainer`,
    rowActions: `rowActions`,
    iconContainer: `iconContainer`,
    loader: `loader`,
    imageError: `imageError`,
    iconCircle: `iconCircle`
};

const InfoMessage = styled("div")(({ theme }) => ({
    [`&.${classes.infoMessage}`]: {
        marginBottom: 16,
        "& p": {
            fontSize: "14px",
            fontStyle: "italic",
            lineHeight: "16px"
        }
    }
}));
const Root = styled("div")(({ theme }) => ({
    [`&.${classes.container}`]: {
        position: "relative",
        padding: "56px 48px 48px",
        display: "flex",
        flexDirection: "column",
        width: "100%"
    },
    [`& .${classes.headline}`]: {
        display: "flex",
        alignItems: "center",
        width: "100%",
        marginBottom: 32
    },
    [`& .${classes.columnsContainer}`]: {
        display: "flex",
        flexDirection: "row",
        columnGap: 32,
        marginBottom: 32
    },
    [`& .${classes.column}`]: {
        display: "flex",
        flexDirection: "column",
        width: "100%"
    },
    [`& .${classes.title}`]: {
        fontSize: "24px",
        fontWeight: 500,
        flexGrow: 1,
        margin: "0 0 0"
    },
    [`& .${classes.actions}`]: {
        display: "flex",
        alignSelf: "center",
        gap: 16
    },
    [`& .${classes.textField}`]: {
        lineHeight: "44px !important",
        // border: "1px solid #B4B3B3",
        "& div.Mui-focused": {
            "& fieldset": {
                borderColor: `${theme.colors.orange[700]} !important`
            }
        },
        "& label.Mui-focused": {
            color: `${theme.colors.orange[700]} !important`
        },
        "& fieldset": {
            borderRadius: "50px",
            "& legend": {
                margin: "4px 0px 4px 2px"
            }
        },
        "& input": {
            padding: "12px 18px !important"
        },
        "& input, div": {
            fontSize: 14
        },
        "& label": {
            transition: "all 100ms linear !important"
        },
        "& label[data-shrink=true].Mui-focused": {
            color: `${theme.colors.orange[700]} !important`
        },
        "& label[data-shrink=true]": {
            padding: `4px${theme.browser.isSafari ? " 10px" : " 8px"}`,
            color: `${theme.colors.grey[900]} !important`,
            background: "white",
            fontWeight: 500,
            fontSize: "16px",
            top: -3,
            "& .MuiFormLabel-asterisk": {
                color: theme.colors.orange[700]
            }
        },
        "& label[data-shrink=false]": {
            paddingLeft: 7
        }
    },
    [`& .${classes.multiline}`]: {
        "& fieldset": {
            borderRadius: "20px !important"
        },
        "& .MuiInputBase-multiline": {
            paddingLeft: 20,
            alignItems: "flex-start",
            height: 150,
            "& textarea": {
                height: "118px !important",
                overflow: "auto !important"
            }
        }
    },
    [`& .${classes.switchLabel}`]: {
        "& .MuiFormControlLabel-label": {
            fontSize: 14,
            lineHeight: "16px",
            color: theme.colors.grey[500]
        },
        "&:hover .MuiFormControlLabel-label": {
            color: theme.colors.grey[900]
        }
    },
    [`& .${classes.switch}`]: {
        "& .MuiSwitch-switchBase": {
            color: `${theme.colors.orange[700]} !important`,
            "&.Mui-checked:hover": {
                backgroundColor: "rgba(250, 211, 184, 0.15) !important"
            }
        },
        "& .MuiSwitch-track": {
            backgroundColor: `${theme.colors.orange[200]} !important`
        }
    },
    [`& .${classes.switchDisabled}`]: {
        "&:hover": {
            backgroundColor: "rgba(250, 250, 250, 0.35) !important"
        }
    },
    [`& .${classes.toggleLabel}`]: {
        padding: "0",
        margin: 0,
        fontSize: 12,
        fontWeight: 500,
        lineHeight: "14px"
    },
    [`& .${classes.centered}`]: {
        textAlign: "center",
        fontWeight: 500,
        fontSize: 12
    },
    [`& .${classes.imageContainer}`]: {
        marginTop: 4,
        height: 150,
        border: "1px dashed rgba(180, 179, 179, 0.5)",
        borderRadius: 20,
        padding: 32,
        position: "relative",
        textAlign: "center",
        "&:hover": {
            "& svg:nth-of-type(2)": {
                "& path": {
                    fill: theme.colors.grey[700]
                }
            },
            "& p": {
                opacity: 1
            }
        }
    },
    [`& .${classes.image}`]: {
        maxWidth: "100%",
        height: "100%",
        objectFit: "scale-down"
    },
    [`& .${classes.deleteImage}`]: {
        position: "absolute",
        top: 16,
        right: 18,
        width: 16,
        cursor: "pointer",
        "& path": {
            fill: theme.colors.grey[500]
        },
        "&:hover": {
            "& path": {
                fill: theme.colors.grey[700]
            }
        }
    },
    [`& .${classes.menuSelector}`]: {
        display: "flex",
        flexDirection: "row",
        columnGap: 32,
        flexGrow: 1,
        minHeight: 350
    },
    [`& .${classes.listContainer}`]: {
        width: 400,
        background: theme.colors.orange[50],
        border: `1px solid ${theme.colors.orange[100]}`,
        borderRadius: 10,
        padding: "16px 20px 22px"
    },
    [`& .${classes.selectorTitle}`]: {
        fontWeight: 500,
        marginBottom: 0,
        fontSize: 16,
        lineHeight: "14.75px",
        color: theme.colors.orange[700]
    },
    [`& .${classes.infoMessage}`]: {
        marginTop: 8,
        marginBottom: 0,
        color: "rgba(34, 34, 34, 0.75)",
        lineHeight: "13px",
        fontSize: "11px",
        "& svg": {
            transform: "translateY(2px)",
            width: 12,
            marginRight: 8,
            height: 12
        },
        "& path": {
            fill: "rgba(34, 34, 34, 0.75)"
        }
    },
    [`& .${classes.inlineSearchBar}`]: {
        marginTop: 16,
        "& > div": {
            height: 36,
            backgroundColor: "white"
        }
    },
    [`& .${classes.menuList}`]: {
        marginTop: 16,
        height: "calc(100% - 105px)",
        width: "100%",
        background: "white",
        display: "flex",
        flexDirection: "column",
        padding: "8px 16px",
        overflowY: "auto",
        border: "1px solid rgba(180, 179, 179, 0.5)",
        borderRadius: 10
    },
    [`& .${classes.menusOrder}`]: {
        width: "calc(100% - 432px)",
        display: "flex",
        flexDirection: "column"
    },
    [`& .${classes.orderTitle}`]: {
        marginTop: 16,
        marginBottom: 8,
        borderBottom: `1px solid ${theme.colors.grey[400]}`,
        paddingBottom: 12,
        color: theme.colors.grey[900],
        fontWeight: 500,
        fontSize: "16px",
        lineHeight: "18.75px"
    },
    [`& .${classes.fileInput}`]: {
        opacity: 0,
        position: "absolute",
        top: 0,
        left: 0,
        cursor: "pointer",
        height: "100%",
        width: "100%"
    },
    [`& .${classes.noImageText}`]: {
        fontWeight: 600,
        fontSize: 12,
        lineHeight: "14px",
        marginTop: 8,
        marginBottom: 0,
        opacity: 0.4,
        color: `${theme.colors.grey[900]}a6`
    },
    [`& .${classes.checkbox}`]: {
        "& path": {
            borderRadius: 4
        }
    },
    [`& .${classes.checkboxActive}`]: {
        color: `${theme.colors.orange[700]} !important`,
        "&.Mui-checked:hover": {
            backgroundColor: "rgba(250, 211, 184, 0.3)"
        }
    },
    [`& .${classes.selectRow}`]: {
        cursor: "pointer",
        padding: "3px",
        display: "flex",
        borderBottom: "1px solid rgba(234, 234, 234, 0.5)",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "flex-start",
        color: theme.colors.grey[700]
    },
    [`& .${classes.sorting}`]: {
        border: `1px solid ${theme.colors.grey[400]}`,
        boxShadow: "0px 5px 12px rgba(34, 34, 34, 0.15)",
        display: "inline-table !important"
    },
    [`& .${classes.menuListTableRow}`]: {
        display: "table-row",
        width: "100%",
        borderRadius: "6px",
        "& td": {
            paddingLeft: 20,
            paddingRight: 10,
            fontWeight: 400,
            fontSize: 16,
            height: 70,
            width: "100%"
        }
    },
    [`& .${classes.collectionsList}`]: {
        fontWeight: 300
    },
    [`& .${classes.color1}`]: {
        background: "#FFFFFF"
    },
    [`& .${classes.color2}`]: { background: "#FAFAFA" },
    [`& .${classes.dragSection}`]: {
        width: "5%"
    },
    [`& .${classes.dragIconContainer}`]: {
        cursor: "grab",
        "&:hover": {
            "& > svg > path": {
                fill: theme.colors.grey[900]
            }
        },
        "& svg": {
            transform: "translateY(2px)"
        }
    },
    [`& .${classes.rowActions}`]: {
        display: "flex",
        height: 70,
        alignItems: "center",
        "& svg": {
            cursor: "pointer",
            margin: "0 11.5px",
            width: 19,
            height: 19,
            "& path": {
                fill: "#9E9E9E"
            }
        },
        "& svg:hover": {
            "& path": {
                fill: theme.colors.orange[700]
            }
        }
    },
    [`& .${classes.iconContainer}`]: {
        transform: "translateY(2px)"
    },
    [`& .${classes.loader}`]: {
        color: theme.colors.orange[700]
    },
    [`& .${classes.imageError}`]: {
        color: "#D8000C",
        fontStyle: "italic",
        fontSize: 12,
        marginTop: 8
    },
    [`& .${classes.iconCircle}`]: {
        width: "36px",
        height: "36px",
        borderRadius: "12px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        background: theme.colors.orange[100],
        cursor: "pointer",
        marginRight: "20px",
        alignSelf: "center"
    }
}));

export { Root, classes, InfoMessage };
