export const getOffset = (timeZone = "UTC", date = new Date()) => {
    const utcDate = new Date(date.toLocaleString("en-US", { timeZone: "UTC" }));
    const tzDate = new Date(date.toLocaleString("en-US", { timeZone }));
    return (tzDate.getTime() - utcDate.getTime()) / 6e4;
};

export const minutesToGMTOffset = (minutes) => {
    return `(GMT${minutes < 0 ? "-" : "+"}${Math.floor(
        Math.abs(minutes) / 60
    )}:${`${Math.abs(minutes) % 60}`.padStart(2, "0")}) `;
};

const timezones = [
    { tzName: "Pacific/Midway", label: "Midway Island, Samoa" },
    { tzName: "Pacific/Honolulu", label: "Hawaii" },
    { tzName: "America/Juneau", label: "Alaska" },
    { tzName: "America/Boise", label: "Mountain Time" },
    { tzName: "America/Dawson", label: "Dawson, Yukon" },
    { tzName: "America/Chihuahua", label: "Chihuahua, La Paz, Mazatlan" },
    { tzName: "America/Phoenix", label: "Arizona" },
    { tzName: "America/Chicago", label: "Central Time" },
    { tzName: "America/Regina", label: "Saskatchewan" },
    {
        tzName: "America/Mexico_City",
        label: "Guadalajara, Mexico City, Monterrey"
    },
    { tzName: "America/Belize", label: "Central America" },
    { tzName: "America/Detroit", label: "Eastern Time" },
    { tzName: "America/Bogota", label: "Bogota, Lima, Quito" },
    { tzName: "America/Caracas", label: "Caracas, La Paz" },
    { tzName: "America/Santiago", label: "Santiago" },
    { tzName: "America/St_Johns", label: "Newfoundland and Labrador" },
    { tzName: "America/Sao_Paulo", label: "Brasilia" },
    { tzName: "America/Tijuana", label: "Tijuana" },
    { tzName: "America/Montevideo", label: "Montevideo" },
    {
        tzName: "America/Argentina/Buenos_Aires",
        label: "Buenos Aires, Georgetown"
    },
    { tzName: "America/Godthab", label: "Greenland" },
    { tzName: "America/Los_Angeles", label: "Pacific Time" },
    { tzName: "Atlantic/Azores", label: "Azores" },
    { tzName: "Atlantic/Cape_Verde", label: "Cape Verde Islands" },
    { tzName: "GMT", label: "UTC" },
    { tzName: "Europe/London", label: "Edinburgh, London" },
    { tzName: "Europe/Dublin", label: "Dublin" },
    { tzName: "Europe/Lisbon", label: "Lisbon" },
    { tzName: "Africa/Casablanca", label: "Casablanca, Monrovia" },
    { tzName: "Atlantic/Canary", label: "Canary Islands" },
    {
        tzName: "Europe/Belgrade",
        label: "Belgrade, Bratislava, Budapest, Ljubljana, Prague"
    },
    { tzName: "Europe/Sarajevo", label: "Sarajevo, Skopje, Warsaw, Zagreb" },
    { tzName: "Europe/Brussels", label: "Brussels, Copenhagen, Madrid, Paris" },
    {
        tzName: "Europe/Amsterdam",
        label: "Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna"
    },
    { tzName: "Africa/Algiers", label: "West Central Africa" },
    { tzName: "Europe/Bucharest", label: "Bucharest" },
    { tzName: "Africa/Cairo", label: "Cairo" },
    {
        tzName: "Europe/Helsinki",
        label: "Helsinki, Kyiv, Riga, Sofia, Tallinn, Vilnius"
    },
    { tzName: "Europe/Athens", label: "Athens, Minsk" },
    { tzName: "Asia/Jerusalem", label: "Jerusalem" },
    { tzName: "Africa/Harare", label: "Harare, Pretoria" },
    {
        tzName: "Europe/Moscow",
        label: "Istanbul, Moscow, St. Petersburg, Volgograd"
    },
    { tzName: "Asia/Kuwait", label: "Kuwait, Riyadh" },
    { tzName: "Africa/Nairobi", label: "Nairobi" },
    { tzName: "Asia/Baghdad", label: "Baghdad" },
    { tzName: "Asia/Tehran", label: "Tehran" },
    { tzName: "Asia/Dubai", label: "Abu Dhabi, Muscat" },
    { tzName: "Asia/Baku", label: "Baku, Tbilisi, Yerevan" },
    { tzName: "Asia/Kabul", label: "Kabul" },
    { tzName: "Asia/Yekaterinburg", label: "Ekaterinburg" },
    { tzName: "Asia/Karachi", label: "Islamabad, Karachi, Tashkent" },
    { tzName: "Asia/Kolkata", label: "Chennai, Kolkata, Mumbai, New Delhi" },
    { tzName: "Asia/Kathmandu", label: "Kathmandu" },
    { tzName: "Asia/Dhaka", label: "Astana, Dhaka" },
    { tzName: "Asia/Colombo", label: "Sri Jayawardenepura" },
    { tzName: "Asia/Almaty", label: "Almaty, Novosibirsk" },
    { tzName: "Asia/Rangoon", label: "Yangon Rangoon" },
    { tzName: "Asia/Bangkok", label: "Bangkok, Hanoi, Jakarta" },
    { tzName: "Asia/Krasnoyarsk", label: "Krasnoyarsk" },
    {
        tzName: "Asia/Shanghai",
        label: "Beijing, Chongqing, Hong Kong SAR, Urumqi"
    },
    { tzName: "Asia/Kuala_Lumpur", label: "Kuala Lumpur, Singapore" },
    { tzName: "Asia/Taipei", label: "Taipei" },
    { tzName: "Australia/Perth", label: "Perth" },
    { tzName: "Asia/Irkutsk", label: "Irkutsk, Ulaanbaatar" },
    { tzName: "Asia/Seoul", label: "Seoul" },
    { tzName: "Asia/Tokyo", label: "Osaka, Sapporo, Tokyo" },
    { tzName: "Asia/Yakutsk", label: "Yakutsk" },
    { tzName: "Australia/Darwin", label: "Darwin" },
    { tzName: "Australia/Adelaide", label: "Adelaide" },
    { tzName: "Australia/Sydney", label: "Canberra, Melbourne, Sydney" },
    { tzName: "Australia/Brisbane", label: "Brisbane" },
    { tzName: "Australia/Hobart", label: "Hobart" },
    { tzName: "Asia/Vladivostok", label: "Vladivostok" },
    { tzName: "Pacific/Guam", label: "Guam, Port Moresby" },
    {
        tzName: "Asia/Magadan",
        label: "Magadan, Solomon Islands, New Caledonia"
    },
    { tzName: "Asia/Kamchatka", label: "Kamchatka, Marshall Islands" },
    { tzName: "Pacific/Fiji", label: "Fiji Islands" },
    { tzName: "Pacific/Auckland", label: "Auckland, Wellington" },
    { tzName: "Pacific/Tongatapu", label: "Nuku'alofa" }
];
for (let i = 0; i < timezones.length; i++) {
    timezones[i].offset = getOffset(timezones[i].tzName);
}
timezones.sort((a, b) => a.offset - b.offset);
for (let i = 0; i < timezones.length; i++) {
    timezones[i].label = `${minutesToGMTOffset(timezones[i].offset)} ${
        timezones[i].label
    }`;
}

export default timezones;
