import { fetchRequest } from "../index";

export const getLastPrintedMenus = (identifier) => {
    return fetchRequest(`/media/${identifier}/get-printed-menus`, {
        method: "GET"
    });
};

export const getMenus = (identifier, order = "A-Z") => {
    return fetchRequest(
        `/menu/${identifier}/active?order=${order === "A-Z" ? 1 : -1}`,
        {
            method: "GET"
        }
    );
};

export const getDraftMenus = (identifier, order = "A-Z") => {
    return fetchRequest(
        `/menu/${identifier}/draft?order=${order === "A-Z" ? 1 : -1}`,
        {
            method: "GET"
        }
    );
};

export const getTrashMenus = (identifier, order = "A-Z") => {
    return fetchRequest(
        `/menu/${identifier}/trash?order=${order === "A-Z" ? 1 : -1}`,
        {
            method: "GET"
        }
    );
};

export const getMenuById = (id, identifier) => {
    return fetchRequest(`/menu/${identifier}/${id}/getFull`, {
        method: "GET"
    });
};

export const searchLastPrintedMenus = (value, identifier, order = "A-Z") => {
    return fetchRequest(`/media/${identifier}/search`, {
        method: "POST",
        body: JSON.stringify({
            internal_name: value,
            order: order === "A-Z" ? 1 : -1
        })
    });
};
export const searchActiveMenus = (value, identifier, order = "A-Z") => {
    return fetchRequest(`/menu/${identifier}/search`, {
        method: "POST",
        body: JSON.stringify({
            internal_name: value,
            status: "active",
            order: order === "A-Z" ? 1 : -1
        })
    });
};

export const searchDraftMenus = (value, identifier, order) => {
    return fetchRequest(`/menu/${identifier}/search`, {
        method: "POST",
        body: JSON.stringify({
            internal_name: value,
            status: "draft",
            order: order === "A-Z" ? 1 : -1
        })
    });
};

export const searchTrashMenus = (value, identifier, order) => {
    return fetchRequest(`/menu/${identifier}/search`, {
        method: "POST",
        body: JSON.stringify({
            internal_name: value,
            status: "trash",
            order: order === "A-Z" ? 1 : -1
        })
    });
};

export const markAsDraftMenu = (id, identifier) => {
    return fetchRequest(`/menu/${identifier}/${id}/set`, {
        method: "PUT",
        body: JSON.stringify({
            status: "draft"
        })
    });
};

export const markAsActiveMenu = (id, identifier) => {
    return fetchRequest(`/menu/${identifier}/${id}/set`, {
        method: "PUT",
        body: JSON.stringify({
            status: "active"
        })
    });
};

export const duplicateMenu = (id, identifier, newName) => {
    return fetchRequest(`/menu/${identifier}/${id}/duplicate`, {
        method: "POST",
        body: JSON.stringify({ newName })
    });
};

export const markAsTrashMenu = (id, identifier) => {
    return fetchRequest(`/menu/${identifier}/${id}`, {
        method: "DELETE"
    });
};

export const deleteMenu = (id, identifier) => {
    return fetchRequest(`/menu/${identifier}/${id}`, {
        method: "PUT",
        body: JSON.stringify({
            status: "delete"
        })
    });
};

export const deletePrintedMenu = (id, identifier) => {
    return fetchRequest(`/media/${identifier}/delete-pdf/${id}`, {
        method: "DELETE"
    });
};

export const createMenu = (body, identifier) => {
    return fetchRequest(`/menu/${identifier}`, {
        method: "POST",
        body: JSON.stringify(body)
    });
};

export const updateMenuById = (id, body, identifier) => {
    return fetchRequest(`/menu/${identifier}/${id}`, {
        method: "PUT",
        body: JSON.stringify(body)
    });
};

export const getCollectionByMenuId = (id, identifier) => {
    return fetchRequest(`/menu/${identifier}/${id}/get-collection`, {
        method: "GET"
    });
};

export const runPublishCompiler = (identifier) => {
    return fetchRequest(`/menu/${identifier}/compiler`, {
        method: "GET"
    });
};

export const refreshMenu = async (idMenu, identifier) => {
    const { success, response } = await getMenuById(idMenu, identifier);
    if (success && response) {
        const menu = { ...response };
        menu.section_links = menu.section_links.map(({ section_id }) => ({
            ...section_id,
            item_links: section_id.item_links.map(
                ({ item_id, sort_order }) => ({
                    item: item_id,
                    sort_order
                })
            )
        }));
        return menu;
    }
};
