import KeyboardDoubleArrowLeft from "@mui/icons-material/KeyboardDoubleArrowLeft";
import KeyboardDoubleArrowRight from "@mui/icons-material/KeyboardDoubleArrowRight";
import { Pagination, PaginationItem } from "@mui/material";

import { Root, classes } from "../../assets/styles/CustomPagination";

const CustomPagination = ({ totalRows, rowsPerPage, handlePageChange }) => {
    return (
        <>
            {totalRows > rowsPerPage && (
                <Root className={classes.paginator}>
                    <Pagination
                        siblingCount={1}
                        boundaryCount={1}
                        className={classes.pagintationItem}
                        onChange={(_event, num) => handlePageChange(num)}
                        count={Math.ceil(totalRows / rowsPerPage)}
                        showFirstButton
                        showLastButton
                        variant="outlined"
                        shape="rounded"
                        renderItem={(item) => {
                            return (
                                <PaginationItem
                                    components={{
                                        first: KeyboardDoubleArrowLeft,
                                        last: KeyboardDoubleArrowRight
                                    }}
                                    {...item}
                                />
                            );
                        }}
                    />
                </Root>
            )}
        </>
    );
};

export default CustomPagination;
