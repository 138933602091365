import { styled } from "@mui/material/styles";
import { Popover } from "@mui/material";

const classes = {
    container: `container`,
    top: `top`,
    form: `form`,
    formMain: `formMain`,
    actions: `actions`,
    title: `title`,
    removePriceContainer: `removePriceContainer`,
    removePrice: `removePrice`,
    closeButtonContainer: `closeButtonContainer`,
    closeButton: `closeButton`,
    sectionClose: `sectionClose`,
    toggleLabel: `toggleLabel`,
    subtitle: `subtitle`,
    hline: `hline`,
    recomendation: `recomendation`,
    switchLabel: `switchLabel`,
    switch: `switch`,
    switchDisabled: `switchDisabled`,
    pricesRow: `pricesRow`,
    iconCircle: `iconCircle`,
    textField: `textField`,
    priceTag: `priceTag`,
    multiline: `multiline`,
    pricesInfo: `pricesInfo`,
    select: `select`,
    selectPlaceholder: `selectPlaceholder`,
    notationsSelect: `notationsSelect`,
    notationRow: `notationRow`,
    selectedNotation: `selectedNotation`,
    checkbox: `checkbox`,
    checkboxActive: `checkboxActive`,
    tag: `tag`,
    dropdown: `dropdown`,
    dropdownInput: `dropdownInput`,
    selectRow: `selectRow`,
    addNotationContainer: `addNotationContainer`,
    addNotationIcon: `addNotationIcon`,
    selectPopper: `selectPopper`,
    labelRow: `labelRow`,
    noOptions: `noOptions`,
    dragIconContainer: `dragIconContainer`,
    priceSorting: `priceSorting`,
    centered: `centered`,
    imageContainer: `imageContainer`,
    image: `image`,
    deleteImage: `deleteImage`,
    fileInput: `fileInput`,
    noImageText: `noImageText`,
    imageError: `imageError`,
    popoverPrice: `popoverPrice`,
    addPriceContainer: `addPriceContainer`,
    addPriceIconContainer: `addPriceIconContainer`,
    addPriceIcon: `addPriceIcon`,
    addPriceText: `addPriceText`,
    inlineSearchBar: `inlineSearchBar`,
    tagSectionContainer: `tagSectionContainer`,
    dropdownHead: `dropdownHead`,
    blurred: `blurred`,
    dropdownToggle: `dropdownToggle`
};

const PopoverPrice = styled(Popover)(({ theme }) => ({
    [`&.${classes.popoverPrice}`]: {
        "& .MuiPopover-paper": {
            borderRadius: 20,
            minWidth: 500,
            minHeight: 227,
            overflow: "hidden"
        }
    }
}));
const Root = styled("div")(({ theme }) => ({
    [`&.${classes.container}`]: {
        position: "absolute",
        display: "flex",
        flexDirection: "column",
        right: 0,
        width: 550,
        overflowX: "hidden",
        height: "100vh",
        overflow: "auto",
        background: "white",
        borderRadius: "20px 0 0 20px",
        padding: "0",
        boxShadow: "0px 10px 30px rgba(31, 19, 0, 0.3)"
    },
    [`& .${classes.top}`]: {
        top: 0,
        padding: "0 32px",
        zIndex: 2,
        position: "sticky",
        background: "white",
        borderBottom: "1px solid #D9D9D9",
        filter: "drop-shadow(0px 1px 4px rgba(37, 37, 37, 0.05))"
    },
    [`& .${classes.form}`]: {
        minHeight: 150,
        flexGrow: 1,
        overflowY: "overlay"
    },
    [`& .${classes.formMain}`]: {
        paddingLeft: 32,
        paddingRight: 32,
        marginBottom: 32,
        display: "flex",
        flexDirection: "column"
    },
    [`& .${classes.actions}`]: {
        gap: 10,
        display: "flex",
        padding: "16px 32px",
        position: "sticky",
        borderTop: "1px solid #D9D9D9",
        flexDirection: "row-reverse",
        background: "white",
        filter: "drop-shadow(0px -1px 4px rgba(37, 37, 37, 0.05))"
    },
    [`& .${classes.title}`]: {
        fontSize: 20,
        fontWeight: 300,
        color: theme.colors.grey[900],
        margin: "0 0 0.5em",
        display: "block"
    },
    [`& .${classes.removePriceContainer}`]: {
        height: 28,
        width: 28,
        cursor: "pointer",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        margin: 8
    },
    [`& .${classes.removePrice}`]: {
        "& path": {
            fill: "#9E9E9E"
        }
    },
    [`& .${classes.closeButtonContainer}`]: {
        marginTop: 6,
        height: 26,
        width: 26,
        borderRadius: "16px",
        transform: "translate(16px, 12px)",
        display: "flex",
        background: theme.colors.orange[700],
        alignItems: "center",
        cursor: "pointer",
        justifyContent: "center",
        "&:hover": {
            boxShadow: `0px 10px 25px ${theme.colors.orange[700]}59`
        }
    },
    [`& .${classes.closeButton}`]: {
        "& path": {
            fill: "white"
        },
        display: "flex",
        alignItems: "center"
    },
    [`& .${classes.sectionClose}`]: {
        width: "100%",
        display: "flex",
        flexDirection: "row-reverse"
    },
    [`& .${classes.toggleLabel}`]: {
        padding: "4px 0 0 20px",
        fontWeight: 500,
        margin: 0,
        fontSize: 12
    },
    [`& .${classes.subtitle}`]: {
        fontWeight: 600,
        fontSize: 16,
        marginTop: 32
    },
    [`& .${classes.hline}`]: {
        margin: "16px 0",
        borderTop: "1px solid #D9D9D9"
    },
    [`& .${classes.recomendation}`]: {
        fontSize: 14,
        fontWeight: 300,
        "& b": {
            fontWeight: 400
        },
        lineHeight: "21px",
        marginBottom: 32
    },
    [`& .${classes.switchLabel}`]: {
        marginLeft: "6px !important",
        "& .MuiFormControlLabel-label": {
            fontSize: 14,
            lineHeight: "16px",
            color: theme.colors.grey[500]
        },
        "&:hover .MuiFormControlLabel-label": {
            color: theme.colors.grey[900]
        }
    },
    [`& .${classes.switch}`]: {
        "& .MuiSwitch-switchBase": {
            color: `${theme.colors.orange[700]} !important`,
            "&.Mui-checked:hover": {
                backgroundColor: "rgba(250, 211, 184, 0.15) !important"
            }
        },
        "& .MuiSwitch-track": {
            backgroundColor: `${theme.colors.orange[200]} !important`
        }
    },
    [`& .${classes.switchDisabled}`]: {
        "&:hover": {
            backgroundColor: "rgba(0, 0, 0, 0.04)"
        }
    },
    [`& .${classes.pricesRow}`]: {
        width: "100%",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "flex-start",
        padding: "20px 0 0 0",
        "& svg": {
            width: 16,
            height: 16,
            "& path": {
                fill: theme.colors.orange[700]
            }
        }
    },
    [`& .${classes.iconCircle}`]: {
        cursor: "pointer",
        width: 50,
        marginLeft: 12,
        height: 44,
        borderRadius: "50%",
        border: `1px solid ${theme.colors.orange[700]}`,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        "&:hover": {
            background: theme.colors.orange[700],
            "& path": {
                fill: "white"
            }
        }
    },
    [`& .${classes.textField}`]: {
        marginTop: "28px !important",
        lineHeight: "44px !important",
        // border: "1px solid #B4B3B3",
        "& div.Mui-focused": {
            "& fieldset": {
                borderColor: `${theme.colors.orange[700]} !important`
            }
        },
        "& label.Mui-focused": {
            color: `${theme.colors.orange[700]} !important`
        },
        "& fieldset": {
            borderRadius: "50px",
            "& legend": {
                margin: "4px 0px 4px 2px"
            }
        },
        "& input": {
            padding: "12px 18px !important"
        },
        "& input, div": {
            fontSize: 14
        },
        "& label": {
            transition: "all 100ms linear !important"
        },
        "& label[data-shrink=true].Mui-focused": {
            color: `${theme.colors.orange[700]} !important`
        },
        "& label[data-shrink=true]": {
            padding: `4px${theme.browser.isSafari ? " 10px" : " 8px"}`,
            color: `${theme.colors.grey[900]} !important`,
            background: "white",
            fontWeight: 500,
            fontSize: "16px",
            top: -3,
            "& .MuiFormLabel-asterisk": {
                color: theme.colors.orange[700]
            }
        },
        "& label[data-shrink=false]": {
            paddingLeft: 7
        }
    },
    [`& .${classes.priceTag}`]: {
        width: 360,
        marginTop: "0 !important",
        "& div.Mui-focused": {
            "& fieldset": {
                borderColor: `${theme.colors.grey[500]} !important`
            }
        }
    },
    [`& .${classes.multiline}`]: {
        "& fieldset": {
            borderRadius: "20px !important"
        },
        "& .MuiInputBase-multiline": {
            paddingLeft: 20,
            alignItems: "flex-start",
            minHeight: 110
        }
    },
    [`& .${classes.pricesInfo}`]: {
        margin: "12px 0 10px 5px",
        height: "13px",
        color: theme.colors.grey[400],
        "& svg": {
            transform: "translateY(2px)",
            width: 12,
            marginRight: 8,
            height: 12
        },
        "& path": {
            fill: theme.colors.grey[400]
        }
    },
    [`& .${classes.select}`]: {
        "& .MuiAutocomplete-input": {
            padding: "3px 8px !important"
        },
        "& .MuiAutocomplete-inputRoot": {
            padding: "10px 12px 8px 12px !important"
        }
    },
    [`& .${classes.selectPlaceholder}`]: {
        margin: 0,
        color: theme.colors.grey[400]
    },
    [`& .${classes.notationsSelect}`]: {
        "&.MuiAutocomplete-inputRoot": {
            padding: 0
        }
    },
    [`& .${classes.notationRow}`]: {
        padding: "0 24px",
        cursor: "pointer",
        "&:hover": {
            background: theme.colors.grey[50]
        }
    },
    [`& .${classes.selectedNotation}`]: {
        background: theme.colors.orange[50]
    },
    [`& .${classes.checkbox}`]: {
        "& path": {
            borderRadius: 4
        }
    },
    [`& .${classes.checkboxActive}`]: {
        color: `${theme.colors.orange[700]} !important`,
        "&.Mui-checked:hover": {
            backgroundColor: "rgba(250, 211, 184, 0.3)"
        }
    },
    [`& .${classes.tag}`]: {
        margin: "3px 0 3px 6px !important",
        backgroundColor: `${theme.colors.orange[100]} !important`,
        border: `1px solid ${theme.colors.orange[200]} !important`,
        padding: "6px 8px 6px 12px !important",
        "& span": {
            fontSize: 11,
            fontWeight: 500,
            padding: 0
        },
        "& svg": {
            alignSelf: "baseline"
        },
        gap: 15,
        height: "25px !important"
    },
    [`& .${classes.dropdown}`]: {
        "& .MuiSelect-select": {
            padding: "12px 0px 12px 16px !important"
        },
        "& .MuiSelect-nativeInput": {
            padding: "10px 12px 8px 12px !important",
            border: "none",
            opacity: "1 !important",
            transform: "translate(6px,-5px) "
        },
        marginTop: "28px !important",
        lineHeight: "44px !important",
        border: "1px solid #B4B3B3",
        "& div.Mui-focused": {
            "& fieldset": {
                borderColor: `${theme.colors.orange[700]} !important`
            }
        },
        "& label.Mui-focused": {
            color: `${theme.colors.orange[700]} !important`
        },
        "& fieldset": {
            borderRadius: "50px",
            "& legend": {
                margin: "4px 0px 4px 2px"
            }
        },
        "& input": {
            padding: "12px 18px !important"
        },
        "& input, div": {
            fontSize: 14
        },
        "& label": {
            transition: "all 100ms linear !important"
        },
        "& label[data-shrink=true].Mui-focused": {
            color: `${theme.colors.orange[700]} !important`
        },
        "& label[data-shrink=true]": {
            padding: `4px${theme.browser.isSafari ? " 10px" : " 8px"}`,
            color: `${theme.colors.grey[900]} !important`,
            background: "white",
            fontWeight: 500,
            fontSize: "16px",
            top: -3,
            "& .MuiFormLabel-asterisk": {
                color: theme.colors.orange[700]
            }
        },
        "& label[data-shrink=false]": {
            paddingLeft: 7
        }
    },
    [`& .${classes.dropdownInput}`]: {
        "&hover": {
            color: "red !important"
        }
    },
    [`& .${classes.selectRow}`]: {
        borderRadius: "20px !important",
        "& li": {
            height: 48,
            width: "100%",
            display: "flex !important",
            alignItems: "center !important",
            padding: "0 16px !important",
            background: "white !important",
            "&:hover": {
                background: `${theme.colors.orange[50]} !important`
            }
        },
        "& li[aria-selected='true']": {
            background: `${theme.colors.orange[50]} !important`
        },
        // select li with class selected
        "& li.dropdown-selected": {
            background: `${theme.colors.orange[50]} !important`,
            color: `${theme.colors.orange[900]} !important`
        }
    },
    [`& .${classes.addNotationContainer}`]: {
        display: "flex",
        cursor: "pointer",
        alignItems: "center",
        fontWeight: 400,
        color: theme.colors.grey[900],
        fontStyle: "italic",
        padding: "12px !important",
        pointerEvents: "all !important",
        "&:hover": {
            background: theme.colors.grey[50]
        }
    },
    [`& .${classes.addNotationIcon}`]: {
        cursor: "pointer",
        width: 25,
        marginRight: 12,
        padding: 7.5,
        float: "left",
        height: 25,
        borderRadius: "50%",
        border: `1px solid ${theme.colors.orange[700]}`,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        background: theme.colors.orange[700],
        "& path": {
            fill: "white"
        }
    },
    [`& .${classes.selectPopper}`]: {
        boxShadow: "0px 4px 12px rgba(34, 34, 34, 0.12) !important",
        borderRadius: "20px !important",
        border: "1px solid #EAEAEA",
        padding: "12px 0",
        "& .MuiAutocomplete-noOptions": {
            padding: 0
        },
        "& .MuiAutocomplete-listbox": {
            padding: 0
        }
    },
    [`& .${classes.labelRow}`]: {
        pointerEvents: "none",
        padding: "12px 0"
    },
    [`& .${classes.noOptions}`]: {
        pointerEvents: "none !important"
    },
    [`& .${classes.dragIconContainer}`]: {
        cursor: "grab",
        "&:hover": {
            "& > svg > path": {
                fill: theme.colors.grey[900]
            }
        },
        "& svg": {
            transform: "translateY(2px)"
        }
    },
    [`& .${classes.priceSorting}`]: {
        zIndex: 2147483004
    },
    [`& .${classes.centered}`]: {
        marginTop: 16,
        textAlign: "center",
        fontWeight: 500,
        fontSize: 12
    },
    [`& .${classes.imageContainer}`]: {
        marginTop: 4,
        height: 150,
        border: "1px dashed rgba(180, 179, 179, 0.5)",
        borderRadius: 20,
        padding: 32,
        position: "relative",
        textAlign: "center",
        "&:hover": {
            "& svg:nth-of-type(2)": {
                "& path": {
                    fill: theme.colors.grey[700]
                }
            },
            "& p": {
                opacity: 1
            }
        }
    },
    [`& .${classes.image}`]: {
        maxWidth: "100%",
        height: "100%",
        objectFit: "scale-down"
    },
    [`& .${classes.deleteImage}`]: {
        position: "absolute",
        top: 16,
        right: 18,
        width: 16,
        cursor: "pointer",
        "& path": {
            fill: theme.colors.grey[500]
        },
        "&:hover": {
            "& path": {
                fill: theme.colors.grey[700]
            }
        }
    },
    [`& .${classes.fileInput}`]: {
        opacity: 0,
        position: "absolute",
        top: 0,
        left: 0,
        cursor: "pointer",
        height: "100%",
        width: "100%"
    },
    [`& .${classes.noImageText}`]: {
        fontWeight: 600,
        fontSize: 12,
        lineHeight: "14px",
        marginTop: 8,
        marginBottom: 0,
        opacity: 0.4,
        color: `${theme.colors.grey[900]}a6`
    },
    [`& .${classes.imageError}`]: {
        color: "#D8000C",
        fontStyle: "italic",
        fontSize: 12,
        marginTop: 8
    },
    [`& .${classes.addPriceContainer}`]: {
        width: "100px",
        height: "30px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        cursor: "pointer"
    },
    [`& .${classes.addPriceIconContainer}`]: {
        backgroundColor: "#CC5803",
        width: "20px",
        height: "20px",
        borderRadius: "50%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        transition: "0.2s ease-in-out"
    },
    [`& .${classes.addPriceIcon}`]: {
        "& path": {
            fill: "#fff !important",
            color: "#fff !important"
        }
    },
    [`& .${classes.addPriceText}`]: {
        color: "#000000",
        fontFamily: "Roboto",
        fontSize: "14px",
        fontStyle: "italic",
        fontWeight: "400",
        margin: "0 0 0 5px",
        transition: "0.2s ease-in-out"
    },
    [`& .${classes.inlineSearchBar}`]: {
        margin: "16px 0px",
        "& > div": {
            height: 36,
            backgroundColor: "white"
        }
    },
    [`& .${classes.tagSectionContainer}`]: {
        padding: "0 32px",
        borderTop: "1px solid #D9D9D9"
    },
    [`& .${classes.dropdownHead}`]: {
        display: "flex",
        fontSize: "16px",
        fontWeight: 600,
        padding: "24px 0",
        justifyContent: "space-between",
        alignItems: "center",
        "& svg": {
            cursor: "pointer",
            "& path": {
                fill: "#9E9E9E"
            }
        }
    },
    [`& .${classes.blurred}`]: {
        opacity: 0.3,
        "&:hover": {
            opacity: 1
        }
    },
    [`& .${classes.dropdownToggle}`]: {
        width: 30,
        height: 30,
        padding: 8,
        borderRadius: "50%",
        "&:hover": {
            backgroundColor: "#EAEAEA"
        }
    }
}));

export { Root, classes, PopoverPrice };
