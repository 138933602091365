import fetchRequest from "./fetchRequest";
import imageCompression from "browser-image-compression";
import { encode } from "blurhash"; // Import the BlurHash library

const valid_image_types = [
    "image/jpg",
    "image/jpeg",
    "image/png",
    "image/svg+xml",
    "image/x-icon",
    "image/webp"
];

export const saveImage = async (image, identifier, recordType) => {
    try {
        const options = {
            maxSizeMB: 1, // (maximum file size in MB)
            maxWidthOrHeight: 1920, // max width or height in pixels
            useWebWorker: true
        };

        const imageType = image?.name?.split(".").pop().toLowerCase();
        let compressedImage;
        if (imageType === "svg") {
            compressedImage = image;
        } else {
            compressedImage = await imageCompression(image, options);
        }

        // Generate the blurhash
        const canvas = document.createElement("canvas");
        const ctx = canvas.getContext("2d");
        const img = new Image();
        img.src = URL.createObjectURL(compressedImage);
        await new Promise((resolve) => {
            img.onload = () => {
                canvas.width = img.width;
                canvas.height = img.height;
                ctx.drawImage(img, 0, 0);
                resolve();
            };
        });
        const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
        const blurhash = encode(
            imageData.data,
            imageData.width,
            imageData.height,
            4,
            4
        );

        const {
            response: { signedUrl, assetUrl }
        } = await fetchRequest("/media/upload-url", {
            method: "POST",
            body: JSON.stringify({
                path: `${identifier}/media/${recordType}/${compressedImage.name.replace(
                    new RegExp("[^a-zA-Z.0-9]+", "g"),
                    ""
                )}`,
                blurhash: blurhash
            })
        });

        if (!valid_image_types.includes(compressedImage.type)) {
            return [
                false,
                "Unsupported file format! Valid extension are .jpg, .jpeg, .png, .svg and .ico"
            ];
        }

        const blob = new Blob([compressedImage], {
            type: compressedImage.type
        });

        const base64DataForImage = await blobToBase64(blob);
        const webpRes = await fetchRequest(`/media/upload-url`, {
            method: "PUT",
            body: JSON.stringify({ imageData: base64DataForImage, signedUrl })
        });
        if (webpRes.success) {
            return [true, assetUrl];
        } else {
            throw new Error(
                "Something went wrong when uploading your image, please try again"
            );
        }
    } catch (e) {
        return [false, e.message];
    }
};

// Create a function to convert the Blob to Base64
function blobToBase64(blob) {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = function () {
            const base64String = reader.result.split(",")[1];
            resolve(base64String);
        };
        reader.onerror = function (error) {
            reject(error);
        };
        reader.readAsDataURL(blob);
    });
}
