import { styled } from "@mui/material/styles";

const classes = {
    container: `container`,
    top: `top`,
    form: `form`,
    actions: `actions`,
    title: `title`,
    removePriceContainer: `removePriceContainer`,
    removePrice: `removePrice`,
    closeButtonContainer: `closeButtonContainer`,
    closeButton: `closeButton`,
    sectionClose: `sectionClose`,
    toggleLabel: `toggleLabel`,
    switchLabel: `switchLabel`,
    switch: `switch`,
    switchDisabled: `switchDisabled`,
    pricesRow: `pricesRow`,
    iconCircle: `iconCircle`,
    textField: `textField`,
    priceTag: `priceTag`,
    multiline: `multiline`,
    pricesInfo: `pricesInfo`,
    select: `select`,
    selectPlaceholder: `selectPlaceholder`,
    notationsSelect: `notationsSelect`,
    notationRow: `notationRow`,
    selectedNotation: `selectedNotation`,
    checkbox: `checkbox`,
    checkboxActive: `checkboxActive`,
    tag: `tag`,
    addNotationContainer: `addNotationContainer`,
    addNotationIcon: `addNotationIcon`,
    selectPopper: `selectPopper`,
    labelRow: `labelRow`,
    noOptions: `noOptions`,
    selectRow: `selectRow`,
    customClock: `customClock`,
    amFocus: `amFocus`,
    pmFocus: `pmFocus`,
    infoMessage: `infoMessage`
};
const Root = styled("div")(({ theme }) => ({
    [`&.${classes.container}`]: {
        position: "absolute",
        display: "flex",
        flexDirection: "column",
        right: 0,
        width: 550,
        overflowX: "hidden",
        height: "100vh",
        overflow: "auto",
        background: "white",
        borderRadius: "20px 0 0 20px",
        padding: "0",
        boxShadow: "0px 10px 30px rgba(31, 19, 0, 0.3)"
    },
    [`& .${classes.top}`]: {
        top: 0,
        padding: "0 32px",
        zIndex: 2,
        position: "sticky",
        background: "white",
        borderBottom: "1px solid #D9D9D9",
        filter: "drop-shadow(0px 1px 4px rgba(37, 37, 37, 0.05))"
    },
    [`& .${classes.form}`]: {
        paddingLeft: 32,
        paddingRight: 32,
        minHeight: 150,
        overflowY: "auto",
        flexGrow: 1,
        display: "flex",
        flexDirection: "column",
        paddingBottom: 32
    },
    [`& .${classes.actions}`]: {
        gap: 10,
        display: "flex",
        padding: "16px 32px",
        position: "sticky",
        borderTop: "1px solid #D9D9D9",
        flexDirection: "row-reverse",
        background: "white",
        filter: "drop-shadow(0px -1px 4px rgba(37, 37, 37, 0.05))"
    },
    [`& .${classes.title}`]: {
        fontSize: 20,
        fontWeight: 700,
        color: theme.colors.grey[900],
        margin: "0 0 0.5em 0"
    },
    [`& .${classes.removePriceContainer}`]: {
        height: 28,
        width: 28,
        cursor: "pointer",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        margin: 8
    },
    [`& .${classes.removePrice}`]: {
        "& path": {
            fill: theme.colors.grey[900]
        }
    },
    [`& .${classes.closeButtonContainer}`]: {
        marginTop: 6,
        height: 26,
        width: 26,
        borderRadius: "16px",
        transform: "translate(16px, 12px)",
        display: "flex",
        background: theme.colors.orange[700],
        alignItems: "center",
        cursor: "pointer",
        justifyContent: "center",
        "&:hover": {
            boxShadow: `0px 10px 25px ${theme.colors.orange[700]}59`
        }
    },
    [`& .${classes.closeButton}`]: {
        "& path": {
            fill: "white"
        },
        display: "flex",
        alignItems: "center"
    },
    [`& .${classes.sectionClose}`]: {
        width: "100%",
        display: "flex",
        flexDirection: "row-reverse"
    },
    [`& .${classes.toggleLabel}`]: {
        padding: "20px 0 0 20px",
        fontWeight: 500,
        margin: 0,
        fontSize: 12
    },
    [`& .${classes.switchLabel}`]: {
        marginLeft: "6px !important",
        "& .MuiFormControlLabel-label": {
            fontSize: 14,
            lineHeight: "16px",
            color: theme.colors.grey[500]
        },
        "&:hover .MuiFormControlLabel-label": {
            color: theme.colors.grey[900]
        }
    },
    [`& .${classes.switch}`]: {
        "& .MuiSwitch-switchBase": {
            color: `${theme.colors.orange[700]} !important`,
            "&.Mui-checked:hover": {
                backgroundColor: "rgba(250, 211, 184, 0.15) !important"
            }
        },
        "& .MuiSwitch-track": {
            backgroundColor: `${theme.colors.orange[200]} !important`
        }
    },
    [`& .${classes.switchDisabled}`]: {
        "&:hover": {
            backgroundColor: "rgba(250, 250, 250, 0.35)"
        }
    },
    [`& .${classes.pricesRow}`]: {
        display: "flex",
        flexDirection: "row",
        alignItems: "flex-end",
        "& svg": {
            width: 16,
            height: 16,
            "& path": {
                fill: theme.colors.orange[700]
            }
        }
    },
    [`& .${classes.iconCircle}`]: {
        cursor: "pointer",
        width: 50,
        marginLeft: 12,
        height: 44,
        borderRadius: "50%",
        border: `1px solid ${theme.colors.orange[700]}`,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        "&:hover": {
            background: theme.colors.orange[700],
            "& path": {
                fill: "white"
            }
        }
    },
    [`& .${classes.textField}`]: {
        marginTop: "28px !important",
        lineHeight: "44px !important",
        // border: "1px solid #B4B3B3",
        "& div.Mui-focused": {
            "& fieldset": {
                borderColor: `${theme.colors.orange[700]} !important`
            }
        },
        "& label.Mui-focused": {
            color: `${theme.colors.orange[700]} !important`
        },
        "& fieldset": {
            borderRadius: "50px",
            "& legend": {
                margin: "4px 0px 4px 2px"
            }
        },
        "& input": {
            padding: "12px 18px !important"
        },
        "& input, div": {
            fontSize: 14
        },
        "& label": {
            transition: "all 100ms linear !important"
        },
        "& label[data-shrink=true].Mui-focused": {
            color: `${theme.colors.orange[700]} !important`
        },
        "& label[data-shrink=true]": {
            padding: `4px${theme.browser.isSafari ? " 10px" : " 8px"}`,
            color: `${theme.colors.grey[900]} !important`,
            background: "white",
            fontWeight: 500,
            fontSize: "16px",
            top: -3,
            "& .MuiFormLabel-asterisk": {
                color: theme.colors.orange[700]
            }
        },
        "& label[data-shrink=false]": {
            paddingLeft: 7
        }
    },
    [`& .${classes.priceTag}`]: {
        width: 360,
        marginTop: "0 !important",
        "& div.Mui-focused": {
            "& fieldset": {
                borderColor: `${theme.colors.grey[500]} !important`
            }
        }
    },
    [`& .${classes.multiline}`]: {
        "& fieldset": {
            borderRadius: "20px !important"
        },
        "& .MuiInputBase-multiline": {
            paddingLeft: 20,
            alignItems: "flex-start",
            minHeight: 110
        }
    },
    [`& .${classes.pricesInfo}`]: {
        marginTop: 8,
        height: "13px",
        color: theme.colors.grey[400],
        "& svg": {
            transform: "translateY(2px)",
            width: 12,
            marginRight: 8,
            height: 12
        },
        "& path": {
            fill: theme.colors.grey[400]
        }
    },
    [`& .${classes.select}`]: {
        marginTop: "36px !important",
        "& .MuiSelect-select": {
            padding: "12px 16px 12px 18px !important"
        },
        "& .MuiAutocomplete-input": {
            padding: "3px 8px !important"
        },
        "& .MuiAutocomplete-inputRoot": {
            padding: "10px 12px 8px 12px !important"
        }
    },
    [`& .${classes.selectPlaceholder}`]: {
        margin: 0,
        color: theme.colors.grey[400]
    },
    [`& .${classes.notationsSelect}`]: {
        "& .MuiAutocomplete-inputRoot": {
            padding: 0
        }
    },
    [`& .${classes.notationRow}`]: {
        padding: "0 24px",
        cursor: "pointer",
        "&:hover": {
            background: theme.colors.grey[50]
        }
    },
    [`& .${classes.selectedNotation}`]: {
        background: theme.colors.orange[50]
    },
    [`& .${classes.checkbox}`]: {
        "& path": {
            borderRadius: 4
        }
    },
    [`& .${classes.checkboxActive}`]: {
        color: `${theme.colors.orange[700]} !important`,
        "&.Mui-checked:hover": {
            backgroundColor: "rgba(250, 211, 184, 0.3)"
        }
    },
    [`& .${classes.tag}`]: {
        margin: "3px 0 3px 6px !important",
        backgroundColor: `${theme.colors.orange[100]} !important`,
        border: `1px solid ${theme.colors.orange[200]} !important`,
        padding: "6px 8px 6px 12px !important",
        "& span": {
            fontSize: 11,
            fontWeight: 500,
            padding: 0
        },
        "& svg": {
            alignSelf: "baseline"
        },
        gap: 15,
        height: "25px !important"
    },
    [`& .${classes.addNotationContainer}`]: {
        display: "flex",
        cursor: "pointer",
        alignItems: "center",
        fontWeight: 400,
        color: theme.colors.grey[900],
        fontStyle: "italic",
        padding: "12px !important",
        pointerEvents: "all !important",
        "&:hover": {
            background: theme.colors.grey[50]
        }
    },
    [`& .${classes.addNotationIcon}`]: {
        cursor: "pointer",
        width: 25,
        marginRight: 12,
        padding: 7.5,
        float: "left",
        height: 25,
        borderRadius: "50%",
        border: `1px solid ${theme.colors.orange[700]}`,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        background: theme.colors.orange[700],
        "& path": {
            fill: "white"
        }
    },
    [`& .${classes.selectPopper}`]: {
        boxShadow: "0px 4px 12px rgba(34, 34, 34, 0.12) !important",
        borderRadius: "20px !important",
        border: "1px solid #EAEAEA",
        padding: "12px 0",
        marginRight: "-18px",
        marginLeft: "18px",
        "& .MuiAutocomplete-noOptions": {
            padding: 0
        },
        "& .MuiAutocomplete-listbox": {
            padding: 0
        }
    },
    [`& .${classes.labelRow}`]: {
        pointerEvents: "none",
        padding: "12px 0"
    },
    [`& .${classes.noOptions}`]: {
        pointerEvents: "none !important"
    },
    [`& .${classes.infoMessage}`]: {
        width: 486,
        fontSize: 12,
        display: "flex",
        fontStyle: "italic",
        flexDirection: "row",
        gap: 8,
        color: "rgba(34,34,34,0.75)",
        "& svg": {
            "& path": {
                fill: "rgba(34,34,34,0.75)"
            }
        }
    }
}));

const selectStyles = (theme) => ({
    cursor: "pointer",
    display: "flex",
    borderBottom: "1px solid rgba(234, 234, 234, 0.5)",
    flexDirection: "column",
    borderRadius: "20px !important",
    justifyContent: "center",
    alignItems: "flex-start",
    color: theme.colors.grey[700],
    boxShadow:
        "0px 5px 5px -3px rgba(0,0,0,0.05), 0px 8px 10px 1px rgba(0,0,0,0.03), 0px 3px 14px 2px rgba(0,0,0,0.1) !important",
    border: "1px solid #d9d9d9 !important",
    "& ul": {
        width: "100%"
    },
    "& li": {
        height: 48,
        width: "100%",
        display: "flex !important",
        alignItems: "center !important",
        padding: "0 16px !important",
        background: "white !important",
        color: "rgba(0, 0, 0, 0.87)",
        "&:hover": {
            background: `${theme.colors.orange[50]} !important`
        }
    },
    "& li[aria-selected='true']": {
        background: `${theme.colors.orange[50]} !important`,
        color: theme.colors.orange[900]
    }
});

const customClock = (theme) => ({
    "& .MuiClockPointer-thumb": {
        backgroundColor: theme.colors.orange[700],
        border: `16px solid ${theme.colors.orange[700]}`
    },
    "& .MuiClockPointer-root": {
        backgroundColor: theme.colors.orange[700]
    },
    "& .MuiClock-pin": {
        backgroundColor: theme.colors.orange[700]
    },
    "& .MuiClock-squareMask": {
        background: "#FAFAFA"
    },
    "&.amFocus .MuiClock-amButton": {
        backgroundColor: theme.colors.orange[700],
        color: "white"
    },
    "&.amFocus .MuiClock-amButton:hover": {
        backgroundColor: theme.colors.orange[500]
    },
    "&.pmFocus .MuiClock-pmButton": {
        backgroundColor: theme.colors.orange[700],
        color: "white"
    },
    "&.pmFocus .MuiClock-pmButton:hover": {
        backgroundColor: theme.colors.orange[500]
    }
});

export { Root, classes, selectStyles, customClock };
