import { useState } from "react";

export const useAlert = () => {
    const [state, setState] = useState({
        open: false,
        severity: "info",
        title: "",
        message: "",
        onClose: onClose
    });

    function onClose() {
        setState((prev) => ({ ...prev, open: false }));
    }

    const changeHandler = (attributes, timeout = 3500) => {
        let hideTimeout;
        clearTimeout(hideTimeout);
        setState((prev) => ({ ...prev, open: true, ...attributes }));
        hideTimeout = setTimeout(onClose, timeout);
    };

    return [state, changeHandler];
};
