import { CircularProgress, Grid } from "@mui/material";
import { Root, classes } from "./Styles";
import { Add } from "@mui/icons-material";
import React from "react";
import FormCreateTag from "../../../components/tags/FormCreateTag";
import { getTags } from "../../../utils/Tag/tag-helper";
import Tag from "../../../components/UI/Tag";

const Tags = ({ searchTerm }: { searchTerm: string }) => {
    const [loading, setLoading] = React.useState(false);
    const [showCreateTagForm, setShowCreateTagForm] = React.useState(false);
    const isEffectExecutedRef = React.useRef(false);
    const [currentTag, setCurrentTag] = React.useState<{
        _id: string;
        tag_type: string;
        tag_name: string;
    } | null>(null);
    const [tags, setTags] = React.useState<
        {
            _id: string;
            tag_type: string;
            tag_name: string;
        }[]
    >([]);
    const [filteredTags, setFilteredTags] = React.useState<
        {
            _id: string;
            tag_type: string;
            tag_name: string;
        }[]
    >([]);

    async function get() {
        setLoading(true);
        const { response, success } = await getTags();
        setLoading(false);
        if (success && response) {
            setTags(response as any);
        }
    }

    React.useMemo(() => {
        if (!isEffectExecutedRef.current) {
            get();
            isEffectExecutedRef.current = true;
        }
    }, []);

    React.useEffect(() => {
        if (searchTerm !== "") {
            const filtered = tags.filter((tag) =>
                tag.tag_name.toLowerCase().includes(searchTerm.toLowerCase())
            );
            setFilteredTags(filtered);
        } else {
            setFilteredTags(tags);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tags]);

    function handleFormClose() {
        setShowCreateTagForm(false);
        get();
        setCurrentTag(null);
    }

    function handleTagClick(tag: {
        _id: string;
        tag_type: string;
        tag_name: string;
    }) {
        setCurrentTag(tag);
        setShowCreateTagForm(true);
    }

    return (
        <Grid container>
            <Grid item xs={12}>
                {loading ? (
                    <div
                        style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            padding: "1.5rem 0"
                        }}
                    >
                        <CircularProgress
                            style={{
                                width: 60,
                                height: 60,
                                color: "#CC5803"
                            }}
                        />
                    </div>
                ) : (
                    <div
                        style={{
                            padding: "1.5rem 0",
                            display: "flex",
                            justifyContent: "flex-start",
                            alignItems: "center",
                            flexWrap: "wrap"
                        }}
                    >
                        {filteredTags.map((tag, index) => (
                            <Tag
                                key={"tag-" + index}
                                label={tag.tag_name}
                                onClick={() => handleTagClick(tag)}
                            ></Tag>
                        ))}
                    </div>
                )}
                <Root
                    className={classes.addTagContainer}
                    onClick={() => setShowCreateTagForm(true)}
                >
                    <span className={classes.addTagIconContainer}>
                        <Add className={classes.addTagIcon} />
                    </span>
                    <p className={classes.addTagText}>Add New Tag</p>
                </Root>
                <FormCreateTag
                    tag={currentTag}
                    show={showCreateTagForm}
                    onClose={handleFormClose}
                ></FormCreateTag>
            </Grid>
        </Grid>
    );
};

export default Tags;
