import { fetchRequest } from "../index";

export const getItem = (id, identifier) => {
    return fetchRequest(`/item/${identifier}/${id}`, {
        method: "GET"
    });
};

export const getItems = (status = null, identifier, order, type) => {
    const queryParams = `?order=${order === "A-Z" ? 1 : -1}&type=${
        type || "all"
    }&status=${status || "active"}`;

    return fetchRequest(`/item/${identifier}${queryParams}`, {
        method: "GET"
    });
};

export const getDescription = (drinkType, drinkName, textType, identifier) => {
    return fetchRequest(`/item/${identifier}/autoGenerateDescriptions`, {
        method: "POST",
        body: JSON.stringify({
            internal_name: drinkName,
            text_type: textType,
            drink_type: drinkType
        })
    });
};

export const createItem = (item, identifier) => {
    return fetchRequest(`/item/${identifier}`, {
        method: "POST",
        body: JSON.stringify(item)
    });
};

export const updateItem = (item, identifier, id) => {
    return fetchRequest(`/item/${identifier}/${id}`, {
        method: "PUT",
        body: JSON.stringify(item)
    });
};

export const searchActiveItems = (value, identifier, order) => {
    return fetchRequest(`/item/${identifier}/search`, {
        method: "POST",
        body: JSON.stringify({
            internal_name: value,
            status: "active",
            order: order === "A-Z" ? 1 : -1
        })
    });
};

export const searchInactiveItems = (value, identifier, order) => {
    return fetchRequest(`/item/${identifier}/search`, {
        method: "POST",
        body: JSON.stringify({
            internal_name: value,
            status: "draft",
            order: order === "A-Z" ? 1 : -1
        })
    });
};

export const searchTrashItems = (value, identifier, order) => {
    return fetchRequest(`/item/${identifier}/search`, {
        method: "POST",
        body: JSON.stringify({
            internal_name: value,
            status: "trash",
            order: order === "A-Z" ? 1 : -1
        })
    });
};

export const duplicateItem = (id, identifier, newName) => {
    return fetchRequest(`/item/${identifier}/${id}/duplicate`, {
        method: "POST",
        body: JSON.stringify({ newName })
    });
};

export const markAsTrashItem = (id, identifier) => {
    return fetchRequest(`/item/${identifier}/${id}/trash`, {
        method: "POST"
    });
};

export const markAsActiveItem = (id, identifier) => {
    return fetchRequest(`/item/${identifier}/${id}/active`, {
        method: "POST"
    });
};

export const markAsInactiveItem = (id, identifier) => {
    return fetchRequest(`/item/${identifier}/${id}/inactive`, {
        method: "POST"
    });
};

export const deleteItem = (id, identifier) => {
    return fetchRequest(`/item/${identifier}/${id}`, {
        method: "DELETE"
    });
};

export const findItemsById = (itemIds, identifier) => {
    return fetchRequest(`/item/${identifier}/findByIds`, {
        method: "POST",
        body: JSON.stringify({ itemIds })
    });
};
