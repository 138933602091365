import { styled } from "@mui/material/styles";

const classes = {
    labelText: `labelText`,
    table: `table`,
    tabsRow: `tabsRow`,
    tabsContainer: `tabsContainer`,
    addSectionButton: `addSectionButton`,
    billingButton: `billingButton`
};

const Root = styled("div")(({ theme }) => ({
    [`& .${classes.labelText}`]: {
        color: "black",
        "&:hover": {
            color: theme.colors.orange[700]
        },
        fontSize: "16px",
        fontWeight: 400,
        textTransform: "none"
    },
    [`& .${classes.table}`]: {
        margin: "0 3rem"
    },
    [`& .${classes.tabsRow}`]: {
        display: "flex",
        gap: 25,
        justifyContent: "space-between",
        overflowX: "auto",
        borderBottom: "0.3px solid #9D9D9D"
    },
    [`& .${classes.tabsContainer}`]: {
        minHeight: "46px !important",
        "& .MuiTabs-indicator": {
            borderBottom: "2px solid #CC5803"
        },
        "& .Mui-selected > span": {
            fontWeight: 500
        },
        "& .MuiTab-root": {
            padding: "0 20px"
        },
        "& .MuiTabs-scroller": {
            overflowX: "auto !important"
        },
        "& .MuiTabs-scroller::-webkit-scrollbar": {
            height: "3px"
        }
    },
    [`& .${classes.addSectionButton}`]: {
        fontWeight: 400,
        fontStyle: "italic",
        fontSize: "14px",
        lineHeight: "17px",
        cursor: "pointer",
        width: "105px",
        display: "flex",
        alignItems: "center",
        "& svg": {
            width: 24,
            height: 24,
            padding: 6,
            marginRight: 10,
            borderRadius: "50%",
            border: `1px solid ${theme.colors.orange[700]}`,
            "& path": {
                fill: theme.colors.orange[700]
            }
        }
    },
    [`& .${classes.billingButton}`]: {
        fontWeight: 400,
        fontStyle: "italic",
        fontSize: "14px",
        marginTop: 24,
        lineHeight: "17px",
        cursor: "pointer",
        display: "flex",
        alignItems: "center",
        "& svg": {
            width: 24,
            height: 24,
            padding: 6,
            marginRight: 10,
            borderRadius: "50%",
            border: `1px solid ${theme.colors.orange[700]}`,
            "& path": {
                fill: theme.colors.orange[700]
            }
        }
    }
}));

export { Root, classes };
