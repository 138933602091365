import { fetchRequest } from "../index";

export const getCollections = (identifier, order) => {
    return fetchRequest(
        `/collection/${identifier}/active?order=${order === "A-Z" ? 1 : -1}`,
        {
            method: "GET"
        }
    );
};

export const getCollectionById = (id, identifier) => {
    return fetchRequest(`/collection/${identifier}/${id}`);
};

export const createCollection = (body, identifier) => {
    return fetchRequest(`/collection/${identifier}`, {
        method: "POST",
        body: JSON.stringify(body)
    });
};

export const updateCollection = (id, body, identifier) => {
    return fetchRequest(`/collection/${identifier}/${id}`, {
        method: "PUT",
        body: JSON.stringify(body)
    });
};

export const getDraftCollections = (identifier, order) => {
    return fetchRequest(
        `/collection/${identifier}/draft?order=${order === "A-Z" ? 1 : -1}`,
        {
            method: "GET"
        }
    );
};

export const getTrashCollections = (identifier, order) => {
    return fetchRequest(
        `/collection/${identifier}/trash?order=${order === "A-Z" ? 1 : -1}`,
        {
            method: "GET"
        }
    );
};

export const searchActiveCollections = (value, identifier, order) => {
    return fetchRequest(
        `/collection/${identifier}/search?order=${order === "A-Z" ? 1 : -1}`,
        {
            method: "POST",
            body: JSON.stringify({
                internal_name: value,
                status: "active"
            })
        }
    );
};

export const searchDraftCollections = (value, identifier, order) => {
    return fetchRequest(
        `/collection/${identifier}/search?order=${order === "A-Z" ? 1 : -1}`,
        {
            method: "POST",
            body: JSON.stringify({
                internal_name: value,
                status: "draft"
            })
        }
    );
};

export const searchTrashCollections = (value, identifier, order) => {
    return fetchRequest(
        `/collection/${identifier}/search?order=${order === "A-Z" ? 1 : -1}`,
        {
            method: "POST",
            body: JSON.stringify({
                internal_name: value,
                status: "trash"
            })
        }
    );
};

export const duplicateCollection = (id, identifier, newName) => {
    return fetchRequest(`/collection/${identifier}/${id}/duplicate`, {
        method: "POST",
        body: JSON.stringify({ newName })
    });
};

export const markAsDraftCollection = (id, identifier) => {
    return fetchRequest(`/collection/${identifier}/${id}/draft`, {
        method: "PUT"
    });
};

export const markAsTrashCollection = (id, identifier) => {
    return fetchRequest(`/collection/${identifier}/${id}/trash`, {
        method: "PUT"
    });
};

export const markAsActiveCollection = (id, identifier) => {
    return fetchRequest(`/collection/${identifier}/${id}/active`, {
        method: "PUT"
    });
};

export const deleteCollection = (id, identifier) => {
    return fetchRequest(`/collection/${identifier}/${id}/delete`, {
        method: "DELETE"
    });
};
