import { Switch } from "@mui/material";
import { Root, classes } from "../../assets/styles/components/Switch";

const CustomSwitch = ({
    checked,
    onChange,
    activeLabel = "Active",
    inactiveLabel = "Inactive",
    ...props
}) => {
    return (
        <Root
            control={
                <Switch
                    checked={checked}
                    classes={{
                        switchBase: classes.switchDisabled
                    }}
                    className={checked ? classes.switch : ""}
                />
            }
            label={checked ? activeLabel : inactiveLabel}
            onChange={onChange}
            className={classes.switchLabel}
            {...props}
        />
    );
};

export default CustomSwitch;
