import { useContext, useState } from "react";
import AuthGlobal from "../context/store/global";
import Button from "./UI/Button";

import { runPublishCompiler } from "../utils/Menus/menu-helper";
import ConfirmAction from "./modals/ConfirmAction";
import { useAlert } from "../hooks/useAlert";
import { refreshRestaurant } from "../utils/Restaurants/restaurant-helper";
import Alert from "./UI/Alert";
import Icon from "./UI/Icon";
import { useSearchParams } from "react-router-dom";
import { styled } from "@mui/material/styles";

const classes = {
    infoMessage: `infoMessage`
};

const Root = styled("span")(({ theme }) => ({
    [`&.${classes.infoMessage}`]: {
        width: 486,
        fontSize: 11,
        display: "flex",
        flexDirection: "row",
        gap: 8,
        color: "rgba(34,34,34,0.75)",
        "& svg": {
            "& path": {
                fill: "rgba(34,34,34,0.75)"
            }
        }
    }
}));

const PublishButton = () => {
    const {
        dispatch,
        globalState: {
            current_restaurant: { identifier, outdated },
            current_restaurant
        }
    } = useContext(AuthGlobal);
    const [alert, handleAlert] = useAlert();

    const [showModal, setShowModal] = useState(false);
    const [disableBtn, setDisableBtn] = useState(false);

    const publish = async () => {
        setDisableBtn(true);
        const { success, response } = await runPublishCompiler(identifier);
        if (success && response) {
            await refreshRestaurant(dispatch, identifier, current_restaurant);
            handleAlert({
                severity: "success",
                title: "Success",
                message: response.message
            });
        } else {
            handleAlert({
                severity: "error",
                title: "Error",
                message: response.message
            });
        }
        setDisableBtn(false);
        setShowModal(false);
    };

    const searchParams = useSearchParams()[0];
    const infoMessage = () => {
        let tabname = "";
        if (window.location.pathname === "/settings") {
            if (searchParams.get("tab") === "6") {
                tabname = "Presenter Settings";
            } else if (searchParams.get("tab") === "7") {
                tabname = "Web Template";
            }
            return tabname.length > 0 ? (
                <Root key={0} className={classes.infoMessage}>
                    <Icon name="info" />
                    <p>
                        Be sure to save the changes on your {tabname} before
                        publishing your restaurant, otherwise you'll lose any
                        unsaved changes
                    </p>
                </Root>
            ) : (
                ""
            );
        }
        return "";
    };
    return (
        <>
            <Alert {...alert} />
            <Button
                innerText="Publish Changes"
                type="primary"
                color="orange"
                onClick={() => setShowModal(true)}
                disableBtn={!outdated}
            />

            <ConfirmAction
                open={showModal}
                actionConfirm={publish}
                actionCancel={() => setShowModal(false)}
                icon="publish"
                dialogText={`Do you want to publish the changes in your menu?`}
                note={infoMessage()}
                disableBtn={disableBtn}
            />
        </>
    );
};

export default PublishButton;
