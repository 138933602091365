import { styled } from "@mui/material/styles";

const classes = {
    infoMessage: `infoMessage`
};
const Root = styled("div")(({ theme }) => ({
    [`&.${classes.infoMessage}`]: {
        marginBottom: 16,
        justifyContent: "center",
        "& p": {
            fontSize: "14px",
            fontStyle: "italic",
            lineHeight: "16px",
            color: "#000"
        }
    }
}));

export { Root, classes };
