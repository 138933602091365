import { styled } from "@mui/material/styles";

const classes = {
    container: `container`,
    top: `top`,
    title: `title`,
    sectionClose: `sectionClose`,
    content: `content`,
    paddingRight: `paddingRight`,
    footer: `footer`,
    footerButtonsContainer: `footerButtonsContainer`,
    pricesInfo: `pricesInfo`,
    textField: `textField`
};
const Root = styled("div")(({ theme }) => ({
    [`&.${classes.container}`]: {
        overflowX: "hidden",
        overflow: "auto",
        padding: "0",
        margin: 0,
        minHeight: 227,
        width: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between"
    },
    [`& .${classes.top}`]: {
        height: 53,
        width: "100%",
        borderBottom: "1px solid #D9D9D9",
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        overflow: "hidden"
    },
    [`& .${classes.title}`]: {
        fontFamily: "Roboto",
        fontSize: 16,
        fontWeight: 500,
        lineHeight: "22px",
        margin: 0,
        padding: 0,
        letterSpacing: 0.15,
        marginLeft: 25,
        marginTop: 5
    },
    [`& .${classes.sectionClose}`]: {
        width: 30,
        height: 30,
        borderRadius: "50%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        marginRight: 16,
        cursor: "pointer",
        transition: "background-color 0.2s ease-in-out",
        "&:hover": {
            backgroundColor: "#F5F5F5"
        },
        "&:active": {
            backgroundColor: "#E0E0E0"
        }
    },
    [`& .${classes.content}`]: {
        height: "100%",
        width: "100%",
        padding: "0 25px 0 25px"
    },
    [`& .${classes.paddingRight}`]: {
        paddingRight: 10
    },
    [`& .${classes.footer}`]: {
        width: "100%",
        borderTop: "1px solid #D9D9D9",
        overflow: "hidden",
        padding: "0 25px"
    },
    [`& .${classes.footerButtonsContainer}`]: {
        height: 53,
        display: "flex",
        flexDirection: "row-reverse",
        justifyContent: "space-between",
        alignItems: "center"
    },
    [`& .${classes.pricesInfo}`]: {
        margin: "10px 0 25px 0",
        height: "13px",
        color: theme.colors.grey[400],
        "& svg": {
            transform: "translateY(2px)",
            width: 12,
            marginRight: 8,
            height: 12
        },
        "& path": {
            fill: theme.colors.grey[400]
        }
    },
    [`& .${classes.textField}`]: {
        marginTop: "28px !important",
        lineHeight: "44px !important",
        // border: "1px solid #B4B3B3",
        "& div.Mui-focused": {
            "& fieldset": {
                borderColor: `${theme.colors.orange[700]} !important`
            }
        },
        "& label.Mui-focused": {
            color: `${theme.colors.orange[700]} !important`
        },
        "& fieldset": {
            borderRadius: "50px",
            "& legend": {
                margin: "4px 0px 4px 2px"
            }
        },
        "& input": {
            padding: "12px 18px !important"
        },
        "& input, div": {
            fontSize: 14
        },
        "& label": {
            transition: "all 100ms linear !important"
        },
        "& label[data-shrink=true].Mui-focused": {
            color: `${theme.colors.orange[700]} !important`
        },
        "& label[data-shrink=true]": {
            padding: `4px${theme.browser.isSafari ? " 10px" : " 8px"}`,
            color: `${theme.colors.grey[900]} !important`,
            background: "white",
            fontWeight: 500,
            fontSize: "16px",
            top: -3,
            "& .MuiFormLabel-asterisk": {
                color: theme.colors.orange[700]
            }
        },
        "& label[data-shrink=false]": {
            paddingLeft: 7
        }
    }
}));

export { Root, classes };
