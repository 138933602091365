import Icon from "../../components/UI/Icon";
import Tag from "../../components/UI/Tag";
import Button from "../../components/UI/Button";
import { useContext, useState } from "react";
import ConfirmAction from "../../components/modals/ConfirmAction";
import { fetchRequest } from "../../utils";
import { useAlert } from "../../hooks/useAlert";
import Alert from "../../components/UI/Alert";
import FormAlertAction from "./FormAlertAction";
import { AlertsContext } from "../../context/store/Alerts";
import { styled } from "@mui/material/styles";

const classes = {
    card: `card`,
    cardIcon: `cardIcon`,
    info: `info`,
    name: `name`,
    type: `type`,
    separator: `separator`,
    text: `text`,
    actions: `actions`
};

const Root = styled("div")(({ theme }) => ({
    [`&.${classes.card}`]: {
        border: "1px solid #D3D3D3",
        height: "100%",
        borderRadius: 20,
        padding: "20px 24px",
        width: "100%",
        display: "flex",
        flexDirection: "column"
    },
    [`& .${classes.cardIcon}`]: {
        "& svg": {
            width: 20,
            height: 20
        },
        display: "flex",
        background: "#FFDEDE",
        borderRadius: 15,
        padding: 10,
        alignItems: "center",
        justifyContent: "center"
    },
    [`& .${classes.info}`]: {
        display: "flex",
        flexGrow: 1,
        flexDirection: "column",
        gap: 6
    },
    [`& .${classes.name}`]: {
        fontWeight: 500,
        fontSize: 18,
        lineHeight: "21px",
        margin: 0
    },
    [`& .${classes.type}`]: {
        color: theme.colors.grey[500],
        fontWeight: 300,
        "& b": {
            fontWeight: 500
        },
        fontSize: 14,
        lineHeight: "16px",
        margin: 0
    },
    [`& .${classes.separator}`]: {
        margin: "24px 0 20px",
        borderBottom: "1px solid #D9D9D9",
        height: 0
    },
    [`& .${classes.text}`]: {
        fontWeight: 300,
        fontSize: 14,
        flexGrow: 1
    },
    [`& .${classes.actions}`]: {
        marginTop: 20,
        display: "flex",
        flexWrap: "wrap",
        gap: 20
    }
}));

const AlertCard = ({ alert, archived = false }) => {
    const [notif, handleAlert] = useAlert();
    const [showActionModal, setShowActionModal] = useState(false);
    const data = useContext(AlertsContext);
    const daysDifference = parseInt(
        (new Date().getTime() - new Date(alert.created_at).getTime()) /
            (1000 * 3600 * 24)
    );

    const [showDismissConfirmation, setShowDismissConfirmation] =
        useState(false);

    const updateAlert = async (id, status) => {
        const { success, response } = await fetchRequest(
            `/alert/${id}/${status}`,
            { method: "PUT" }
        );
        if (success && response) {
            handleAlert({
                severity: "success",
                title: "Success",
                message: response.message
            });
        } else {
            handleAlert({
                severity: "error",
                title: "Error",
                message: response.message
            });
        }
        setTimeout(data.getData, 1000);
    };

    return (
        <>
            <Alert {...notif} />
            <Root className={classes.card}>
                <div style={{ display: "flex", gap: 18, flexWrap: "wrap" }}>
                    <span>
                        <span className={classes.cardIcon}>
                            <Icon name="costIncrease" />
                        </span>
                    </span>
                    <span className={classes.info}>
                        <h4 className={classes.name}>
                            {alert.item_id.internal_name}
                        </h4>
                        <h5 className={classes.type}>
                            <b>Cost Increase:</b>{" "}
                            {daysDifference > 0
                                ? `${daysDifference} day${
                                      daysDifference > 1 ? "s" : ""
                                  } ago`
                                : "Today"}
                        </h5>
                        <span
                            style={{
                                display: "flex",
                                alignItems: "center",
                                gap: 12,
                                flexWrap: "wrap"
                            }}
                        >
                            <Tag label={alert.tag_id.tag_name} noMargin />
                            <span
                                style={{
                                    display: "flex",
                                    alignItems: "center"
                                }}
                            >
                                <p
                                    style={{
                                        lineHeight: "32px",
                                        marginRight: 5
                                    }}
                                >
                                    {`${alert.number_of_days}-Day: `}
                                </p>
                                <Icon name="arrowUp" />
                                <p
                                    style={{
                                        lineHeight: "32px",
                                        marginLeft: 7,
                                        fontWeight: 700
                                    }}
                                >
                                    {`${alert.increase_percentage}%`}
                                </p>
                            </span>
                        </span>
                    </span>
                </div>
                <div className={classes.separator} />
                <span className={classes.text}>
                    The cost of{" "}
                    <b>
                        {alert.tag_id.tag_name} has increased by{" "}
                        {alert.increase_percentage}%
                    </b>{" "}
                    since you last set your price
                </span>
                <span className={classes.actions}>
                    <Button
                        type="secondary"
                        color="dark"
                        innerText={archived ? "Clear alert" : "Dismiss"}
                        onClick={() => setShowDismissConfirmation(true)}
                        style={{ flexGrow: 1 }}
                    />
                    <Button
                        type="primary"
                        color="dark"
                        innerText="Take Action"
                        onClick={() => setShowActionModal(true)}
                        style={{ flexGrow: 1 }}
                    />
                </span>
                <ConfirmAction
                    dialogText={`Are you sure want to ${
                        ["new", "email sent"].includes(alert.status)
                            ? "dismiss"
                            : "delete"
                    } this alert?`}
                    open={showDismissConfirmation}
                    icon="bell"
                    actionCancel={() => setShowDismissConfirmation(false)}
                    actionConfirm={async () => {
                        await updateAlert(
                            alert._id,
                            ["new", "email sent"].includes(alert.status)
                                ? "dismissed"
                                : "cleared"
                        );
                        setShowDismissConfirmation(false);
                    }}
                />
                <FormAlertAction
                    show={showActionModal}
                    onClose={() => setShowActionModal(false)}
                    updateAlert={updateAlert}
                    alert={alert}
                />
            </Root>
        </>
    );
};

export default AlertCard;
