import { styled } from "@mui/material/styles";

const classes = {
    banner: `banner`,
    publishMessage: `publishMessage`,
    inlinePublish: `inlinePublish`,
    dismissBanner: `dismissBanner`
};

const Root = styled("div")(({ theme }) => ({
    [`&.${classes.banner}`]: {
        background: theme.colors.orange[100],
        position: "absolute",
        justifyContent: "center !important",
        top: 0,
        left: 0,
        display: "flex",
        alignItems: "center",
        width: "100%",
        fontSize: "13px",
        borderBottom: "1px solid" + theme.colors.orange[200]
    },
    [`& .${classes.publishMessage}`]: {
        height: "40px !important",
        display: "flex",
        justifyContent: "center",
        textAlign: "center",
        width: "100%",
        padding: "0 !important",
        alignItems: "center",
        "& svg": {
            margin: "0 8px",
            "& path": {
                fill: theme.colors.orange[700]
            }
        }
    },
    [`& .${classes.inlinePublish}`]: {
        margin: "0 8px",
        fontWeight: 500,
        color: theme.colors.orange[700],
        fontSize: "13px",
        textDecoration: "underline",
        cursor: "pointer"
    },
    [`& .${classes.dismissBanner}`]: {
        width: 18,
        marginRight: 12,
        height: "18px !important",
        padding: "0 !important",
        cursor: "pointer",
        textAlign: "center",
        display: "flex",
        alignItems: "center"
    }
}));

export { Root, classes };
