import { Checkbox, FormControlLabel } from "@mui/material";
import { styled } from "@mui/material/styles";
import Icon from "./Icon";

const classes = {
    switchDisabled: `switchDisabled`,
    checkbox: `checkbox`,
    checkboxActive: `checkboxActive`
};

const Root = styled(Checkbox)(({ theme }) => ({
    [`&.${classes.switchDisabled}`]: {
        "&:hover": {
            backgroundColor: "rgba(250, 250, 250, 0.35) !important"
        }
    },
    [`&.${classes.checkbox}`]: {
        "& path": {
            borderRadius: 4
        }
    },
    [`&.${classes.checkboxActive}`]: {
        color: `${theme.colors.orange[700]} !important`,
        "&.Mui-checked:hover": {
            backgroundColor: "rgba(250, 211, 184, 0.3)"
        }
    }
}));

const CustomCheckBox = ({ label, checked }) => {
    return (
        <FormControlLabel
            control={
                <Root
                    classes={
                        !checked
                            ? {
                                  root: classes.switchDisabled
                              }
                            : {}
                    }
                    checked={checked}
                    icon={<Icon name="checkboxEmpty" />}
                    checkedIcon={<Icon name="checkboxActive" />}
                    className={`${classes.checkbox} ${
                        checked ? classes.checkboxActive : ""
                    }`}
                    style={{
                        pointerEvents: "auto"
                    }}
                />
            }
            label={label}
            style={{
                pointerEvents: "none"
            }}
        />
    );
};

export default CustomCheckBox;
