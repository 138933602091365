import Icon from "./UI/Icon";
import { styled } from "@mui/material/styles";

const classes = {
    noResults: `noResults`
};

const Root = styled("div")(({ theme }) => ({
    [`&.${classes.noResults}`]: {
        width: "100%",
        textAlign: "center",
        paddingTop: 30,
        "& svg": {
            width: 184,
            height: 100,
            marginBottom: 5
        },
        fontWeight: 500,
        fontSize: "14px"
    }
}));

const NoResults = ({ text = "No search results" }) => {
    return (
        <Root className={classes.noResults}>
            <Icon name="empty" />
            <br />
            <span>{text}</span>
        </Root>
    );
};

export default NoResults;
