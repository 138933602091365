import { useCallback, useContext, useEffect, useState } from "react";
import AuthGlobal from "./context/store/global";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import Sidebar from "./components/UI/Sidebar";

import Menu from "./pages/menus/Menu";
import Collection from "./pages/collections/Collection";
import Items from "./pages/items/Items";
import Settings from "./pages/settings/Settings";
import "./assets/scss/App.scss";
import { Root, classes } from "./assets/styles/App";

import { useIntercom } from "react-use-intercom";
import FormCreateCollection from "./pages/collections/FormCreateCollection";
import FormUpdateCollection from "./pages/collections/FormUpdateCollection";
import FormCreateMenu from "./pages/menus/FormCreateMenu";
import FormUpdateMenu from "./pages/menus/FormUpdateMenu";
import NewVersionAlert from "./components/dialogs/NewVersionAlert";
import RestaurantSelector from "./components/RestaurantSelector";
import Alerts from "./pages/alerts/Alerts";

function App({ version: localVersion }: { version: string }) {
    const {
        globalState: {
            isAuthenticated,
            user,
            current_restaurant: { _id, company_name }
        }
    } = useContext(AuthGlobal);

    const { boot } = useIntercom();
    const [loading, setLoading] = useState(true);
    const [showUpdatedMessage, setShowUpdatedMessage] = useState(
        window.location.href.includes("updated")
    );

    const isUpdated = useCallback(
        (targetVersion: string) => {
            // Compare local version with server(target) version to see if a hard reload is required
            const [localMajor, localMinor, localPatch] = localVersion
                .split(".")
                .map(Number);
            const [serverMajor, serverMinor, serverPatch] = targetVersion
                .split(".")
                .map(Number);

            if (localMajor < serverMajor) return false;
            if (localMinor < serverMinor) return false;
            if (localPatch < serverPatch) return false;
            return true;
        },
        [localVersion]
    );

    const handleHardReload = async () => {
        const assets = performance
            .getEntries()
            .filter((x) => x.name.match(new RegExp(".+main.+|.+bundle.+")))
            .map((x) => x.name);
        await fetch(window.location.href, {
            headers: {
                Pragma: "no-cache",
                Expires: "-1",
                "Cache-Control": "no-cache"
            }
        });
        for (const url of assets) {
            await fetch(url, {
                headers: {
                    Pragma: "no-cache",
                    Expires: "-1",
                    "Cache-Control": "no-cache"
                }
            });
        }
        window.location.href.includes("updated") &&
            //@ts-ignore
            window.location.reload(true);
        !window.location.href.includes("updated") &&
            (window.location.href = window.location.href + "?updated");
    };

    const fetchVersion = useCallback(async () => {
        const baseUrl = window.location.origin;
        console.log("Using version " + localVersion);
        const response = await fetch(
            `${baseUrl}/meta.json?no-cache=${Date.now()}`
        );
        const { version } = await response.json();
        const hasLatestVersion = isUpdated(version);
        if (!hasLatestVersion) {
            handleHardReload();
        } else {
            setLoading(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isUpdated]);

    useEffect(() => {
        // Check for new versions every 5 minutes
        fetchVersion();
    }, [fetchVersion]);

    useEffect(() => {
        if (Object.keys(user).length && user.role === "user") {
            boot({
                alignment: "right",
                email: user.email,
                name: `${user.first_name}`,
                userId: user._id as string,
                company: { companyId: _id, name: company_name }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user]);

    if (!isAuthenticated || loading) {
        return (
            <div
                style={{
                    width: "100%",
                    height: "auto",
                    bottom: "0px",
                    top: "0px",
                    left: 0,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    position: "absolute"
                }}
            >
                <p style={{ color: "white", fontSize: 30 }}>Loading...</p>
            </div>
        );
    }

    return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <Router>
                <NewVersionAlert
                    showUpdatedMessage={showUpdatedMessage}
                    setShowUpdatedMessage={setShowUpdatedMessage}
                />
                <Root className={classes.flex}>
                    <div className={classes.flexColumn}>
                        <Sidebar />
                        <RestaurantSelector />
                    </div>
                    <div className="content" style={{ display: "contents" }}>
                        <Routes>
                            <Route path="/" element={<Menu />} />
                            <Route path="/items" element={<Items />} />
                            <Route
                                path="/menu/:idMenu/update"
                                element={<FormUpdateMenu />}
                            />
                            <Route
                                path="/menu/new"
                                element={<FormCreateMenu />}
                            />
                            <Route path="alerts" element={<Alerts />} />
                            <Route
                                path="/service-periods/:idCollection/update"
                                element={<FormUpdateCollection />}
                            />
                            <Route
                                path="/service-periods/new"
                                element={<FormCreateCollection />}
                            />
                            <Route
                                path="/service-periods"
                                element={<Collection />}
                            />
                            <Route path="/settings" element={<Settings />} />
                        </Routes>
                    </div>
                </Root>
            </Router>
        </LocalizationProvider>
    );
}

export default App;
