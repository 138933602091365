import {
    Autocomplete,
    Checkbox,
    FormControlLabel,
    MenuItem,
    Modal,
    Slide,
    TextField
} from "@mui/material";
import { useContext, useEffect, useState } from "react";
import {
    Root,
    classes,
    selectStyles,
    customClock
} from "../../assets/styles/components/FormScheduleCollection";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import Icon from "../../components/UI/Icon";
import Button from "../../components/UI/Button";
import { isAfter, isDate, set } from "date-fns";
import { updateSchedule } from "../../utils/Restaurants/restaurant-helper";
import AuthGlobal from "../../context/store/global";
import { useAlert } from "../../hooks/useAlert";
import Alert from "../../components/UI/Alert";
import { useIntercom } from "react-use-intercom";
import { useTheme } from "@mui/material/styles";

const daysOfTheWeek = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday"
];

const FormScheduleCollection = ({
    show = true,
    handleClose,
    scheduleData = {},
    collectionName = ""
}) => {
    const {
        globalState: {
            current_restaurant: { identifier }
        }
    } = useContext(AuthGlobal);
    const { update } = useIntercom();
    useEffect(() => {
        update({
            alignment: show ? "left" : "right"
        });
    }, [show, update]);
    const [mode, setMode] = useState("unscheduled");
    const [days, setDays] = useState([]);
    const [start, setStart] = useState("");
    const [end, setEnd] = useState("");
    const [invalidStart, setInvalidStart] = useState(false);
    const [emptyStartMessage, setEmptyStartMessage] = useState("");
    const [emptyEndMessage, setEmptyEndMessage] = useState("");
    const [invalidEnd, setInvalidEnd] = useState(false);
    const [alert, handleAlert] = useAlert();

    const theme = useTheme();

    useEffect(() => {
        if (scheduleData.start && scheduleData.end) {
            const startTime = set(new Date(), {
                hours: scheduleData.start?.split(":")[0],
                minutes: scheduleData.start?.split(":")[1]
            });
            const endTime = set(new Date(), {
                hours: scheduleData.end?.split(":")[0],
                minutes: scheduleData.end?.split(":")[1]
            });
            setStart(startTime);
            setEnd(endTime);
        }
        setMode(scheduleData.mode || "unscheduled");
        setDays(scheduleData.days || []);
    }, [scheduleData]);

    const toggleDay = (i) => {
        let newDays = [...days];
        if (newDays.includes(i)) {
            newDays = newDays.filter((x) => x !== i);
        } else {
            newDays.push(i);
        }
        setDays(newDays);
    };

    const handleSubmit = async () => {
        let valid = true;
        if (mode !== "unscheduled" && (!start || !end)) {
            valid = false;
            if (!start) {
                setInvalidStart(true);
                setEmptyStartMessage("* Please, complete this field");
            }
            if (!end) {
                setInvalidEnd(true);
                setEmptyEndMessage("* Please, complete this field");
            }
        }
        if (start && start.toString() === "Invalid Date") {
            valid = false;
            setInvalidStart(true);
            setEmptyStartMessage("");
        }
        if (end && end.toString() === "Invalid Date") {
            valid = false;
            setInvalidEnd(true);
            setEmptyEndMessage("");
        }
        if (mode !== "unscheduled" && !isAfter(end, start)) {
            valid = false;
            setEmptyEndMessage("* End time must be after start time");
        }
        if (!valid) return;
        const payload = {
            collection_id: scheduleData.collection_id,
            sort_order: scheduleData.sort_order,
            days:
                mode === "custom"
                    ? days
                    : mode === "everyday"
                    ? [0, 1, 2, 3, 4, 5, 6]
                    : [],
            mode,
            start:
                mode !== "unscheduled" && isDate(start)
                    ? `${start.getHours().toString().padStart(2, "0")}:${start
                          .getMinutes()
                          .toString()
                          .padStart(2, "0")}`
                    : "",
            end:
                mode !== "unscheduled" && isDate(end)
                    ? `${end.getHours().toString().padStart(2, "0")}:${end
                          .getMinutes()
                          .toString()
                          .padStart(2, "0")}`
                    : ""
        };
        const { success, response } = await updateSchedule(
            identifier,
            scheduleData.collection_id,
            payload
        );
        if (success && response) {
            handleClose();
            handleAlert({
                severity: "success",
                title: "Success",
                message: response.message
            });
        }
    };

    return (
        <>
            <Alert {...alert} />
            <Modal open={show} onClose={handleClose} disablePortal>
                <Slide in={show} direction="left">
                    <Root className={classes.container}>
                        <div className={classes.top}>
                            <span className={classes.sectionClose}>
                                <span
                                    className={classes.closeButtonContainer}
                                    onClick={handleClose}
                                >
                                    <Icon
                                        name="clear"
                                        className={classes.closeButton}
                                    />
                                </span>
                            </span>
                            <h1 className={classes.title}>
                                Edit {collectionName} Schedule
                            </h1>
                        </div>
                        <div className={classes.form}>
                            <TextField
                                select
                                value={mode}
                                onChange={(e) => setMode(e.target.value)}
                                label="Schedule Period"
                                InputLabelProps={{ shrink: true }}
                                SelectProps={{
                                    MenuProps: {
                                        PaperProps: {
                                            sx: selectStyles(theme)
                                        }
                                    }
                                }}
                                className={`${classes.select} ${classes.textField}`}
                            >
                                <MenuItem value="unscheduled">
                                    Unscheduled
                                </MenuItem>
                                <MenuItem value="everyday">Everyday</MenuItem>
                                <MenuItem value="custom">
                                    Select Days of the week
                                </MenuItem>
                            </TextField>
                            {mode === "custom" && (
                                <Autocomplete
                                    fullWidth
                                    multiple
                                    value={days}
                                    disableCloseOnSelect={true}
                                    options={[1, 2, 3, 4, 5, 6, 0]}
                                    getOptionLabel={(option) =>
                                        daysOfTheWeek[option]
                                    }
                                    renderOption={(_props, option) => {
                                        const active = days.includes(option);
                                        return (
                                            <div
                                                key={option}
                                                onClick={() => {
                                                    toggleDay(option);
                                                }}
                                                className={`${
                                                    classes.notationRow
                                                } ${
                                                    active
                                                        ? classes.selectedNotation
                                                        : ""
                                                }`}
                                            >
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            checked={active}
                                                            icon={
                                                                <Icon name="checkboxEmpty" />
                                                            }
                                                            checkedIcon={
                                                                <Icon name="checkboxActive" />
                                                            }
                                                            className={`${
                                                                classes.checkbox
                                                            } ${
                                                                active
                                                                    ? classes.checkboxActive
                                                                    : ""
                                                            }`}
                                                            style={{
                                                                pointerEvents:
                                                                    "auto"
                                                            }}
                                                        />
                                                    }
                                                    label={
                                                        daysOfTheWeek[option]
                                                    }
                                                    className={classes.labelRow}
                                                />
                                            </div>
                                        );
                                    }}
                                    disablePortal={true}
                                    className={classes.notationsSelect}
                                    clearIcon={false}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Days of the Week"
                                            InputLabelProps={{ shrink: true }}
                                            className={`${classes.select} ${classes.textField}`}
                                        />
                                    )}
                                    renderTags={(value) => (
                                        <div
                                            style={{
                                                padding: "2px 0px 2px 7px"
                                            }}
                                        >
                                            {value.length > 1 &&
                                                value
                                                    .sort((a, b) => {
                                                        if (parseInt(a) === 0)
                                                            return 1;
                                                        if (parseInt(b) === 0)
                                                            return -1;
                                                        return a - b;
                                                    })
                                                    .map((x) =>
                                                        daysOfTheWeek[x].slice(
                                                            0,
                                                            3
                                                        )
                                                    )
                                                    .join(", ")}
                                            {value.length === 1 &&
                                                daysOfTheWeek[value[0]]}
                                        </div>
                                    )}
                                    componentsProps={{
                                        paper: {
                                            className: classes.selectPopper
                                        }
                                    }}
                                    noOptionsText={
                                        <div style={{ paddingLeft: 15 }}>
                                            No options
                                        </div>
                                    }
                                    style={{ marginBottom: 8 }}
                                />
                            )}
                            {mode === "custom" && (
                                <span className={classes.infoMessage}>
                                    <Icon name="info" />
                                    <p>
                                        A "Select Days of the Week" service
                                        period will override an "Everyday"
                                        service period if scheduled at the same
                                        time
                                    </p>
                                </span>
                            )}
                            {mode !== "unscheduled" && (
                                <div
                                    style={{
                                        display: "flex",
                                        justifyContent: "space-between"
                                    }}
                                >
                                    <TimePicker
                                        label="Start Time"
                                        value={start}
                                        onChange={(val) => {
                                            setInvalidStart(false);
                                            setEmptyStartMessage("");
                                            setStart(val);
                                        }}
                                        PopperProps={{
                                            sx: customClock(theme),
                                            style: { zIndex: 2147483005 },
                                            className: `${
                                                classes.customClock
                                            } ${
                                                typeof start === "string" ||
                                                start?.getHours() < 12
                                                    ? classes.amFocus
                                                    : classes.pmFocus
                                            }`
                                        }}
                                        PaperProps={{
                                            style: { borderRadius: 20 }
                                        }}
                                        DialogProps={{
                                            color: "#CC5803"
                                        }}
                                        renderInput={(params) => (
                                            <TextField
                                                required
                                                {...params}
                                                InputLabelProps={{
                                                    shrink: true
                                                }}
                                                className={classes.textField}
                                                style={{ width: "48%" }}
                                                error={invalidStart}
                                                helperText={
                                                    start && invalidStart
                                                        ? "* Please, enter a valid value"
                                                        : emptyStartMessage
                                                }
                                                inputProps={{
                                                    ...params.inputProps,
                                                    placeholder:
                                                        "Enter Start Time"
                                                }}
                                            />
                                        )}
                                    />
                                    <TimePicker
                                        label="End Time"
                                        value={end}
                                        onChange={(val) => {
                                            setInvalidEnd(false);
                                            setEmptyEndMessage("");
                                            setEnd(val);
                                        }}
                                        PopperProps={{
                                            sx: customClock(theme),
                                            style: { zIndex: 2147483005 },
                                            className: `${
                                                classes.customClock
                                            } ${
                                                typeof end === "string" ||
                                                end?.getHours() < 12
                                                    ? classes.amFocus
                                                    : classes.pmFocus
                                            }`
                                        }}
                                        renderInput={(params) => (
                                            <TextField
                                                required
                                                {...params}
                                                InputLabelProps={{
                                                    shrink: true
                                                }}
                                                error={
                                                    invalidEnd ||
                                                    Boolean(emptyEndMessage)
                                                }
                                                helperText={
                                                    end && invalidEnd
                                                        ? "* Please, enter a valid value"
                                                        : emptyEndMessage
                                                }
                                                className={classes.textField}
                                                style={{ width: "48%" }}
                                                inputProps={{
                                                    ...params.inputProps,
                                                    placeholder:
                                                        "Enter End Time"
                                                }}
                                            />
                                        )}
                                    />
                                </div>
                            )}
                        </div>
                        <div className={classes.actions}>
                            <Button
                                type="primary"
                                color="orange"
                                innerText="Save"
                                onClick={() => handleSubmit()}
                            />
                            <Button
                                type="secondary"
                                color="dark"
                                innerText="Cancel"
                                onClick={() => handleClose()}
                            />
                        </div>
                    </Root>
                </Slide>
            </Modal>
        </>
    );
};

export default FormScheduleCollection;
