import { createContext, useContext, useEffect, useState } from "react";
import AuthGlobal from "./global";
import { fetchRequest } from "../../utils";

export const AlertsContext = createContext();

const Alerts = ({ children }) => {
    const {
        globalState: {
            current_restaurant: { identifier }
        }
    } = useContext(AuthGlobal);
    const [activeAlerts, setActiveAlerts] = useState([]);
    const [dismissedAlerts, setDismissedAlerts] = useState([]);
    const [order, setOrder] = useState("DESC");
    const [value, setValue] = useState(0);
    const [counter, setCounter] = useState(0);

    const getData = async (order = "DESC") => {
        const { success, response } = await fetchRequest(
            `/alert/${identifier}?order=${order}`
        );
        if (success && response) {
            setActiveAlerts(
                response.filter((x) => ["new", "email sent"].includes(x.status))
            );
            setDismissedAlerts(
                response.filter((x) =>
                    ["resolved", "dismissed", "rescinded"].includes(x.status)
                )
            );
        }
    };

    useEffect(() => {
        getData(order);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [order, identifier, value]);

    useEffect(() => {
        setCounter(activeAlerts.length);
    }, [activeAlerts]);

    return (
        <AlertsContext.Provider
            value={{
                activeAlerts,
                dismissedAlerts,
                order,
                setOrder,
                value,
                setValue,
                counter,
                getData
            }}
        >
            {children}
        </AlertsContext.Provider>
    );
};

export default Alerts;
