import { Slider } from "@mui/material";
import { styled } from "@mui/material/styles";

const classes = {
    label: `label`,
    slider: `slider`
};

const Root = styled("span")(({ theme }) => ({
    [`& .${classes.label}`]: {
        fontSize: 12,
        fontWeight: 500
    },
    [`&.${classes.slider}`]: {
        "& .MuiSlider-rail": {
            backgroundColor: "#BFBFBF"
        },
        "& .MuiSlider-track": {
            border: "none",
            backgroundColor: "#CC580360"
        },
        "& .MuiSlider-thumbColorPrimary": {
            color: "white",
            border: "3px solid #CC5803DD",
            "&:hover, &.Mui-focusVisible": {
                boxShadow:
                    "0px 0px 0px 8px rgba(250, 211, 184, 0.15) !important"
            },
            "&.Mui-active:not(:hover)": {
                boxShadow:
                    "0px 0px 0px 14px rgba(250, 211, 184, 0.30) !important"
            }
        }
    }
}));

const CustomSlider = ({
    min = 0,
    max = 100,
    label,
    value,
    setValue,
    step = 1,
    customMarks = null
}) => {
    const handleChange = (_event, newValue) => {
        setValue(newValue);
    };

    const marks = [
        { value: min, label: `${min}` },
        { value: max, label: `${max}` }
    ];

    return (
        <Root className={classes.slider}>
            <label className={classes.label}>{label}</label>
            <Slider
                min={min}
                max={max}
                value={value}
                step={step}
                onChange={handleChange}
                valueLabelDisplay="auto"
                marks={customMarks || marks}
            />
        </Root>
    );
};

export { CustomSlider as Slider };
