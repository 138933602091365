import _ from "lodash";
import { setCurrentRestaurant } from "../../context/actions";
import { fetchRequest } from "../index";

export const getRestaurant = (identifier) => {
    return fetchRequest(`/restaurant/${identifier}`, { method: "GET" });
};

export const updateRestaurant = (identifier, body) => {
    return fetchRequest(`/restaurant/${identifier}`, {
        method: "PUT",
        body: JSON.stringify(body)
    });
};

export const getPaymentLink = async (identifier) => {
    return await fetchRequest(`/restaurant/${identifier}/get-payment-link`, {
        method: "GET"
    });
};

export const toggleStatus = async (identifier) => {
    return await fetchRequest(`/restaurant/${identifier}/toggle`, {
        method: "PUT"
    });
};

export const getPresenterTemplate = async (identifier) => {
    return await fetchRequest(
        `/restaurant/${identifier}/get-presenter-template`,
        {
            method: "GET"
        }
    );
};

export const getWebTemplate = async (identifier) => {
    return await fetchRequest(`/restaurant/${identifier}/get-web-template`, {
        method: "GET"
    });
};

export const updatePresenterTemplate = async (identifier, body) => {
    return await fetchRequest(
        `/restaurant/${identifier}/update-presenter-template`,
        {
            method: "PUT",
            body: JSON.stringify(body)
        }
    );
};

export const updateWebTemplate = async (identifier, body) => {
    return await fetchRequest(`/restaurant/${identifier}/update-web-template`, {
        method: "PUT",
        body: JSON.stringify(body)
    });
};

export const refreshRestaurant = async (dispatch, identifier, current) => {
    const { success, response } = await getRestaurant(identifier);
    if (success && response && !_.isEqual(response, current)) {
        dispatch(setCurrentRestaurant(response));
    }
};

export const getCollectionLinks = (identifier) => {
    return fetchRequest(`/restaurant/${identifier}/get-collection-links`, {
        method: "GET"
    });
};

export const updateSchedule = (identifier, collectionId, body) => {
    return fetchRequest(
        `/restaurant/${identifier}/update-schedule/${collectionId}`,
        {
            method: "PUT",
            body: JSON.stringify(body)
        }
    );
};
