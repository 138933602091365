import { fetchRequest } from '../index';

export const getSection = (id, identifier) => {
    return fetchRequest(`/section/${identifier}/getById/${id}`, {
        method: 'GET'
    });
}

export const getSections = (identifier) => {
    return fetchRequest(`/section/${identifier}/active`, {
        method: 'GET'
    });
};

export const getDraftSections = (identifier) => {
    return fetchRequest(`/section/${identifier}/draft`, {
        method: 'GET'
    });
};

export const getTrashSections = (identifier) => {
    return fetchRequest(`/section/${identifier}/trash`, {
        method: 'GET'
    });
};

export const searchActiveSections = (value, identifier) => {
    return fetchRequest(`/section/${identifier}/search`, {
        method: 'POST',
        body: JSON.stringify({
            internal_name: value,
            status: 'active'
        }),
    });
}

export const searchDraftSections = (value, identifier) => {
    return fetchRequest(`/section/${identifier}/search`, {
        method: 'POST',
        body: JSON.stringify({
            internal_name: value,
            status: 'draft'
        }),
    });
}

export const searchTrashSections = (value, identifier) => {
    return fetchRequest(`/section/${identifier}/search`, {
        method: 'POST',
        body: JSON.stringify({
            internal_name: value,
            status: 'trash'
        }),
    });
}

export const duplicateSection = (id, identifier) => {
    return fetchRequest(`/section/${identifier}/${id}/duplicate`, {
        method: 'GET'
    });   
}

export const markAsDraftSection = (id, identifier) => {
    return fetchRequest(`/section/${identifier}/${id}/draft`, {
        method: 'PUT'
    });
}

export const markAsTrashSection = (id, identifier) => {
    return fetchRequest(`/section/${identifier}/${id}/trash`, {
        method: 'PUT'
    });
}

export const markAsActiveSection = (id, identifier) => {
    return fetchRequest(`/section/${identifier}/${id}/active`, {
        method: 'PUT'
    });
}

export const deleteSection = (id, identifier) => {
    return fetchRequest(`/section/${identifier}/${id}/delete`, {
        method: 'DELETE'
    });
}

export const createSection = (section, identifier) => {
    return fetchRequest(`/section/${identifier}`, {
        method: 'POST',
        body: JSON.stringify(section),
    });
}

export const updateSection = (id, section, identifier) => {
    return fetchRequest(`/section/${identifier}/${id}`, {
        method: 'PUT',
        body: JSON.stringify(section),
    });
}