import { styled } from "@mui/material/styles";

const classes = {
    columnContainer: `columnContainer`,
    column60: `column60`,
    column50: `column50`,
    column40: `column40`,
    textField: `textField`,
    select: `select`,
    selectRow: `selectRow`,
    invisibleColorPicker: `invisibleColorPicker`,
    centered: `centered`,
    iconCircle: `iconCircle`,
    qrLink: `qrLink`,
    info: `info`
};

const Root = styled("div")(({ theme }) => ({
    [`&.${classes.columnContainer}`]: {
        display: "flex",
        gap: 30
    },
    [`& .${classes.column60}`]: {
        width: "60%",
        display: "flex",
        gap: 16,
        flexDirection: "column"
    },
    [`& .${classes.column50}`]: {
        width: "50%",
        gap: 16,
        display: "flex",
        flexDirection: "column"
    },
    [`& .${classes.column40}`]: {
        width: "40%",
        display: "flex",
        flexDirection: "column"
    },
    [`& .${classes.textField}`]: {
        lineHeight: "44px !important",
        // border: "1px solid #B4B3B3",
        "& div.Mui-focused": {
            "& fieldset": {
                borderColor: `${theme.colors.orange[700]} !important`
            }
        },
        "& label.Mui-focused": {
            color: `${theme.colors.orange[700]} !important`
        },
        "& fieldset": {
            borderRadius: "50px",
            "& legend": {
                margin: "4px 0px 4px 2px"
            }
        },
        "& input": {
            padding: "12px 18px !important"
        },
        "& input, div": {
            fontSize: 14
        },
        "& label": {
            transition: "all 100ms linear !important"
        },
        "& label[data-shrink=true].Mui-focused": {
            color: `${theme.colors.orange[700]} !important`
        },
        "& label[data-shrink=true]": {
            padding: `4px${theme.browser.isSafari ? " 10px" : " 8px"}`,
            color: `${theme.colors.grey[900]} !important`,
            background: "white",
            fontWeight: 500,
            fontSize: "16px",
            top: -3,
            "& .MuiFormLabel-asterisk": {
                color: theme.colors.orange[700]
            }
        },
        "& label[data-shrink=false]": {
            paddingLeft: 7
        }
    },
    [`& .${classes.select}`]: {
        marginTop: "36px !important",
        "& .MuiSelect-select": {
            padding: "12px 16px 12px 18px !important"
        },
        "& .MuiAutocomplete-input": {
            padding: "3px 8px !important"
        },
        "& .MuiAutocomplete-inputRoot": {
            padding: "10px 12px 8px 12px !important"
        }
    },
    [`& .${classes.invisibleColorPicker}`]: {
        opacity: 0,
        cursor: "pointer",
        padding: "0px !important",
        width: "20px",
        height: "20px",
        "&::-webkit-file-upload-button": {
            cursor: "pointer"
        }
    },
    [`& .${classes.centered}`]: {
        display: "flex",
        width: "100%",
        justifyContent: "center",
        marginTop: 40,
        "& > div > canvas": {
            width: "100% !important",
            maxWidth: 350,
            height: "fit-content !important"
        }
    },
    [`& .${classes.iconCircle}`]: {
        cursor: "pointer",
        width: 50,
        height: 44,
        borderRadius: "50%",
        border: `1px solid ${theme.colors.orange[700]}`,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        "&:hover": {
            background: theme.colors.orange[700],
            "& path": {
                fill: "white"
            }
        }
    },
    [`& .${classes.qrLink}`]: {
        display: "flex",
        alignItems: "center",
        gap: 16,
        flexDirection: "row",
        paddingTop: 28,
        "& svg": {
            padding: 6,
            width: 32,
            height: 32,
            "& path": {
                fill: theme.colors.orange[700]
            }
        }
    },
    [`& .${classes.info}`]: {
        display: "flex",
        alignItems: "center",
        gap: 8,
        fontSize: 11,
        marginTop: 8,
        color: theme.colors.grey[400],
        "& svg": {
            width: 18,
            height: 18
        },
        "& path": {
            fill: theme.colors.grey[400]
        }
    },
    "& .logoWrapper": {
        marginTop: 20
    },
    "& .logoTitle": {
        fontSize: "12px",
        textAlign: "center",
        display: "block",
        fontWeight: 500,
        marginBottom: 10
    },
    "& .eye-styling": {
        "& .select": {
            margin: "0 !important"
        }
    }
}));

const selectRow = (theme) => ({
    borderRadius: "20px !important",
    boxShadow:
        "0px 5px 5px -3px rgba(0,0,0,0.05), 0px 8px 10px 1px rgba(0,0,0,0.03), 0px 3px 14px 2px rgba(0,0,0,0.1) !important",
    border: "1px solid #d9d9d9 !important",
    "& li": {
        height: 48,
        width: "100%",
        display: "flex !important",
        alignItems: "center !important",
        padding: "0 16px !important",
        background: "white !important",
        "&:hover": {
            background: `${theme.colors.orange[50]} !important`
        }
    },
    "& li[aria-selected='true']": {
        background: `${theme.colors.orange[50]} !important`
    },
    // select li with class selected
    "& li.dropdown-selected": {
        background: `${theme.colors.orange[50]} !important`,
        color: `${theme.colors.orange[900]} !important`
    }
});

export { Root, classes, selectRow };
