import { styled } from "@mui/material/styles";
import { FormControlLabel } from "@mui/material";

const classes = {
    switchLabel: `switchLabel`,
    switch: `switch`,
    switchDisabled: `switchDisabled`
};

const Root = styled(FormControlLabel)(({ theme }) => ({
    [`&.${classes.switchLabel}`]: {
        "& .MuiFormControlLabel-label": {
            fontSize: 14,
            lineHeight: "16px",
            color: theme.colors.grey[500],
            width: 50
        },
        "&:hover .MuiFormControlLabel-label": {
            color: theme.colors.grey[900]
        }
    },
    [`& .${classes.switch}`]: {
        "& .MuiSwitch-switchBase": {
            color: `${theme.colors.orange[700]} !important`,
            "&.Mui-checked:hover": {
                backgroundColor: "rgba(250, 211, 184, 0.15) !important"
            }
        },
        "& .MuiSwitch-track": {
            backgroundColor: `${theme.colors.orange[200]} !important`
        }
    },
    [`& .${classes.switchDisabled}`]: {
        "&:hover": {
            backgroundColor: "rgba(250, 250, 250, 0.35)"
        }
    }
}));

export { Root, classes };
