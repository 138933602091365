import { Close } from "@mui/icons-material";
import {
    Box,
    FormControlLabel,
    Grid,
    MenuItem,
    Radio,
    RadioGroup,
    TextField
} from "@mui/material";
import CustomIcon from "../UI/Icon";
import React, { useContext, useEffect, useState } from "react";
import {
    Root,
    classes,
    selectRow
} from "../../assets/styles/items/FormItemDescription";
import Button from "../UI/Button";
import AuthGlobal from "../../context/store/global";
import Icon from "../UI/Icon";
import { useTheme } from "@mui/material/styles";
import { getDescription } from "../../utils/Items/item-helper";
import { useAlert } from "../../hooks/useAlert";
import Alert from "../UI/Alert";

export default function FormItemDescription({
    onSave = () => {},
    onClose = () => {},
    itemName = ""
}) {
    const {
        globalState: {
            current_restaurant: { identifier }
        }
    } = useContext(AuthGlobal);

    const theme = useTheme();

    const [error, setError] = React.useState(false);

    const [descriptionTypeValue, setDescriptionTypeValue] = useState("");
    const [descriptionSize, setDescriptionSize] = useState("short");
    const [selectDescriptionType, setSelectDescriptionType] = useState(true);
    const [disableBtn, setDisableBtn] = useState(true);
    const [hasLoading, setHasLoading] = useState(false);
    const [description, setDescription] = useState("");
    const [alert, handleAlert] = useAlert();
    const [displayResponse, setDisplayResponse] = useState("");

    useEffect(() => {
        let i = 0;
        const stringResponse = description;

        const intervalId = setInterval(() => {
            setDisplayResponse(stringResponse.slice(0, i));

            i++;

            if (i > stringResponse.length) {
                clearInterval(intervalId);
            }
        }, 20);

        return () => clearInterval(intervalId);
    }, [description]);

    const descriptionItem = [
        {
            value: "short",
            label: "Short"
        },
        {
            value: "medium",
            label: "Medium"
        },
        {
            value: "long",
            label: "Long"
        }
    ];

    useEffect(() => {
        if (descriptionTypeValue !== "") {
            setDisableBtn(false);
        }
    }, [descriptionTypeValue]);

    const handleSave = () => {
        if (description.length === 0) {
            setError(true);
            return;
        }
        onSave({
            description
        });
    };

    const handleGenerateDescription = async () => {
        setHasLoading(true);
        setDisableBtn(true);
        if (descriptionTypeValue !== "") {
            const { success, response } = await getDescription(
                descriptionTypeValue,
                itemName,
                descriptionSize,
                identifier
            );
            if (success && response) {
                setDescription(response.message);
                setSelectDescriptionType(false);
            } else {
                handleAlert({
                    severity: "error",
                    title: "Error",
                    message: response?.message
                });
            }
        }
        setHasLoading(false);
        setDisableBtn(false);
    };

    return (
        <>
            <Alert {...alert} />
            <Root className={classes.container}>
                <div className={classes.top}>
                    <h1 className={classes.title}>
                        {selectDescriptionType
                            ? "What type of description do you want to generate?"
                            : itemName}
                    </h1>
                    <span className={classes.sectionClose} onClick={onClose}>
                        <Close
                            sx={{
                                width: 20
                            }}
                        ></Close>
                    </span>
                </div>
                <div className={classes.content}>
                    <Grid container>
                        <Grid item xs={12}>
                            {selectDescriptionType ? (
                                <>
                                    <RadioGroup
                                        value={descriptionTypeValue}
                                        onChange={({ target }) => {
                                            setDescriptionTypeValue(
                                                target.value
                                            );
                                        }}
                                    >
                                        <FormControlLabel
                                            value="wine"
                                            control={<Radio />}
                                            label={
                                                <>
                                                    <Icon name="drink" />
                                                    Wine description
                                                </>
                                            }
                                            labelPlacement="start"
                                            className={classes.descriptionItem}
                                        />
                                    </RadioGroup>
                                    <span className={classes.descriptionInfo}>
                                        <CustomIcon name="info" /> Currently
                                        only wine descriptions are available. We
                                        will continue to add more in the future.
                                    </span>
                                </>
                            ) : (
                                <TextField
                                    multiline
                                    fullWidth
                                    label="Item Description"
                                    className={`item_description ${classes.textField} ${classes.multiline}`}
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                    value={displayResponse}
                                    onChange={(e) =>
                                        setDescription(e.target.value)
                                    }
                                />
                            )}
                        </Grid>
                    </Grid>
                    <span
                        style={{
                            color: "rgb(211, 47, 47)",
                            fontWeight: "400",
                            fontSize: ".75rem",
                            lineHeight: "1.66",
                            letterSpacing: ".03333em",
                            margin: "5px 0 0",
                            display: error ? "block" : "none"
                        }}
                    >
                        These field is required.
                    </span>
                </div>
                <div className={classes.footer}>
                    {selectDescriptionType ? (
                        <div className={classes.footerGenerateContainer}>
                            <Button
                                color="orange"
                                type="primary"
                                innerText="Generate"
                                onClick={handleGenerateDescription}
                                className="generate_item_button"
                                disableBtn={disableBtn}
                                hasLoading={hasLoading}
                            />
                        </div>
                    ) : (
                        <div className={classes.saveContainer}>
                            <TextField
                                select
                                value={descriptionSize}
                                onChange={(e) =>
                                    setDescriptionSize(e.target.value)
                                }
                                SelectProps={{
                                    MenuProps: {
                                        PaperProps: {
                                            sx: selectRow(theme)
                                        },
                                        classes: {
                                            paper: classes.selectRow
                                        }
                                    }
                                }}
                                className={`${classes.select} ${classes.textField}`}
                            >
                                {descriptionItem.map((option) => (
                                    <MenuItem
                                        key={option.value}
                                        value={option.value}
                                    >
                                        {option.label}
                                    </MenuItem>
                                ))}
                            </TextField>
                            <Box
                                sx={{
                                    display: "flex"
                                }}
                            >
                                <Button
                                    color="dark"
                                    type="secondary"
                                    innerText="Retry"
                                    onClick={handleGenerateDescription}
                                    className="retry_item_button"
                                    disableBtn={disableBtn}
                                    hasLoading={hasLoading}
                                />
                                <Button
                                    color="orange"
                                    type="primary"
                                    innerText="Save"
                                    onClick={handleSave}
                                    icon="defualt"
                                    className="save_item_button"
                                    disableBtn={disableBtn}
                                />
                            </Box>
                        </div>
                    )}
                </div>
            </Root>
        </>
    );
}
