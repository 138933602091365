import { styled } from "@mui/material/styles";

const classes = {
    sidebar: `sidebar`,
    publishWrapper: `publishWrapper`
};
const Root = styled("div")(({ theme }) => ({
    [`&.${classes.sidebar}`]: {
        width: 248,
        minHeight: 550,
        marginTop: 40,
        marginLeft: 40,
        borderRadius: "20px",
        background: "#FDECDF",
        boxShadow: "0px 10px 30px rgba(31,19,0,0.3)",
        "&.view_only": {
            minHeight: "auto",
            paddingBottom: "20px"
        },
        "& .logo-img": {
            width: 190,
            height: 56,
            marginTop: 30,
            marginLeft: 30,
            marginBottom: 30
        },
        "& ul": {
            listStyle: "none"
        }
    },
    [`& .${classes.publishWrapper}`]: {
        padding: "0 24px 32px 24px",
        marginTop: "24px",
        "& > button": {
            width: "100%",
            borderRadius: "36px",
            height: "42px",
            fontSize: "15px",
            fontFamily: "Roboto, sans-serif",
            "&:disabled": {
                borderColor: "#F2F2F2",
                outlineColor: "#D3D3D3"
            }
        }
    }
}));

export { Root, classes };
