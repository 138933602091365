import { useContext, useState, useEffect } from "react";
import BoxLayout from "../../components/UI/BoxLayout";
import FormCreateItem from "../../components/items/FormCreateItem";
import AuthGlobal from "../../context/store/global";

import ItemList from "./ItemList";

import {
    getItems,
    searchActiveItems,
    searchInactiveItems,
    searchTrashItems,
    markAsActiveItem,
    markAsTrashItem,
    duplicateItem,
    deleteItem,
    markAsInactiveItem
} from "../../utils/Items/item-helper";

import { useAlert } from "../../hooks/useAlert";
import Header from "../../components/UI/Header";
import { Root, classes } from "../../assets/styles/pages/menus/Menu";
import {
    Menu as Options,
    Tabs,
    Tab,
    MenuItem,
    CircularProgress
} from "@mui/material";
import Icon from "../../components/UI/Icon";
import { refreshRestaurant } from "../../utils/Restaurants/restaurant-helper";
import Alert from "../../components/UI/Alert";

const Item = () => {
    const {
        dispatch,
        globalState: {
            current_restaurant: { identifier },
            current_restaurant
        }
    } = useContext(AuthGlobal);
    // const classes = useStyles();

    const [activeItems, setActiveItems] = useState([]);
    const [allItemsRendered, setAllItemsRendered] = useState(true);
    const [inactiveItems, setInactiveItems] = useState([]);
    const [trashItems, setTrashItems] = useState([]);
    const [searchText, setSearchText] = useState("");
    const [value, setValue] = useState(0);
    const [createItemModalVisible, setCreateItemModalVisible] = useState(false);
    const [flag, setFlag] = useState(false);
    const [flag2, setFlag2] = useState(false);
    const [order, setOrder] = useState("A-Z");
    const [anchorEl, setAnchorEl] = useState(null);
    const [loading, setLoading] = useState(true);
    const dropdownOpen = Boolean(anchorEl);

    useEffect(() => {
        refreshRestaurant(dispatch, identifier, current_restaurant);
        //eslint-disable-next-line
    }, []);

    const flagChanged = () => {
        if (flag !== flag2) {
            setFlag2((x) => !x);
            return true;
        }
        return false;
    };
    const handleSortOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleSort = (order) => {
        setAnchorEl(null);
        if (order) {
            setOrder(order);
        }
    };

    const [alert, handleAlert] = useAlert();

    useEffect(() => {
        loadData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [order]);

    const loadData = async () => {
        setLoading(true);
        await getAllItems();
        await getAllInactiveItems();
        await getAllTrashItems();
        setLoading(false);
    };

    const getAllItems = async () => {
        setAllItemsRendered(true);
        const { success, response } = await getItems(
            "active",
            identifier,
            order
        );
        if (success && response) {
            setActiveItems(response);
        }
    };

    const getAllInactiveItems = async () => {
        const { success, response } = await getItems(
            "draft",
            identifier,
            order
        );
        if (success && response) {
            setInactiveItems(response);
        }
    };

    const getAllTrashItems = async () => {
        const { success, response } = await getItems(
            "trash",
            identifier,
            order
        );
        if (success && response) {
            setTrashItems(response);
        }
    };

    useEffect(() => {
        const searchTimeout = setTimeout(() => {
            (async () => {
                if (searchText.length > 2) {
                    setAllItemsRendered(false);
                    if (value === 0) {
                        const { success, response } = await searchActiveItems(
                            searchText,
                            identifier,
                            order
                        );
                        if (success && response) {
                            setActiveItems(response);
                        }
                    } else if (value === 1) {
                        const { success, response } = await searchInactiveItems(
                            searchText,
                            identifier,
                            order
                        );
                        if (success && response) {
                            setInactiveItems(response);
                        }
                    } else {
                        const { success, response } = await searchTrashItems(
                            searchText,
                            identifier,
                            order
                        );
                        if (success && response) {
                            setTrashItems(response);
                        }
                    }
                }

                if (
                    searchText.length <= 2 &&
                    (!allItemsRendered || flagChanged())
                ) {
                    loadData();
                }
            })();
        }, 300);

        return () => clearTimeout(searchTimeout);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        allItemsRendered,
        identifier,
        searchText,
        value,
        order,
        createItemModalVisible,
        flag
    ]);

    const clearSearchText = () => {
        setSearchText("");
        loadData();
    };

    const markAsActive = async (id) => {
        const { success, response } = await markAsActiveItem(id, identifier);
        if (success && response) {
            await refreshRestaurant(dispatch, identifier, current_restaurant);
            handleAlert({
                severity: "success",
                title: "Success",
                message: response.message
            });
            setTimeout(loadData, 500);
        } else {
            handleAlert({
                severity: "error",
                title: "Error",
                message: response.message
            });
        }
    };

    const markAsInactive = async (id) => {
        const { success, response } = await markAsInactiveItem(id, identifier);
        if (success && response) {
            await refreshRestaurant(dispatch, identifier, current_restaurant);
            handleAlert({
                severity: "success",
                title: "Success",
                message: response.message
            });
            setTimeout(loadData, 500);
        } else {
            handleAlert({
                severity: "error",
                title: "Error",
                message: response.message
            });
        }
    };

    const duplicateElementItem = async (id, newName) => {
        const { success, response } = await duplicateItem(
            id,
            identifier,
            newName
        );

        if (success && response) {
            await refreshRestaurant(dispatch, identifier, current_restaurant);
            handleAlert({
                severity: "success",
                title: "Success",
                message: response.message
            });
            setTimeout(loadData, 500);
        } else {
            handleAlert({
                severity: "error",
                title: "Error",
                message: response.message
            });
        }
    };

    const markAsTrash = async (id) => {
        const { success, response } = await markAsTrashItem(id, identifier);

        if (success && response) {
            await refreshRestaurant(dispatch, identifier, current_restaurant);
            handleAlert({
                severity: "success",
                title: "Success",
                message: response.message
            });
            setTimeout(loadData, 500);
        } else {
            handleAlert({
                severity: "error",
                title: "Error",
                message: response.message
            });
        }
    };

    const deleteElementItem = async (id) => {
        const { success, response } = await deleteItem(id, identifier);
        if (success && response) {
            await refreshRestaurant(dispatch, identifier, current_restaurant);
            handleAlert({
                severity: "success",
                title: "Success",
                message: response.message
            });
            setTimeout(loadData, 500);
        } else {
            handleAlert({
                severity: "error",
                title: "Error",
                message: response.message
            });
        }
    };

    const actionButtonCreateItem = (value) => {
        setCreateItemModalVisible(value);
    };

    const itemsCreated = (items, changeItemModalVisible = false) => {
        setActiveItems((prevState) => [...prevState, ...items]);

        if (!changeItemModalVisible) {
            setCreateItemModalVisible(changeItemModalVisible);
        }
    };

    const handleChangeTab = (_event, newValue) => {
        setValue(newValue);
        clearSearchText();
    };

    const tabLabel = (text) => {
        const count =
            text === "Active Items"
                ? activeItems.length
                : text === "Inactive Items"
                ? inactiveItems.length
                : text === "Trash"
                ? trashItems.length
                : 0;

        return (
            <span className={classes.labelText}>
                {text}
                <span
                    style={{
                        color: "#888888",
                        marginLeft: "0.2rem",
                        fontWeight: "normal"
                    }}
                >
                    ({count})
                </span>
            </span>
        );
    };

    const labelProps = (index) => {
        return {
            id: `simple-tab-${index}`,
            "aria-controls": `simple-tabpanel-${index}`
        };
    };

    function TabPanel(props) {
        const { children, value, index, ...other } = props;

        return (
            <div
                role="tabpanel"
                hidden={value !== index}
                id={`simple-tabpanel-${index}`}
                aria-labelledby={`simple-tab-${index}`}
                {...other}
            >
                {value === index && <div>{children}</div>}
            </div>
        );
    }

    return (
        <>
            <Alert {...alert} />
            <BoxLayout>
                <Header
                    clearSearchText={clearSearchText}
                    title="Items"
                    createButtonText="New Item"
                    searchText={searchText}
                    setSearchText={setSearchText}
                    onClickCreate={() => actionButtonCreateItem(true)}
                />
                <Root className={classes.table}>
                    <div className={classes.tabsRow}>
                        <Tabs
                            className={classes.tabsContainer}
                            value={value}
                            onChange={handleChangeTab}
                        >
                            <Tab
                                label={tabLabel("Active Items")}
                                {...labelProps(0)}
                            />
                            <Tab
                                label={tabLabel("Inactive Items")}
                                {...labelProps(1)}
                            />
                            <Tab label={tabLabel("Trash")} {...labelProps(2)} />
                        </Tabs>
                        <>
                            <div
                                className={classes.sortHandler}
                                id="sort-dropdown"
                                aria-controls={
                                    dropdownOpen ? "basic-menu" : undefined
                                }
                                aria-haspopup="true"
                                aria-expanded={
                                    dropdownOpen ? "true" : undefined
                                }
                                onClick={handleSortOpen}
                            >
                                <span>
                                    {order === "Z-A" ? "Z to A" : "A to Z"}
                                </span>
                                <Icon name="dropdown" />
                            </div>
                            <Options
                                id="basic-menu"
                                anchorEl={anchorEl}
                                open={dropdownOpen}
                                onClose={() => handleSort()}
                                MenuListProps={{
                                    "aria-labelledby": "sort-dropdown"
                                }}
                                disableScrollLock={true}
                            >
                                <MenuItem onClick={() => handleSort("A-Z")}>
                                    A to Z
                                </MenuItem>
                                <MenuItem onClick={() => handleSort("Z-A")}>
                                    Z to A
                                </MenuItem>
                            </Options>
                        </>
                    </div>
                    {!loading && (
                        <>
                            <TabPanel value={value} index={0}>
                                <ItemList
                                    data={activeItems}
                                    status="active"
                                    duplicateElementItem={duplicateElementItem}
                                    markAsTrash={markAsTrash}
                                    markAsInactive={markAsInactive}
                                    setPagination={true}
                                    draggable={false}
                                    flagReload={setFlag}
                                    handleAlert={handleAlert}
                                />
                            </TabPanel>
                            <TabPanel value={value} index={1}>
                                <ItemList
                                    data={inactiveItems}
                                    status="inactive"
                                    duplicateElementItem={duplicateElementItem}
                                    markAsTrash={markAsTrash}
                                    markAsActive={markAsActive}
                                    setPagination={true}
                                    flagReload={setFlag}
                                    handleAlert={handleAlert}
                                />
                            </TabPanel>
                            <TabPanel value={value} index={2}>
                                <ItemList
                                    data={trashItems}
                                    status="trash"
                                    markAsInactive={markAsInactive}
                                    deleteElementItem={deleteElementItem}
                                    setPagination={true}
                                    flagReload={setFlag}
                                    handleAlert={handleAlert}
                                />
                            </TabPanel>
                        </>
                    )}
                    {loading && (
                        <div className={classes.loader}>
                            <CircularProgress />
                        </div>
                    )}
                </Root>
                <FormCreateItem
                    show={createItemModalVisible}
                    onClose={(_, alertData, _newItem) => {
                        setFlag((x) => !x);
                        setCreateItemModalVisible(false);
                        handleAlert(alertData);
                    }}
                    itemsCreated={itemsCreated}
                />
            </BoxLayout>
        </>
    );
};

export default Item;
