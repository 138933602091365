import {
    FormControlLabel,
    Modal,
    // Popover,
    Slide,
    Switch,
    Table,
    TableBody,
    TextField
} from "@mui/material";
import { useContext, useEffect, useRef, useState } from "react";
import {
    Root,
    classes,
    PopoverPrice
} from "../../assets/styles/pages/FormAlertAction";
import Button from "../../components/UI/Button";
import Icon from "../../components/UI/Icon";
import { useAlert } from "../../hooks/useAlert";
import Alert from "../../components/UI/Alert";
import { useIntercom } from "react-use-intercom";
import Tag from "../../components/UI/Tag";
import {
    SortableContainer,
    SortableElement,
    SortableHandle
} from "react-sortable-hoc";
import AuthGlobal from "../../context/store/global";
import { formatItemPrice } from "../../utils/Items/pricing";
import CustomTooltip from "../../components/UI/CustomTooltip";
import { arrayMoveImmutable } from "array-move";
import { Add } from "@mui/icons-material";
import FormItemPrice from "../../components/items/FormItemPrice";
import { updateItem } from "../../utils/Items/item-helper";
import { format } from "date-fns";
import { refreshRestaurant } from "../../utils/Restaurants/restaurant-helper";

const DragHandle = SortableHandle(() => {
    // const classes = useStyles();
    return (
        <CustomTooltip title="Drag to Order">
            <span className={classes.dragIconContainer}>
                <Icon name={"drag"} />{" "}
            </span>
        </CustomTooltip>
    );
});

const TableBodySortable = SortableContainer(({ children }) => {
    return <TableBody>{children}</TableBody>;
});

const PriceRow = SortableElement(
    ({
        data: {
            price, // object containing leadingText, trailingText, price
            index,
            handleRemovePrice,
            showDragHandler,
            onRowClick = () => {}
        }
    }) => {
        // const classes = useStyles();
        const {
            globalState: { current_restaurant }
        } = useContext(AuthGlobal);

        function getPrice() {
            const currency_symbol = current_restaurant.currency_symbol;
            const currency_placement = current_restaurant.currency_placement;

            return formatItemPrice(
                {
                    price_value: price.priceValue,
                    leading_text: price.leadingText,
                    trailing_text: price.trailingText
                },
                currency_placement,
                currency_symbol
            );
        }

        return (
            <tr
                style={{
                    marginTop: 12,
                    display: "flex",
                    flexDirection: "row"
                }}
                key={index}
            >
                <td className="priceRow">
                    <TextField
                        onClick={onRowClick}
                        value={getPrice()}
                        onChange={() => {}}
                        label={`Price #${index + 1}`}
                        className={`${classes.textField} ${classes.priceTag}`}
                        style={!showDragHandler ? { width: 396 } : {}}
                        InputLabelProps={{ shrink: true }}
                    />
                </td>
                {showDragHandler && (
                    <td
                        className={classes.removePriceContainer}
                        style={{ marginRight: 0 }}
                    >
                        <DragHandle />
                    </td>
                )}
                <td
                    className={classes.removePriceContainer}
                    onClick={() => handleRemovePrice(index)}
                >
                    {<Icon name="trash" className={classes.removePrice} />}
                </td>
            </tr>
        );
    }
);

const FormAlertAction = ({ show, onClose, alert = {}, updateAlert }) => {
    const {
        dispatch,
        globalState: {
            current_restaurant: { identifier },
            current_restaurant
        }
    } = useContext(AuthGlobal);

    const { update } = useIntercom();
    useEffect(() => {
        update({
            alignment: show ? "left" : "right"
        });
    }, [show, update]);

    // const classes = useStyles();
    const handleClose = () => {
        updateAlert(
            alert._id,
            ["new", "email sent"].includes(alert.status)
                ? "dismissed"
                : "cleared"
        );
        onClose();
    };
    // eslint-disable-next-line no-unused-vars
    const [notif, handleAlert] = useAlert();
    const [active, setActive] = useState(true);
    const [showPriceForm, setShowPriceForm] = useState(false);
    const [priceFormPosition, setPriceFormPosition] = useState({
        top: 0,
        left: 0
    });
    const [currentPrice, setCurrentPrice] = useState(null);
    const [prices, setPrices] = useState([]);
    const [addPriceHover, setAddPriceHover] = useState(false);

    const priceElementRef = useRef(null);

    useEffect(() => {
        setPrices(
            alert.item_id.price_variations.map((p) => ({
                leadingText: p.leading_text,
                trailingText: p.trailing_text,
                priceValue: p.price_value,
                integrationId: p.integration_id,
                _id: p._id,
                last_price_updated_at: p.last_price_updated_at
            }))
        );
        setActive(alert.item_id.status === "active");
    }, [alert]);

    const handleSubmit = async () => {
        let price_variations = prices;

        price_variations = price_variations.map((price) => ({
            leading_text: price.leadingText,
            trailing_text: price.trailingText,
            price_value: price.priceValue,
            integration_id: price.integrationId,
            _id: price._id,
            last_price_updated_at: price.last_price_updated_at
        }));
        try {
            const body = {
                ...alert.item_id,
                price_variations,
                status: active ? "active" : "draft"
            };
            await updateItem(body, alert.identifier, alert.item_id._id);
            await updateAlert(alert._id, "resolved");
            await refreshRestaurant(dispatch, identifier, current_restaurant);
            onClose();
        } catch (e) {
            handleAlert({
                severity: "error",
                title: "Error",
                message: e.message
            });
        }
    };

    const addPrice = () => {
        //setPrices((prev) => [...prev, priceInput]);
        if (priceElementRef.current) {
            const top = priceElementRef.current.getClientRects()[0].top;
            const left = priceElementRef.current.getClientRects()[0].left;
            setPriceFormPosition({ top, left });
        }
        setShowPriceForm(true);
    };

    useEffect(() => {
        if (currentPrice) {
            if (priceElementRef.current) {
                let top = priceElementRef.current.getClientRects()[0].top;
                // If we have more than 1 price, we need to adjust the top position of the prices form
                if (prices.length && typeof currentPrice.index === "number") {
                    top -= (prices.length - currentPrice.index) * 55;
                }
                const left = priceElementRef.current.getClientRects()[0].left;
                setPriceFormPosition({ top, left });
                setShowPriceForm(true);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentPrice]);

    const onSortEnd = (arr) => {
        setPrices((prev) =>
            arrayMoveImmutable(prev, arr.oldIndex, arr.newIndex)
        );
    };

    const handleRemovePrice = (index) => {
        const currentPrices = [
            ...prices.slice(0, index),
            ...prices.slice(index + 1)
        ];
        setPrices(currentPrices);
    };

    function handleItemPriceFormClose() {
        setShowPriceForm(false);
        setCurrentPrice(null);
    }

    function handlePriceFormSubmit(price) {
        if (typeof price.index !== "number") {
            // if we dont have an index, we are creating a new price
            setPrices((prev) => [
                ...prev,
                {
                    priceValue: price.priceValue,
                    trailingText: price.trailingText,
                    leadingText: price.leadingText,
                    integrationId: price.integrationId,
                    _id: price._id,
                    last_price_updated_at: price.last_price_updated_at
                }
            ]);
        } else {
            // if we have an index, we are updating an existing price
            setPrices((prev) => {
                const newPrices = [...prev];
                newPrices[price.index] = {
                    priceValue: price.priceValue,
                    trailingText: price.trailingText,
                    leadingText: price.leadingText,
                    integrationId: price.integrationId,
                    _id: price._id,
                    last_price_updated_at: price.last_price_updated_at
                };
                return newPrices;
            });
        }

        handleItemPriceFormClose();
    }

    return (
        <>
            <Alert {...notif} />
            <PopoverPrice
                anchorReference="anchorPosition"
                anchorPosition={{
                    top: priceFormPosition.top,
                    left: priceFormPosition.left - 32
                }}
                anchorOrigin={{
                    vertical: "top",
                    horizontal: "right"
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "right"
                }}
                open={showPriceForm}
                onClose={handleItemPriceFormClose}
                className={classes.popoverPrice}
            >
                <FormItemPrice
                    onSave={handlePriceFormSubmit}
                    price={currentPrice}
                    onClose={handleItemPriceFormClose}
                ></FormItemPrice>
            </PopoverPrice>
            <Modal open={show} onClose={onClose} disablePortal>
                <Slide in={show} direction="left">
                    <Root className={classes.container}>
                        <div className={classes.top}>
                            <span className={classes.sectionClose}>
                                <span
                                    className={classes.closeButtonContainer}
                                    onClick={onClose}
                                >
                                    <Icon
                                        name="clear"
                                        className={classes.closeButton}
                                    />
                                </span>
                            </span>
                            <h1 className={classes.title}>
                                <b>Cost Increase Alert</b> -{" "}
                                {alert.item_id.internal_name}
                            </h1>
                        </div>
                        <div className={classes.form}>
                            <div className={classes.formMain}>
                                <p className={classes.subtitle}>
                                    Affected Ingredients
                                </p>
                                <div className={classes.hline} />
                                <span
                                    style={{
                                        display: "flex",
                                        alignItems: "center",
                                        gap: 12,
                                        flexWrap: "wrap",
                                        marginBottom: 24
                                    }}
                                >
                                    <Tag
                                        label={alert.tag_id.tag_name}
                                        noMargin
                                    />
                                    <span
                                        style={{
                                            display: "flex",
                                            alignItems: "center"
                                        }}
                                    >
                                        <p
                                            style={{
                                                lineHeight: "32px",
                                                marginRight: 5
                                            }}
                                        >
                                            {`${alert.number_of_days}-Day: `}
                                        </p>
                                        <Icon name="arrowUp" />
                                        <p
                                            style={{
                                                lineHeight: "32px",
                                                marginLeft: 7,
                                                fontWeight: 700
                                            }}
                                        >
                                            {`${alert.increase_percentage}%`}
                                        </p>
                                    </span>
                                </span>
                                <p className={classes.subtitle}>
                                    Recomendation
                                </p>
                                <div className={classes.hline} />
                                <p className={classes.recomendation}>
                                    Your costs for {alert.item_id.internal_name}{" "}
                                    have increased. Protect your margin by{" "}
                                    <b>increasing your price</b> or by{" "}
                                    <b>removing this item</b> from your menu. If
                                    you don't want to take any action, you can
                                    dismiss this alert
                                </p>
                                <p className={classes.subtitle}>
                                    Change item price
                                </p>
                                <div className={classes.hline} />
                                <p
                                    style={{ marginBottom: -4 }}
                                    className={classes.recomendation}
                                >
                                    The last price update was:{" "}
                                    {format(
                                        new Date(
                                            alert.item_id.last_price_updated_at
                                        ),
                                        "MM/dd/yyyy"
                                    )}
                                </p>
                                <h5 className={classes.pricesInfo}>
                                    <Icon name="info" />
                                    Multiple prices can be added to a single
                                    item
                                </h5>
                                <Table className="sortable-tbody-alert-action">
                                    <TableBodySortable
                                        lockAxis="y"
                                        onSortEnd={onSortEnd}
                                        useDragHandle
                                        helperClass={classes.priceSorting}
                                        helperContainer={() =>
                                            document.querySelector(
                                                ".sortable-tbody-alert-action"
                                            )
                                        }
                                    >
                                        {prices.map((price, index) => (
                                            <PriceRow
                                                index={index}
                                                key={index}
                                                data={{
                                                    price,
                                                    index,
                                                    handleRemovePrice,
                                                    showDragHandler:
                                                        prices.length > 1,
                                                    onRowClick: () =>
                                                        setCurrentPrice({
                                                            index,
                                                            ...price
                                                        })
                                                }}
                                            />
                                        ))}
                                    </TableBodySortable>
                                </Table>
                                <div
                                    className={classes.pricesRow}
                                    ref={priceElementRef}
                                >
                                    <div
                                        className={classes.addPriceContainer}
                                        onClick={addPrice}
                                        // capture when the element is hovered
                                        onMouseEnter={() =>
                                            setAddPriceHover(true)
                                        }
                                        onMouseLeave={() =>
                                            setAddPriceHover(false)
                                        }
                                    >
                                        <span
                                            className={
                                                classes.addPriceIconContainer
                                            }
                                            style={{
                                                ...(addPriceHover && {
                                                    boxShadow:
                                                        "0px 10px 25px rgba(204, 88, 3, 0.35)"
                                                })
                                            }}
                                        >
                                            <Add
                                                className={classes.addPriceIcon}
                                            />
                                        </span>
                                        <p
                                            className={classes.addPriceText}
                                            style={{
                                                ...(addPriceHover && {
                                                    fontWeight: 500
                                                })
                                            }}
                                        >
                                            Add Price
                                        </p>
                                    </div>
                                </div>

                                <p className={classes.subtitle}>
                                    Take Item Off Menu
                                </p>
                                <div className={classes.hline} />
                                <p className={classes.toggleLabel}>
                                    Item Status
                                </p>
                                <FormControlLabel
                                    control={
                                        <Switch
                                            checked={active}
                                            classes={{
                                                switchBase:
                                                    classes.switchDisabled
                                            }}
                                            className={
                                                active ? classes.switch : ""
                                            }
                                        />
                                    }
                                    label={active ? "Active" : "Inactive"}
                                    onChange={() => setActive((val) => !val)}
                                    className={classes.switchLabel}
                                />
                            </div>
                        </div>
                        {/* push buttons at the end of the screen by leaving a dynamic empty space */}
                        <div className={classes.actions}>
                            <Button
                                type="primary"
                                color="orange"
                                innerText="Save Updates"
                                onClick={() => handleSubmit()}
                                className="save_item_button"
                            />
                            <Button
                                type="secondary"
                                color="dark"
                                innerText="Dismiss Alert"
                                onClick={() => handleClose()}
                            />
                        </div>
                    </Root>
                </Slide>
            </Modal>
        </>
    );
};

export default FormAlertAction;
