import { styled } from "@mui/material/styles";

const classes = {
    exportButton: `exportButton`,
    fileInput: `fileInput`
};

const Root = styled("div")(({ theme }) => ({
    [`&.${classes.exportButton}`]: {
        height: 36,
        width: 115,
        borderRadius: 20,
        cursor: "pointer",
        position: "relative",
        textAlign: "center",
        "&:hover > button": {
            // the 59 at the end sets opacity to 35% (on its hex format)
            boxShadow: `0px 10px 25px ${theme.colors.orange[700]}59`,
            outlineColor: "rgba(0, 0, 0, 0)",
            outlineOffset: "5px"
        }
    },
    [`& .${classes.fileInput}`]: {
        opacity: 0,
        position: "absolute",
        top: 0,
        left: 0,
        zIndex: 1,
        cursor: "pointer !important",
        height: "100%",
        width: "100%",
        "&::-webkit-file-upload-button": {
            cursor: "pointer"
        }
    }
}));

export { Root, classes };
