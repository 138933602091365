import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getMenuById } from "../../utils/Menus/menu-helper";
import FormCreateMenu from "./FormCreateMenu";
import AuthGlobal from "../../context/store/global";
import BoxLayout from "../../components/UI/BoxLayout";
import { CircularProgress } from "@mui/material";
const FormUpdateMenu = () => {
    const {
        globalState: {
            current_restaurant: { identifier }
        }
    } = useContext(AuthGlobal);
    const { idMenu } = useParams();
    const [menu, setMenu] = useState(null);

    useEffect(() => {
        (async () => {
            const { success, response } = await getMenuById(idMenu, identifier);
            if (success && response) {
                const menu = { ...response };
                menu.section_links = menu.section_links.map(
                    ({ section_id }) => ({
                        ...section_id,
                        item_links: section_id.item_links.map(
                            ({ item_id, sort_order }) => ({
                                item: item_id,
                                sort_order
                            })
                        )
                    })
                );
                setMenu(menu);
            }
        })();
    }, [idMenu, identifier]);

    return menu ? (
        <FormCreateMenu mode="update" setMenu={setMenu} menu={menu || {}} />
    ) : (
        <BoxLayout
            style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center"
            }}
        >
            <div>
                <CircularProgress
                    style={{
                        width: 80,
                        color: "#CC5803",
                        height: "100%"
                    }}
                />
            </div>
        </BoxLayout>
    );
};

export default FormUpdateMenu;
