import { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import Icon from "../../../components/UI/Icon";
import { TableRow, TableBody } from "@mui/material";

import {
    SortableContainer,
    SortableElement,
    SortableHandle
} from "react-sortable-hoc";

import { arrayMoveImmutable } from "array-move";
import NoResults from "../../../components/NoResults";
import {
    InfoText,
    Root,
    classes
} from "../../../assets/styles/pages/menus/tabs/Active";
import ConfirmAction from "../../../components/modals/ConfirmAction";
import CustomTooltip from "../../../components/UI/CustomTooltip";

import CustomPagination from "../../../components/UI/CustomPagination";
import Button from "../../../components/UI/Button";
import AuthGlobal from "../../../context/store/global";

const DragHandle = SortableHandle(() => {
    // const classes = useStyles();
    return (
        <CustomTooltip title="Drag to Order">
            <span className={classes.dragIconContainer}>
                <Icon name={"drag"} />{" "}
            </span>
        </CustomTooltip>
    );
});

const TableBodySortable = SortableContainer(({ children }) => (
    <TableBody className="sortable-tbody-active-menu">{children}</TableBody>
));

const Row = SortableElement(({ data, has_prints, user_type }) => {
    const navigate = useNavigate();

    const handlePDFView = () => {
        const url = `${data?.pdf_url}`;
        if (url) {
            // Open the URL in a new window
            window.open(url, "_blank", "noopener");
        }
    };
    
    const handlePrint = () => {
        if (has_prints) {
            const url = `${process.env.REACT_APP_MENU_PRINTER_URL}/menu/${data.identifier}/${data._id}/edit`;

            // Open the URL in a new window
            window.open(url, "_self");
        } else {
            data.showUpgradePlanModal();
        }
    };

    return (
        <TableRow
            className={`${classes.menuListTableRow} ${
                data.index % 2 === 0 ? classes.color1 : classes.color2
            }`}
        >
            <td className={classes.name}>
                {data.internal_name}{" "}
                {data.belongsTo?.length > 0 && (
                    <span className={classes.collections}>
                        {data.belongsTo.map(([id, collectionName], index) => (
                            <span key={index}>
                                <Icon name="clock" />
                                {collectionName}
                            </span>
                        ))}
                    </span>
                )}
            </td>
            {user_type === "view_only" ? (
                <td className={classes.dragSection}>
                    <Button
                        type="primary"
                        color="dark"
                        innerText="PDF"
                        className={`${classes.recoverMenu} ${
                            has_prints ? "" : classes.disablePrint
                        }`}
                        disableBtn={data?.pdf_url === ""}
                        onClick={handlePDFView}
                    />
                </td>
            ) : (
                <>
                    {!data.hideActions && (
                        <td className={classes.actions}>
                            <CustomTooltip title="Duplicate Menu">
                                <span className={classes.iconContainer}>
                                    <Icon
                                        onClick={() =>
                                            data.showDuplicateModal(
                                                data._id,
                                                data.internal_name
                                            )
                                        }
                                        name="duplicate"
                                    />
                                </span>
                            </CustomTooltip>
                            {/* <CustomTooltip title="Archive Menu">
                        <span className={classes.iconContainer}>
                            <Icon
                                onClick={() =>
                                    data.showDraftModal(
                                        data._id,
                                        data.internal_name
                                    )
                                }
                                name="archive"
                            />
                        </span>
                    </CustomTooltip> */}
                            <CustomTooltip title="Delete Menu">
                                <span className={classes.iconContainer}>
                                    <Icon
                                        onClick={() =>
                                            data.showDeleteModal(
                                                data._id,
                                                data.internal_name
                                            )
                                        }
                                        name="trash"
                                    />
                                </span>
                            </CustomTooltip>
                            <Button
                                type="primary"
                                color="dark"
                                innerText="Print"
                                className={`${classes.recoverMenu} ${
                                    has_prints ? "" : classes.disablePrint
                                }`}
                                onClick={handlePrint}
                            />
                            <Button
                                type="primary"
                                color="dark"
                                innerText="Edit"
                                className={classes.recoverMenu}
                                onClick={() =>
                                    navigate(`/menu/${data._id}/update`)
                                }
                            />
                        </td>
                    )}
                    {data.draggable && (
                        <td className={classes.dragSection}>
                            <DragHandle />
                        </td>
                    )}
                </>
            )}
        </TableRow>
    );
});

const ActiveTab = ({
    data,
    setData,
    duplicateMenuItem,
    setPagination,
    markAsTrash,
    markAsDraft,
    hideActions = false,
    draggable = false,
    setFirstLoad = () => {}
}) => {
    const {
        globalState: {
            current_restaurant: { has_prints },
            user_type
        }
    } = useContext(AuthGlobal);
    const [draftModalVisible, setDraftModalVisible] = useState(false);
    const [upgradePlanPopup, setUpgradePlanPopup] = useState(false);
    const [deleteModalVisible, setDeleteModalVisible] = useState(false);
    const [duplicateModalVisible, setDuplicateModalVisible] = useState(false);
    const [itemDelete, setItemDelete] = useState(false);
    const [itemDraft, setItemDraft] = useState(false);
    const [duplicateItem, setDuplicateItem] = useState();
    const [itemName, setItemName] = useState("");
    const [page, setPage] = useState(1);
    const rowsPerPage = setPagination ? 10 : data.length;

    const onSortEnd = (arr) => {
        setFirstLoad(false);
        setData((prev) => arrayMoveImmutable(prev, arr.oldIndex, arr.newIndex));
    };

    // const classes = useStyles();

    const showDeleteModal = (id, name) => {
        setItemName(name);
        setDeleteModalVisible(true);
        setItemDelete(id);
    };

    const showDuplicateModal = (id, name) => {
        setItemName(name);
        setDuplicateModalVisible(true);
        setDuplicateItem(id);
    };

    const showDraftModal = (id, name) => {
        setItemName(name);
        setDraftModalVisible(true);
        setItemDraft(id);
    };

    const showUpgradePlanModal = () => {
        setUpgradePlanPopup(true);
    };

    const okDeleteClick = () => {
        setDeleteModalVisible(false);
        markAsTrash(itemDelete);
    };

    const okDuplicateClick = (itemName) => {
        setDuplicateModalVisible(false);
        duplicateMenuItem(duplicateItem, itemName);
        setItemName("");
    };

    const okDraftClick = () => {
        setDraftModalVisible(false);
        markAsDraft(itemDraft);
    };

    return (
        <>
            {data.length > 0 ? (
                <Root
                    style={{
                        marginTop: 15,
                        display: "block",
                        minHeight: "calc(100vh - 335px)",
                        overflowY: "auto"
                    }}
                >
                    <TableBodySortable
                        onSortEnd={onSortEnd}
                        useDragHandle
                        helperClass={classes.sorting}
                        lockAxis="y"
                        helperContainer={() =>
                            document.querySelector(
                                ".sortable-tbody-active-menu"
                            )
                        }
                    >
                        {data
                            .slice((page - 1) * rowsPerPage, page * rowsPerPage)
                            .map((row, index) => {
                                return (
                                    <Row
                                        index={index}
                                        key={index}
                                        has_prints={has_prints}
                                        user_type={user_type}
                                        data={{
                                            ...row,
                                            index,
                                            showDeleteModal,
                                            showDuplicateModal,
                                            showDraftModal,
                                            showUpgradePlanModal,
                                            draggable,
                                            hideActions
                                        }}
                                    ></Row>
                                );
                            })}
                    </TableBodySortable>
                </Root>
            ) : (
                <NoResults text={"No menus found"} />
            )}

            {setPagination && (
                <CustomPagination
                    totalRows={data.length}
                    rowsPerPage={rowsPerPage}
                    handlePageChange={setPage}
                />
            )}

            {itemName && (
                <ConfirmAction
                    open={duplicateModalVisible}
                    actionCancel={() => {
                        setItemName("");
                        setDuplicateModalVisible(false);
                    }}
                    actionConfirm={okDuplicateClick}
                    icon="duplicate"
                    dialogText={`Are you sure want to duplicate the ${itemName} menu?`}
                    params={[
                        {
                            label: "New Menu Name",
                            value: `Copy of ${itemName}`,
                            description: "Change the Menu Name:"
                        }
                    ]}
                />
            )}

            <ConfirmAction
                open={deleteModalVisible}
                actionCancel={() => setDeleteModalVisible(false)}
                actionConfirm={okDeleteClick}
                icon="trash"
                dialogText={`Are you sure want to delete the ${itemName} menu?`}
            />

            <ConfirmAction
                open={draftModalVisible}
                actionCancel={() => setDraftModalVisible(false)}
                actionConfirm={okDraftClick}
                icon="archive"
                dialogText={`Are you sure want to archive the ${itemName} menu?`}
            />

            <ConfirmAction
                open={upgradePlanPopup}
                actionCancel={() => setUpgradePlanPopup(false)}
                hasInfo={true}
                icon="upgrade"
                dialogText={`Upgrade your plan to print menu.`}
                note={
                    <InfoText>
                        Please contact our support team to get more information
                        about printing your menus and upgrading your
                        subscription plan.
                    </InfoText>
                }
            />
        </>
    );
};

export default ActiveTab;
