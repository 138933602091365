import { TooltipCustom } from "../../assets/styles/CustomTooltip";

const CustomTooltip = ({ title, delay = 300, children }) => {
    // const classes = useStyles({ delay });

    return (
        <TooltipCustom
            title={title}
            delay={delay}
            children={children}
            placement="top"
            className="customPopper"
        ></TooltipCustom>
    );
};

export default CustomTooltip;
