import { Close } from "@mui/icons-material";
import { Grid, TextField } from "@mui/material";
import CustomIcon from "../UI/Icon";
import React, { useContext } from "react";
import { Root, classes } from "../../assets/styles/items/FormItemPrice";
import Button from "../UI/Button";
import AuthGlobal from "../../context/store/global";
import Accordion from "../UI/Accordion";

export default function FormItemPrice({
    onSave = () => {},
    onClose = () => {},
    price = null
}: {
    onSave?: any;
    onClose?: any;
    price: {
        leadingText?: string;
        priceValue?: string;
        trailingText?: string;
        integrationId?: string;
        index: number;
        _id: string;
        last_price_updated_at: string;
    } | null;
}) {
    const {
        globalState: { current_restaurant, user: current_user }
    } = useContext(AuthGlobal);
    const [leadingText, setLeadingText] = React.useState(
        price?.leadingText || ""
    );
    const [priceValue, setPriceValue] = React.useState(price?.priceValue || "");
    const [trailingText, setTrailingText] = React.useState(
        price?.trailingText || ""
    );
    const [integrationId, setIntegrationId] = React.useState(
        price?.integrationId || ""
    );
    const [error, setError] = React.useState(false);
    const [showMoreOptions, setShowMoreOptions] = React.useState(false);

    function submit() {
        if (!priceValue && !leadingText && !trailingText) {
            setError(true);
            return;
        }
        onSave({
            leadingText,
            priceValue,
            trailingText,
            index: price?.index,
            _id: price?._id,
            last_price_updated_at: price?.last_price_updated_at,
            integrationId
        });
    }

    React.useEffect(() => {
        if (priceValue || leadingText || trailingText) {
            setError(false);
        }
    }, [priceValue, leadingText, trailingText]);

    return (
        <Root className={classes.container}>
            <div className={classes.top}>
                <h1 className={classes.title}>
                    {price
                        ? "Edit Price " + (price.index + 1)
                        : "Add New Price"}
                </h1>
                <span className={classes.sectionClose} onClick={onClose}>
                    <Close
                        sx={{
                            width: 20
                        }}
                    ></Close>
                </span>
            </div>
            <div className={classes.content}>
                <Grid container>
                    <Grid item xs={4} className={classes.paddingRight}>
                        <TextField
                            fullWidth
                            error={error}
                            helperText={""}
                            placeholder="Add Text"
                            className={"item_name " + classes.textField}
                            label="Leading Text"
                            value={leadingText}
                            InputLabelProps={{ shrink: true }}
                            onChange={(event) =>
                                setLeadingText(event.target.value)
                            }
                        />
                    </Grid>
                    <Grid
                        item
                        xs={4}
                        className={classes.paddingRight}
                        style={{ width: "60px" }}
                    >
                        <TextField
                            type={"number"}
                            error={error}
                            helperText={""}
                            placeholder="Add Price"
                            className={"item_name " + classes.textField}
                            label="Item Price"
                            value={priceValue}
                            InputLabelProps={{ shrink: true }}
                            onChange={(event) => {
                                // Prevent negative numbers
                                setPriceValue(
                                    `${Math.abs(+event.target.value)}`
                                );
                            }}
                            // At the beginning, add a dollar sign to the price
                            InputProps={{
                                startAdornment:
                                    current_restaurant.currency_symbol || "$"
                            }}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <TextField
                            fullWidth
                            error={error}
                            helperText={""}
                            placeholder="Add Text"
                            className={"item_name " + classes.textField}
                            label="Trailing Text"
                            value={trailingText}
                            InputLabelProps={{ shrink: true }}
                            onChange={(event) =>
                                setTrailingText(event.target.value)
                            }
                            onKeyDown={(e) => {
                                if (e.key === "Enter") {
                                    submit();
                                }
                            }}
                        />
                    </Grid>
                </Grid>
                <span
                    style={{
                        color: "rgb(211, 47, 47)",
                        fontWeight: "400",
                        fontSize: ".75rem",
                        lineHeight: "1.66",
                        letterSpacing: ".03333em",
                        margin: "5px 0 -13px 23px",
                        display: error ? "block" : "none"
                    }}
                >
                    At least one of these fields is required.
                </span>
                <h5 className={classes.pricesInfo}>
                    <CustomIcon name="info" /> Currency symbols are changed in
                    Restaurant Settings.
                </h5>
            </div>
            <div className={classes.footer}>
                <div>
                    <Accordion isExpanded={showMoreOptions}>
                        <TextField
                            fullWidth
                            placeholder="Enter ID"
                            className={`item_name ${classes.textField}`}
                            label="Integration ID"
                            value={integrationId}
                            InputLabelProps={{ shrink: true }}
                            onChange={(event) => {
                                setIntegrationId(event.target.value);
                            }}
                            sx={{
                                margin: "25px 0 10px 0 !important"
                            }}
                        />
                    </Accordion>
                </div>
                <div className={classes.footerButtonsContainer}>
                    <Button
                        color="orange"
                        type="primary"
                        innerText="Save"
                        onClick={submit}
                        className="save_item_button"
                    />
                    {current_user.role === "super_admin" && (
                        <label
                            style={{
                                fontSize: "14px",
                                fontWeight: "400",
                                fontStyle: "italic",
                                color: showMoreOptions
                                    ? "rgba(34, 34, 34, 0.25)"
                                    : "rgba(34, 34, 34, 0.75)",
                                cursor: "pointer"
                            }}
                            onClick={() => setShowMoreOptions(!showMoreOptions)}
                        >
                            {showMoreOptions
                                ? "Hide options"
                                : "Show more options"}
                        </label>
                    )}
                </div>
            </div>
        </Root>
    );
}
