import { ReactComponent as ArrowDownIcon } from "../../assets/icons/ArrowDownIcon.svg";
import { ReactComponent as ArrowForwardIcon } from "../../assets/icons/ArrowForwardIcon.svg";
import { ReactComponent as ArrowRightIcon } from "../../assets/icons/ArrowRightIcon.svg";
import { ReactComponent as ArrowRight2Icon } from "../../assets/icons/ArrowRight2Icon.svg";
import { ReactComponent as ArrowUpIcon } from "../../assets/icons/ArrowUpIcon.svg";
import { ReactComponent as ArchiveIcon } from "../../assets/icons/ArchiveIcon.svg";
import { ReactComponent as BellIcon } from "../../assets/icons/BellIcon.svg";
import { ReactComponent as CheckCircleIcon } from "../../assets/icons/CheckCircleIcon.svg";
import { ReactComponent as CheckBoxActive } from "../../assets/icons/CheckBoxActive.svg";
import { ReactComponent as CheckBoxEmpty } from "../../assets/icons/CheckBoxEmpty.svg";
import { ReactComponent as CheckBoxIcon } from "../../assets/icons/CheckBoxIcon.svg";
import { ReactComponent as CheckButtonIcon } from "../../assets/icons/CheckButtonIcon.svg";
import { ReactComponent as ClearIcon } from "../../assets/icons/ClearIcon.svg";
import { ReactComponent as ClockIcon } from "../../assets/icons/ClockIcon.svg";
import { ReactComponent as CollectionsIcon } from "../../assets/icons/CollectionsIcon.svg";
import { ReactComponent as CopyIcon } from "../../assets/icons/CopyIcon.svg";
import { ReactComponent as CostIncreaseIcon } from "../../assets/icons/CostIncreaseIcon.svg";
import { ReactComponent as DragIcon } from "../../assets/icons/DragIcon.svg";
import { ReactComponent as DropdownIcon } from "../../assets/icons/DropdownIcon.svg";
import { ReactComponent as DuplicateIcon } from "../../assets/icons/DuplicateIcon.svg";
import { ReactComponent as EditIcon } from "../../assets/icons/EditIcon.svg";
import { ReactComponent as EmptyIcon } from "../../assets/icons/EmptyIcon.svg";
import { ReactComponent as ErrorIcon } from "../../assets/icons/ErrorIcon.svg";
import { ReactComponent as ExitIcon } from "../../assets/icons/ExitIcon.svg";
import { ReactComponent as ExportIcon } from "../../assets/icons/ExportIcon.svg";
import { ReactComponent as HelpIcon } from "../../assets/icons/HelpIcon.svg";
import { ReactComponent as ImportIcon } from "../../assets/icons/ImportIcon.svg";
import { ReactComponent as InfoIcon } from "../../assets/icons/InfoIcon.svg";
import { ReactComponent as ItemIcon } from "../../assets/icons/ItemIcon.svg";
import { ReactComponent as LogoutIcon } from "../../assets/icons/LogoutIcon.svg";
import { ReactComponent as MenuIcon } from "../../assets/icons/MenuIcon.svg";
import { ReactComponent as MinusIcon } from "../../assets/icons/MinusIcon.svg";
import { ReactComponent as NoImageIcon } from "../../assets/icons/NoImage.svg";
import { ReactComponent as PlusIcon } from "../../assets/icons/PlusIcon.svg";
import { ReactComponent as PlusButtonIcon } from "../../assets/icons/PlusButtonIcon.svg";
import { ReactComponent as PointerIcon } from "../../assets/icons/PointerIcon.svg";
import { ReactComponent as PrintIcon } from "../../assets/icons/PrintIcon.svg";
import { ReactComponent as PublishIcon } from "../../assets/icons/PublishIcon.svg";
import { ReactComponent as RefreshIcon } from "../../assets/icons/RefreshIcon.svg";
import { ReactComponent as RemoveIcon } from "../../assets/icons/RemoveIcon.svg";
import { ReactComponent as RestoreIcon } from "../../assets/icons/RestoreIcon.svg";
import { ReactComponent as SearchIcon } from "../../assets/icons/SearchIcon.svg";
import { ReactComponent as SettingsIcon } from "../../assets/icons/SettingsIcon.svg";
import { ReactComponent as Settings2Icon } from "../../assets/icons/Settings2Icon.svg";
import { ReactComponent as SaveIcon } from "../../assets/icons/SaveIcon.svg";
import { ReactComponent as StoreIcon } from "../../assets/icons/StoreIcon.svg";
import { ReactComponent as TrashIcon } from "../../assets/icons/TrashIcon.svg";
import { ReactComponent as UserIcon } from "../../assets/icons/UserIcon.svg";
import { ReactComponent as ArrowBack } from "../../assets/icons/ArrowBack.svg";
import { ReactComponent as Drink } from "../../assets/icons/Drinks.svg";
import { ReactComponent as PdfIcon } from "../../assets/icons/PdfIcon.svg";
import { ReactComponent as Upgrade } from "../../assets/icons/Upgrade.svg";
import { ReactComponent as FileIcon } from "../../assets/icons/FileIcon.svg";

const iconTypes = {
    file: FileIcon,
    upgrade: Upgrade,
    pdf: PdfIcon,
    arrowDown: ArrowDownIcon,
    arrowForward: ArrowForwardIcon,
    arrowRight: ArrowRightIcon,
    arrowRight2: ArrowRight2Icon,
    arrowUp: ArrowUpIcon,
    archive: ArchiveIcon,
    bell: BellIcon,
    check: CheckCircleIcon,
    checkbox: CheckBoxIcon,
    checkboxActive: CheckBoxActive,
    checkboxEmpty: CheckBoxEmpty,
    checkButton: CheckButtonIcon,
    clear: ClearIcon,
    clock: ClockIcon,
    collections: CollectionsIcon,
    copy: CopyIcon,
    costIncrease: CostIncreaseIcon,
    drag: DragIcon,
    dropdown: DropdownIcon,
    duplicate: DuplicateIcon,
    edit: EditIcon,
    empty: EmptyIcon,
    error: ErrorIcon,
    exit: ExitIcon,
    export: ExportIcon,
    help: HelpIcon,
    import: ImportIcon,
    info: InfoIcon,
    item: ItemIcon,
    logout: LogoutIcon,
    menu: MenuIcon,
    minus: MinusIcon,
    noImage: NoImageIcon,
    plus: PlusIcon,
    plusButton: PlusButtonIcon,
    pointer: PointerIcon,
    print: PrintIcon,
    publish: PublishIcon,
    refresh: RefreshIcon,
    restore: RestoreIcon,
    remove: RemoveIcon,
    search: SearchIcon,
    settings: SettingsIcon,
    settings2: Settings2Icon,
    save: SaveIcon,
    store: StoreIcon,
    trash: TrashIcon,
    user: UserIcon,
    back: ArrowBack,
    drink: Drink
};

const Icon = ({ name, ...props }) => {
    let Icon = iconTypes[name];
    return Icon ? <Icon {...props} /> : <></>;
};

export default Icon;
