import { styled } from "@mui/material/styles";

const classes = {
    paginator: `paginator`,
    pagintationItem: `pagintationItem`
};
const Root = styled("div")(({ theme }) => ({
    [`&.${classes.paginator}`]: {
        display: "flex",
        flexDirection: "row-reverse",
        marginTop: 20,
        marginBottom: 20
    },
    [`& .${classes.pagintationItem}`]: {
        "& li > button": {
            background: "white",
            minWidth: 20,
            maxWidth: 20,
            height: 20,
            fontSize: 11,
            fontWeight: 500,
            borderRadius: 3,
            "&[aria-current=true], &:hover": {
                background: `${theme.colors.orange[700]} !important`,
                color: "white",
                border: 0,
                minWidth: 20,
                maxWidth: 20
            }
        }
    }
}));

export { Root, classes };
