import { useState, useEffect, useContext } from "react";

import BoxLayout from "../../components/UI/BoxLayout";
import CollectionsList from "./CollectionsList";
import {
    Tabs,
    Tab,
    Menu as Options,
    MenuItem,
    CircularProgress
} from "@mui/material";

import AuthGlobal from "../../context/store/global";

import {
    getCollections,
    getDraftCollections,
    getTrashCollections,
    searchActiveCollections,
    searchDraftCollections,
    searchTrashCollections,
    markAsActiveCollection,
    markAsDraftCollection,
    markAsTrashCollection,
    duplicateCollection,
    deleteCollection
} from "../../utils/Collections/collection-helper";
import { Root, classes } from "../../assets/styles/pages/menus/Menu";

import { useAlert } from "../../hooks/useAlert";
import Header from "../../components/UI/Header";
import Icon from "../../components/UI/Icon";
import {
    getCollectionLinks,
    refreshRestaurant
} from "../../utils/Restaurants/restaurant-helper";
import { fetchRequest } from "../../utils";
import Alert from "../../components/UI/Alert";

const Collection = () => {
    const {
        dispatch,
        globalState: {
            current_restaurant: { identifier },
            current_restaurant
        }
    } = useContext(AuthGlobal);

    const [alert, handleAlert] = useAlert();

    const [activeCollections, setActiveCollections] = useState([]);
    const [draftCollections, setDraftCollections] = useState([]);
    const [trashCollections, setTrashCollections] = useState([]);
    const [allCollectionsRendered, setAllCollectionsRendered] = useState(true);
    const [searchText, setSearchText] = useState("");
    const [value, setValue] = useState(0);
    const [schedules, setSchedules] = useState([]);
    const [order, setOrder] = useState("A-Z");
    const [anchorEl, setAnchorEl] = useState(null);
    const [loading, setLoading] = useState(false);
    const dropdownOpen = Boolean(anchorEl);
    const handleSortOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleSort = (order) => {
        setAnchorEl(null);
        if (order) {
            setOrder(order);
        }
    };

    useEffect(() => {
        refreshRestaurant(dispatch, identifier, current_restaurant);
        //eslint-disable-next-line
    }, []);

    useEffect(() => {
        loadData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [order]);

    const loadData = async () => {
        setLoading(true);
        await getAllCollections();
        await getAllDraftCollections();
        await getAllTrashCollections();
        setLoading(false);
    };

    const getAllCollections = async () => {
        setAllCollectionsRendered(true);
        const {
            success: collectionLinksSuccess,
            response: collectionLinksResponse
        } = await getCollectionLinks(identifier);
        if (collectionLinksSuccess && collectionLinksResponse) {
            setSchedules(collectionLinksResponse.collection_links);
        }
        const { success, response } = await getCollections(identifier, order);
        if (success && response) {
            setActiveCollections(response);
        }
    };

    const getAllDraftCollections = async () => {
        const { success, response } = await getDraftCollections(
            identifier,
            order
        );
        if (success && response) {
            setDraftCollections(response);
        }
    };

    const getAllTrashCollections = async () => {
        const { success, response } = await getTrashCollections(
            identifier,
            order
        );
        if (success && response) {
            setTrashCollections(response);
        }
    };

    useEffect(() => {
        const searchTimeout = setTimeout(() => {
            (async () => {
                if (searchText.length > 2) {
                    setLoading(true);
                    setAllCollectionsRendered(false);
                    if (value === 0) {
                        const { success, response } =
                            await searchActiveCollections(
                                searchText,
                                identifier,
                                order
                            );
                        if (success && response) {
                            setActiveCollections(response);
                        }
                    } else if (value === 1) {
                        const { success, response } =
                            await searchDraftCollections(
                                searchText,
                                identifier,
                                order
                            );
                        if (success && response) {
                            setDraftCollections(response);
                        }
                    } else {
                        const { success, response } =
                            await searchTrashCollections(
                                searchText,
                                identifier,
                                order
                            );
                        if (success && response) {
                            setTrashCollections(response);
                        }
                    }
                    setLoading(false);
                }
                if (searchText.length <= 2 && !allCollectionsRendered) {
                    loadData();
                }
            })();
        }, 300);

        return () => clearTimeout(searchTimeout);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchText, value, identifier, order]);

    const clearSearchText = () => {
        setSearchText("");
        loadData();
    };

    const markAsDraft = async (id) => {
        const { success, response } = await markAsDraftCollection(
            id,
            identifier
        );
        if (success && response) {
            await refreshRestaurant(dispatch, identifier, current_restaurant);
            handleAlert({
                severity: "success",
                title: "Success",
                message: response.message
            });
            setTimeout(loadData, 500);
        } else {
            handleAlert({
                severity: "error",
                title: "Error",
                message: response.message
            });
        }
    };

    const markAsActive = async (id) => {
        const { success, response } = await markAsActiveCollection(
            id,
            identifier
        );
        if (success && response) {
            await refreshRestaurant(dispatch, identifier, current_restaurant);
            handleAlert({
                severity: "success",
                title: "Success",
                message: response.message
            });
            setTimeout(loadData, 500);
        } else {
            handleAlert({
                severity: "error",
                title: "Error",
                message: response.message
            });
        }
    };

    const duplicateCollectionItem = async (id, newName) => {
        const { success, response } = await duplicateCollection(
            id,
            identifier,
            newName
        );
        if (success && response) {
            await refreshRestaurant(dispatch, identifier, current_restaurant);
            handleAlert({
                severity: "success",
                title: "Success",
                message: response.message
            });
            setTimeout(loadData, 500);
        } else {
            handleAlert({
                severity: "error",
                title: "Error",
                message: response.message
            });
        }
    };

    const markAsTrash = async (id) => {
        const { success, response } = await markAsTrashCollection(
            id,
            identifier
        );
        if (success && response) {
            await refreshRestaurant(dispatch, identifier, current_restaurant);
            handleAlert({
                severity: "success",
                title: "Success",
                message: response.message
            });
            setTimeout(loadData, 500);
        } else {
            handleAlert({
                severity: "error",
                title: "Error",
                message: response.message
            });
        }
    };

    const deleteElementItem = async (id) => {
        const { success, response } = await deleteCollection(id, identifier);
        if (success && response) {
            await refreshRestaurant(dispatch, identifier, current_restaurant);
            handleAlert({
                severity: "success",
                title: "Success",
                message: response.message
            });
            setTimeout(loadData, 500);
        } else {
            handleAlert({
                severity: "error",
                title: "Error",
                message: response.message
            });
        }
    };

    const handleChangeTab = (_event, newValue) => {
        setValue(newValue);
        clearSearchText();
    };

    const tabLabel = (text) => {
        const count =
            text === "Active Periods"
                ? activeCollections.length
                : text === "Inactive Periods"
                ? draftCollections.length
                : text === "Trash"
                ? trashCollections.length
                : 0;

        return (
            <span className={classes.labelText}>
                {text}
                <span
                    style={{
                        color: "#888888",
                        marginLeft: "0.2rem",
                        fontWeight: "normal"
                    }}
                >
                    ({count})
                </span>
            </span>
        );
    };

    const labelProps = (index) => {
        return {
            id: `simple-tab-${index}`,
            "aria-controls": `simple-tabpanel-${index}`
        };
    };

    function TabPanel(props) {
        const { children, value, index, ...other } = props;

        return (
            <div
                role="tabpanel"
                hidden={value !== index}
                id={`simple-tabpanel-${index}`}
                aria-labelledby={`simple-tab-${index}`}
                {...other}
            >
                {value === index && <div>{children}</div>}
            </div>
        );
    }
    const [firstLoad, setFirstLoad] = useState(true);

    useEffect(() => {
        (async () => {
            if (!firstLoad) {
                const { success, response } = await fetchRequest(
                    `/restaurant/${identifier}/reorderPeriods`,
                    {
                        method: "PUT",
                        body: JSON.stringify({ rows: activeCollections })
                    }
                );
                await refreshRestaurant(
                    dispatch,
                    identifier,
                    current_restaurant
                );
                if (!success || !response) {
                    handleAlert({
                        severity: "error",
                        title: "Error",
                        message: response.message
                    });
                }
            }
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeCollections, firstLoad, identifier, dispatch]);

    return (
        <>
            <Alert {...alert} />
            <BoxLayout>
                <Header
                    onClickCreate={() =>
                        (window.location.href = "/service-periods/new")
                    }
                    title="Service Periods"
                    createButtonText="Service Period"
                    showSearchBar={true}
                    clearSearchText={clearSearchText}
                    searchText={searchText}
                    setSearchText={setSearchText}
                />

                <Root className={classes.table}>
                    <div className={classes.tabsRow}>
                        <Tabs
                            className={classes.tabsContainer}
                            value={value}
                            onChange={handleChangeTab}
                        >
                            <Tab
                                label={tabLabel("Active Periods")}
                                {...labelProps(0)}
                            />
                            <Tab
                                label={tabLabel("Inactive Periods")}
                                {...labelProps(1)}
                            />
                            <Tab label={tabLabel("Trash")} {...labelProps(2)} />
                        </Tabs>
                        {value !== 0 && (
                            <>
                                <div
                                    className={classes.sortHandler}
                                    id="sort-dropdown"
                                    aria-controls={
                                        dropdownOpen ? "basic-menu" : undefined
                                    }
                                    aria-haspopup="true"
                                    aria-expanded={
                                        dropdownOpen ? "true" : undefined
                                    }
                                    onClick={handleSortOpen}
                                >
                                    <span>
                                        {order === "Z-A" ? "Z to A" : "A to Z"}
                                    </span>
                                    <Icon name="dropdown" />
                                </div>
                                <Options
                                    id="basic-menu"
                                    anchorEl={anchorEl}
                                    open={dropdownOpen}
                                    onClose={() => handleSort()}
                                    MenuListProps={{
                                        "aria-labelledby": "sort-dropdown"
                                    }}
                                    disableScrollLock={true}
                                >
                                    <MenuItem onClick={() => handleSort("A-Z")}>
                                        A to Z
                                    </MenuItem>
                                    <MenuItem onClick={() => handleSort("Z-A")}>
                                        Z to A
                                    </MenuItem>
                                </Options>
                            </>
                        )}
                    </div>
                    {!loading && (
                        <>
                            <TabPanel value={value} index={0}>
                                <CollectionsList
                                    data={activeCollections}
                                    setData={setActiveCollections}
                                    status="active"
                                    duplicateElementItem={
                                        duplicateCollectionItem
                                    }
                                    markAsTrash={markAsTrash}
                                    markAsInactive={markAsDraft}
                                    setPagination={true}
                                    draggable={true}
                                    setFirstLoad={setFirstLoad}
                                    schedules={schedules}
                                    refresh={getAllCollections}
                                />
                            </TabPanel>
                            <TabPanel value={value} index={1}>
                                <CollectionsList
                                    data={draftCollections}
                                    status="inactive"
                                    duplicateElementItem={
                                        duplicateCollectionItem
                                    }
                                    markAsTrash={markAsTrash}
                                    markAsActive={markAsActive}
                                    setPagination={true}
                                />
                            </TabPanel>
                            <TabPanel value={value} index={2}>
                                <CollectionsList
                                    data={trashCollections}
                                    status="trash"
                                    markAsInactive={markAsDraft}
                                    deleteElementItem={deleteElementItem}
                                    setPagination={true}
                                />
                            </TabPanel>
                        </>
                    )}
                    {loading && (
                        <div className={classes.loader}>
                            <CircularProgress />
                        </div>
                    )}
                </Root>
            </BoxLayout>
        </>
    );
};

export default Collection;
