import {
    Autocomplete,
    Checkbox,
    Chip,
    CircularProgress,
    Collapse,
    FormControlLabel,
    MenuItem,
    Modal,
    // Popover,
    Slide,
    Switch,
    Table,
    TableBody,
    TextField
} from "@mui/material";
import { useContext, useEffect, useState, useRef } from "react";
import AuthGlobal from "../../context/store/global";

import {
    Root,
    classes,
    PopoverRoot,
    selectRow
} from "../../assets/styles/items/FormCreateItem";
import {
    Root as TagStyles,
    classes as tagStyles
} from "../../pages/settings/Tags/Styles";

import Button from "../UI/Button";
import CustomTooltip from "../UI/CustomTooltip";
import Icon from "../UI/Icon";
import { createItem, updateItem } from "../../utils/Items/item-helper";
import { useAlert } from "../../hooks/useAlert";
import {
    createNotation,
    getAllNotations
} from "../../utils/Notations/notations-helper";
import {
    SortableContainer,
    SortableElement,
    SortableHandle
} from "react-sortable-hoc";
import { arrayMoveImmutable } from "array-move";
import { refreshRestaurant } from "../../utils/Restaurants/restaurant-helper";
import Alert from "../UI/Alert";
import { saveImage } from "../../utils/mediaHelper";
import { useIntercom } from "react-use-intercom";
import FormItemPrice from "./FormItemPrice";
import { Add } from "@mui/icons-material";
import { formatItemPrice } from "../../utils/Items/pricing";
import SearchBar from "../UI/SearchBar";
import Tag from "../UI/Tag";
import { fetchRequest } from "../../utils";
import FormCreateTag from "../tags/FormCreateTag";
import { useTheme } from "@mui/material/styles";
import FormItemDescription from "./FormItemDescription";

const DragHandle = SortableHandle(() => {
    return (
        <CustomTooltip title="Drag to Order">
            <span className={classes.dragIconContainer}>
                <Icon name={"drag"} />{" "}
            </span>
        </CustomTooltip>
    );
});

const TableBodySortable = SortableContainer(({ children }) => {
    return <TableBody>{children}</TableBody>;
});

const urlRegex = new RegExp(
    // eslint-disable-next-line no-useless-escape
    /[(http(s)?):\/\/(www\.)?a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/gi
);

const PriceRow = SortableElement(
    ({
        data: {
            price, // object containing leadingText, trailingText, price
            index,
            handleRemovePrice,
            showDragHandler,
            onRowClick = () => {}
        }
    }) => {
        // const classes = useStyles();
        const {
            globalState: { current_restaurant }
        } = useContext(AuthGlobal);

        function getPrice() {
            const currency_symbol = current_restaurant.currency_symbol;
            const currency_placement = current_restaurant.currency_placement;

            return formatItemPrice(
                {
                    price_value: price.priceValue,
                    leading_text: price.leadingText,
                    trailing_text: price.trailingText
                },
                currency_placement,
                currency_symbol
            );
        }

        return (
            <tr
                style={{
                    marginTop: 12,
                    display: "flex",
                    flexDirection: "row"
                }}
                key={index}
            >
                <td className="priceRow">
                    <TextField
                        onClick={onRowClick}
                        value={getPrice()}
                        onChange={() => {}}
                        label={`Price #${index + 1}`}
                        className={`${classes.textField} ${classes.priceTag}`}
                        style={!showDragHandler ? { width: 396 } : {}}
                        InputLabelProps={{ shrink: true }}
                    />
                </td>
                {showDragHandler && (
                    <td
                        className={classes.removePriceContainer}
                        style={{ marginRight: 0 }}
                    >
                        <DragHandle />
                    </td>
                )}
                <td
                    className={classes.removePriceContainer}
                    onClick={() => handleRemovePrice(index)}
                >
                    {<Icon name="trash" className={classes.removePrice} />}
                </td>
            </tr>
        );
    }
);

const PriceTypes = [
    {
        value: "standard",
        label: "Standard Price (e.g. $25)"
    },
    {
        value: "modifier",
        label: "Modifier Multi Price (e.g. $15, add chicken + $5)"
    },
    {
        value: "slash",
        label: "Slash Multi Price (e.g. $12 glass / $48 bottle)"
    }
];

const defaultPriceType = PriceTypes[0];

const FormCreateItem = ({
    show,
    onClose,
    mode = "create",
    item = {},
    handleItemSave = true,
    onUpdateItems
}) => {
    const {
        dispatch,
        globalState: {
            current_restaurant: { identifier },
            current_restaurant,
            user
        }
    } = useContext(AuthGlobal);
    const { update } = useIntercom();
    useEffect(() => {
        update({
            alignment: show ? "left" : "right"
        });
    }, [show, update]);

    // const classes = useStyles();
    // const tagStyles = tagButtonStyles();
    const [allNotations, setAllNotations] = useState([]);
    const [allAllergies, setAllAllergies] = useState([]);
    const [NotationsAndAllergies, setNotationsAndAllergies] = useState([]);
    const [showTagForm, setShowTagForm] = useState(false);
    const [active, setActive] = useState(true);
    const [syncWithPOS, setSyncWithPOS] = useState(false);
    const [syncWithPOSPriceLevel, setSyncWithPOSPriceLevel] = useState(false);
    const [posItem, setPosItem] = useState(false);
    const [itemButton, setItemButton] = useState(false);
    const [buttonUrl, setButtonUrl] = useState("");
    const [urlError, setUrlError] = useState("");
    const [buttonText, setButtonText] = useState("");
    const [buttonTextError, setButtonTextError] = useState("");
    const [name, setName] = useState("");
    const [description, setDescription] = useState("");
    const [prices, setPrices] = useState([]);
    const [priceType, setPriceType] = useState(defaultPriceType);
    const [notations, setNotations] = useState([]);
    const [allergies, setAllergies] = useState([]);
    const [file, setFile] = useState(null);
    const [image, setImage] = useState("");
    const [imageError, setImageError] = useState("");
    const [deleteImage, setDeleteImage] = useState(false);
    const [allTags, setAllTags] = useState([]);
    const [selectedTags, setSelectedTags] = useState([]);
    const [newItems, setNewItems] = useState([]);
    const [tagSearch, setTagSearch] = useState("");
    const [showDropDown, toggleDropdown] = useState(false);
    const [loadingNotations, setLoadingNotations] = useState(false);
    const [loadingAllergies, setLoadingAllergies] = useState(false);
    const handleClose = (
        preventReload = true,
        alertData = { open: false },
        newItem,
        resetData = false
    ) => {
        if (resetData && mode === "create") {
            setActive(true);
            setName("");
            setDescription("");
            setPrices([]);
            setPriceType(defaultPriceType);
            setAllergies([]);
            setNotations([]);
            setErrorText("");
            setFile(null);
            setImage("");
            setButtonUrl("");
            setButtonText("");
            setSelectedTags([]);
            setItemButton(false);
        }
        onClose(preventReload, alertData, newItem);
    };
    const [searchNotation, setSearchNotation] = useState("");
    const [searchAllergy, setSearchAllergy] = useState("");
    const [errorText, setErrorText] = useState("");
    const [alert, handleAlert] = useAlert();
    // eslint-disable-next-line no-unused-vars
    const [_priceErrors, setPriceErrors] = useState([]);
    const [showPriceForm, setShowPriceForm] = useState(false);
    const [showDescriptionForm, setShowDescriptionForm] = useState(false);
    const [priceFormPosition, setPriceFormPosition] = useState({
        top: 0,
        left: 0
    });
    const [descriptionFormPosition, setDescriptionFormPosition] = useState({
        top: 0,
        left: 0
    });
    const [addPriceHover, setAddPriceHover] = useState(false);
    const [saveLoad, setSaveLoad] = useState(false);
    const [saveAddLoad, setSaveAddLoad] = useState(false);
    const [disableBtn, setDisableBtn] = useState(false);
    const [currentPrice, setCurrentPrice] = useState(null);
    const priceElementRef = useRef(null);
    const descriptionElementRef = useRef(null);
    const scrollableDivRef = useRef(null);
    const newItemText = useRef(null);

    const theme = useTheme();

    useEffect(() => {
        if (!show) {
            setNewItems([]);
        } else {
            newItemText.current.focus();
        }
    }, [show]);

    useEffect(() => {
        getAllNotations(identifier).then(({ response }) => {
            setNotationsAndAllergies(response);
            setAllNotations(response?.filter((x) => x?.type === "notation"));
            setAllAllergies(response?.filter((x) => x?.type === "allergy"));
        });
    }, [identifier]);
    useEffect(() => {
        if (Object.keys(item).length > 0 && show) {
            setActive(item.status === "active");
            setPosItem(item.pos_item ? true : false);
            setSyncWithPOS(item.sync_with_pos ? true : false);
            setSyncWithPOSPriceLevel(
                item.sync_with_pos_at_price_level ? true : false
            );
            setName(item.display_name);
            setDescription(item.description);
            setPrices(
                item.price_variations.map((p) => ({
                    leadingText: p.leading_text,
                    trailingText: p.trailing_text,
                    priceValue: p.price_value,
                    integrationId: p.integration_id,
                    _id: p._id,
                    last_price_updated_at: p.last_price_updated_at
                }))
            );
            let _price_type = PriceTypes.find(
                (x) => x.value === item.price_type
            );
            if (!_price_type) _price_type = defaultPriceType;
            setPriceType(_price_type);
            setNotations(
                item.notation_links
                    .map((link) => {
                        return allNotations.find(
                            (x) => x._id === (link?.notation_id || link)
                        )?.internal_name;
                    })
                    .filter((x) => x)
            );
            setAllergies(
                item.notation_links
                    .map((link) => {
                        return allAllergies.find(
                            (x) => x._id === (link?.notation_id || link)
                        )?.internal_name;
                    })
                    .filter((x) => x)
            );
            setImage(item.image_url || "");
            setItemButton(item.button_status || false);
            setButtonUrl(item.button_url || "");
            setButtonText(item.button_text || "");
            setSelectedTags(item.tags || []);
        }
        // eslint-disable-next-line
    }, [item, show]);

    useEffect(() => {
        if (name.length > 0) {
            setErrorText("");
        }
    }, [name]);
    useEffect(() => {
        setUrlError("");
    }, [itemButton, buttonUrl]);

    useEffect(() => {
        !showTagForm && getAllTags();
    }, [showTagForm]);

    useEffect(() => {
        setButtonTextError("");
    }, [buttonText]);

    useEffect(() => {
        if (currentPrice) {
            if (priceElementRef.current) {
                let top = priceElementRef.current.getClientRects()[0].top;
                // If we have more than 1 price, we need to adjust the top position of the prices form
                if (prices.length && typeof currentPrice.index === "number") {
                    top -= (prices.length - currentPrice.index) * 55;
                }
                const left = priceElementRef.current.getClientRects()[0].left;
                setPriceFormPosition({ top, left });
                setShowPriceForm(true);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentPrice]);

    const toggleNotation = (key) => {
        if (notations.includes(key)) {
            setNotations((prev) => [...prev.filter((item) => item !== key)]);
        } else {
            setNotations((prev) => [...prev, key]);
        }
    };

    const toggleAllergy = (key) => {
        if (allergies.includes(key)) {
            setAllergies((prev) => [...prev.filter((item) => item !== key)]);
        } else {
            setAllergies((prev) => [...prev, key]);
        }
    };

    const getAllTags = async () => {
        const { success, response } = await fetchRequest("/tag");
        if (success && response) {
            setAllTags(Object.fromEntries(response.map((x) => [x._id, x])));
        }
    };

    const addPrice = () => {
        //setPrices((prev) => [...prev, priceInput]);
        if (priceElementRef.current) {
            const top = priceElementRef.current.getClientRects()[0].top;
            const left = priceElementRef.current.getClientRects()[0].left;
            setPriceFormPosition({ top, left });
        }
        setShowPriceForm(true);
    };

    const addDescription = () => {
        if (name.length === 0) {
            setErrorText("* Please enter the Item Name");
            scrollableDivRef.current.scrollTop = 0;
            return;
        }
        if (descriptionElementRef.current) {
            const top = descriptionElementRef.current.getClientRects()[0].top;
            const left = descriptionElementRef.current.getClientRects()[0].left;
            setDescriptionFormPosition({ top, left });
        }
        setShowDescriptionForm(true);
    };

    const handleRemovePrice = (index) => {
        const currentPrices = [
            ...prices.slice(0, index),
            ...prices.slice(index + 1)
        ];
        setPrices(currentPrices);
    };

    const handleSubmit = async ({ close = true }) => {
        if (
            name.length === 0 ||
            prices.some((x) => x.length === 0) ||
            name.trim() === ""
        ) {
            if (name.length === 0 || name.trim() === "")
                setErrorText("* Please enter the Item Name");
            if (prices.some((x) => x.length === 0))
                setPriceErrors(prices.map((x) => x.length === 0));
            return;
        }

        if (
            (itemButton && !buttonUrl) ||
            (buttonUrl && !buttonUrl.match(urlRegex))
        ) {
            setUrlError("*Please Input a Valid URL");
            return;
        }

        let success,
            response,
            successUpload = true,
            image_url = "";
        if (file) {
            setSaveLoad(close);
            setDisableBtn(true);
            [successUpload, image_url] = await saveImage(
                file,
                identifier,
                "items"
            );
        } else if (!deleteImage) {
            image_url = item?.image_url || "";
        }
        if (!successUpload) {
            image_url = "";
            handleAlert({
                severity: "error",
                title: "Error",
                message:
                    "There was a problem while trying to save your item image, please try again in a few seconds"
            });
            return;
        }
        let price_variations = prices;

        price_variations = price_variations.map((price) => ({
            leading_text: price.leadingText,
            trailing_text: price.trailingText,
            price_value: price.priceValue,
            integration_id: price.integrationId,
            _id: price._id,
            last_price_updated_at: price.last_price_updated_at
        }));
        const body = {
            status: active ? "active" : "draft",
            type: item?.type || "item",
            notation_links: [...notations, ...allergies]
                .map(
                    (selectedNotation) =>
                        NotationsAndAllergies.find(
                            (x) => x.internal_name === selectedNotation
                        )?._id
                )
                .filter((x) => x),
            price_variations,
            internal_name: name,
            display_name: name,
            description,
            image_url,
            button_status: itemButton,
            button_url: buttonUrl,
            button_text: buttonText || "Buy Now",
            price_type: priceType.value,
            tags: selectedTags,
            sync_with_pos: syncWithPOS,
            sync_with_pos_at_price_level: syncWithPOSPriceLevel,
            pos_item: posItem
        };

        setSaveLoad(close);
        setSaveAddLoad(!close);
        setDisableBtn(true);

        if (mode === "create") {
            ({ success, response } = await createItem(body, identifier));
        } else {
            if (handleItemSave) {
                ({ success, response } = await updateItem(
                    body,
                    identifier,
                    item._id
                ));
            }
        }

        let updatedItems = [];

        if (close && success && response) {
            refreshRestaurant(dispatch, identifier, current_restaurant);
            updatedItems = [...newItems, response.item];
            setNewItems(updatedItems);

            handleClose(
                false,
                {
                    severity: "success",
                    title: "Success",
                    message: response.message
                },
                [...updatedItems] || body,
                true
            );
        } else if (!close && success && response) {
            updatedItems = [...newItems, response.item];
            setNewItems(updatedItems);

            setActive(true);
            setPosItem(false);
            setName("");
            setDescription("");
            setPrices([]);
            setAllergies([]);
            setNotations([]);
            setErrorText("");
            setImage("");
            setButtonUrl("");
            setButtonText("");
            setSelectedTags([]);
            setItemButton(false);
            handleAlert({
                severity: "success",
                title: "Success",
                message: response.message
            });
            scrollableDivRef.current.scrollTop = 0;
            newItemText.current.focus();
        } else {
            if (handleItemSave) {
                handleAlert({
                    severity: "error",
                    title: "Error",
                    message: response.message
                });
            } else {
                onUpdateItems(body, item._id);
                handleClose();
            }
        }
        setSaveLoad(false);
        setSaveAddLoad(false);
        setDisableBtn(false);
    };

    const handleCreateNotation = async (name, type) => {
        if (type === "Notation") {
            setLoadingNotations(true);
        } else {
            setLoadingAllergies(true);
        }
        const { success, response } = await createNotation(
            identifier,
            name,
            type.toLowerCase()
        );
        if (success && response) {
            setNotationsAndAllergies((prev) => [...prev, response.notation]);
            if (type === "Notation") {
                setAllNotations((prev) => [...prev, response.notation]);
                setNotations((prev) => [
                    ...prev,
                    response.notation.internal_name
                ]);
            } else {
                setAllAllergies((prev) => [...prev, response.notation]);
                setAllergies((prev) => [
                    ...prev,
                    response.notation.internal_name
                ]);
            }
        } else {
            handleAlert({
                severity: "error",
                title: "Error",
                message: "Unexpected error, please try again"
            });
        }
        if (type === "Notation") {
            setLoadingNotations(false);
        } else {
            setLoadingAllergies(false);
        }
    };

    const handleImageChange = (e) => {
        if (e.target.files[0]?.size) {
            if (e.target.files[0].size > 5000000) {
                setImageError(
                    "*Your file was too large to upload. The maximum file size is 5 MB."
                );
                setFile(null);
                setImage("");
                e.target.value = null;
                return;
            }
            if (
                ![
                    "image/png",
                    "image/jpg",
                    "image/jpeg",
                    "image/svg+xml"
                ].includes(e.target.files[0].type)
            ) {
                setImageError(
                    "*Unsupported file type. Please upload only a JPG, PNG, or SVG file."
                );
                setFile(null);
                setImage("");
                e.target.value = null;
                return;
            }
            const url = URL.createObjectURL(e.target.files[0]);
            setFile(e.target.files[0]);
            setImage(url);
            e.target.value = null;
        }
    };

    const buttonCreate = (type) => {
        const name = type === "Notation" ? searchNotation : searchAllergy;
        return (
            <Root>
                {name.length > 0 && (
                    <span
                        className={`${classes.addNotationContainer} ${classes.labelRow}`}
                        onClick={() => handleCreateNotation(name, type)}
                    >
                        <Icon name="plus" className={classes.addNotationIcon} />
                        {`Add New ${name} ${type}`}
                    </span>
                )}
                {name.length <= 0 && (
                    <span
                        className={`${classes.addNotationContainer} ${classes.noOptions}`}
                    >
                        No options
                    </span>
                )}
            </Root>
        );
    };

    const onSortEnd = (arr) => {
        setPrices((prev) =>
            arrayMoveImmutable(prev, arr.oldIndex, arr.newIndex)
        );
    };

    // Handle Description the form close
    function handleDescriptionFormClose() {
        setShowDescriptionForm(false);
    }

    // Handle description form submit
    function handleDescriptionFormSubmit(val) {
        handleDescriptionFormClose();
        setDescription(val.description);
    }

    // just close the form
    function handleItemPriceFormClose() {
        setShowPriceForm(false);
        setCurrentPrice(null);
    }

    function handlePriceFormSubmit(price) {
        if (typeof price.index !== "number") {
            // if we dont have an index, we are creating a new price
            setPrices((prev) => [
                ...prev,
                {
                    priceValue: price.priceValue,
                    trailingText: price.trailingText,
                    leadingText: price.leadingText,
                    integrationId: price.integrationId,
                    _id: price._id,
                    last_price_updated_at: price.last_price_updated_at
                }
            ]);
        } else {
            // if we have an index, we are updating an existing price
            setPrices((prev) => {
                const newPrices = [...prev];
                newPrices[price.index] = {
                    priceValue: price.priceValue,
                    trailingText: price.trailingText,
                    leadingText: price.leadingText,
                    integrationId: price.integrationId,
                    _id: price._id,
                    last_price_updated_at: price.last_price_updated_at
                };
                return newPrices;
            });
        }

        handleItemPriceFormClose();
    }

    return (
        <>
            <Alert {...alert} />
            <PopoverRoot
                anchorReference="anchorPosition"
                anchorPosition={{
                    top: descriptionFormPosition.top,
                    left: descriptionFormPosition.left - 32
                }}
                anchorOrigin={{
                    vertical: "top",
                    horizontal: "right"
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "right"
                }}
                open={showDescriptionForm}
                onClose={handleDescriptionFormClose}
                className={classes.popoverPrice}
            >
                <FormItemDescription
                    onSave={handleDescriptionFormSubmit}
                    itemName={name}
                    onClose={handleDescriptionFormClose}
                ></FormItemDescription>
            </PopoverRoot>
            <PopoverRoot
                anchorReference="anchorPosition"
                anchorPosition={{
                    top: priceFormPosition.top,
                    left: priceFormPosition.left - 32
                }}
                anchorOrigin={{
                    vertical: "top",
                    horizontal: "right"
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "right"
                }}
                open={showPriceForm}
                onClose={handleItemPriceFormClose}
                className={classes.popoverPrice}
            >
                <FormItemPrice
                    onSave={handlePriceFormSubmit}
                    price={currentPrice}
                    onClose={handleItemPriceFormClose}
                ></FormItemPrice>
            </PopoverRoot>
            <Modal open={show} onClose={() => handleClose()} disablePortal>
                <Slide in={show} direction="left">
                    <Root className={classes.container}>
                        <div className={classes.top}>
                            <span className={classes.sectionClose}>
                                <span
                                    className={classes.closeButtonContainer}
                                    onClick={() => handleClose()}
                                >
                                    <Icon
                                        name="clear"
                                        className={classes.closeButton}
                                    />
                                </span>
                            </span>
                            <h1 className={classes.title}>
                                {mode === "create"
                                    ? "Create New Item"
                                    : `Edit ${item.internal_name}`}
                            </h1>
                        </div>
                        <div
                            className={classes.form}
                            ref={scrollableDivRef}
                            style={{ scrollBehavior: "smooth" }}
                        >
                            <div className={classes.formMain}>
                                <p className={classes.toggleLabel}>
                                    Item Status
                                </p>
                                <FormControlLabel
                                    control={
                                        <Switch
                                            checked={active}
                                            classes={{
                                                switchBase:
                                                    classes.switchDisabled
                                            }}
                                            className={
                                                active ? classes.switch : ""
                                            }
                                        />
                                    }
                                    label={active ? "Active" : "Inactive"}
                                    onChange={() => setActive((val) => !val)}
                                    className={classes.switchLabel}
                                />
                                <div
                                    style={{
                                        display:
                                            user.role === "super_admin" &&
                                            posItem
                                                ? "block"
                                                : "none"
                                    }}
                                >
                                    <p className={classes.toggleLabel}>
                                        Sync with POS
                                    </p>
                                    <FormControlLabel
                                        control={
                                            <Switch
                                                checked={syncWithPOS}
                                                classes={{
                                                    switchBase:
                                                        classes.switchDisabled
                                                }}
                                                className={
                                                    syncWithPOS
                                                        ? classes.switch
                                                        : ""
                                                }
                                            />
                                        }
                                        label={
                                            syncWithPOS ? "Active" : "Inactive"
                                        }
                                        onChange={() =>
                                            setSyncWithPOS((val) => !val)
                                        }
                                        className={classes.switchLabel}
                                    />
                                </div>
                                <div
                                    style={{
                                        display:
                                            user.role === "super_admin" &&
                                            posItem
                                                ? "block"
                                                : "none"
                                    }}
                                >
                                    <p className={classes.toggleLabel}>
                                        Sync with POS Price Level
                                    </p>
                                    <FormControlLabel
                                        control={
                                            <Switch
                                                checked={syncWithPOSPriceLevel}
                                                classes={{
                                                    switchBase:
                                                        classes.switchDisabled
                                                }}
                                                className={
                                                    syncWithPOSPriceLevel
                                                        ? classes.switch
                                                        : ""
                                                }
                                            />
                                        }
                                        label={
                                            syncWithPOSPriceLevel
                                                ? "Active"
                                                : "Inactive"
                                        }
                                        onChange={() =>
                                            setSyncWithPOSPriceLevel(
                                                (val) => !val
                                            )
                                        }
                                        className={classes.switchLabel}
                                    />
                                </div>
                                <TextField
                                    required
                                    fullWidth
                                    inputRef={newItemText}
                                    error={errorText.length > 0}
                                    helperText={errorText}
                                    placeholder="Enter Item Name"
                                    className={"item_name " + classes.textField}
                                    label="Item Name"
                                    value={name}
                                    InputLabelProps={{ shrink: true }}
                                    onChange={(event) =>
                                        setName(event.target.value)
                                    }
                                />
                                {item.type !== "title" && (
                                    <>
                                        <div ref={descriptionElementRef}>
                                            <TextField
                                                multiline
                                                fullWidth
                                                label="Item Description"
                                                placeholder="Enter Descriptive Text"
                                                className={`item_description ${classes.textField} ${classes.multiline}`}
                                                InputLabelProps={{
                                                    shrink: true
                                                }}
                                                value={description}
                                                onChange={(e) =>
                                                    setDescription(
                                                        e.target.value
                                                    )
                                                }
                                            />
                                            <div
                                                className={
                                                    classes.autoDescription
                                                }
                                                onClick={addDescription}
                                            >
                                                <Icon name="refresh" />
                                                Auto-Generate Description
                                            </div>
                                        </div>
                                        <h5 className={classes.pricesInfo}>
                                            <Icon name="info" />
                                            Multiple prices can be added to a
                                            single item
                                        </h5>
                                        <Table className="sortable-tbody-create-item">
                                            <TableBodySortable
                                                lockAxis="y"
                                                onSortEnd={onSortEnd}
                                                useDragHandle
                                                helperClass={
                                                    classes.priceSorting
                                                }
                                                helperContainer={() =>
                                                    document.querySelector(
                                                        ".sortable-tbody-create-item"
                                                    )
                                                }
                                            >
                                                {prices.map((price, index) => (
                                                    <PriceRow
                                                        index={index}
                                                        key={index}
                                                        data={{
                                                            price,
                                                            index,
                                                            handleRemovePrice,
                                                            showDragHandler:
                                                                prices.length >
                                                                1,
                                                            onRowClick: () =>
                                                                setCurrentPrice(
                                                                    {
                                                                        index,
                                                                        ...price
                                                                    }
                                                                )
                                                        }}
                                                    />
                                                ))}
                                            </TableBodySortable>
                                        </Table>
                                        <div
                                            className={classes.pricesRow}
                                            ref={priceElementRef}
                                        >
                                            <div
                                                className={
                                                    classes.addPriceContainer
                                                }
                                                onClick={addPrice}
                                                // capture when the element is hovered
                                                onMouseEnter={() =>
                                                    setAddPriceHover(true)
                                                }
                                                onMouseLeave={() =>
                                                    setAddPriceHover(false)
                                                }
                                            >
                                                <span
                                                    className={
                                                        classes.addPriceIconContainer
                                                    }
                                                    style={{
                                                        ...(addPriceHover && {
                                                            boxShadow:
                                                                "0px 10px 25px rgba(204, 88, 3, 0.35)"
                                                        })
                                                    }}
                                                >
                                                    <Add
                                                        className={
                                                            classes.addPriceIcon
                                                        }
                                                    />
                                                </span>
                                                <p
                                                    className={
                                                        classes.addPriceText
                                                    }
                                                    style={{
                                                        ...(addPriceHover && {
                                                            fontWeight: 500
                                                        })
                                                    }}
                                                >
                                                    Add Price
                                                </p>
                                            </div>
                                        </div>
                                        {Boolean(prices.length) && (
                                            <TextField
                                                select
                                                value={priceType.label}
                                                onChange={(e) => {
                                                    setPriceType(
                                                        PriceTypes.find(
                                                            (type) =>
                                                                type.value ===
                                                                e.target.value
                                                        )
                                                    );
                                                }}
                                                label="Price Type"
                                                InputLabelProps={{
                                                    shrink: true
                                                }}
                                                SelectProps={{
                                                    MenuProps: {
                                                        PaperProps: {
                                                            sx: selectRow(theme)
                                                        },
                                                        classes: {
                                                            paper: classes.selectRow
                                                        }
                                                    }
                                                }}
                                                className={classes.dropdown}
                                            >
                                                {PriceTypes.map((type) => (
                                                    <MenuItem
                                                        key={type.value}
                                                        value={type.value}
                                                        className={
                                                            type.value ===
                                                            priceType.value
                                                                ? "dropdown-selected"
                                                                : ""
                                                        }
                                                    >
                                                        {type.label}
                                                    </MenuItem>
                                                ))}
                                            </TextField>
                                        )}
                                        <Autocomplete
                                            fullWidth
                                            multiple
                                            value={notations}
                                            inputValue={searchNotation}
                                            onInputChange={(e) =>
                                                setSearchNotation(
                                                    e?.target?.value || ""
                                                )
                                            }
                                            disableCloseOnSelect={true}
                                            options={allNotations.map(
                                                (notation) =>
                                                    notation.internal_name
                                            )}
                                            renderOption={(_props, option) => {
                                                const active =
                                                    notations.includes(option);
                                                return (
                                                    <div
                                                        key={option}
                                                        onClick={() => {
                                                            toggleNotation(
                                                                option
                                                            );
                                                        }}
                                                        className={`${
                                                            classes.notationRow
                                                        } ${
                                                            active
                                                                ? classes.selectedNotation
                                                                : ""
                                                        }`}
                                                    >
                                                        <FormControlLabel
                                                            control={
                                                                <Checkbox
                                                                    classes={{
                                                                        root: classes.switchDisabled
                                                                    }}
                                                                    checked={
                                                                        active
                                                                    }
                                                                    icon={
                                                                        <Icon name="checkboxEmpty" />
                                                                    }
                                                                    checkedIcon={
                                                                        <Icon name="checkboxActive" />
                                                                    }
                                                                    className={`${
                                                                        classes.checkbox
                                                                    } ${
                                                                        active
                                                                            ? classes.checkboxActive
                                                                            : ""
                                                                    }`}
                                                                    style={{
                                                                        pointerEvents:
                                                                            "auto"
                                                                    }}
                                                                />
                                                            }
                                                            label={option}
                                                            className={
                                                                classes.labelRow
                                                            }
                                                        />
                                                    </div>
                                                );
                                            }}
                                            className={classes.notationsSelect}
                                            clearIcon={false}
                                            disablePortal={true}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    InputProps={{
                                                        ...params.InputProps,
                                                        endAdornment: (
                                                            <>
                                                                {loadingNotations && (
                                                                    <CircularProgress
                                                                        style={{
                                                                            color: "#CC5803",
                                                                            float: "left"
                                                                        }}
                                                                        size={
                                                                            20
                                                                        }
                                                                    />
                                                                )}
                                                                {!loadingNotations &&
                                                                    params
                                                                        .InputProps
                                                                        .endAdornment}
                                                            </>
                                                        )
                                                    }}
                                                    label="Dietary Restrictions"
                                                    placeholder="+ Add Dietary Restriction"
                                                    InputLabelProps={{
                                                        shrink: true
                                                    }}
                                                    className={`${classes.select} ${classes.textField}`}
                                                />
                                            )}
                                            renderTags={(value) => {
                                                return value.map((item) => (
                                                    <Chip
                                                        key={item}
                                                        label={item}
                                                        className={classes.tag}
                                                        deleteIcon={
                                                            <Icon name="clear" />
                                                        }
                                                        onDelete={() =>
                                                            setNotations(
                                                                (prev) =>
                                                                    prev.filter(
                                                                        (x) =>
                                                                            x !==
                                                                            item
                                                                    )
                                                            )
                                                        }
                                                    />
                                                ));
                                            }}
                                            // open={true}
                                            componentsProps={{
                                                paper: {
                                                    className:
                                                        classes.selectPopper
                                                }
                                            }}
                                            noOptionsText={buttonCreate(
                                                "Notation"
                                            )}
                                        />
                                        <Autocomplete
                                            fullWidth
                                            multiple
                                            value={allergies}
                                            inputValue={searchAllergy}
                                            onInputChange={(e) =>
                                                setSearchAllergy(
                                                    e?.target?.value || ""
                                                )
                                            }
                                            disableCloseOnSelect={true}
                                            options={allAllergies.map(
                                                (allergy) =>
                                                    allergy.internal_name
                                            )}
                                            renderOption={(_props, option) => {
                                                const active =
                                                    allergies.includes(option);
                                                return (
                                                    <div
                                                        key={option}
                                                        onClick={() => {
                                                            toggleAllergy(
                                                                option
                                                            );
                                                        }}
                                                        className={`${
                                                            classes.notationRow
                                                        } ${
                                                            active
                                                                ? classes.selectedNotation
                                                                : ""
                                                        }`}
                                                    >
                                                        <FormControlLabel
                                                            control={
                                                                <Checkbox
                                                                    checked={
                                                                        active
                                                                    }
                                                                    icon={
                                                                        <Icon name="checkboxEmpty" />
                                                                    }
                                                                    checkedIcon={
                                                                        <Icon name="checkboxActive" />
                                                                    }
                                                                    className={`${
                                                                        classes.checkbox
                                                                    } ${
                                                                        active
                                                                            ? classes.checkboxActive
                                                                            : ""
                                                                    }`}
                                                                    style={{
                                                                        pointerEvents:
                                                                            "auto"
                                                                    }}
                                                                />
                                                            }
                                                            label={option}
                                                            className={
                                                                classes.labelRow
                                                            }
                                                        />
                                                    </div>
                                                );
                                            }}
                                            disablePortal={true}
                                            className={classes.notationsSelect}
                                            clearIcon={false}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    InputProps={{
                                                        ...params.InputProps,
                                                        endAdornment: (
                                                            <>
                                                                {loadingAllergies && (
                                                                    <CircularProgress
                                                                        style={{
                                                                            color: "#CC5803",
                                                                            float: "left"
                                                                        }}
                                                                        size={
                                                                            20
                                                                        }
                                                                    />
                                                                )}
                                                                {!loadingAllergies &&
                                                                    params
                                                                        .InputProps
                                                                        .endAdornment}
                                                            </>
                                                        )
                                                    }}
                                                    label="Allergies"
                                                    placeholder="+ Add Allergy"
                                                    InputLabelProps={{
                                                        shrink: true
                                                    }}
                                                    className={`${classes.select} ${classes.textField}`}
                                                />
                                            )}
                                            // open={true}
                                            renderTags={(value) => {
                                                return value.map((item) => (
                                                    <Chip
                                                        key={item}
                                                        label={item}
                                                        className={classes.tag}
                                                        deleteIcon={
                                                            <Icon name="clear" />
                                                        }
                                                        onDelete={() =>
                                                            setAllergies(
                                                                (prev) =>
                                                                    prev.filter(
                                                                        (x) =>
                                                                            x !==
                                                                            item
                                                                    )
                                                            )
                                                        }
                                                    />
                                                ));
                                            }}
                                            componentsProps={{
                                                paper: {
                                                    className:
                                                        classes.selectPopper
                                                }
                                            }}
                                            noOptionsText={buttonCreate(
                                                "Allergy"
                                            )}
                                        />
                                        <span className={classes.centered}>
                                            Item Image
                                        </span>
                                        <div className={classes.imageContainer}>
                                            <input
                                                type="file"
                                                title=""
                                                className={classes.fileInput}
                                                onChange={handleImageChange}
                                            />
                                            <Icon
                                                name="remove"
                                                className={classes.deleteImage}
                                                onClick={() => {
                                                    setImage("");
                                                    setDeleteImage(true);
                                                    setFile(null);
                                                }}
                                            />
                                            {image && (
                                                // eslint-disable-next-line jsx-a11y/img-redundant-alt
                                                <img
                                                    className={classes.image}
                                                    src={image}
                                                    alt="Item image"
                                                />
                                            )}
                                            {!image && (
                                                <>
                                                    <Icon name="noImage" />
                                                    <p
                                                        className={
                                                            classes.noImageText
                                                        }
                                                    >
                                                        Upload or Drag Image.
                                                        JPG, PNG or SVG - 5 MB
                                                        max
                                                    </p>
                                                </>
                                            )}
                                        </div>
                                        {imageError ? (
                                            <span
                                                className={classes.imageError}
                                            >
                                                {imageError}
                                            </span>
                                        ) : (
                                            <h5 className={classes.pricesInfo}>
                                                <Icon name="info" /> For item
                                                images to display best, upload
                                                images with a 1:1 square size
                                                ratio
                                            </h5>
                                        )}
                                        <p className={classes.toggleLabel}>
                                            Item Button
                                        </p>
                                        <FormControlLabel
                                            control={
                                                <Switch
                                                    checked={itemButton}
                                                    classes={{
                                                        switchBase:
                                                            classes.switchDisabled
                                                    }}
                                                    className={
                                                        itemButton
                                                            ? classes.switch
                                                            : ""
                                                    }
                                                />
                                            }
                                            label={
                                                itemButton
                                                    ? "Active"
                                                    : "Inactive"
                                            }
                                            onChange={() =>
                                                setItemButton((val) => !val)
                                            }
                                            className={classes.switchLabel}
                                        />
                                        <TextField
                                            fullWidth
                                            error={urlError.length > 0}
                                            helperText={urlError}
                                            placeholder="Enter URL"
                                            className={classes.textField}
                                            label="Button URL"
                                            type="url"
                                            value={buttonUrl}
                                            InputLabelProps={{ shrink: true }}
                                            onChange={(event) =>
                                                setButtonUrl(event.target.value)
                                            }
                                        />
                                        <TextField
                                            fullWidth
                                            error={buttonTextError.length > 0}
                                            helperText={buttonTextError}
                                            placeholder="Buy Now"
                                            className={classes.textField}
                                            label="Button Text"
                                            type="text"
                                            value={buttonText}
                                            InputLabelProps={{ shrink: true }}
                                            onChange={(event) =>
                                                setButtonText(
                                                    event.target.value
                                                )
                                            }
                                        />
                                    </>
                                )}
                            </div>
                            <div
                                className={`${classes.tagSectionContainer} ${
                                    !showDropDown && classes.blurred
                                }`}
                                style={{
                                    display:
                                        user.role === "super_admin"
                                            ? "block"
                                            : "none"
                                }}
                            >
                                <span className={classes.dropdownHead}>
                                    <p className={classes.title2}>Item Tags</p>
                                    <Icon
                                        className={classes.dropdownToggle}
                                        name={showDropDown ? "minus" : "plus"}
                                        onClick={() =>
                                            toggleDropdown((prev) => !prev)
                                        }
                                    />
                                </span>
                                <Collapse in={showDropDown}>
                                    <div
                                        style={{
                                            fontSize: "12px",
                                            fontWeight: 500,
                                            marginBottom: 10
                                        }}
                                    >
                                        Current Item Tags
                                    </div>
                                    {selectedTags.length === 0 && (
                                        <Tag label="No Tags" disabled />
                                    )}
                                    {selectedTags.map((_id) => (
                                        <Tag
                                            key={_id}
                                            label={allTags[_id]?.tag_name}
                                            onDelete={() => {
                                                let newTags = [...selectedTags];
                                                newTags = newTags.filter(
                                                    (x) => x !== _id
                                                );
                                                setSelectedTags(newTags);
                                            }}
                                            deleteIcon={<Icon name="clear" />}
                                        />
                                    ))}
                                    <div
                                        style={{
                                            fontSize: "12px",
                                            fontWeight: 500
                                        }}
                                    >
                                        Add Tags
                                    </div>
                                    <div className={classes.inlineSearchBar}>
                                        <SearchBar
                                            placeholder="Search for tags"
                                            searchText={tagSearch}
                                            setSearchText={setTagSearch}
                                            clearSearchText={() =>
                                                setTagSearch("")
                                            }
                                        />
                                    </div>
                                    {Object.values(allTags)
                                        .filter(
                                            ({ tag_name, _id }) =>
                                                tag_name.match(
                                                    new RegExp(tagSearch, "i")
                                                ) && !selectedTags.includes(_id)
                                        )
                                        .map(({ tag_name, _id }) => (
                                            <Tag
                                                key={_id}
                                                label={tag_name}
                                                onClick={() =>
                                                    setSelectedTags((prev) => [
                                                        ...prev,
                                                        _id
                                                    ])
                                                }
                                            />
                                        ))}
                                    <TagStyles
                                        className={tagStyles.addTagContainer}
                                        style={{ margin: "8px 0 20px" }}
                                        onClick={() => setShowTagForm(true)}
                                    >
                                        <span
                                            className={
                                                tagStyles.addTagIconContainer
                                            }
                                        >
                                            <Add
                                                className={tagStyles.addTagIcon}
                                            />
                                        </span>
                                        <p className={tagStyles.addTagText}>
                                            Add New Tag
                                        </p>
                                    </TagStyles>
                                </Collapse>
                            </div>
                        </div>
                        {/* push buttons at the end of the screen by leaving a dynamic empty space */}
                        <div className={classes.actions}>
                            <Button
                                type="primary"
                                color="orange"
                                innerText="Save"
                                itemLoading={saveLoad}
                                disableBtn={disableBtn}
                                onClick={() => handleSubmit({ close: true })}
                                className="save_item_button"
                            />
                            {/* {mode === "create" && (
                                <Button
                                    type="secondary"
                                    color="dark"
                                    innerText="Save & New Item"
                                    itemLoading={saveAddLoad}
                                    disableBtn={disableBtn}
                                    className="save_and_new_button"
                                    icon="plus"
                                    onClick={() =>
                                        handleSubmit({ close: false })
                                    }
                                />
                            )}
                            {mode !== "create" && ( */}
                            <Button
                                type="secondary"
                                color="dark"
                                innerText="Cancel"
                                itemLoading={saveAddLoad}
                                disableBtn={disableBtn}
                                onClick={() =>
                                    handleClose(
                                        undefined,
                                        undefined,
                                        undefined,
                                        true
                                    )
                                }
                            />
                            {/* )} */}
                        </div>
                    </Root>
                </Slide>
            </Modal>
            <FormCreateTag
                show={showTagForm}
                tag={null}
                onClose={() => setShowTagForm(false)}
            />
        </>
    );
};

export default FormCreateItem;
