import { useContext, useState } from "react";
import Icon from "./Icon";
import { saveImage } from "../../utils/mediaHelper";
import AuthContext from "../../context/store/global";
import { styled } from "@mui/material/styles";
import { useAlert } from "../../hooks/useAlert";
import Alert from "./Alert";
import { CircularProgress } from "@mui/material";

const classes = {
    imageContainer: `imageContainer`,
    image: `image`,
    deleteImage: `deleteImage`,
    fileInput: `fileInput`,
    noImageText: `noImageText`,
    imageError: `imageError`,
    pricesInfo: `pricesInfo`
};

const ImageError = styled("span")(({ theme }) => ({
    [`&.${classes.imageError}`]: {
        color: "#D8000C",
        fontStyle: "italic",
        fontSize: 12,
        marginTop: 8
    }
}));
const PricesInfo = styled("h5")(({ theme }) => ({
    [`&.${classes.pricesInfo}`]: {
        margin: "0px 0 20px 5px",
        height: "13px",
        color: theme.colors.grey[400],
        "& svg": {
            transform: "translateY(2px)",
            width: 12,
            marginRight: 8,
            height: 12
        },
        "& path": {
            fill: theme.colors.grey[400]
        }
    }
}));
const Root = styled("div")(({ theme }) => ({
    [`&.${classes.imageContainer}`]: {
        marginTop: 4,
        height: 150,
        border: "1px dashed rgba(180, 179, 179, 0.5)",
        borderRadius: 20,
        padding: 32,
        position: "relative",
        textAlign: "center",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
        "&:hover": {
            "& svg:nth-of-type(2)": {
                "& path": {
                    fill: theme.colors.grey[700]
                }
            },
            "& p": {
                opacity: 1
            }
        }
    },
    [`& .${classes.image}`]: {
        maxWidth: "100%",
        height: "100%",
        objectFit: "scale-down"
    },
    [`& .${classes.deleteImage}`]: {
        position: "absolute",
        top: 16,
        right: 18,
        width: 16,
        cursor: "pointer",
        "& path": {
            fill: theme.colors.grey[500]
        },
        "&:hover": {
            "& path": {
                fill: theme.colors.grey[700]
            }
        }
    },
    [`& .${classes.fileInput}`]: {
        opacity: 0,
        position: "absolute",
        top: 0,
        left: 0,
        cursor: "pointer",
        height: "100%",
        width: "100%"
    },
    [`& .${classes.noImageText}`]: {
        fontWeight: 600,
        fontSize: 12,
        lineHeight: "14px",
        marginTop: 8,
        marginBottom: 0,
        opacity: 0.4,
        color: `${theme.colors.grey[900]}a6`
    }
}));

const ImageUploader = ({
    image,
    setFile,
    setDeleteImage,
    setImage,
    autoSave = false
}) => {
    const {
        globalState: {
            current_restaurant: { identifier }
        }
    } = useContext(AuthContext);

    const [imageError, setImageError] = useState("");
    const [logoLoading, setLogoLoading] = useState(false);
    const [alert, handleAlert] = useAlert();

    const handleImageChange = async (e) => {
        setLogoLoading(true);
        if (e.target.files[0]?.size) {
            setDeleteImage(false);
            if (e.target.files[0].size > 5000000) {
                setImageError(
                    "*Your file was too large to upload. The maximum file size is 5 MB."
                );
                setFile(null);
                setImage("");
                e.target.value = null;
                setLogoLoading(false);
                return;
            }
            if (
                ![
                    "image/png",
                    "image/jpg",
                    "image/jpeg",
                    "image/svg+xml"
                ].includes(e.target.files[0].type)
            ) {
                setImageError(
                    "*Unsupported file type. Please upload only a JPG, PNG, or SVG file."
                );
                setFile(null);
                setImage("");
                e.target.value = null;
                setLogoLoading(false);
                return;
            }
            if (autoSave) {
                const [successUpload, image_url] = await saveImage(
                    e.target.files[0],
                    identifier,
                    "items"
                );

                if (successUpload) {
                    setImage(image_url);
                    setImageError("");
                } else {
                    handleAlert({
                        severity: "error",
                        title: "Error",
                        message:
                            image_url ||
                            "Something went wrong when uploading your image, please try again"
                    });
                }
            } else {
                const url = URL.createObjectURL(e.target.files[0]);
                setImage(url);
                setImageError("");
            }
            setFile(e.target.files[0]);
            e.target.value = null;
        }
        setLogoLoading(false);
    };

    return (
        <>
            <Alert {...alert} />
            <Root className={classes.imageContainer}>
                <input
                    type="file"
                    title=""
                    className={classes.fileInput}
                    onChange={handleImageChange}
                />
                <Icon
                    name="remove"
                    className={classes.deleteImage}
                    onClick={() => {
                        setImage("");
                        setDeleteImage(true);
                        setFile(null);
                    }}
                />
                {image &&
                    // eslint-disable-next-line jsx-a11y/img-redundant-alt
                    (logoLoading ? (
                        <CircularProgress
                            style={{
                                width: 40,
                                height: 40,
                                color: "#CC5803"
                            }}
                        />
                    ) : (
                        <img
                            className={classes.image}
                            src={image}
                            alt="Item Logo"
                        />
                    ))}
                {!image && (
                    <>
                        {logoLoading ? (
                            <CircularProgress
                                style={{
                                    width: 40,
                                    height: 40,
                                    color: "#CC5803"
                                }}
                            />
                        ) : (
                            <>
                                <Icon name="noImage" />
                                <p className={classes.noImageText}>
                                    Upload or Drag Image. JPG, PNG or SVG - 5 MB
                                    max
                                </p>
                            </>
                        )}
                    </>
                )}
            </Root>
            {imageError.length > 0 ? (
                <ImageError className={classes.imageError}>
                    {imageError}
                </ImageError>
            ) : (
                <PricesInfo className={classes.pricesInfo}>
                    <Icon name="info" /> To get a best display, upload images
                    with a 1:1 square size ratio
                </PricesInfo>
            )}
        </>
    );
};

export default ImageUploader;
