import {
    CircularProgress,
    FormControlLabel,
    Modal,
    Slide,
    Switch,
    TextField
} from "@mui/material";
import { useContext, useEffect, useMemo, useState } from "react";
import { Root, classes } from "../../assets/styles/components/SectionModal.js";
import Button from "../../components/UI/Button.js";
import Icon from "../../components/UI/Icon.js";
import SearchBar from "../../components/UI/SearchBar.js";
import { getItems } from "../../utils/Items/item-helper.js";
import AuthGlobal from "../../context/store/global";
import { useAlert } from "../../hooks/useAlert.js";
import CustomCheckBox from "../../components/UI/CustomCheckBox.js";
import NoResults from "../../components/NoResults.js";
import Alert from "../../components/UI/Alert.js";
import { useIntercom } from "react-use-intercom";
import { formatItemPrice } from "../../utils/Items/pricing";
import { createSection } from "../../utils/Sections/section-helper.js";

const FormCreateSection = ({
    open,
    handleClose,
    editedIndex,
    section = {},
    refreshList,
    onUpdatedSection,
    onNewSection
}) => {
    const {
        globalState: { current_restaurant }
    } = useContext(AuthGlobal);
    const identifier = current_restaurant.identifier;

    const [search, setSearch] = useState("");
    const [items, setItems] = useState([]);
    const [active, setActive] = useState(true);
    const [header_text, setHeaderText] = useState("");
    const [section_always_open, setSectionAlwaysOpen] = useState(false);
    const [name, setName] = useState("");
    const [selectedItems, setSelectedItems] = useState([]);
    const [loading, setLoading] = useState(true);
    const [errorText, setErrorText] = useState("");
    const [alert, handleAlert] = useAlert();
    const [saveAddLoad, setSaveAddLoad] = useState(false);
    const [disableBtn, setDisableBtn] = useState(false);

    const { update } = useIntercom();
    useEffect(() => {
        update({
            alignment: open ? "left" : "right"
        });
    }, [open, update]);

    useEffect(() => {
        setActive(!section.status || section.status === "active");
        setHeaderText(section.header_text || "");
        setName(section.internal_name || "");
        setSelectedItems(section.item_links || []);
        setSectionAlwaysOpen(section.section_always_open || false);
    }, [section]);

    const toggleSelect = (index) => {
        const newItems = [...items];
        newItems[index] = {
            ...newItems[index],
            checked: !newItems[index].checked
        };
        if (newItems[index].checked) {
            setSelectedItems((prev) => [
                ...prev,
                {
                    item: newItems[index].item,
                    sort_order: (prev.at(-1)?.sort_order || 0) + 1
                }
            ]);
        } else {
            setSelectedItems((prev) =>
                prev.filter(
                    (item) => item.item._id !== newItems[index].item._id
                )
            );
        }
        setItems(newItems);
    };

    useEffect(() => {
        setErrorText("");
    }, [name]);

    const handleSave = async () => {
        setSaveAddLoad(true);
        setDisableBtn(true);
        if (name === "" || name.trim() === "") {
            setErrorText("* Please, enter the section name");
            setSaveAddLoad(false);
            setDisableBtn(false);
            return;
        }
        let success, response;
        const sectionData = {
            _id: section._id,
            status: active ? "active" : "inactive",
            internal_name: name,
            display_name: name,
            header_text,
            section_always_open,
            item_links: selectedItems.map((item, index) => ({
                item_id: item.item._id,
                sort_order: index + 1
            }))
        };
        if (section._id) {
            // If it's an existing section
            onUpdatedSection(sectionData, section._id);
            refreshList(
                { ...sectionData, item_links: selectedItems },
                editedIndex
            );
            handleClose();
            setSaveAddLoad(false);
            setDisableBtn(false);
        } else {
            // If it's a new section
            ({ success, response } = await createSection(
                {
                    status: active ? "active" : "inactive",
                    internal_name: name,
                    display_name: name,
                    header_text,
                    section_always_open,
                    item_links: selectedItems.map((item, index) => ({
                        item_id: item.item._id,
                        sort_order: index + 1
                    }))
                },
                identifier
            ));
            if (success && response) {
                setSaveAddLoad(false);
                setDisableBtn(false);
                onNewSection(response.section._id);
                delete response.section.__v;
                refreshList(
                    { ...response.section, item_links: selectedItems },
                    editedIndex
                );
                handleClose();
            } else {
                setSaveAddLoad(false);
                setDisableBtn(false);
                handleAlert({
                    severity: "error",
                    title: "Error",
                    message: "Couldn't save section, please try again"
                });
            }
        }
    };

    useEffect(() => {
        setLoading(true);
        open &&
            (async () => {
                const { success, response } = await getItems(
                    "active",
                    identifier,
                    "A-Z"
                );
                if (success && response) {
                    setItems(
                        response.map((item, index) => ({
                            item,
                            idx: index,
                            label: `${item.internal_name}${
                                item.price_variations.length > 0
                                    ? ` (${item.price_variations
                                          .filter(
                                              (p) =>
                                                  p.price_value ||
                                                  p.leading_text ||
                                                  p.trailing_text
                                          )
                                          .map((p) =>
                                              formatItemPrice(
                                                  {
                                                      price_value:
                                                          p.price_value,
                                                      leading_text:
                                                          p.leading_text,
                                                      trailing_text:
                                                          p.trailing_text
                                                  },
                                                  current_restaurant.currency_placement,
                                                  current_restaurant.currency_symbol
                                              )
                                          )
                                          .join(", ")})`
                                    : ""
                            }`,
                            checked:
                                section.item_links?.findIndex(
                                    ({ item: { _id } }) => _id === item._id
                                ) >= 0
                        }))
                    );
                    setLoading(false);
                } else {
                    handleAlert({
                        severity: "error",
                        title: "Error",
                        message:
                            "The items list couldn't be loaded, please try reloading the page"
                    });
                }
            })();
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [open]);

    const itemsList = useMemo(
        () => {
            const render = items
                .filter(({ label }) => label.match(new RegExp(search, "i")))
                .map(({ label, checked, idx }, index) => (
                    <div
                        key={index}
                        className={classes.row}
                        onClick={() => toggleSelect(idx)}
                    >
                        <CustomCheckBox label={label} checked={checked} />
                    </div>
                ));
            return (
                <>
                    {render}
                    {render.length === 0 && <NoResults text="No items found" />}
                </>
            );
        },
        //eslint-disable-next-line react-hooks/exhaustive-deps
        [items, search]
    );

    return (
        <>
            <Alert {...alert} />
            <Modal open={open} onClose={handleClose} disablePortal>
                <Slide in={open} direction="left">
                    <Root className={classes.container}>
                        <div className={classes.top}>
                            <span className={classes.sectionClose}>
                                <span
                                    className={classes.closeButtonContainer}
                                    onClick={handleClose}
                                >
                                    <Icon
                                        name="clear"
                                        className={classes.closeButton}
                                    />
                                </span>
                            </span>
                            <h1 className={classes.title}>
                                {section.internal_name
                                    ? `Edit ${section.internal_name}`
                                    : "Create New Section"}
                            </h1>
                        </div>
                        <div className={classes.form}>
                            <p className={classes.toggleLabel}>
                                Section Status
                            </p>
                            <FormControlLabel
                                control={
                                    <Switch
                                        checked={active}
                                        classes={{
                                            switchBase: classes.switchDisabled
                                        }}
                                        className={active ? classes.switch : ""}
                                    />
                                }
                                label={active ? "Active" : "Inactive"}
                                onChange={() => setActive((val) => !val)}
                                className={classes.switchLabel}
                            />

                            <TextField
                                fullWidth
                                required
                                label="Section Name"
                                helperText={errorText}
                                error={errorText.length > 0}
                                placeholder="Enter Section Name"
                                className={`section_name ${classes.textField}`}
                                InputLabelProps={{ shrink: true }}
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                            />
                            <TextField
                                multiline
                                fullWidth
                                label="Header Text"
                                placeholder="This text that will display above your menu."
                                className={`${classes.textField} ${classes.multiline}`}
                                InputLabelProps={{ shrink: true }}
                                value={header_text}
                                onChange={(e) => setHeaderText(e.target.value)}
                            />
                            <p className={classes.toggleLabel}>
                                Section Always Open
                            </p>
                            <FormControlLabel
                                control={
                                    <Switch
                                        checked={section_always_open}
                                        classes={{
                                            switchBase: classes.switchDisabled
                                        }}
                                        className={
                                            section_always_open
                                                ? classes.switch
                                                : ""
                                        }
                                    />
                                }
                                label={true ? "Active" : "Inactive"}
                                onChange={() =>
                                    setSectionAlwaysOpen((val) => !val)
                                }
                                className={classes.switchLabel}
                            />
                            <div className={classes.itemSelector}>
                                <p className={classes.selectorTitle}>
                                    Item Selector
                                </p>
                                <span className={classes.selectorInfo}>
                                    <Icon name="info" />
                                    Preload items into your section here or
                                    manually add them after creating your
                                    section. Only Active Items will be displayed
                                    here
                                </span>
                                <div className={classes.inlineSearchBar}>
                                    <SearchBar
                                        placeholder={"Search Items"}
                                        clearSearchText={() => setSearch("")}
                                        searchText={search}
                                        setSearchText={setSearch}
                                    />
                                </div>
                                <div className={classes.itemsList}>
                                    {loading && (
                                        <div
                                            style={{
                                                display: "flex",
                                                justifyContent: "center",
                                                height: "100%"
                                            }}
                                        >
                                            <CircularProgress
                                                style={{
                                                    color: "#CC5803",
                                                    alignSelf: "center",
                                                    width: 55,
                                                    height: 55
                                                }}
                                            />
                                        </div>
                                    )}
                                    {!loading && itemsList}
                                </div>
                            </div>
                        </div>
                        <div className={classes.actions}>
                            <Button
                                type="primary"
                                color="orange"
                                innerText="Save"
                                className="save_section_button"
                                onClick={handleSave}
                                itemLoading={saveAddLoad}
                                disableBtn={disableBtn}
                            />
                            <Button
                                type="secondary"
                                color="dark"
                                innerText="Cancel"
                                onClick={handleClose}
                                itemLoading={saveAddLoad}
                                disableBtn={disableBtn}
                            />
                        </div>
                    </Root>
                </Slide>
            </Modal>
        </>
    );
};

export default FormCreateSection;
