import { TextField } from "@mui/material";
import Cookie from "js-cookie";
import { useContext, useEffect, useState } from "react";
import { Root, classes } from "../../../assets/styles/components/AccountTab";
import Alert from "../../../components/UI/Alert";
import Button from "../../../components/UI/Button";
import { setCurrentUser } from "../../../context/actions";
import AuthContext from "../../../context/store/global";
import { useAlert } from "../../../hooks/useAlert";
import { client } from "../../../utils/apiClient";
import { isValidEmail } from "../../../utils/validation";

const AccountTab = () => {
    const {
        dispatch,
        globalState: { user }
    } = useContext(AuthContext);
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [email, setEmail] = useState("");
    const [alert, handleAlert] = useAlert();

    useEffect(() => {
        setFirstName(user.first_name || "");
        setLastName(user.last_name || "");
        setEmail(user.email || "");
    }, [user]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!firstName || !isValidEmail(email)) {
            handleAlert({
                severity: "error",
                title: "Error",
                message: "Please, fix the errors below to update your profile"
            });
            return;
        }

        const token = Cookie.get("sm_c_t");

        const { success, response } = await client(
            `user/update/${user._id}`,
            {
                first_name: firstName,
                last_name: lastName,
                email
            },
            "PUT",
            {
                Authorization: `${token}`
            }
        );
        if (success && response) {
            handleAlert({
                severity: "success",
                title: "Success",
                message: "Your account settings were updated successfully"
            });
            setTimeout(
                () =>
                    dispatch(
                        setCurrentUser({
                            ...user,
                            first_name: firstName,
                            last_name: lastName,
                            email
                        })
                    ),
                3000
            );
        } else {
            handleAlert({
                severity: "error",
                title: "Error",
                message: response.message
            });
        }
    };

    return (
        <>
            <Alert {...alert} />
            <form onSubmit={handleSubmit}>
                <Root className={classes.columnsContainer}>
                    <div className={classes.column}>
                        <TextField
                            fullWidth
                            tabIndex={0}
                            required
                            error={!firstName}
                            helperText={
                                !firstName && "* Please, complete this field"
                            }
                            placeholder="Enter first name"
                            className={classes.textField}
                            label="First name"
                            value={firstName}
                            InputLabelProps={{ shrink: true }}
                            onChange={(event) =>
                                setFirstName(event.target.value)
                            }
                        />
                    </div>
                    <div className={classes.column}>
                        <TextField
                            fullWidth
                            tabIndex={1}
                            placeholder="Enter last name"
                            className={classes.textField}
                            label="Last name"
                            value={lastName}
                            InputLabelProps={{ shrink: true }}
                            onChange={(event) =>
                                setLastName(event.target.value)
                            }
                        />
                    </div>
                </Root>
                <Root className={classes.columnsContainer}>
                    <div className={classes.column}>
                        <TextField
                            fullWidth
                            required
                            tabIndex={2}
                            error={!isValidEmail(email)}
                            helperText={
                                !isValidEmail(email) &&
                                "Please, enter a valid email address"
                            }
                            placeholder="Enter user email"
                            className={classes.textField}
                            label="User Email"
                            value={email}
                            InputLabelProps={{ shrink: true }}
                            onChange={(event) => setEmail(event.target.value)}
                        />
                    </div>
                    <div className={classes.column}></div>
                </Root>
                <Button
                    style={{ marginTop: 28 }}
                    htmlType="submit"
                    innerText="Save Changes"
                    color="orange"
                    type="secondary"
                />
            </form>
        </>
    );
};

export default AccountTab;
