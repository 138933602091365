import { styled } from "@mui/material/styles";

const classes = {
    navButton: `navButton`,
    hidden: `hidden`,
    label: `label`,
    active: `active`,
    icon: `icon`
};

const Root = styled("li")(({ theme }) => ({
    [`&.${classes.navButton}`]: {
        width: 232,
        height: 42,
        borderRadius: "0px 20px 20px 0px",
        padding: "11px 30px",
        display: "flex",
        cursor: "pointer",
        transition: "all 300ms linear, opacity 0ms",
        marginBottom: 8,
        "&:hover": {
            "& > div > span": {
                color: theme.colors.orange[700],
                fontWeight: 700
            },
            "& > div > svg > path": {
                fill: theme.colors.orange[700]
            }
        }
    },
    [`&.${classes.hidden}`]: {
        opacity: 0,
        pointerEvents: "none",
        margin: 0,
        padding: "0 30px !important",
        height: 0
    },
    [`& .${classes.label}`]: {
        fontWeight: 400,
        fontSize: 16,
        position: "relative",
        top: "0",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        lineHeight: "18.75px",
        left: "25px",
        color: "black"
    },
    [`&.${classes.active}`]: {
        background: "rgba(204, 88, 3, 0.2)",
        borderLeft: "5px solid #E56C33",
        "& > div > span": {
            color: theme.colors.orange[700],
            fontWeight: 700
        },
        padding: "11px 25px",
        "& > div > svg > path": {
            fill: theme.colors.orange[700]
        }
    },
    [`& .${classes.icon}`]: {
        "& > path": {
            fill: "black"
        },
        float: "left"
    }
}));

export { Root, classes };
