import { styled } from "@mui/material/styles";

const classes = {
    header: `header`,
    banner: `banner`,
    publishMessage: `publishMessage`,
    inlinePublish: `inlinePublish`,
    dismissBanner: `dismissBanner`,
    restaurantName: `restaurantName`,
    infoMessage: `infoMessage`
};

const Root = styled("div")(({ theme }) => ({
    [`&.${classes.header}`]: {
        display: "flex",
        position: "relative",
        "& > div": {
            display: "flex",
            flexDirection: "row",
            justifyContent: "right",
            alignItems: "center",
            flexGrow: 1,
            float: "right",
            height: 45,
            "& > .input-container": {
                flexGrow: 1,
                height: 36,
                paddingLeft: 40
            },
            "& > span": {
                paddingLeft: 15,
                height: 36
            }
        },
        margin: "62px 45px 40px 45px",
        height: 45,
        "& img": {
            float: "left",
            width: 240,
            height: 45,
            objectFit: "scale-down"
        }
    },
    [`& .${classes.banner}`]: {
        background: theme.colors.orange[100],
        position: "absolute",
        justifyContent: "center !important",
        top: -62,
        left: -45,
        display: "flex",
        width: "calc(100% + 90px)",
        height: "40px !important",
        fontSize: "13px",
        borderBottom: "1px solid" + theme.colors.orange[200]
    },
    [`& .${classes.publishMessage}`]: {
        height: "40px !important",
        display: "flex",
        justifyContent: "center",
        textAlign: "center",
        width: "100%",
        padding: "0 !important",
        alignItems: "center",
        "& svg": {
            margin: "0 8px",
            "& path": {
                fill: theme.colors.orange[700]
            }
        }
    },
    [`& .${classes.inlinePublish}`]: {
        margin: "0 8px",
        fontWeight: 500,
        color: theme.colors.orange[700],
        fontSize: "13px",
        textDecoration: "underline",
        cursor: "pointer"
    },
    [`& .${classes.dismissBanner}`]: {
        width: 18,
        marginRight: 12,
        height: "18px !important",
        padding: "0 !important",
        cursor: "pointer",
        textAlign: "center"
    },
    [`& .${classes.restaurantName}`]: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        lineHeight: "28px",
        fontSize: "24px",
        fontWeight: 500
    }
}));

const InfoMessage = styled("span")(({ theme }) => ({
    [`&.${classes.infoMessage}`]: {
        width: 486,
        fontSize: 11,
        display: "flex",
        flexDirection: "row",
        gap: 8,
        color: "rgba(34,34,34,0.75)",
        "& svg": {
            "& path": {
                fill: "rgba(34,34,34,0.75)"
            }
        }
    }
}));

export { Root, classes, InfoMessage };
