/* 
Dropdown Values
$ - (United States Dollar) 
If selected the  variable string value used in the database is - “$”
M$ - (Mexican Peso) 
If selected the  variable string value used in the database is - “M”
M - (Mexican Peso) 
If selected the  variable string value used in the database is - “MX”
MXN - (Mexican Peso) 
If selected the  variable string value used in the database is - “MXN”
€  -  (Euro)
If selected the  variable string value used in the database is - “€”
£  -  (British Pound)
If selected the  variable string value used in the database is - “£”
*/
export const CURRENCY_SYMBOLS = [
    { currency: "$", label: "$ - (United States Dollar)" },
    { currency: "M$", label: "M$ - (Mexican Peso)" },
    { currency: "M", label: "M - (Mexican Peso)" },
    { currency: "MXN", label: "MXN - (Mexican Peso)" },
    { currency: "€", label: "€ - (Euro)" },
    { currency: "£", label: "£ - (British Pound)" }
];

export const CURRENCY_PLACEMENT = [
    { placement: "before", label: "Before Price" },
    { placement: "after", label: "After Price" }
];
