import { styled } from "@mui/material/styles";

const classes = {
    container: `container`,
    restaurantName: `restaurantName`,
    modal: `modal`,
    title: `title`,
    closeButton: `closeButton`,
    list: `list`,
    inactive: `inactive`,
    row: `row`,
    changeButton: `changeButton`
};

const Root = styled("div")(({ theme }) => ({
    [`&.${classes.container}`]: {
        width: 248,
        height: 54,
        marginTop: 32,
        marginLeft: 40,
        borderRadius: "20px",
        background: "#FDECDF",
        boxShadow: "0px 10px 30px rgba(31,19,0,0.3)",
        display: "flex",
        alignItems: "center",
        cursor: "pointer",
        "& svg:nth-of-type(1)": {
            marginLeft: 24,
            marginRight: 16
        },
        "& svg:nth-of-type(2)": {
            marginRight: 21,
            marginLeft: 8
        },
        "&:hover": {
            color: theme.colors.orange[700],
            "& svg:nth-of-type(1)": {
                "& path": {
                    fill: theme.colors.orange[700]
                }
            }
        }
    },
    [`& .${classes.restaurantName}`]: {
        margin: 0,
        fontSize: "16px",
        width: 152,
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap"
    },
    [`&.${classes.modal}`]: {
        margin: "auto",
        position: "relative",
        background: "white",
        minHeight: 200,
        width: 500,
        borderRadius: "20px",
        boxShadow: "0px 10px 30px rgba(31, 19, 0, 0.3)"
    },
    [`& .${classes.title}`]: {
        fontSize: "20px",
        lineHeight: "28px",
        padding: "0 12.5px 8px",
        margin: "20px 20px 16px",
        borderBottom: "1px solid rgba(34, 34, 34, 0.15)"
    },
    [`& .${classes.closeButton}`]: {
        height: 10,
        width: 10,
        top: 20,
        right: 20,
        position: "absolute",
        cursor: "pointer"
    },
    [`& .${classes.list}`]: {
        listStyle: "none",
        "&>:nth-of-type(even)": {
            background: theme.colors.grey[50]
        },
        maxHeight: 600,
        overflow: "auto"
    },
    [`& .${classes.inactive}`]: {
        cursor: "default",
        pointerEvents: "none",
        opacity: 0.4
    },
    [`& .${classes.row}`]: {
        height: 60,
        display: "flex",
        alignItems: "center",
        margin: "0 20px",
        padding: "24px 16px",
        borderRadius: "6px",
        justifyContent: "space-between"
    },
    [`& .${classes.changeButton}`]: {
        display: "flex",
        gap: 10,
        fontStyle: "italic",
        fontSize: "14px",
        alignItems: "center",
        cursor: "pointer",
        "& svg": {
            height: "100%",
            borderRadius: "50%",
            padding: 6,
            width: 23.75,
            border: `1px solid ${theme.colors.orange[700]}`,
            "& path": {
                fill: theme.colors.orange[700]
            }
        },
        "&:hover svg": {
            background: theme.colors.orange[700],
            "& path": {
                fill: "white"
            }
        }
    }
}));

export { Root, classes };
