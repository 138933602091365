import { styled } from "@mui/material/styles";

const classes = {
    alertContainer: `alertContainer`,
    success: `success`,
    error: `error`,
    info: `info`,
    content: `content`,
    title: `title`,
    message: `message`,
    closeButtonContainer: `closeButtonContainer`,
    closeButton: `closeButton`
};

const Root = styled("div")(({ theme }) => ({
    [`&.${classes.alertContainer}`]: {
        display: "flex",
        flexDirection: "row",
        alignItems: "flex-start",
        width: 400,
        minHeight: 69,
        padding: "16px 32px 16px 16px",
        borderRadius: 20,
        boxShadow: "0px 10px 30px rgba(31, 19, 0, 0.3)",
        background: "white"
    },
    [`& .${classes.success}`]: {
        "& path": {
            fill: "#70C040"
        }
    },
    [`& .${classes.error}`]: {
        width: 20,
        height: 18,
        "& path": {
            fill: "#D8000C"
        }
    },
    [`& .${classes.info}`]: {
        width: 20,
        "& path": {
            fill: "#3C8CB5"
        }
    },
    [`& .${classes.content}`]: {
        marginLeft: 8,
        display: "flex",
        flexGrow: 1,
        flexDirection: "column"
    },
    [`& .${classes.title}`]: {
        fontWeight: 500,
        fontSize: 15,
        lineHeight: "21px",
        color: "black",
        margin: 0
    },
    [`& .${classes.message}`]: {
        fontWeight: 300,
        marginBottom: 0,
        "& b": {
            fontWeight: 500
        },
        fontSize: 13,
        fontStyle: "italic"
    },
    [`& .${classes.closeButtonContainer}`]: {
        marginTop: -2,
        // width: '100%',
        marginRight: 10,
        // height: 12,
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
    },
    [`& .${classes.closeButton}`]: {
        cursor: "pointer",
        width: 10,
        height: 10,
        "& path": {
            fill: theme.colors.grey[900]
        },
        "&:hover path": {
            fill: theme.colors.orange[700]
        },
        display: "flex",
        transform: "translate(25px,-3px)",
        flexDirection: "row-reverse",
        alignItems: "center"
    }
}));

export { Root, classes };
