import { styled } from "@mui/material/styles";

const classes = {
    addTagContainer: `addTagContainer`,
    addTagIconContainer: `addTagIconContainer`,
    addTagIcon: `addTagIcon`,
    addTagText: `addTagText`
};

const Root = styled("div")(({ theme }) => ({
    [`&.${classes.addTagContainer}`]: {
        width: "100px",
        height: "30px",
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
        cursor: "pointer",
        "&:hover": {
            "& p": {
                fontWeight: 500
            },
            "& span": {
                boxShadow: "0px 10px 25px rgba(204, 88, 3, 0.35)"
            }
        }
    },
    [`& .${classes.addTagIconContainer}`]: {
        backgroundColor: "#CC5803",
        width: "20px !important",
        height: "20px !important",
        borderRadius: "50%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        transition: "0.2s ease-in-out"
    },
    [`& .${classes.addTagIcon}`]: {
        width: "20px !important",
        height: "20px !important",
        "& path": {
            fill: "#fff !important",
            color: "#fff !important"
        }
    },
    [`& .${classes.addTagText}`]: {
        color: "#000000",
        fontFamily: "Roboto",
        fontSize: "14px",
        fontStyle: "italic",
        fontWeight: "400",
        margin: "0 0 0 5px",
        transition: "0.2s ease-in-out",
        whiteSpace: "nowrap"
    }
}));

export { Root, classes };
