import Cookie from "js-cookie";
import { client } from "../../utils/apiClient";
import _ from "lodash";
export const SET_CURRENT_USER = "SET_CURRENT_USER";
export const SET_CURRENT_USER_TYPE = "SET_CURRENT_USER_TYPE";
export const SET_CURRENT_RESTAURANT = "SET_CURRENT_RESTAURANT";
export const SET_ALERT = "SET_ALERT";
export const SET_LOCATION = "SET_LOCATION";

export const setCurrentUser = (user) => {
    return {
        type: SET_CURRENT_USER,
        payload: user
    };
};

export const setCurrentUserType = (user) => {
    return {
        type: SET_CURRENT_USER_TYPE,
        payload: user
    };
};

export const setCurrentRestaurant = (restaurant) => {
    return {
        type: SET_CURRENT_RESTAURANT,
        payload: _.merge(
            {
                display_name: "",
                logo_image: "",
                timezone: "",
                ohwaiter_phone_number: "",
                default_collection: null,
                all_day_menu: false,
                use_schedules: false
            },
            restaurant
        )
    };
};

export const setLocation = (location) => {
    return {
        type: SET_LOCATION,
        payload: location
    };
};

export const logoutUser = async (dispatch) => {
    //logout
    const { success } = await client("user/logout", null, "POST", {
        Authorization: `${Cookie.get("sm_c_t")}`
    });
    if (success) {
        Cookie.remove("sm_c_t", {
            path: "",
            domain: process.env.REACT_APP_COOKIE_DOMAIN
        });
        Cookie.remove("sm_r_t", {
            path: "",
            domain: process.env.REACT_APP_COOKIE_DOMAIN
        });
        Cookie.remove(
            `intercom-session-${process.env.REACT_APP_INTERCOM_APP_ID}`,
            { path: "", domain: process.env.REACT_APP_COOKIE_DOMAIN }
        );

        dispatch(setCurrentUser({}));
        dispatch(setCurrentRestaurant({}));
    }
};
