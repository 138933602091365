import { Root, classes } from "../../assets/styles/components/InfoMessage.js";
const InfoMessage = () => {
    return (
        <Root className={classes.infoMessage}>
            <p>This will apply all unpublished changes to your live menus.</p>
        </Root>
    );
};

export default InfoMessage;
