import SearchIcon from "@mui/icons-material/Search";
import ClearIcon from "@mui/icons-material/Clear";
import { Root, classes } from "../../assets/styles/SearchBar";

const SearchBar = ({
    searchText,
    setSearchText,
    clearSearchText,
    placeholder = "Enter filter text"
}) => {
    return (
        <Root className={classes.searchBar}>
            <SearchIcon
                name="search"
                className={`${classes.icon} ${classes.searchIcon}`}
            />
            <input
                className={`${searchText.length > 0 ? classes.showClear : ""}`}
                placeholder={placeholder}
                value={searchText}
                onChange={(e) => setSearchText(e.target.value)}
            />
            {searchText.length > 0 && (
                <ClearIcon
                    name="clear"
                    onClick={clearSearchText}
                    className={`${classes.icon} ${classes.clearButton}`}
                />
            )}
        </Root>
    );
};

export default SearchBar;
