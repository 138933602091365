import { Root, classes } from "../../assets/styles/components/InfoMessage.js";
const ExitInfoMessage = () => {
    return (
        <Root className={classes.infoMessage}>
            <p>
                If you leave this page before saving, your changes will be lost.
            </p>
        </Root>
    );
};

export default ExitInfoMessage;
