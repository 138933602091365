import { Autocomplete, Modal, Slide, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import { Root, classes } from "./Styles";
import Button from "../UI/Button";
import Icon from "../UI/Icon";
import { useAlert } from "../../hooks/useAlert";
import Alert from "../UI/Alert";
import { useIntercom } from "react-use-intercom";
import { createTag, updateTag } from "../../utils/Tag/tag-helper";

const TAG_TYPES = [
    {
        value: "cost_driver",
        label: "Cost Driver"
    },
    {
        value: "menu_category",
        label: "Menu Category"
    }
];

const defaultTagType = TAG_TYPES[0];

const FormCreateTag = ({ show, onClose, tag }) => {
    const { update } = useIntercom();
    useEffect(() => {
        update({
            alignment: show ? "left" : "right"
        });
    }, [show, update]);

    const mode = tag ? "edit" : "create";

    const [tagId, setTagId] = useState("");
    const [name, setName] = useState("");
    const [tagType, setTagType] = useState(defaultTagType);
    const handleClose = (preventReload = true, alertData = { open: false }) => {
        setName("");
        setTagType("");
        onClose();
    };
    const [errorText, setErrorText] = useState("");
    const [alert, handleAlert] = useAlert();
    // eslint-disable-next-line no-unused-vars
    const [_priceErrors, setPriceErrors] = useState([]);

    useEffect(() => {
        setTagId(tag?._id);
        setName(tag?.tag_name || "");
        setTagType(
            TAG_TYPES.find((type) => type.value === tag?.tag_type) ||
                defaultTagType
        );
    }, [tag]);

    const handleSubmit = async ({ close = true }) => {
        if (!name.length) {
            setErrorText("Tag name is required");
            return;
        }
        if (mode === "create") {
            const { success, response } = await createTag({
                tag_name: name,
                tag_type: tagType.value
            });
            if (success && response) {
                handleAlert({
                    open: true,
                    message: "Tag created successfully",
                    severity: "success"
                });
                if (close) {
                    handleClose();
                }
            } else {
                console.log(response);
                handleAlert({
                    open: true,
                    message: response?.error || "Error creating tag",
                    severity: "error"
                });
            }
        } else {
            const { success, response } = await updateTag({
                id: tagId,
                tag_name: name,
                tag_type: tagType.value
            });
            if (success && response) {
                handleAlert({
                    open: true,
                    message: "Tag updated successfully",
                    severity: "success"
                });
                if (close) {
                    handleClose();
                }
            } else {
                handleAlert({
                    open: true,
                    message: response?.error || "Error updating tag",
                    severity: "error"
                });
            }
        }
    };

    return (
        <>
            <Alert {...alert} />
            <Modal open={show} onClose={() => handleClose()} disablePortal>
                <Slide in={show} direction="left">
                    <Root className={classes.container}>
                        <div className={classes.top}>
                            <span className={classes.sectionClose}>
                                <span
                                    className={classes.closeButtonContainer}
                                    onClick={() => handleClose()}
                                >
                                    <Icon
                                        name="clear"
                                        className={classes.closeButton}
                                    />
                                </span>
                            </span>
                            <h1 className={classes.title}>
                                {mode === "create"
                                    ? "Create New Tag"
                                    : `Edit ${tag.tag_name}`}
                            </h1>
                        </div>
                        <div
                            className={classes.form}
                            style={{
                                padding: "0 32px"
                            }}
                        >
                            <TextField
                                required
                                fullWidth
                                error={errorText.length > 0}
                                helperText={errorText}
                                placeholder="Enter Tag Name"
                                className={"item_name " + classes.textField}
                                label="Tag Name"
                                value={name}
                                InputLabelProps={{ shrink: true }}
                                onChange={(event) =>
                                    setName(event.target.value)
                                }
                            />
                            <Autocomplete
                                fullWidth
                                value={tagType}
                                onChange={(_e, v) => setTagType(v)}
                                options={TAG_TYPES}
                                disableClearable={true}
                                disablePortal
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Tag Type"
                                        placeholder="Select Tag Type"
                                        InputLabelProps={{
                                            shrink: true
                                        }}
                                        className={`${classes.select} ${classes.textField}`}
                                    />
                                )}
                                componentsProps={{
                                    paper: {
                                        className: classes.selectRow
                                    }
                                }}
                                isOptionEqualToValue={(option, value) =>
                                    option.label === value.label
                                }
                            />
                        </div>
                        {/* push buttons at the end of the screen by leaving a dynamic empty space */}
                        <div className={classes.actions}>
                            <Button
                                type="primary"
                                color="orange"
                                innerText="Save"
                                onClick={() => handleSubmit({ close: true })}
                                className="save_item_button"
                            />
                            {mode !== "create" && (
                                <Button
                                    type="secondary"
                                    color="dark"
                                    innerText="Cancel"
                                    onClick={() => handleClose()}
                                />
                            )}
                        </div>
                    </Root>
                </Slide>
            </Modal>
        </>
    );
};

export default FormCreateTag;
