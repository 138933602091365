import Button from "../../components/UI/Button";
import { useEffect, useState, useContext } from "react";
import AuthGlobal from "../../context/store/global";

import { fetchRequest } from "../../utils";
import { Buffer } from "buffer";
import languageEncoding from "detect-file-encoding-and-language";
import { useAlert } from "../../hooks/useAlert";
import { Root, classes } from "../../assets/styles/settings/ImportExport.js";
import ConfirmAction from "../../components/modals/ConfirmAction";
import { Typography, Box } from "@mui/material";
import Alert from "../../components/UI/Alert";
import ExportEvents from "../../components/ExportEvents";

const ImportExport = () => {
    const [disableExport, setDisableExport] = useState(false);
    const {
        globalState: { current_restaurant }
    } = useContext(AuthGlobal);
    const [fileToUpload, setFileToUpload] = useState(null);
    const [confirmUploadModal, setConfirmUploadModal] = useState(false);
    const [loading, setLoading] = useState(false);
    const [alertImport, setAlertImport] = useState(false);
    const [itemImport, setItemImport] = useState(false);

    const [alert, handleAlert] = useAlert();

    const exportRestaurant = async () => {
        setDisableExport(true);
        const { success, response } = await fetchRequest(
            `/restaurant/${current_restaurant.identifier}/export`,
            { method: "GET" },
            "text/plain"
        );
        if (success && response) {
            const content = new Blob([
                decodeURIComponent(await new Response(response).text())
            ]);
            const file = URL.createObjectURL(content);
            const link = document.createElement("a");
            link.href = file;
            link.setAttribute(
                "download",
                `export-${current_restaurant.identifier}.tsv`
            );

            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);
        } else {
            handleAlert({
                severity: "error",
                title: "Error",
                message: "Something went wrong..."
            });
        }
        setDisableExport(false);
    };
    const importTSV = async (file) => {
        setLoading(true);
        const reader = new FileReader();
        const { encoding } = await languageEncoding(file);

        reader.addEventListener("load", async (event) => {
            let content = Buffer.from(
                event.target.result.split(",")[1],
                "base64"
            );
            const { success, response } = await fetchRequest(
                `/restaurant/${current_restaurant.identifier}/import`,
                { method: "POST", body: JSON.stringify({ content, encoding }) }
            );
            setConfirmUploadModal(false);
            setFileToUpload(null);
            if (success && response) {
                handleAlert({
                    severity: "success",
                    title: "Success",
                    message: response.message
                });
            } else {
                handleAlert({
                    severity: "error",
                    title: "Error",
                    message: response.message
                });
            }
            setTimeout(() => {
                setLoading(false);
            }, 500);
        });
        reader.readAsDataURL(file);
    };

    const importCSV = async (file) => {
        setLoading(true);
        const reader = new FileReader();
        const { encoding } = await languageEncoding(file);

        reader.addEventListener("load", async (event) => {
            let content = Buffer.from(
                event.target.result.split(",")[1],
                "base64"
            );
            const { success, response } = await fetchRequest(
                `/restaurant/${current_restaurant.identifier}/importAlert`,
                { method: "POST", body: JSON.stringify({ content, encoding }) }
            );
            setConfirmUploadModal(false);
            setFileToUpload(null);
            if (success && response) {
                handleAlert({
                    severity: "success",
                    title: "Success",
                    message: response.message
                });
            } else {
                handleAlert({
                    severity: "error",
                    title: "Error",
                    message: response.message
                });
            }
            setTimeout(() => {
                setLoading(false);
            }, 500);
        });
        reader.readAsDataURL(file);
    };
    const importItemTSV = async (file) => {
        setLoading(true);
        const reader = new FileReader();
        const { encoding } = await languageEncoding(file);

        reader.addEventListener("load", async (event) => {
            let content = Buffer.from(
                event.target.result.split(",")[1],
                "base64"
            );
            const { success, response } = await fetchRequest(
                `/restaurant/${current_restaurant.identifier}/importItems`,
                { method: "POST", body: JSON.stringify({ content, encoding }) }
            );
            setConfirmUploadModal(false);
            setFileToUpload(null);
            if (success && response) {
                handleAlert({
                    severity: "success",
                    title: "Success",
                    message: response.message
                });
            } else {
                handleAlert({
                    severity: "error",
                    title: "Error",
                    message: response.message
                });
            }
            setTimeout(() => {
                setLoading(false);
            }, 500);
        });
        reader.readAsDataURL(file);
    };
    useEffect(() => {
        if (fileToUpload) {
            setConfirmUploadModal(true);
        }
    }, [fileToUpload]);

    const handleFileUpload = (e) => {
        setFileToUpload(e.target.files[0]);
        e.target.value = null;
    };

    const handleAlertFileUpload = (e) => {
        if (e.target.files[0].type !== "text/csv") {
            alert("Only .csv files are allowed!");
            e.target.value = null; // Reset the input field
        } else {
            setFileToUpload(e.target.files[0]);
            setAlertImport(true);
            e.target.value = null; // Reset the input field
        }
    };
    const handleItemFileUpload = (e) => {
        if (e.target.files[0].type !== "text/tab-separated-values") {
            // alert("Only .tsv files are allowed!");
            handleAlert({
                severity: "error",
                title: "Error",
                message: "Only .tsv files are allowed!"
            });
            e.target.value = null; // Reset the input field
        } else {
            setFileToUpload(e.target.files[0]);
            setItemImport(true);
            e.target.value = null; // Reset the input field
        }
    };

    return (
        <>
            <Alert {...alert} />
            <Typography component="h3" style={{ fontWeight: 500 }}>
                On this section you can export all the restaurants in the
                platform, including their service periods, menus, sections and
                items.
                <br />
                You can also import a tsv file to create new records or edit the
                existing ones
                <br />
                <br />
                Choose an option below:
            </Typography>
            <div
                style={{
                    display: "flex",
                    width: "100%",
                    justifyContent: "space-around"
                }}
            >
                <Root className={classes.exportButton}>
                    <input
                        type="file"
                        title=""
                        className={classes.fileInput}
                        onChange={handleFileUpload}
                    />
                    <Button
                        innerText="Import"
                        type="primary"
                        color="orange"
                        style={{ zIndex: 2 }}
                    />
                </Root>

                <Button
                    innerText="Export"
                    onClick={exportRestaurant}
                    disabled={disableExport}
                />
            </div>
            <Box sx={{ display: "flex", alignItems: "center", mt: 4 }}>
                <Box sx={{ mr: 4 }}>
                    <Typography component="h3" style={{ fontWeight: 500 }}>
                        Alert Import
                    </Typography>
                    <Root className={classes.exportButton}>
                        <input
                            accept=".csv"
                            type="file"
                            title=""
                            className={classes.fileInput}
                            onChange={handleAlertFileUpload}
                        />
                        <Button
                            innerText="Import"
                            type="primary"
                            color="orange"
                            style={{ zIndex: 2 }}
                        />
                    </Root>
                </Box>
                <Box>
                    <Typography component="h3" style={{ fontWeight: 500 }}>
                        Item Import
                    </Typography>
                    <Root className={classes.exportButton}>
                        <input
                            accept=".tsv"
                            type="file"
                            title=""
                            className={classes.fileInput}
                            onChange={handleItemFileUpload}
                        />
                        <Button
                            innerText="Import"
                            type="primary"
                            color="orange"
                            style={{ zIndex: 2 }}
                        />
                    </Root>
                </Box>
            </Box>
            <div>
                <ExportEvents></ExportEvents>
            </div>
            <ConfirmAction
                open={confirmUploadModal}
                actionCancel={() => {
                    setConfirmUploadModal(false);
                    setFileToUpload(null);
                    setAlertImport(false);
                    setItemImport(false);
                }}
                actionConfirm={() =>
                    !loading &&
                    (alertImport
                        ? importCSV(fileToUpload)
                        : itemImport
                        ? importItemTSV(fileToUpload)
                        : importTSV(fileToUpload))
                }
                icon="publish"
                dialogText={
                    loading
                        ? "Importing file"
                        : `Are you sure you want to import the content
                from the file ${fileToUpload?.name}?`
                }
                note={loading && "This may take a few seconds..."}
            />
        </>
    );
};

export default ImportExport;
