import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import BoxLayout from "../../components/UI/BoxLayout";
import RestaurantTab from "./tabs/Restaurant";
import QRCodeTab from "./tabs/QRCode";
import SuperAdmin from "./tabs/SuperAdmin";
import AuthGlobal from "../../context/store/global";
import "../../assets/scss/Item.scss";
import ImportExport from "./ImportExport";
import UsersTab from "./tabs/Users";
import Header from "../../components/UI/Header";
import { CircularProgress, Tab, Tabs } from "@mui/material";
import { Root, classes } from "../../assets/styles/pages/Settings";
import Icon from "../../components/UI/Icon";
import FormCreateUser from "./FormCreateUser";
import { client } from "../../utils/apiClient";
import Cookie from "js-cookie";
import {
    getPaymentLink,
    refreshRestaurant
} from "../../utils/Restaurants/restaurant-helper";
import { useSearchParams } from "react-router-dom";
import PresenterSettings from "./PresenterSettings";
import { useAlert } from "../../hooks/useAlert";
import Alert from "../../components/UI/Alert";
import WebSettings from "./tabs/WebSettings";
import ConfirmAction from "../../components/modals/ConfirmAction";
import AccountTab from "./tabs/Account";
import MobileMenu from "./MobileMenu";
import Tags from "./Tags/Tags";

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <div>{children}</div>}
        </div>
    );
}

const Settings = () => {
    const token = Cookie.get("sm_c_t");
    const {
        dispatch,
        globalState: {
            current_restaurant: { identifier, _id },
            current_restaurant,
            user,
            user_type
        }
    } = useContext(AuthGlobal);

    const [searchParams, setSearchParams] = useSearchParams();
    const [value, setValue] = useState(parseInt(searchParams.get("tab") || 0));
    const [showUserForm, setShowUserForm] = useState(false);
    const [users, setUsers] = useState([]);
    const [userFormMode, setUserFormMode] = useState("");
    const [stripeLink, setStripeLink] = useState("");
    const [search, setSearch] = useState("");
    const [loading, setLoading] = useState(false);
    const [alert, handleAlert] = useAlert();
    const [showUserPopup, setShowUserPopup] = useState(false);
    const [firstLoad, setFirstLoad] = useState(true);
    const [hasChildChanged, setHasChildChanged] = useState(false);
    const [parentUpdate, setParentUpdate] = useState(false);
    const [newTabVal, setNewTabVal] = useState(0);

    useEffect(() => {
        (async () => {
            setLoading(true);
            if (value === 3) {
                fetchUsers();
            }
            setSearchParams({ tab: value });
            await refreshRestaurant(dispatch, identifier, current_restaurant);
            setTimeout(() => {
                setLoading(false);
            }, 200);
        })();
        return async () => {
            if (value === 4) {
                setSearch("");
            }
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value]);

    const fetchUsers = useCallback(
        async (alertConfig) => {
            const { success, response } = await client(
                "user/get-all-users",
                { identifier: identifier },
                "PUT",
                {
                    Authorization: `${token}`
                }
            );
            if (success) {
                setUsers(response);
                if (alertConfig) handleAlert(alertConfig);
            }
        },
        // eslint-disable-next-line
        [identifier, token, handleAlert]
    );

    // Check the role of the user in the current restaurant
    const user_role =
        user.user_permissions.find(
            (x) => x.identifier.identifier === identifier
        )?.user_role || "user";

    useEffect(() => {
        if (user_role === "account_owner") {
            getPaymentLink(identifier).then(({ success, response }) => {
                setStripeLink(response.url);
            });
        }
        setFirstLoad(false);
    }, [identifier, user_role]);

    const tabLabel = (text) => {
        return <span className={classes.labelText}>{text}</span>;
    };

    const labelProps = (index) => {
        return {
            id: `simple-tab-${index}`,
            "aria-controls": `simple-tabpanel-${index}`,
            key: index,
            value: index
        };
    };

    const handleChangeTab = (_event, newValue) => {
        // Check for unsaved changes before changing the tab
        if (value !== newValue && parentUpdate) {
            setHasChildChanged(true);
            setNewTabVal(newValue);
        } else {
            setValue(newValue);
        }
    };

    const handleChangeTabCustom = (newValue) => {
        setValue(newValue);
    };

    const listOfUsers = useMemo(
        () =>
            users.map((row) => ({
                ...row,
                role:
                    row.user_permissions.find((p) => p.identifier === _id)
                        ?.user_role || "user",
                status:
                    row.user_permissions.find((p) => p.identifier === _id)
                        ?.status || "inactive"
            })),
        [users, _id]
    );

    return (
        <>
            <BoxLayout key="content">
                <Alert {...alert} key="alert" />

                <Header
                    title={"Account Settings"}
                    showSearchBar={value === 5 || value === 10}
                    searchText={search}
                    setSearchText={setSearch}
                    clearSearchText={() => setSearch("")}
                    enableForcePublish={value === 9 ? true : false}
                />
                <Root className={classes.table}>
                    <div className={classes.tabsRow}>
                        <Tabs
                            className={classes.tabsContainer}
                            value={value}
                            onChange={handleChangeTab}
                        >
                            <Tab
                                label={tabLabel("Account Settings")}
                                {...labelProps(0)}
                            />
                            {user_type !== "view_only" && (
                                <Tab
                                    label={tabLabel("Restaurant Settings")}
                                    {...labelProps(1)}
                                />
                            )}
                            {user_type !== "view_only" && (
                                <Tab
                                    label={tabLabel("QR Code")}
                                    {...labelProps(2)}
                                />
                            )}
                            {[
                                "account_owner",
                                "admin",
                                "customer_support"
                            ].includes(user_role) && [
                                <Tab
                                    label={tabLabel("Users")}
                                    {...labelProps(3)}
                                />
                            ]}
                            {["account_owner", "customer_support"].includes(
                                user_role
                            ) && (
                                <Tab
                                    label={tabLabel("Billing")}
                                    {...labelProps(4)}
                                />
                            )}
                            {user.role === "super_admin" && (
                                <Tab
                                    label={tabLabel("Super Admin")}
                                    {...labelProps(5)}
                                />
                            )}
                            {(user.role === "super_admin" ||
                                user_role === "customer_support") && [
                                <Tab
                                    label={tabLabel("Import/Export")}
                                    {...labelProps(6)}
                                />,
                                <Tab
                                    label={tabLabel("Presenter Settings")}
                                    {...labelProps(7)}
                                />,
                                <Tab
                                    label={tabLabel("Web Template")}
                                    {...labelProps(8)}
                                />,
                                <Tab
                                    label={tabLabel("Tags")}
                                    {...labelProps(10)}
                                />
                            ]}
                            {user_type !== "view_only" && (
                                <Tab
                                    label={tabLabel("Mobile Menu")}
                                    {...labelProps(9)}
                                />
                            )}
                        </Tabs>
                        {value === 3 && (
                            <div
                                className={classes.addSectionButton}
                                onClick={() => setShowUserPopup(true)}
                            >
                                <Icon name="plus" />
                                <span style={{ width: 60 }}>Add User</span>
                            </div>
                        )}
                    </div>
                    {loading && (
                        <div
                            style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                height: 500
                            }}
                        >
                            <CircularProgress
                                style={{
                                    width: 60,
                                    height: 60,
                                    color: "#CC5803"
                                }}
                            />
                        </div>
                    )}
                    {!loading && (
                        <>
                            <TabPanel value={value} index={0}>
                                <AccountTab />
                            </TabPanel>
                            <TabPanel value={value} index={1}>
                                <RestaurantTab
                                    firstLoad={firstLoad}
                                    hasChildChanged={hasChildChanged}
                                    setHasChildChanged={setHasChildChanged}
                                    setParentUpdate={setParentUpdate}
                                    onTabChange={handleChangeTabCustom}
                                    newTabVal={newTabVal}
                                />
                            </TabPanel>
                            <TabPanel value={value} index={2}>
                                <QRCodeTab />
                            </TabPanel>
                            <TabPanel value={value} index={3}>
                                <UsersTab
                                    users={listOfUsers}
                                    reload={fetchUsers}
                                />
                            </TabPanel>
                            <TabPanel value={value} index={4}>
                                <div
                                    className={classes.billingButton}
                                    onClick={() => {
                                        window.location = stripeLink;
                                    }}
                                >
                                    <Icon name="arrowRight2" />
                                    <span>Manage Subscription</span>
                                </div>
                            </TabPanel>
                            <TabPanel value={value} index={5}>
                                <SuperAdmin
                                    user={user}
                                    dispatch={dispatch}
                                    searchTerm={search}
                                />
                            </TabPanel>
                            <TabPanel value={value} index={6}>
                                <ImportExport />
                            </TabPanel>
                            <TabPanel value={value} index={7}>
                                <PresenterSettings />
                            </TabPanel>
                            <TabPanel value={value} index={8}>
                                <WebSettings />
                            </TabPanel>
                            <TabPanel value={value} index={9}>
                                <MobileMenu
                                    hasChildChanged={hasChildChanged}
                                    setHasChildChanged={setHasChildChanged}
                                    setParentUpdate={setParentUpdate}
                                    onTabChange={handleChangeTabCustom}
                                    newTabVal={newTabVal}
                                ></MobileMenu>
                            </TabPanel>
                            <TabPanel value={value} index={10}>
                                <Tags searchTerm={search}></Tags>
                            </TabPanel>
                        </>
                    )}
                </Root>
                <FormCreateUser
                    handleClose={() => {
                        setShowUserForm(false);
                        fetchUsers();
                    }}
                    mode={userFormMode}
                    open={showUserForm}
                />
            </BoxLayout>
            <ConfirmAction
                open={showUserPopup}
                icon="user"
                actionConfirm={() => {
                    setUserFormMode("create");
                    setShowUserForm(true);
                    setShowUserPopup(false);
                }}
                actionSecondButton={() => {
                    setUserFormMode("invite");
                    setShowUserForm(true);
                    setShowUserPopup(false);
                }}
                actionCancel={() => setShowUserPopup(false)}
                dialogText="Would you like to add a New or Existing User?"
                button1Text="New User"
                button2Text="Existing User"
            />
        </>
    );
};

export default Settings;
