import { useContext, useState, useEffect } from "react";
import {
    Menu as Options,
    MenuItem,
    Tabs,
    Tab,
    CircularProgress
} from "@mui/material";
import BoxLayout from "../../components/UI/BoxLayout";
import AuthGlobal from "../../context/store/global";
import { useNavigate } from "react-router-dom";

import ActiveTab from "../../pages/menus/tabs/Active";
import DraftTab from "../../pages/menus/tabs/Draft";
import TrashTab from "../../pages/menus/tabs/Trash";

import {
    getMenus,
    getDraftMenus,
    getTrashMenus,
    searchActiveMenus,
    searchDraftMenus,
    searchTrashMenus,
    markAsDraftMenu,
    markAsActiveMenu,
    markAsTrashMenu,
    duplicateMenu,
    deleteMenu,
    getLastPrintedMenus,
    searchLastPrintedMenus,
    deletePrintedMenu
} from "../../utils/Menus/menu-helper";

import Header from "../../components/UI/Header";
import { Root, classes } from "../../assets/styles/pages/menus/Menu";
import Icon from "../../components/UI/Icon";
import { useAlert } from "../../hooks/useAlert";
import { refreshRestaurant } from "../../utils/Restaurants/restaurant-helper";
import { fetchRequest } from "../../utils";
import Alert from "../../components/UI/Alert";
import PrintedMenus from "./tabs/PrintedMenus";

const Menu = () => {
    const {
        dispatch,
        globalState: {
            current_restaurant: { identifier, all_day_menu, has_prints },
            current_restaurant,
            user_type
        }
    } = useContext(AuthGlobal);
    const [alert, handleAlert] = useAlert();

    const navigate = useNavigate();

    const [activeMenus, setActiveMenus] = useState([]);
    const [allMenusRendered, setAllMenusRendered] = useState(true);
    const [draftMenus, setDraftMenus] = useState([]);
    const [trashMenus, setTrashMenus] = useState([]);
    const [lastPrintedMenus, setLastPrintedMenus] = useState([]);
    const [searchText, setSearchText] = useState("");
    const [value, setValue] = useState(0);
    const [order, setOrder] = useState("A-Z");
    const [anchorEl, setAnchorEl] = useState(null);
    const [loading, setLoading] = useState(true);
    const [firstLoad, setFirstLoad] = useState(true);
    const dropdownOpen = Boolean(anchorEl);
    const handleSortOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleSort = (order) => {
        setAnchorEl(null);
        if (order) {
            setOrder(order);
        }
    };

    useEffect(() => {
        refreshRestaurant(dispatch, identifier, current_restaurant);
        //eslint-disable-next-line
    }, []);

    useEffect(() => {
        loadData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [order, current_restaurant]);

    const loadData = async () => {
        // Optimal display results, get first the results of the tab
        // we're on and show them while loading the rest of tabs
        setLoading(true);
        if (value === 0) {
            await getAllMenus();
            setLoading(false);
            await getAllDraftMenus();
            await getAllTrashMenus();
            await getAllPrintedMenus();
            return;
        } else if (value === 1) {
            await getAllDraftMenus();
            setLoading(false);
            await getAllMenus();
            await getAllTrashMenus();
            return;
        } else if (value === 2) {
            await getAllTrashMenus();
            setLoading(false);
            await getAllMenus();
            await getAllDraftMenus();
            return;
        } else {
            await getAllPrintedMenus();
            setLoading(false);
            await getAllMenus();
            await getAllDraftMenus();
            await getAllTrashMenus();
            return;
        }
    };

    const getAllMenus = async () => {
        setAllMenusRendered(true);
        const { success, response } = await getMenus(identifier, order);
        if (success && response) {
            setActiveMenus(response);
        }
    };

    const getAllPrintedMenus = async () => {
        const { success, response } = await getLastPrintedMenus(identifier);
        if (success && response) {
            setLastPrintedMenus(response?.menuPrintedPdfs);
        }
    };

    const getAllDraftMenus = async () => {
        const { success, response } = await getDraftMenus(identifier, order);

        if (success && response) {
            setDraftMenus(response);
        }
    };

    const getAllTrashMenus = async () => {
        const { success, response } = await getTrashMenus(identifier, order);

        if (success && response) {
            setTrashMenus(response);
        }
    };

    useEffect(() => {
        // If the searchbar contains more than 2 characters, perform a search request,
        // else load all the menus (separated by status on each case)
        const searchTimeout = setTimeout(() => {
            (async () => {
                if (searchText.length > 2) {
                    setLoading(true);
                    setAllMenusRendered(false);
                    if (value === 0) {
                        const { success, response } = await searchActiveMenus(
                            searchText,
                            identifier,
                            order
                        );
                        if (success && response) {
                            setActiveMenus(response);
                        }
                    } else if (value === 1) {
                        const { success, response } = await searchDraftMenus(
                            searchText,
                            identifier,
                            order
                        );
                        if (success && response) {
                            setActiveMenus(response);
                        }
                    } else if (value === 2) {
                        const { success, response } = await searchTrashMenus(
                            searchText,
                            identifier,
                            order
                        );
                        if (success && response) {
                            setTrashMenus(response);
                        }
                    } else if (value === 3) {
                        const { success, response } =
                            await searchLastPrintedMenus(
                                searchText,
                                identifier,
                                order
                            );
                        if (success && response) {
                            setLastPrintedMenus(response);
                        }
                    }
                    setLoading(false);
                }

                if (searchText.length <= 2 && !allMenusRendered) {
                    loadData();
                }
            })();
        }, 300);

        return () => clearTimeout(searchTimeout);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [identifier, value, searchText, order]);

    useEffect(() => {
        (async () => {
            if (!firstLoad) {
                const { success, response } = await fetchRequest(
                    `/restaurant/${identifier}/reorder`,
                    {
                        method: "PUT",
                        body: JSON.stringify({ rows: activeMenus })
                    }
                );
                await refreshRestaurant(
                    dispatch,
                    identifier,
                    current_restaurant
                );
                setFirstLoad(true);
                if (!success || !response) {
                    handleAlert({
                        severity: "error",
                        title: "Error",
                        message: response.message
                    });
                }
            }
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeMenus, firstLoad, identifier]);

    const clearSearchText = () => {
        setSearchText("");
        loadData();
    };

    const markAsActive = async (id) => {
        const { success, response } = await markAsActiveMenu(id, identifier);

        if (success && response) {
            await refreshRestaurant(dispatch, identifier, current_restaurant);
            handleAlert({
                severity: "success",
                title: "Success",
                message: "Menu placed in the <b>Active Menus Tab</b"
            });
            setTimeout(loadData, 500);
        } else {
            handleAlert({
                severity: "error",
                title: "Error",
                message: response.message
            });
        }
    };

    const duplicateMenuItem = async (id, newName) => {
        const { success, response } = await duplicateMenu(
            id,
            identifier,
            newName
        );

        if (success && response) {
            await refreshRestaurant(dispatch, identifier, current_restaurant);
            handleAlert({
                severity: "success",
                title: "Success",
                message:
                    "Duplicated Menu placed in the <b>Inactive Menus Tab</b>"
            });
            setTimeout(loadData, 500);
        } else {
            handleAlert({
                severity: "error",
                title: "Error",
                message: response.message
            });
        }
    };

    const markAsTrash = async (id) => {
        const { success, response } = await markAsTrashMenu(id, identifier);
        if (success && response) {
            await refreshRestaurant(dispatch, identifier, current_restaurant);
            handleAlert({
                severity: "success",
                title: "Success",
                message: "Menu placed in the <b>Trash Tab</b>"
            });
            setTimeout(loadData, 500);
        } else {
            handleAlert({
                severity: "error",
                title: "Error",
                message: response.message
            });
        }
    };

    const deleteMenuItem = async (id) => {
        const { success, response } = await deleteMenu(id, identifier);
        if (success && response) {
            await refreshRestaurant(dispatch, identifier, current_restaurant);
            handleAlert({
                severity: "success",
                title: "Success",
                message: "The menu was <b>Permanently Deleted</b>"
            });
            setTimeout(loadData, 500);
        } else {
            handleAlert({
                severity: "error",
                title: "Error",
                message: response.message
            });
        }
    };
    const printedMenuDelete = async (id) => {
        const { success, response } = await deletePrintedMenu(id, identifier);
        if (success && response) {
            await refreshRestaurant(dispatch, identifier, current_restaurant);
            handleAlert({
                severity: "success",
                title: "Success",
                message: "The menu was <b> Deleted</b>"
            });
            setTimeout(loadData, 500);
        } else {
            handleAlert({
                severity: "error",
                title: "Error",
                message: response.message
            });
        }
    };

    const markAsDraft = async (id) => {
        const { success, response } = await markAsDraftMenu(id, identifier);
        if (success && response) {
            await refreshRestaurant(dispatch, identifier, current_restaurant);
            handleAlert({
                severity: "success",
                title: "Success",
                message: "Menu placed in the <b>Inactive Menus Tab</b>"
            });
            setTimeout(loadData, 500);
        } else {
            handleAlert({
                severity: "error",
                title: "Error",
                message: response.message
            });
        }
    };

    const actionButtonCreateMenu = () => {
        navigate("/menu/new");
    };

    const handleChangeTab = (_event, newValue) => {
        clearSearchText();
        setValue(newValue);
    };

    const labelProps = (index) => {
        return {
            id: `simple-tab-${index}`,
            "aria-controls": `simple-tabpanel-${index}`
        };
    };

    const tabLabel = (text) => {
        const count =
            text === "Active Menus"
                ? activeMenus.length
                : text === "Inactive Menus"
                ? draftMenus.length
                : text === "Trash"
                ? trashMenus.length
                : text === "Last Printed Menus"
                ? lastPrintedMenus.length
                : 0;

        return (
            <span className={classes.labelText}>
                {text}
                <span
                    style={{
                        color: "#888888",
                        marginLeft: "0.2rem",
                        fontWeight: "normal"
                    }}
                >
                    ({count})
                </span>
            </span>
        );
    };

    function TabPanel(props) {
        const { children, value, index, ...other } = props;

        return (
            <div
                role="tabpanel"
                hidden={value !== index}
                id={`simple-tabpanel-${index}`}
                aria-labelledby={`simple-tab-${index}`}
                {...other}
            >
                {value === index && <div>{children}</div>}
            </div>
        );
    }

    return (
        <>
            <Alert {...alert} />
            <BoxLayout>
                <Header
                    clearSearchText={clearSearchText}
                    title="Menus"
                    searchText={searchText}
                    setSearchText={setSearchText}
                    onClickCreate={actionButtonCreateMenu}
                    createButtonText="Menu"
                />
                <Root className={classes.table}>
                    <div className={classes.tabsRow}>
                        <Tabs
                            className={classes.tabsContainer}
                            value={value}
                            onChange={handleChangeTab}
                        >
                            <Tab
                                label={tabLabel("Active Menus")}
                                {...labelProps(0)}
                            />
                            {user_type !== "view_only" && (
                                <Tab
                                    label={tabLabel("Inactive Menus")}
                                    {...labelProps(1)}
                                />
                            )}
                            {user_type !== "view_only" && (
                                <Tab
                                    label={tabLabel("Trash")}
                                    {...labelProps(2)}
                                />
                            )}
                            {has_prints || user_type === "view_only" ? (
                                <Tab
                                    label={tabLabel("Last Printed Menus")}
                                    {...labelProps(3)}
                                />
                            ) : (
                                ""
                            )}
                        </Tabs>
                        {!all_day_menu && value !== 3 && (
                            <>
                                <div
                                    className={classes.sortHandler}
                                    id="sort-dropdown"
                                    aria-controls={
                                        dropdownOpen ? "basic-menu" : undefined
                                    }
                                    aria-haspopup="true"
                                    aria-expanded={
                                        dropdownOpen ? "true" : undefined
                                    }
                                    onClick={handleSortOpen}
                                >
                                    <span>
                                        {order === "Z-A" ? "Z to A" : "A to Z"}
                                    </span>
                                    <Icon name="dropdown" />
                                </div>
                                <Options
                                    id="basic-menu"
                                    anchorEl={anchorEl}
                                    open={dropdownOpen}
                                    onClose={() => handleSort()}
                                    MenuListProps={{
                                        "aria-labelledby": "sort-dropdown"
                                    }}
                                    disableScrollLock={true}
                                >
                                    <MenuItem onClick={() => handleSort("A-Z")}>
                                        A to Z
                                    </MenuItem>
                                    <MenuItem onClick={() => handleSort("Z-A")}>
                                        Z to A
                                    </MenuItem>
                                </Options>
                            </>
                        )}
                    </div>
                    {!loading && (
                        <>
                            <TabPanel value={value} index={0}>
                                <ActiveTab
                                    data={activeMenus}
                                    setFirstLoad={setFirstLoad}
                                    setData={setActiveMenus}
                                    duplicateMenuItem={duplicateMenuItem}
                                    markAsTrash={markAsTrash}
                                    markAsDraft={markAsDraft}
                                    setPagination={!all_day_menu}
                                    draggable={all_day_menu && !searchText}
                                />
                            </TabPanel>
                            
                            {user_type !== "view_only" && (
                                <TabPanel value={value} index={1}>
                                    <DraftTab
                                        data={draftMenus}
                                        markAsActive={markAsActive}
                                        markAsDraft={markAsDraft}
                                        duplicateMenuItem={duplicateMenuItem}
                                        markAsTrash={markAsTrash}
                                        setPagination={true}
                                    />
                                </TabPanel>
                            )}
                            {user_type !== "view_only" && (
                                <TabPanel value={value} index={2}>
                                    <TrashTab
                                        data={trashMenus}
                                        markAsDraft={markAsDraft}
                                        deleteMenuItem={deleteMenuItem}
                                        setPagination={true}
                                    />
                                </TabPanel>
                            )}
                            {has_prints || user_type === "view_only" ? (
                                <TabPanel
                                    value={value}
                                    index={user_type === "view_only" ? 1 : 3}
                                >
                                    <PrintedMenus
                                        data={lastPrintedMenus}
                                        deletePrintedMenu={printedMenuDelete}
                                        setPagination={true}
                                        userType={user_type}
                                    />
                                </TabPanel>
                            ) : (
                                ""
                            )}
                        </>
                    )}
                    {loading && (
                        <div className={classes.loader}>
                            <CircularProgress />
                        </div>
                    )}
                </Root>
            </BoxLayout>
        </>
    );
};

export default Menu;
