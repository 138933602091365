import { styled } from "@mui/material/styles";

const classes = {
    searchBar: `searchBar`,
    searchIcon: `searchIcon`,
    icon: `icon`,
    clearButton: `clearButton`
};
const Root = styled("div")(({ theme }) => ({
    [`&.${classes.searchBar}`]: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        border: "1px solid #B4B3B3",
        borderRadius: "30px",
        height: "100%",
        minWidth: 160,
        transition: "all 350ms ease-in-out",
        "&:focus-within": {
            border: `1px solid ${theme.colors.orange[700]}`,
            "& > svg:nth-of-type(1)": {
                background: theme.colors.orange[700],
                "& path": {
                    fill: "white"
                }
            }
        },
        "& > input": {
            borderRadius: "15px",
            border: "0 !important",
            width: "100%",
            paddingLeft: 10
        },
        "& > input:focus": {
            outline: "none"
        }
    },
    [`& .${classes.searchIcon}`]: {
        transition: "all 350ms ease-in-out !important",
        background: `${theme.colors.orange[700]}00`,
        "& > path": {
            fill: theme.colors.grey[500]
        },
        margin: "10px 0px 10px 10px"
    },
    [`& .${classes.icon}`]: {
        width: 24,
        padding: 4.5,
        height: 24,
        borderRadius: "50%"
    },
    [`& .${classes.clearButton}`]: {
        cursor: "pointer",
        fontSize: "26px !important",
        marginRight: "8px !important",
        "& > path": {
            width: 10,
            height: 10,
            fill: theme.colors.grey[900]
        }
    }
}));

export { Root, classes };
