import { styled } from "@mui/material/styles";

const classes = {
    tableRow: `tableRow`,
    color1: `color1`,
    color2: `color2`,
    textLight: `textLight`,
    switchLabel: `switchLabel`,
    switch: `switch`,
    switchDisabled: `switchDisabled`,
    iconContainer: `iconContainer`,
    actions: `actions`,
    roleDropdown: `roleDropdown`
};

const Root = styled("div")(({ theme }) => ({
    [`& .${classes.tableRow}`]: {
        display: "flex !important",
        flexDirection: "row",
        width: "100%",
        height: 70,
        borderRadius: "6px",
        "& td": {
            display: "flex",
            float: "left",
            alignItems: "center",
            paddingLeft: 20,
            paddingRight: 10,
            fontWeight: 500,
            fontSize: 18,
            height: 70
        }
    },
    [`& .${classes.color1}`]: {
        background: "#FFFFFF"
    },
    [`& .${classes.color2}`]: { background: "#FAFAFA" },
    [`& .${classes.textLight}`]: {
        color: theme.colors.grey[700],
        fontSize: "14px !important"
    },
    [`& .${classes.switchLabel}`]: {
        marginLeft: "6px !important",
        "& .MuiFormControlLabel-label": {
            fontSize: 14,
            lineHeight: "16px",
            color: theme.colors.grey[500],
            width: 50
        },
        "&:hover .MuiFormControlLabel-label": {
            color: theme.colors.grey[900]
        },
        "& > * > .Mui-disabled": {
            color: "#EAEAEA !important"
        }
    },
    [`& .${classes.switch}`]: {
        "& .MuiSwitch-switchBase:not(.Mui-disabled)": {
            color: `${theme.colors.orange[700]} !important`,
            "&.Mui-checked:hover": {
                backgroundColor: "rgba(250, 211, 184, 0.15) !important"
            }
        },
        "& .MuiSwitch-switchBase.Mui-disabled": {
            color: "#EAEAEA !important"
        },
        "& .MuiSwitch-track": {
            backgroundColor: `${theme.colors.orange[200]} !important`
        },
        "& .Mui-disabled+.MuiSwitch-track": {
            backgroundColor: `#EAEAEA !important`,
            opacity: "1 !important"
        }
    },
    [`& .${classes.switchDisabled}`]: {
        "&:hover": {
            backgroundColor: "rgba(250, 250, 250, 0.35) !important"
        }
    },
    [`& .${classes.iconContainer}`]: {
        transform: "translateY(2px)"
    },
    [`& .${classes.actions}`]: {
        display: "flex",
        height: 70,
        width: 230,
        alignItems: "center",
        "& svg": {
            cursor: "pointer",
            margin: "0 11.5px",
            width: 19,
            height: 19,
            "& path": {
                fill: "#9E9E9E"
            }
        },
        "& svg:hover": {
            "& path": {
                fill: theme.colors.orange[700]
            }
        }
    },
    [`& .${classes.roleDropdown}`]: {
        width: 200,
        background: "white",
        border: "1px solid #EAEAEA",
        boxShadow: "0px 4px 12px rgba(34, 34, 34, 0.12)",
        borderRadius: "20px !important",
        "& li": {
            height: 48
        },
        "& li.Mui-selected": {
            backgroundColor: theme.colors.orange[50],
            color: theme.colors.orange[900],
            "&:hover": {
                backgroundColor: theme.colors.orange[50]
            }
        }
    }
}));

const roleDropdown = (theme) => ({
    width: 200,
    background: "white",
    border: "1px solid #EAEAEA",
    boxShadow: "0px 4px 12px rgba(34, 34, 34, 0.12)",
    borderRadius: "20px !important",
    "& li": {
        height: 48
    },
    "& li.Mui-selected": {
        backgroundColor: theme.colors.orange[50],
        color: theme.colors.orange[900],
        "&:hover": {
            backgroundColor: theme.colors.orange[50]
        }
    }
});

export { Root, classes, roleDropdown };
