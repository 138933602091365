import { Autocomplete, Grid, TextField, Typography } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import React, { useContext } from "react";
import {
    RootExport,
    classes
} from "../assets/styles/pages/settings/tabs/Restaurant.js";
import Button from "./UI/Button.js";
import ConfirmAction from "./modals/ConfirmAction.js";
import { useAlert } from "../hooks/useAlert.js";
import Alert from "./UI/Alert.js";
import AuthGlobal from "../context/store/global.js";

const RESTAURANT_OPTIONS = [
    {
        label: "Current Restaurant",
        value: "current"
    },
    {
        label: "All Restaurants",
        value: "all"
    }
];

const DEFAULT_RESTAURANT_OPTION = RESTAURANT_OPTIONS[0];

export default function ExportEvents() {
    const [startDate, setStartDate] = React.useState(new Date());
    const [endDate, setEndDate] = React.useState(new Date());
    const [showConfirmModal, setShowConfirmModal] = React.useState(false);
    const [alert, _handleAlert] = useAlert();
    const [restaurantOption, setRestaurantOption] = React.useState(
        DEFAULT_RESTAURANT_OPTION
    );
    const {
        globalState: {
            current_restaurant: { identifier, display_name }
        }
    } = useContext(AuthGlobal);
    const handleAlert = _handleAlert as any;

    function handleExport() {
        setShowConfirmModal(true);
    }

    async function exporting() {
        // Create an object _startDate with the time set to UTC 00:00:00:000
        const _startDate = new Date(startDate);
        _startDate.setUTCHours(0, 0, 0, 0);
        const _endDate = new Date(endDate);
        _endDate.setUTCHours(23, 59, 59, 999);
        // build a query string with the start and end date
        const queries = new URLSearchParams();
        restaurantOption.value !== "all" &&
            queries.append("identifier", identifier);
        queries.append("start_date", _startDate.toISOString());
        queries.append("end_date", _endDate.toISOString());

        const response = await window.fetch(
            `${
                process.env.REACT_APP_EVENTS_URL
            }/export/as-csv?${queries.toString()}`,
            {
                headers: {
                    "Content-Type": "application/json"
                },
                method: "GET",
                mode: "cors"
            }
        );

        if (response.ok) {
            try {
                const blob = await response.blob();
                const url = window.URL.createObjectURL(blob);

                // create a new anchor tag with the desired download name
                const a = document.createElement("a");
                a.href = url;
                a.target = "_blank";
                a.download =
                    restaurantOption.value === "all"
                        ? "All Restaurants - Events.csv"
                        : `${display_name} - Events.csv`;
                a.style.display = "none";
                document.body.appendChild(a);

                // trigger a click event on the anchor tag to start the download
                a.click();

                // clean up the anchor tag after the download is started
                document.body.removeChild(a);
                setShowConfirmModal(false);
                handleAlert({
                    severity: "success",
                    title: "Success",
                    message:
                        "The events were exported successfully, please check your downloads folder"
                });
            } catch (e) {
                console.log(e);
                setShowConfirmModal(false);
                handleAlert({
                    severity: "error",
                    title: "Error",
                    message:
                        "There was a problem while trying to export the events, please try again in a few seconds"
                });
            }
        } else {
            const data = await response.json();
            setShowConfirmModal(false);
            handleAlert({
                severity: "error",
                title: "Error",
                message:
                    data.error ||
                    "There was a problem while trying to get the events, please try again in a few seconds"
            });
        }
    }

    return (
        <RootExport container>
            <Grid item xs={12}>
                <div
                    style={{
                        padding: "80px 0px 15px 0px"
                    }}
                >
                    <Typography component="h3" style={{ fontWeight: 500 }}>
                        Menu Presenter Event Data Export
                    </Typography>
                </div>
            </Grid>
            <Grid
                item
                xs={12}
                sm={6}
                md={4}
                style={{
                    paddingRight: "20px"
                }}
            >
                <DatePicker
                    value={startDate}
                    onChange={(date) => date && setStartDate(date)}
                    label="Start Date"
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            className={classes.textField}
                            fullWidth
                            required
                        />
                    )}
                ></DatePicker>
            </Grid>
            <Grid
                item
                xs={12}
                sm={6}
                md={4}
                style={{
                    paddingRight: "20px"
                }}
            >
                <DatePicker
                    value={endDate}
                    onChange={(date) => date && setEndDate(date)}
                    label="End Date"
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            className={classes.textField}
                            fullWidth
                            required
                        />
                    )}
                ></DatePicker>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
                <Autocomplete
                    fullWidth
                    value={restaurantOption}
                    onChange={(_e, v) => setRestaurantOption(v)}
                    options={RESTAURANT_OPTIONS}
                    disableClearable={true}
                    disablePortal
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label="Restaurant"
                            placeholder="Select a restaurant"
                            InputLabelProps={{
                                shrink: true
                            }}
                            className={`${classes.select} ${classes.textField}`}
                        />
                    )}
                    componentsProps={{
                        paper: {
                            className: classes.selectRow
                        }
                    }}
                    isOptionEqualToValue={(option, value) =>
                        option.label === value.label
                    }
                />
            </Grid>
            <Grid item xs={12} sm={6} md={12}>
                <div
                    style={{
                        display: "flex",
                        justifyContent: "flex-end"
                    }}
                >
                    <Button
                        innerText="Export"
                        type="primary"
                        color="orange"
                        style={{ zIndex: 2, marginTop: "20px" }}
                        onClick={handleExport}
                    />
                </div>
            </Grid>
            <Alert {...(alert as any)} />
            <ConfirmAction
                open={showConfirmModal}
                actionCancel={() => {
                    setShowConfirmModal(false);
                }}
                actionConfirm={() => exporting()}
                icon="export"
                dialogText="Do you want to export the menu presenter events?"
                note={
                    "This may take a few seconds/minutes depending on the time range..."
                }
            />
        </RootExport>
    );
}
