import { styled } from "@mui/material/styles";
import { GlobalStyles } from "@mui/system";

const classes = {
    boxContainer: `boxContainer`
};

const Root = styled("div")(({ theme }) => ({
    [`&.${classes.boxContainer}`]: {
        margin: 40,
        minWidth: 700,
        width: "calc(100vw - 378px)",
        minHeight: "calc(100vh - 80px)",
        overflow: "hidden",
        background: "white",
        boxShadow: "0px 10px 30px rgba(31, 19, 0, 0.3)",
        borderRadius: "20px"
    },
    "@global .atawny": {
        color: theme.colors.orange[700]
    }
}));

const BoxLayout = ({ children, ...props }) => {
    return (
        <Root className={classes.boxContainer} {...props}>
            <GlobalStyles
                styles={(theme) => ({
                    ".atawny": {
                        color: theme.colors.orange[700]
                    }
                })}
            />
            {children}
        </Root>
    );
};

export default BoxLayout;
