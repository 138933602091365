import { Link, Modal, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";

import { Root, classes } from "../../assets/styles/components/Modal";
import Button from "../UI/Button";
import Icon from "../UI/Icon";

const ConfirmAction = ({
    itemLoading = false,
    disableBtn = false,
    open = true,
    icon = "duplicate",
    dialogText,
    note = "",
    actionConfirm,
    actionSecondButton = null,
    actionCancel,
    params = [],
    children = null,
    button1Text = "Confirm",
    button2Text = "Cancel",
    hasInfo = false
}) => {
    const [disable, setDisable] = useState(false);
    const [parameters, setParameters] = useState([]);

    const clickConfirm = (...props) => {
        setDisable(true);
        setTimeout(async () => {
            await actionConfirm(...props);
            setDisable(false);
        }, 200);
    };

    useEffect(() => {
        setParameters([...params]);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleChange = (value, index) => {
        const newParameters = parameters.map((item, i) => {
            if (index === i) {
                return { ...item, value };
            }
            return item;
        });
        setParameters(newParameters);
    };

    return (
        <Modal open={open} onClose={actionCancel} disablePortal>
            <Root className={classes.modal}>
                <span className={classes.sectionClose}>
                    <span
                        className={classes.closeButtonContainer}
                        onClick={actionCancel}
                    >
                        <Icon name="clear" className={classes.closeButton} />
                    </span>
                </span>
                <span className={classes.iconCircle}>
                    <Icon className={classes.icon} name={icon} />
                </span>
                <p className={classes.message}>{dialogText}</p>
                {typeof note === "string" && (
                    <p className={classes.note}>{note}</p>
                )}
                {typeof note !== "string" && note}
                <hr className={`hrow ${classes.hr}`} />
                {parameters.length > 0 && (
                    <div className={classes.dialogForm}>
                        {parameters.map((item, index) => (
                            <React.Fragment key={index}>
                                <p className={classes.fieldDescription}>
                                    {item.description}
                                </p>
                                <TextField
                                    fullWidth
                                    className={classes.textField}
                                    label={item.label}
                                    value={item.value}
                                    onChange={(event) =>
                                        handleChange(event.target.value, index)
                                    }
                                />
                            </React.Fragment>
                        ))}
                    </div>
                )}
                {children && (
                    <div className={classes.dialogForm}>{children}</div>
                )}
                {!hasInfo ? (
                    <span className={classes.actions}>
                        <Button
                            innerText={button1Text}
                            type="primary"
                            color="orange"
                            disabled={disableBtn ? disableBtn : disable}
                            onClick={() =>
                                clickConfirm(...parameters.map((x) => x.value))
                            }
                            itemLoading={itemLoading}
                            disableBtn={disableBtn}
                        />
                        <Button
                            onClick={() => {
                                typeof actionSecondButton === "function"
                                    ? actionSecondButton()
                                    : actionCancel();
                            }}
                            innerText={button2Text}
                            type="secondary"
                            color="orange"
                            itemLoading={itemLoading}
                            disableBtn={disableBtn}
                        />
                    </span>
                ) : (
                    <div className={classes.conactInfo}>
                        <p>
                            Phone:{" "}
                            <Link href="tel:+16178222800">+1 617 822 2800</Link>
                        </p>
                        <p>
                            Email:{" "}
                            <Link href="mailto:support@stellarmenus.com">
                                support@stellarmenus.com
                            </Link>
                        </p>
                    </div>
                )}
            </Root>
        </Modal>
    );
};

export default ConfirmAction;
