import { CircularProgress, Modal, Slide, Table } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { Root, classes } from "../../assets/styles/items/FormAddItems.js";
import { getItems } from "../../utils/Items/item-helper.js";
import Button from "../UI/Button.js";
import Icon from "../UI/Icon";
import SearchBar from "../UI/SearchBar.js";
import AuthGlobal from "../../context/store/global";
import { useAlert } from "../../hooks/useAlert.js";
import NoResults from "../NoResults.js";
import FormCreateItem from "./FormCreateItem.js";
import FormCreateTitle from "./FormCreateTitle.js";
import Alert from "../UI/Alert.js";
import { useIntercom } from "react-use-intercom";
import { formatItemPrice } from "../../utils/Items/pricing";

const FormAddItems = ({
    open,
    handleClose,
    handleSave,
    itemsToAdd,
    setItemsToAdd,
    currentItems = [],
    type = "item"
}) => {
    const {
        globalState: {
            current_restaurant: { identifier }
        }
    } = useContext(AuthGlobal);
    const [search, setSearch] = useState("");
    const [loading, setLoading] = useState(true);
    const [showCreateItem, setShowCreateItem] = useState(false);
    const [time, setTime] = useState(null);

    const [items, setItems] = useState([]);
    const [filteredItems, setFilteredItems] = useState([]);
    const [alert, handleAlert] = useAlert();

    const appendItem = (index) => {
        setItemsToAdd((prev) => [
            ...prev,
            ...items.filter((item) => item.idx === index)
        ]);
        setItems(
            items.map((item) => {
                if (item.idx === index) {
                    return { ...item, checked: !item.checked };
                }
                return item;
            })
        );
    };

    const removeItem = (index) => {
        setItemsToAdd((prev) => prev.filter(({ idx }) => idx !== index));
        setItems(
            items.map((item) => {
                if (item.idx === index) {
                    return { ...item, checked: !item.checked };
                }
                return item;
            })
        );
    };

    const { update } = useIntercom();
    useEffect(() => {
        update({
            alignment: open ? "left" : "right"
        });
    }, [open, update, showCreateItem]);

    useEffect(() => {
        setFilteredItems(
            items.filter(
                (item) =>
                    item.item.internal_name
                        .toLowerCase()
                        .includes(search.toLowerCase()) && !item.checked
            )
        );
    }, [search, items]);

    const ItemRow = ({ item, checked = false }) => {
        const {
            globalState: { current_restaurant }
        } = useContext(AuthGlobal);
        return (
            <tr className={classes.itemRow}>
                <>
                    <td
                        style={{
                            padding: "0px 0px 0px 20px",
                            flexGrow: 1,
                            display: "-webkit-box",
                            overflow: "hidden",
                            WebkitLineClamp: 2,
                            lineClamp: 2,
                            WebkitBoxOrient: "vertical",
                            textOverflow: "ellipsis"
                        }}
                    >
                        <span
                            style={{
                                marginRight: 16,
                                fontWeight: 400,
                                fontSize: "16px",
                                lineHeight: "19px"
                            }}
                        >
                            {item.item.internal_name}
                            <span
                                style={{
                                    marginLeft: 10,
                                    fontWeight: 300,
                                    fontFamily: "Roboto",
                                    fontSize: "14px"
                                }}
                            >
                                {item.item.price_variations
                                    .map((x) => {
                                        const currencySymbol =
                                            current_restaurant.currency_symbol;
                                        const currencyPosition =
                                            current_restaurant.currency_placement;

                                        return formatItemPrice(
                                            {
                                                price_value: x.price_value,
                                                leading_text: x.leading_text,
                                                trailing_text: x.trailing_text
                                            },
                                            currencyPosition,
                                            currencySymbol
                                        );
                                    })
                                    .join(", ")}
                            </span>
                        </span>
                    </td>
                    <td
                        style={{
                            display: "flex",
                            alignItems: "center",
                            paddingRight: 20
                        }}
                    >
                        {checked ? (
                            <Icon
                                name="checkButton"
                                onClick={() => removeItem(item.idx)}
                                style={{ cursor: "pointer" }}
                            />
                        ) : (
                            <Icon
                                name="plusButton"
                                className={classes.plusButton}
                                onClick={() => appendItem(item.idx)}
                            />
                        )}
                    </td>
                </>
            </tr>
        );
    };

    useEffect(() => {
        setLoading(true);
        open &&
            (async () => {
                const { success, response } = await getItems(
                    "active",
                    identifier,
                    "A-Z",
                    type
                );
                if (success && response) {
                    const filteredItems = response.filter(
                        (item) =>
                            currentItems.findIndex((id) => item._id === id) < 0
                    );
                    const newIds = time
                        ? response
                              .filter((item) => {
                                  return (
                                      time < new Date(item.created_at).getTime()
                                  );
                              })
                              .map((x) => x._id)
                        : [];
                    const newItems = filteredItems.map((item, index) => ({
                        item,
                        idx: index,
                        checked:
                            itemsToAdd?.findIndex(
                                ({ _id }) => _id === item._id
                            ) >= 0 || newIds.includes(item._id)
                    }));
                    setItems(newItems);
                    setLoading(false);
                } else {
                    handleAlert({
                        severity: "error",
                        title: "Error",
                        message:
                            "The items list couldn't be loaded, please try reloading the page"
                    });
                }
            })();
        !open && setTime(null);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [open]);

    return (
        <>
            <Alert {...alert} />
            <Modal
                open={open}
                onClose={() => {
                    setSearch("");
                    handleClose();
                }}
                disablePortal
            >
                <Slide in={open} direction="left">
                    <Root className={classes.container}>
                        <div className={classes.top}>
                            <span className={classes.sectionClose}>
                                <span
                                    className={classes.closeButtonContainer}
                                    onClick={() => {
                                        setSearch("");
                                        handleClose();
                                    }}
                                >
                                    <Icon
                                        name="clear"
                                        className={classes.closeButton}
                                    />
                                </span>
                            </span>
                            <h1 className={classes.title}>
                                {type === "title"
                                    ? "Add Title(s)"
                                    : "Add Item(s)"}
                            </h1>
                        </div>
                        <div className={classes.inlineSearchBar}>
                            <SearchBar
                                placeholder={`Search for an existing ${
                                    type === "title" ? "title" : "item"
                                }`}
                                clearSearchText={() => setSearch("")}
                                searchText={search}
                                setSearchText={setSearch}
                            />
                            <Button
                                type="primary"
                                color="orange"
                                innerText={`New ${
                                    type === "title" ? "Title" : "Item"
                                }`}
                                className="new_item_button"
                                onClick={() => {
                                    setTime(new Date().getTime());
                                    setShowCreateItem(true);
                                }}
                            />
                        </div>
                        <div className={classes.form}>
                            <h5 className={classes.infoMessage}>
                                <Icon name="info" />
                                Only Active
                                {type === "title" ? " Titles " : " Items "}
                                will be displayed here.
                            </h5>

                            <div className={classes.itemsSelector}>
                                <div className={classes.listContainer}>
                                    <div className={classes.list}>
                                        {loading && (
                                            <CircularProgress
                                                style={{
                                                    width: 60,
                                                    color: "#CC5803",
                                                    display: "flex",
                                                    height: "100%",
                                                    alignSelf: "center"
                                                }}
                                            />
                                        )}
                                        {!loading && (
                                            <Table>
                                                <tbody>
                                                    {/* 
                                                        Show items that are already added, no matter of the search
                                                    */}
                                                    {itemsToAdd.map((item) => {
                                                        return (
                                                            <ItemRow
                                                                key={item.idx}
                                                                item={item}
                                                                checked={true}
                                                            />
                                                        );
                                                    })}
                                                    {/* 
                                                        Show separator if there are items to add and items already added
                                                    */}
                                                    {itemsToAdd.length > 0 &&
                                                        filteredItems.length >
                                                            0 && (
                                                            <tr
                                                                style={{
                                                                    padding: 0
                                                                }}
                                                            >
                                                                <td
                                                                    style={{
                                                                        padding: 0,
                                                                        height: 24
                                                                    }}
                                                                >
                                                                    <hr
                                                                        style={{
                                                                            margin: "8px 0 16px"
                                                                        }}
                                                                    />
                                                                </td>
                                                            </tr>
                                                        )}
                                                    {filteredItems.length ? (
                                                        filteredItems.map(
                                                            (item) => (
                                                                <ItemRow
                                                                    key={
                                                                        item.idx
                                                                    }
                                                                    item={item}
                                                                />
                                                            )
                                                        )
                                                    ) : (
                                                        <tr>
                                                            <td>
                                                                <NoResults
                                                                    text={
                                                                        type ===
                                                                        "title"
                                                                            ? "No Titles Found"
                                                                            : "No Items Found"
                                                                    }
                                                                />
                                                            </td>
                                                        </tr>
                                                    )}
                                                </tbody>
                                            </Table>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={classes.actions}>
                            <Button
                                type="primary"
                                color="orange"
                                disabled={loading}
                                innerText="Save"
                                className="save_selected_items"
                                onClick={() => {
                                    setSearch("");
                                    handleSave();
                                }}
                            />

                            <Button
                                type="secondary"
                                color="dark"
                                disabled={loading}
                                innerText="Cancel"
                                onClick={() => {
                                    setSearch("");
                                    handleClose();
                                }}
                            />
                        </div>
                    </Root>
                </Slide>
            </Modal>
            {type === "item" && (
                <FormCreateItem
                    show={showCreateItem}
                    onClose={(_, alertData, newItem) => {
                        if (newItem) {
                            const updatedItemsToAdd = newItem.map(
                                (newItems, index) => ({
                                    item: newItems,
                                    idx:
                                        new Date().getTime().toString() + index,
                                    checked: true
                                })
                            );

                            setItemsToAdd((prev) => [
                                ...prev,
                                ...updatedItemsToAdd
                            ]);

                            const updatedItems = newItem.map(
                                (newItems, index) => ({
                                    item: newItems,
                                    idx:
                                        new Date().getTime().toString() + index,
                                    checked: true
                                })
                            );

                            setItems((prevItems) => [
                                ...prevItems,
                                ...updatedItems
                            ]);
                        }
                        setShowCreateItem(false);
                        handleAlert(alertData);
                    }}
                />
            )}
            {type === "title" && (
                <FormCreateTitle
                    open={showCreateItem}
                    handleClose={(alertData, newTitle) => {
                        if (newTitle) {
                            setItemsToAdd((prev) => [
                                ...prev,
                                {
                                    item: newTitle,
                                    idx: items.length,
                                    checked: true
                                }
                            ]);
                            setItems((prev) => [
                                ...prev,
                                {
                                    item: newTitle,
                                    idx: prev.length,
                                    checked: true
                                }
                            ]);
                        }
                        handleAlert(alertData);
                        setShowCreateItem(false);
                    }}
                />
            )}
        </>
    );
};

export default FormAddItems;
