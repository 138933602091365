import React from "react";
import Icon from "./Icon";
import { Root, classes } from "../../assets/styles/Buttons";
import { CircularProgress } from "@mui/material";

const Button = ({
    itemLoading = false,
    disableBtn = false,
    hasLoading = false,
    onClick = () => {},
    innerText = "",
    icon = "default",
    type = "secondary",
    color = "dark",
    className = "",
    htmlType = "button",
    ...props
}) => {
    const pickIcon = (innerText) => {
        if (icon !== "default") {
            return <Icon name={icon} />;
        }
        switch (innerText) {
            case "New":
            case "New Item":
            case "New Title":
            case "Section":
                return <Icon name="plus" />;
            case "Save":
                return <Icon name="save" />;
            case "Export":
                return <Icon name="export" />;
            case "Import":
                return <Icon name="import" />;
            case "Publish":
                return <Icon name="publish" />;
            case "Empty":
                return <Icon name="trash" />;
            case "Exit":
                return <Icon name="exit" />;
            case "Edit":
                return <Icon name="edit" />;
            case "Print":
                return <Icon name="print" />;
            case "Recover":
                return <Icon name="refresh" />;
            case "PDF":
                return <Icon name="file" />;
            default:
                return <></>;
        }
    };

    const hasIcon = () => {
        return pickIcon(innerText).type !== React.Fragment;
    };
    return (
        <Root
            propsColor={color}
            className={`${className} muinewbuttons ${classes.button} ${classes[type]} `}
            disabled={disableBtn || itemLoading}
            onClick={onClick}
            type={htmlType}
            {...props}
        >
            {hasIcon() && (
                <>
                    {itemLoading ? (
                        <CircularProgress
                            color="inherit"
                            size={18}
                            className={classes.circularProgress}
                        />
                    ) : (
                        <span className={classes.icon}>
                            {pickIcon(innerText)}
                        </span>
                    )}
                </>
            )}
            <span>
                {hasLoading ? (
                    <CircularProgress
                        color="inherit"
                        size={18}
                        className={classes.circularProgress}
                    />
                ) : (
                    innerText
                )}
            </span>
        </Root>
    );
};

export default Button;
