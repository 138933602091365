import { styled } from "@mui/material/styles";

const classes = {
    card: `card`,
    selected: `selected`,
    currentBadge: `currentBadge`,
    restName: `restName`,
    hr: `hr`
};

const Root = styled("div")(({ theme }) => ({
    [`&.${classes.card}`]: {
        margin: 12,
        minHeight: 115,
        cursor: "pointer",
        borderRadius: 10,
        position: "relative",
        padding: 24,
        border: `1px solid ${theme.colors.grey[200]}`
    },
    [`&.${classes.selected}`]: {
        border: `1px solid ${theme.colors.orange[700]}`,
        boxShadow: "3px 4px 18px rgba(211, 211, 211, 0.75)"
    },
    [`& .${classes.currentBadge}`]: {
        position: "absolute",
        width: 60,
        fontSize: 11,
        top: 11,
        right: -12,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: 25,
        border: "1px solid #5A9E31",
        color: "#5A9E31",
        borderRadius: 16,
        background: "#DEF2D2"
    },
    [`& .${classes.restName}`]: {
        fontSize: 16,
        margin: 0,
        lineHeight: "19px",
        fontWeight: 700,
        paddingBottom: "12px"
    },
    [`& .${classes.hr}`]: {
        border: `1px solid ${theme.colors.grey[200]}`
    }
}));

export { Root, classes };
