import { styled } from "@mui/material/styles";

const classes = {
    container: `container`,
    actions: `actions`,
    switchLabel: `switchLabel`,
    switch: `switch`,
    switchDisabled: `switchDisabled`,
    addSectionButton: `addSectionButton`,
    itemRow: `itemRow`,
    itemInactive: `itemInactive`,
    sorting: `sorting`,
    itemDrag: `itemDrag`,
    arrowDownButton: `arrowDownButton`,
    arrowRightButton: `arrowRightButton`,
    transparent: `transparent`
};

const Root = styled("tr")(({ theme }) => ({
    [`&.${classes.container}`]: {
        background: "white",
        width: "100%",
        border: `1px solid ${theme.colors.grey[100]}`,
        marginBottom: 12,
        borderRadius: "6px",
        display: "flex",
        flexDirection: "column"
    },
    [`& .${classes.actions}`]: {
        display: "flex",
        height: 70,
        alignItems: "center",
        "& svg": {
            cursor: "pointer",
            margin: "0 11.5px",
            width: 19,
            height: 19,
            "& path": {
                fill: "#9E9E9E"
            }
        },
        "& svg:hover": {
            "& path": {
                fill: theme.colors.orange[700]
            }
        }
    },
    [`& .${classes.switchLabel}`]: {
        marginLeft: "6px !important",
        "& .MuiFormControlLabel-label": {
            fontSize: 14,
            lineHeight: "16px",
            color: theme.colors.grey[500],
            width: 50
        },
        "&:hover .MuiFormControlLabel-label": {
            color: theme.colors.grey[900]
        }
    },
    [`& .${classes.switch}`]: {
        "& .MuiSwitch-switchBase": {
            color: `${theme.colors.orange[700]} !important`,
            "&.Mui-checked:hover": {
                backgroundColor: "rgba(250, 211, 184, 0.15) !important"
            }
        },
        "& .MuiSwitch-track": {
            backgroundColor: `${theme.colors.orange[200]} !important`
        }
    },
    [`& .${classes.switchDisabled}`]: {
        "&:hover": {
            backgroundColor: "rgba(0, 0, 0, 0.04)"
        }
    },
    [`& .${classes.addSectionButton}`]: {
        fontWeight: 400,
        fontStyle: "italic",
        fontSize: "14px",
        lineHeight: "17px",
        cursor: "pointer",
        width: "fit-content",
        display: "flex",
        alignItems: "center",
        margin: 0,
        "& svg": {
            width: "24px !important",
            height: "24px !important",
            padding: 6,
            marginRight: 10,
            borderRadius: "50%",
            border: `1px solid ${theme.colors.orange[700]} `,
            backgroundColor: "transparent",
            "& path": {
                fill: theme.colors.orange[700]
            }
        }
    },
    [`& .${classes.itemRow}`]: {
        display: "flex !important",
        alignItems: "center",
        marginTop: 16,
        borderRadius: 6,
        "&:hover": {
            background: theme.colors.grey[50],
            "& td:nth-of-type(1)": {
                opacity: 1,
                transform: "translateY(2px)"
            }
        }
    },
    [`& .${classes.itemInactive}`]: {
        opacity: "25%",
        "&:hover": {
            background: `${theme.colors.grey[100]} !important`
        }
    },
    [`& .${classes.sorting}`]: {
        display: "flex !important",
        alignItems: "center",
        marginTop: 16,
        borderRadius: 6,
        background: theme.colors.grey[50],
        border: `1px solid ${theme.colors.grey[300]}`,
        boxShadow: "0px 5px 12px rgba(34, 34, 34, 0.15)",
        "& td:nth-of-type(1)": {
            opacity: 1,
            transform: "translateY(2px)"
        }
    },
    [`& .${classes.itemDrag}`]: {
        cursor: "grab",
        padding: 0,
        display: "flex",
        alignItems: "center",
        opacity: 0
    },
    [`& .${classes.arrowDownButton}`]: {
        margin: "0px 12px",
        width: 26,
        height: 26,
        padding: "0px 8px",
        borderRadius: "50%",
        background: theme.colors.orange[50],
        "& path": {
            fill: theme.colors.orange[700]
        },
        "&:hover": {
            background: `${theme.colors.orange[200]}`
        }
    },
    [`& .${classes.arrowRightButton}`]: {
        margin: "0px 12px",
        width: 26,
        height: 26,
        padding: "0px 10px",
        borderRadius: "50%",
        "&:hover": {
            background: `${theme.colors.grey[100]}80`
        }
    },
    [`&.${classes.transparent}`]: {
        opacity: "40%"
    }
}));

export { Root, classes };
