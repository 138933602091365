import { styled } from "@mui/material/styles";
import { GlobalStyles } from "@mui/system";

const classes = {
    flex: `flex`,
    flexColumn: `flexColumn`
};

const Root = styled("div")(({ theme }) => ({
    [`&.${classes.flex}`]: {
        display: "flex",
        ...(theme.browser.isSafari && {
            "& button": {
                outline: "transparent 0px !important"
            }
        })
    },
    [`&.${classes.flexColumn}`]: {
        display: "flex",
        flexDirection: "column"
    },
    "@global": {
        "*::-webkit-scrollbar": {
            backgroundColor: "transparent",
            width: 8
        },
        "*::-webkit-scrollbar-thumb": {
            backgroundColor: "#22222280",
            borderRadius: "4px"
        },
        '.MuiTextField-root input:not(:focus):not(:hover):not(.MuiSelect-nativeInput):not([type="color"]):not([type="file"]), .MuiInputBase-multiline:not(:focus-within):not(:hover) textarea, .MuiSelect-select:not(:hover):not(:focus), .MuiAutocomplete-root:not(:hover):not(:focus-within) .MuiAutocomplete-inputRoot > div:nth-of-type(1):not(:has(span))':
            {
                opacity: 0.45
            },
        ".MuiSelect-select.Mui-disabled": {
            opacity: "0 !important",
            pointerEvents: "none"
        }
    },
    "@global *": {
        margin: 0,
        padding: 0,
        boxSizing: "border-box"
    },
    "@global body": {
        fontFamily: '"Roboto", sans-serif !important',
        background: `${theme.colors.orange[700]}`
    }
}));

const MyGlobalStyles = () => {
    return (
        <GlobalStyles
            styles={(theme) => ({
                body: {
                    fontFamily: '"Roboto", sans-serif !important',
                    background: theme?.colors?.orange[700]
                },
                "*": {
                    margin: 0,
                    padding: 0,
                    boxSizing: "border-box"
                },
                "*::-webkit-scrollbar": {
                    backgroundColor: "transparent",
                    width: 8
                },
                "*::-webkit-scrollbar-thumb": {
                    backgroundColor: "#22222280",
                    borderRadius: "4px"
                },
                '.MuiTextField-root input:not(:focus):not(:hover):not(.MuiSelect-nativeInput):not([type="color"]):not([type="file"]), .MuiInputBase-multiline:not(:focus-within):not(:hover) textarea, .MuiSelect-select:not(:hover):not(:focus), .MuiAutocomplete-root:not(:hover):not(:focus-within) .MuiAutocomplete-inputRoot > div:nth-of-type(1):not(:has(span))':
                    {
                        opacity: 0.45
                    },
                ".MuiSelect-select.Mui-disabled": {
                    opacity: "0 !important",
                    pointerEvents: "none"
                }
            })}
        />
    );
};

export { Root, classes, MyGlobalStyles };
