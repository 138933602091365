import { useState } from "react";
import { TableRow } from "@mui/material";
import Icon from "../../../components/UI/Icon";
import { Root, classes } from "../../../assets/styles/pages/menus/tabs/Active";
import NoResults from "../../../components/NoResults";
import ConfirmAction from "../../../components/modals/ConfirmAction";
import CustomTooltip from "../../../components/UI/CustomTooltip";
import CustomPagination from "../../../components/UI/CustomPagination";
import Button from "../../../components/UI/Button";

const Row = ({ data, userType }) => {
    const inputDate = new Date(data.created_at);
    const month = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December"
    ][inputDate.getMonth()];
    const period = inputDate.getHours() < 12 ? "AM" : "PM";
    let formattedHours = inputDate.getHours() % 12;
    formattedHours = formattedHours ? formattedHours : 12;
    const formattedDateTime =
        month +
        " " +
        inputDate.getDate() +
        ", " +
        inputDate.getFullYear() +
        ` ${formattedHours}:${inputDate
            .getMinutes()
            .toString()
            .padStart(2, "0")} ${period}`;

    return (
        <TableRow
            className={`${classes.menuListTableRow} ${
                data.index % 2 === 0 ? classes.color1 : classes.color2
            }`}
        >
            <td className={classes.name}>
                <Icon className={classes.pdfIcon} name="pdf" />
                {data?.menu?.internal_name}
                {"-"}
                {data?.version}
                <span className={classes.deletedOn}>
                    (created at: {formattedDateTime})
                </span>
            </td>
            {!data.hideActions && (
                <td className={classes.actions}>
                    {userType !== "view_only" && (
                        <CustomTooltip title="Delete Menu">
                            <span className={classes.iconContainer}>
                                <Icon
                                    onClick={() =>
                                        data.showDeleteModal(
                                            data._id,
                                            data?.menu?.internal_name
                                        )
                                    }
                                    name="trash"
                                />
                            </span>
                        </CustomTooltip>
                    )}
                    <Button
                        type="primary"
                        color="dark"
                        innerText="Open PDF"
                        className={classes.recoverMenu}
                        onClick={() => window.open(data?.pdf_url, "_blank")}
                    />
                </td>
            )}
        </TableRow>
    );
};

const PrintedMenus = ({ data, deletePrintedMenu, setPagination, userType }) => {
    const [deleteModalVisible, setDeleteModalVisible] = useState(false);
    const [deleteItem, setDeleteItem] = useState();

    const [itemName, setItemName] = useState("");
    const [page, setPage] = useState(1);
    const rowsPerPage = setPagination ? 10 : data.length;

    const showDeleteModal = (id, name) => {
        setItemName(name);
        setDeleteModalVisible(true);
        setDeleteItem(id);
    };

    const okDeleteClick = () => {
        setDeleteModalVisible(false);
        deletePrintedMenu(deleteItem);
    };

    return (
        <>
            {data.length > 0 ? (
                <Root
                    style={{
                        marginTop: 15,
                        display: "block",
                        minHeight: "calc(100vh - 335px)",
                        overflowY: "auto"
                    }}
                >
                    <tbody>
                        {data
                            .slice((page - 1) * rowsPerPage, page * rowsPerPage)
                            .map((row, index) => {
                                return (
                                    <Row
                                        index={index}
                                        key={index}
                                        data={{
                                            ...row,
                                            index,
                                            showDeleteModal
                                        }}
                                        userType={userType}
                                    ></Row>
                                );
                            })}
                    </tbody>
                </Root>
            ) : (
                <NoResults text={"No Menus Found"} />
            )}

            {setPagination && (
                <CustomPagination
                    totalRows={data.length}
                    rowsPerPage={rowsPerPage}
                    handlePageChange={setPage}
                />
            )}

            <ConfirmAction
                open={deleteModalVisible}
                actionConfirm={okDeleteClick}
                actionCancel={() => setDeleteModalVisible(false)}
                icon="trash"
                dialogText={`Are you sure want to permanently delete the ${itemName} menu? It will be gone for good.`}
            />
        </>
    );
};

export default PrintedMenus;
