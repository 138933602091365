import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getCollectionById } from "../../utils/Collections/collection-helper";
import FormCreateCollection from "./FormCreateCollection";
import AuthGlobal from "../../context/store/global";

const FormUpdateCollection = () => {
    const {
        globalState: {
            current_restaurant: { identifier }
        }
    } = useContext(AuthGlobal);
    const { idCollection } = useParams();
    const [collection, setCollection] = useState(null);

    useEffect(() => {
        (async () => {
            const { success, response } = await getCollectionById(
                idCollection,
                identifier
            );
            if (success && response) {
                setCollection(response);
            }
        })();
    }, [idCollection, identifier]);

    return (
        <>
            <FormCreateCollection mode="update" collection={collection} />
        </>
    );
};

export default FormUpdateCollection;
