import { useContext, useEffect, useRef, useState } from "react";
import {
    Root,
    classes,
    selectRow
} from "../../../assets/styles/components/QRTab";
import { MenuItem, TextField } from "@mui/material";
import ColorInput from "../../../components/UI/ColorInput";
import Icon from "../../../components/UI/Icon";
import ImageUploader from "../../../components/UI/ImageUploader";
import { Slider } from "../../../components/UI/Slider";
import Button from "../../../components/UI/Button";
import { fetchRequest } from "../../../utils";
import AuthContext from "../../../context/store/global";
import CustomTooltip from "../../../components/UI/CustomTooltip";
import { useAlert } from "../../../hooks/useAlert";
import Alert from "../../../components/UI/Alert";
import { useTheme } from "@mui/material/styles";
import QRCodeStyling from "qr-code-styling";

const qrCode = new QRCodeStyling({
    width: 400,
    height: 400,
    imageOptions: {
        crossOrigin: "anonymous",
        margin: 8
    }
});

async function fetchImage(imageUrl) {
    try {
        if (!imageUrl) {
            return;
        }

        const response = await fetch(imageUrl);
        const blob = await response.blob();
        const reader = new FileReader();

        return new Promise((resolve, reject) => {
            reader.onloadend = () => {
                resolve(reader.result); // Resolve with base64 data
            };
            reader.onerror = reject;
            reader.readAsDataURL(blob);
        });
    } catch (error) {
        console.error("Error fetching image:", error);
        throw error; // Propagate the error
    }
}

const QRCodeTab = () => {
    const {
        globalState: {
            current_restaurant: { identifier }
        }
    } = useContext(AuthContext);

    const [loading, setLoading] = useState(true);
    const [QRStyle, setQRStyle] = useState("square");
    const [bgColor, setBgColor] = useState("#ffffff");
    const [qrColor, setQrColor] = useState("#000000");
    const [innerColor, setInnerColor] = useState("#000000");
    const [outerColor, setOuterColor] = useState("#000000");
    const [, setFile] = useState(null);
    const [image, setImage] = useState("");
    const [deleteImage, setDeleteImage] = useState(false);
    const [logoSize, setLogoSize] = useState(0.3);
    const [cornerRadius, setCornerRadius] = useState(20);
    const [innerEyeStyle, setInnerEyeStyle] = useState("square");
    const [outerEyeStyle, setOuterEyeStyle] = useState("square");
    const [qrLink, setQrLink] = useState("");
    const [alert, handleAlert] = useAlert();

    const ref = useRef(null);

    useEffect(() => {
        async function getCurrentQR() {
            const { success, response } = await fetchRequest(
                `/qr/${identifier}`,
                {
                    method: "GET"
                }
            );

            if (success && response) {
                const {
                    color,
                    eye_radius,
                    font_color,
                    logo_width,
                    inner_eye_color,
                    outer_eye_color,
                    qr_style,
                    inner_eye_style,
                    outer_eye_style,
                    qrcode_id,
                    image
                } = response[0];

                const base64Data = await fetchImage(image);

                setQRStyle(qr_style === "squares" ? "square" : qr_style);
                setInnerEyeStyle(inner_eye_style || "square");
                setOuterEyeStyle(outer_eye_style || "square");
                setBgColor(color);
                setQrColor(font_color);
                setInnerColor(inner_eye_color || "#000000");
                setOuterColor(outer_eye_color || "#000000");
                setLogoSize(logo_width > 0.53 ? 0.3 : logo_width);
                setImage(base64Data);
                setCornerRadius(eye_radius);
                setQrLink(qrcode_id);
                setLoading(false);
            }
        }

        getCurrentQR();
    }, [identifier]);

    const theme = useTheme();

    const downloadCode = () => {
        qrCode.download({
            name: `${identifier}-qrcode`,
            extension: "png"
        });
        // const canvas = document.getElementById("qr-code");

        // if (canvas) {
        //     const pngUrl = canvas
        //         .toDataURL("image/png")
        //         .replace("image/png", "image/octet-stream");
        //     let downloadLink = document.createElement("a");
        //     downloadLink.href = pngUrl;
        //     downloadLink.download = `QR_${identifier}.png`;
        //     document.body.appendChild(downloadLink);
        //     downloadLink.click();
        //     document.body.removeChild(downloadLink);
        // }
    };

    const copyToClipboard = () => {
        if (window.isSecureContext) {
            navigator.clipboard.writeText(
                `${process.env.REACT_APP_QR_HANDLER_URL}/${qrLink}`
            );
            handleAlert({
                severity: "success",
                title: "Success",
                message: "QR link copied to clipboard"
            });
        } else {
            console.error(
                "Content can't be copied to the clipboard on unsecured environments"
            );
            handleAlert({
                severity: "error",
                title: "Error",
                message:
                    "Content can't be copied to the clipboard on unsecured environments"
            });
        }
    };

    useEffect(() => {
        qrCode.append(ref.current);
    }, []);

    useEffect(() => {
        const updateQRCode = async () => {
            const base64Data = await fetchImage(image);

            qrCode.update({
                data: `${process.env.REACT_APP_QR_HANDLER_URL}/${qrLink}`,
                qrOptions: {
                    typeNumber: 0,
                    mode: "Byte",
                    errorCorrectionLevel: "H"
                },
                imageOptions: {
                    hideBackgroundDots: true,
                    imageSize: logoSize
                },
                margin: 10,
                backgroundOptions: {
                    color: bgColor
                },
                dotsOptions: {
                    color: qrColor,
                    type: QRStyle
                },
                image: !deleteImage && logoSize > 0 && base64Data,
                cornersSquareOptions: {
                    color: outerColor,
                    type: outerEyeStyle
                },
                cornersDotOptions: {
                    color: innerColor,
                    type: innerEyeStyle
                }
            });
        };
        updateQRCode();
        setTimeout(() => {
            updateQRCode();
        }, 500);

        const saveQR = async () => {
            await fetchRequest(`/qr/${identifier}/${qrLink}`, {
                method: "PUT",
                body: JSON.stringify({
                    color: bgColor,
                    eye_radius: cornerRadius,
                    inner_eye_style: innerEyeStyle,
                    outer_eye_style: outerEyeStyle,
                    font_color: qrColor,
                    logo_width: logoSize,
                    qr_style: QRStyle,
                    image: image,
                    inner_eye_color: innerColor,
                    outer_eye_color: outerColor
                })
            });
        };

        const saveTimeout = setTimeout(() => {
            !loading && saveQR();
        }, 1200);
        return () => clearTimeout(saveTimeout);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        QRStyle,
        innerEyeStyle,
        outerEyeStyle,
        bgColor,
        qrColor,
        innerColor,
        outerColor,
        image,
        logoSize,
        cornerRadius,
        identifier,
        qrLink,
        loading
    ]);

    // useEffect(() => {
    //     console.log(logoHeight, logoSize, aspectRatio);

    //     if (
    //         (aspectRatio === 1 && qrSize * 0.35 < logoSize) ||
    //         (aspectRatio !== 1 &&
    //             (qrSize * 0.23 < logoHeight || qrSize * 0.61 < logoSize))
    //     ) {
    //         setScannable(true);
    //     } else {
    //         setScannable(false);
    //     }
    // }, [logoSize, logoHeight]);

    return (
        <>
            <Alert {...alert} />
            <Root
                className={classes.columnContainer}
                style={{ marginBottom: 10 }}
            >
                <div className={classes.column60}>
                    <div className="titleWrapper">
                        <h2 style={{ marginTop: 40 }}>Style Options</h2>
                        <hr style={{ margin: "10px 0 15px" }} />
                    </div>
                    <div className="flexBox g-30 eye-styling">
                        <div className="flex-1">
                            <TextField
                                select
                                fullWidth
                                value={QRStyle}
                                onChange={(e) => setQRStyle(e.target.value)}
                                label="QR Dot"
                                InputLabelProps={{ shrink: true }}
                                SelectProps={{
                                    MenuProps: {
                                        PaperProps: {
                                            sx: selectRow(theme)
                                        },
                                        classes: {
                                            paper: classes.selectRow
                                        }
                                    }
                                }}
                                className={`${classes.select} ${classes.textField}`}
                            >
                                <MenuItem value="square">Squares</MenuItem>
                                <MenuItem value="dots">Dots</MenuItem>
                                <MenuItem value="rounded">Rounded</MenuItem>
                                <MenuItem value="classy">Classy</MenuItem>
                                <MenuItem value="classy-rounded">
                                    Classy Rounded
                                </MenuItem>
                                <MenuItem value="extra-rounded">
                                    Extra Rounded
                                </MenuItem>
                            </TextField>
                        </div>
                        <div className="flex-1">
                            <TextField
                                select
                                fullWidth
                                value={outerEyeStyle}
                                onChange={(e) =>
                                    setOuterEyeStyle(e.target.value)
                                }
                                label="Corner Squares"
                                InputLabelProps={{ shrink: true }}
                                SelectProps={{
                                    MenuProps: {
                                        PaperProps: {
                                            sx: selectRow(theme)
                                        },
                                        classes: {
                                            paper: classes.selectRow
                                        }
                                    }
                                }}
                                className={`${classes.select} ${classes.textField}`}
                            >
                                <MenuItem value="square">Squares</MenuItem>
                                <MenuItem value="dots">Dots</MenuItem>
                                <MenuItem value="extra-rounded">
                                    Extra Rounded
                                </MenuItem>
                            </TextField>
                        </div>
                        <div className="flex-1">
                            <TextField
                                select
                                fullWidth
                                value={innerEyeStyle}
                                onChange={(e) =>
                                    setInnerEyeStyle(e.target.value)
                                }
                                label="Corner Dots"
                                InputLabelProps={{ shrink: true }}
                                SelectProps={{
                                    MenuProps: {
                                        PaperProps: {
                                            sx: selectRow(theme)
                                        },
                                        classes: {
                                            paper: classes.selectRow
                                        }
                                    }
                                }}
                                className={`${classes.select} ${classes.textField}`}
                            >
                                <MenuItem value="square">Squares</MenuItem>
                                <MenuItem value="dots">Dots</MenuItem>
                            </TextField>
                        </div>
                    </div>
                    <div className="colorLogoWrapper">
                        <div className="titleWrapper">
                            <h2 style={{ marginTop: 15 }}>Colors & Logo</h2>
                            <hr style={{ margin: "10px 0 30px" }} />
                        </div>
                        <Root className={classes.columnContainer}>
                            <div className={classes.column50}>
                                <ColorInput
                                    label="Background Color"
                                    value={bgColor}
                                    setValue={setBgColor}
                                />
                                <ColorInput
                                    label="Corner Square Color"
                                    value={innerColor}
                                    setValue={setInnerColor}
                                />
                            </div>
                            <div className={classes.column50}>
                                <ColorInput
                                    label="QR Dot Color"
                                    value={qrColor}
                                    setValue={setQrColor}
                                />
                                <ColorInput
                                    label="Corner Dot Color"
                                    value={outerColor}
                                    setValue={setOuterColor}
                                />
                            </div>
                        </Root>
                        <div className="logoWrapper">
                            <span className="logoTitle">Add Logo</span>
                            <ImageUploader
                                image={image}
                                setImage={setImage}
                                setDeleteImage={setDeleteImage}
                                setFile={setFile}
                                autoSave={true}
                            />
                        </div>
                        <Slider
                            label="Logo Size"
                            value={logoSize}
                            min={0}
                            max={0.53}
                            step={0.01}
                            setValue={setLogoSize}
                            customMarks={[]}
                        />
                    </div>
                </div>
                {/* {!loading && ( */}
                <div
                    className={classes.column40}
                    style={{
                        transition: "opacity 0.3s",
                        opacity: !loading ? 1 : 0
                    }}
                >
                    <span className={classes.centered}>
                        <div ref={ref} />
                    </span>
                    <span
                        className={classes.centered}
                        style={{ marginBottom: 0, marginTop: 20 }}
                    >
                        <Button
                            type="primary"
                            color="orange"
                            innerText="Download QR Code"
                            onClick={downloadCode}
                        />
                    </span>
                    <span className={classes.qrLink}>
                        <TextField
                            fullWidth
                            value={`${process.env.REACT_APP_QR_HANDLER_URL}/${qrLink}`}
                            disabled
                            label="QR Link"
                            InputLabelProps={{ shrink: true }}
                            className={`${classes.textField}`}
                        />
                        <CustomTooltip title="Copy Link">
                            <span
                                className={classes.iconCircle}
                                onClick={copyToClipboard}
                            >
                                <Icon name="copy" />
                            </span>
                        </CustomTooltip>
                    </span>
                    <h5 className={classes.info}>
                        <Icon name="info" />
                        <p>
                            Make sure to test the QR code before downloading, if
                            unable to scan the QR code, try adjusting your logo
                            size
                        </p>
                    </h5>
                </div>
                {/* )} */}
            </Root>
        </>
    );
};

export default QRCodeTab;
