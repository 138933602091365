import { createTheme } from "@mui/material/styles";

const mainTheme = createTheme({
    colors: {
        primary: "#CC5803",
        orange: {
            900: "#A34602",
            800: "#B84F03",
            700: "#CC5803",
            500: "#E06E1A",
            200: "#FAD3B8",
            100: "#FDECDF",
            50: "#FDF6F2"
        },
        grey: {
            900: "#222222",
            700: "#727272",
            600: "#5A5A5A",
            500: "#9E9E9E",
            400: "#B4B3B3",
            300: "#B4B3B3",
            200: "#D3D3D3",
            100: "#EAEAEA",
            50: "#FAFAFA"
        }
    },
    browser: {
        isSafari:
            navigator.userAgent.indexOf("Safari") > -1 &&
            !(navigator.userAgent.indexOf("Chrome") > -1)
    }
});

export default mainTheme;
