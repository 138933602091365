import React from "react";

function Accordion(props: { children: any; isExpanded?: boolean }) {
    const { isExpanded = false } = props;

    const contentStyle = {
        maxHeight: isExpanded ? "300px" : "0",
        opacity: isExpanded ? "1" : "0",
        overflow: "hidden",
        transition: "max-height 0.5s, opacity 0.5s"
    };

    return <div style={contentStyle}>{props.children}</div>;
}

export default Accordion;
