import ReactDOM from "react-dom/client";
import App from "./App";
import "./index.css";
import { CookiesProvider } from "react-cookie";
import Auth from "./context/store/Auth";
import Alerts from "./context/store/Alerts";
import { IntercomProvider } from "react-use-intercom";
import { ThemeProvider } from "@mui/material/styles";
import mainTheme from "./themes/MainTheme";
import packageJSON from "../package.json";
import { MyGlobalStyles } from "./assets/styles/App";

const root = ReactDOM.createRoot(
    document.getElementById("root") as HTMLElement
);

root.render(
    <Auth>
        <Alerts>
            <CookiesProvider>
                <IntercomProvider
                    appId={process.env.REACT_APP_INTERCOM_APP_ID || ""}
                >
                    <ThemeProvider theme={mainTheme}>
                        <MyGlobalStyles />
                        <App version={packageJSON.version} />
                    </ThemeProvider>
                </IntercomProvider>
            </CookiesProvider>
        </Alerts>
    </Auth>
);
