import { useState, useEffect, useContext, memo } from "react";
import { useNavigate } from "react-router-dom";

import { setCurrentRestaurant } from "../../../context/actions";
import useLocalStorage from "../../../hooks/useLocalStorage";

import AuthGlobal from "../../../context/store/global";
import { Grid } from "@mui/material";
import { Root, classes } from "../../../assets/styles/settings/SuperAdmin";
import { toggleStatus } from "../../../utils/Restaurants/restaurant-helper";
import CustomSwitch from "../../../components/UI/CustomSwitch";
import { client } from "../../../utils/apiClient";

const SuperAdmin = ({ dispatch, user, searchTerm }) => {
    const {
        globalState: { current_restaurant }
    } = useContext(AuthGlobal);

    const { getItem } = useLocalStorage();
    const [data, setData] = useState([]);
    const localIdentifier = parseInt(getItem("sm_a_i"));
    const navigate = useNavigate();

    useEffect(() => {
        setData(
            user?.user_permissions?.map((item, index) => {
                return {
                    identifier: item.identifier.identifier,
                    company_name: item.identifier.company_name,
                    display_name: item.identifier.display_name,
                    outdated: item.identifier.outdated,
                    all_day_menu: item.identifier.all_day_menu,
                    status: item.identifier.status,
                    index
                };
            })
        );
    }, [user]);

    const checkLocalIdentifier = (item) => {
        if (localIdentifier) {
            return (
                user?.user_permissions[localIdentifier].identifier
                    .identifier === item.identifier
            );
        } else {
            return current_restaurant.identifier === item.identifier;
        }
    };

    const toggleRestaurantStatus = async (identifier) => {
        const { success, response } = await toggleStatus(identifier);
        if (success && response) {
            const newData = [...data];
            const idx = newData.findIndex((x) => x.identifier === identifier);
            newData[idx] = {
                ...newData[idx],
                status: newData[idx].status === "active" ? "inactive" : "active"
            };
            setData(newData);
            setTimeout(() => {
                window.location.reload();
            }, 1500);
        }
    };

    return (
        <Grid container style={{ margin: "12px 0" }}>
            {data
                .filter((x) =>
                    x.display_name.match(new RegExp(searchTerm, "i"))
                )
                .map((item, index) => (
                    <Grid item xs={4} key={index}>
                        <Root
                            className={`${classes.card} ${
                                checkLocalIdentifier(item)
                                    ? classes.selected
                                    : ""
                            }`}
                            onClick={(e) => {
                                if (
                                    !checkLocalIdentifier(item) &&
                                    e.target.tagName !== "LABEL" &&
                                    e.target.tagName !== "INPUT" &&
                                    e.target.tagName !== "SPAN"
                                ) {
                                    console.log(
                                        user.user_permissions[item.index]
                                            .identifier.identifier
                                    );
                                    dispatch(
                                        setCurrentRestaurant(
                                            user.user_permissions[item.index]
                                                .identifier
                                        )
                                    );

                                    client(
                                        `user/${user._id}/switch-restaurant`,
                                        {
                                            identifier:
                                                user.user_permissions[
                                                    item.index
                                                ].identifier.identifier
                                        },
                                        "PUT"
                                    );

                                    navigate("/");
                                }
                            }}
                        >
                            {checkLocalIdentifier(item) && (
                                <div className={classes.currentBadge}>
                                    Current
                                </div>
                            )}
                            <p className={classes.restName}>
                                {item.display_name}
                            </p>
                            <hr className={classes.hr} />
                            <CustomSwitch
                                checked={item.status === "active"}
                                onChange={(e) => {
                                    toggleRestaurantStatus(item.identifier);
                                }}
                                style={{ marginTop: 6 }}
                            />
                        </Root>
                    </Grid>
                ))}
        </Grid>
    );
};
export default memo(SuperAdmin);
