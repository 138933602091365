import { useState } from "react";
import { TableRow } from "@mui/material";
import Icon from "../../../components/UI/Icon";
import { Root, classes } from "../../../assets/styles/pages/menus/tabs/Active";
import NoResults from "../../../components/NoResults";
import ConfirmAction from "../../../components/modals/ConfirmAction";
import CustomTooltip from "../../../components/UI/CustomTooltip";
import CustomPagination from "../../../components/UI/CustomPagination";
import Button from "../../../components/UI/Button";

const Row = ({ data }) => {
    const inputDate = new Date(data.updated_at);
    const month = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December"
    ][inputDate.getMonth()];
    const formattedDate =
        month + " " + inputDate.getDate() + ", " + inputDate.getFullYear();

    return (
        <TableRow
            className={`${classes.menuListTableRow} ${
                data.index % 2 === 0 ? classes.color1 : classes.color2
            }`}
        >
            <td className={classes.name}>
                {data.internal_name}{" "}
                <span className={classes.deletedOn}>
                    (deleted on: {formattedDate})
                </span>
            </td>
            {!data.hideActions && (
                <td className={classes.actions}>
                    <CustomTooltip title="Delete Menu">
                        <span className={classes.iconContainer}>
                            <Icon
                                onClick={() =>
                                    data.showDeleteModal(
                                        data._id,
                                        data.internal_name
                                    )
                                }
                                name="trash"
                            />
                        </span>
                    </CustomTooltip>
                    <Button
                        type="primary"
                        color="dark"
                        innerText="Recover"
                        className={classes.recoverMenu}
                        onClick={() =>
                            data.showMarkAsDraftModal(
                                data._id,
                                data.internal_name
                            )
                        }
                    />
                </td>
            )}
        </TableRow>
    );
};

const TrashTab = ({ data, markAsDraft, deleteMenuItem, setPagination }) => {
    const [deleteModalVisible, setDeleteModalVisible] = useState(false);
    const [deleteItem, setDeleteItem] = useState();
    const [markAsDraftModalVisible, setMarkAsDraftModalVisible] =
        useState(false);
    const [itemName, setItemName] = useState("");
    const [markAsDraftItem, setMarkAsDraftItem] = useState();
    const [page, setPage] = useState(1);
    const rowsPerPage = setPagination ? 10 : data.length;

    const showMarkAsDraftModal = (record, name) => {
        setItemName(name);
        setMarkAsDraftModalVisible(true);
        setMarkAsDraftItem(record);
    };

    const showDeleteModal = (id, name) => {
        setItemName(name);
        setDeleteModalVisible(true);
        setDeleteItem(id);
    };

    const okDeleteClick = () => {
        setDeleteModalVisible(false);
        deleteMenuItem(deleteItem);
    };

    const okMarkAsDraftClick = () => {
        setMarkAsDraftModalVisible(false);
        markAsDraft(markAsDraftItem);
    };

    return (
        <>
            {data.length > 0 ? (
                <Root
                    style={{
                        marginTop: 15,
                        display: "block",
                        minHeight: "calc(100vh - 335px)",
                        overflowY: "auto"
                    }}
                >
                    <tbody>
                        {data
                            .slice((page - 1) * rowsPerPage, page * rowsPerPage)
                            .map((row, index) => {
                                return (
                                    <Row
                                        index={index}
                                        key={index}
                                        data={{
                                            ...row,
                                            index,
                                            showDeleteModal,
                                            showMarkAsDraftModal
                                        }}
                                    ></Row>
                                );
                            })}
                    </tbody>
                </Root>
            ) : (
                <NoResults text={"No Menus Found"} />
            )}

            {setPagination && (
                <CustomPagination
                    totalRows={data.length}
                    rowsPerPage={rowsPerPage}
                    handlePageChange={setPage}
                />
            )}

            <ConfirmAction
                open={markAsDraftModalVisible}
                actionConfirm={okMarkAsDraftClick}
                actionCancel={() => setMarkAsDraftModalVisible(false)}
                icon="restore"
                dialogText={`Are you sure want to recover the ${itemName} menu?`}
            />

            <ConfirmAction
                open={deleteModalVisible}
                actionConfirm={okDeleteClick}
                actionCancel={() => setDeleteModalVisible(false)}
                icon="trash"
                dialogText={`Are you sure want to permanently delete the ${itemName} menu? It will be gone for good.`}
            />
        </>
    );
};

export default TrashTab;
