import { useContext, useState } from "react";
import Icon from "../../components/UI/Icon";
import { TableRow, TableBody } from "@mui/material";
import NoResults from "../../components/NoResults";
import { Root, classes } from "../../assets/styles/pages/menus/tabs/Active";
import CustomTooltip from "../../components/UI/CustomTooltip";
import CustomPagination from "../../components/UI/CustomPagination";
import ConfirmAction from "../../components/modals/ConfirmAction";
import AuthContext from "../../context/store/global";

import {
    SortableContainer,
    SortableElement,
    SortableHandle
} from "react-sortable-hoc";

import { arrayMoveImmutable } from "array-move";
import FormScheduleCollection from "./FormScheduleCollection";
import { refreshRestaurant } from "../../utils/Restaurants/restaurant-helper";
import { format24hto12h } from "../../utils/timefunctions";
import { useNavigate } from "react-router-dom";

const DragHandle = SortableHandle(() => {
    // const classes = useStyles();
    return (
        <CustomTooltip title="Drag to Order">
            <span className={classes.dragIconContainer}>
                <Icon name={"drag"} />{" "}
            </span>
        </CustomTooltip>
    );
});

const TableBodySortable = SortableContainer(({ children }) => (
    <TableBody className="sortable-tbody-collection">{children}</TableBody>
));

const Actions = ({
    status,
    data: {
        showMarkAsActiveModal,
        showEditItemModal,
        showDuplicateModal,
        showArchiveModal,
        showDeleteModal,
        showRecoverModal,
        showPermanentDeleteModal,
        ...data
    }
}) => {
    // const classes = useStyles();
    const navigate = useNavigate();
    return (
        <td className={classes.actions}>
            {status === "inactive" && (
                <CustomTooltip title="Move to Active">
                    <span className={classes.iconContainer}>
                        <Icon
                            name="refresh"
                            onClick={() =>
                                showMarkAsActiveModal(
                                    data._id,
                                    data.internal_name
                                )
                            }
                        />
                    </span>
                </CustomTooltip>
            )}
            {(status === "active" || status === "inactive") && (
                <CustomTooltip title="Edit service period">
                    <span className={classes.iconContainer}>
                        <Icon
                            name="edit"
                            onClick={() =>
                                navigate(`/service-periods/${data._id}/update`)
                            }
                        />
                    </span>
                </CustomTooltip>
            )}
            {(status === "active" || status === "inactive") && (
                <CustomTooltip title="Duplicate service period">
                    <span className={classes.iconContainer}>
                        <Icon
                            name="duplicate"
                            onClick={() =>
                                showDuplicateModal(data._id, data.internal_name)
                            }
                        />
                    </span>
                </CustomTooltip>
            )}
            {status === "active" && (
                <CustomTooltip title="Archive service period">
                    <span className={classes.iconContainer}>
                        <Icon
                            name="archive"
                            onClick={() =>
                                showArchiveModal(data._id, data.internal_name)
                            }
                        />
                    </span>
                </CustomTooltip>
            )}
            {status === "trash" && (
                <CustomTooltip title="Recover service period">
                    <span className={classes.iconContainer}>
                        <Icon
                            name="restore"
                            onClick={() =>
                                showRecoverModal(data._id, data.internal_name)
                            }
                        />
                    </span>
                </CustomTooltip>
            )}
            {["active", "inactive", "trash"].includes(status) && (
                <CustomTooltip title="Delete service period">
                    <span className={classes.iconContainer}>
                        <Icon
                            name="trash"
                            onClick={() => {
                                if (status !== "trash") {
                                    showDeleteModal(
                                        data._id,
                                        data.internal_name
                                    );
                                } else {
                                    showPermanentDeleteModal(
                                        data._id,
                                        data.internal_name
                                    );
                                }
                            }}
                        />
                    </span>
                </CustomTooltip>
            )}
        </td>
    );
};

const printDays = (days) => {
    const daysOfTheWeek = [
        "Sunday",
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday"
    ];
    if (days?.length === 1) {
        return daysOfTheWeek[days[0]];
    }
    return (days || [])
        .sort((a, b) => {
            if (parseInt(a) === 0) return 1;
            if (parseInt(b) === 0) return -1;
            return a - b;
        })
        .map((x) => daysOfTheWeek[x].slice(0, 3))
        .join(", ");
};

const Row = SortableElement(({ data: { status, ...data } }) => {
    const {
        globalState: {
            current_restaurant: { use_schedules, default_collection }
        }
    } = useContext(AuthContext);

    return (
        <TableRow
            className={`${classes.menuListTableRow} ${
                data.index % 2 === 0 ? classes.color1 : classes.color2
            }`}
        >
            <td className={classes.name}>
                <span className={classes.itemName}>{data.internal_name}</span>
                {data._id === default_collection && (
                    <span className={classes.defaultIndicator}>(Default)</span>
                )}
            </td>
            <td>
                <div className={classes.timeInfo} style={{ width: 250 }}>
                    {use_schedules &&
                        (data.schedule?.mode === "unscheduled"
                            ? "Unscheduled"
                            : data.schedule?.mode === "everyday"
                            ? "Everyday"
                            : printDays(data.schedule?.days))}
                </div>
            </td>
            <td>
                {use_schedules && data.schedule?.mode !== "unscheduled" && (
                    <div className={classes.timeInfo}>
                        {format24hto12h(data.schedule?.start)} -{" "}
                        {format24hto12h(data.schedule?.end)}
                    </div>
                )}
            </td>
            {!data.hideActions && <Actions status={status} data={data} />}
            {data.draggable && !use_schedules && (
                <td className={classes.dragSection}>
                    <DragHandle />
                </td>
            )}
            {use_schedules && (
                <td className={classes.clockButton}>
                    <CustomTooltip title="Edit Schedule">
                        <span
                            className={classes.dragIconContainer}
                            onClick={() =>
                                data.handleOpenSchedule({ status, ...data })
                            }
                        >
                            <Icon name="clock" />
                        </span>
                    </CustomTooltip>
                </td>
            )}
        </TableRow>
    );
});

const ActiveTab = ({
    data,
    setData = () => {},
    duplicateElementItem,
    setPagination,
    markAsTrash,
    markAsActive,
    markAsInactive,
    deleteElementItem,
    status = "active",
    hideActions = null,
    draggable = false,
    schedules = [],
    setFirstLoad = () => {},
    refresh = () => {}
}) => {
    const {
        dispatch,
        globalState: {
            current_restaurant: { identifier },
            current_restaurant
        }
    } = useContext(AuthContext);
    const [modalVisible, setModalVisible] = useState("");
    const [itemName, setItemName] = useState("");
    const [selectedItem, setSelectedItem] = useState("");
    const [page, setPage] = useState(1);
    const [currentConfig, setCurrentConfig] = useState({});
    const [showScheduleForm, setShowScheduleForm] = useState(false);
    const [selectedCollectionName, setSelectedCollectionName] = useState("");

    const rowsPerPage = setPagination ? 10 : data.length;

    // const classes = useStyles();
    const onSortEnd = (arr) => {
        setFirstLoad(false);
        setData((prev) => arrayMoveImmutable(prev, arr.oldIndex, arr.newIndex));
    };

    const showDuplicateModal = (id, name) => {
        setItemName(name);
        setModalVisible("duplicate");
        setSelectedItem(id);
    };

    const showMarkAsActiveModal = (id, name) => {
        setItemName(name);
        setModalVisible("active");
        setSelectedItem(id);
    };

    const showArchiveModal = (id, name) => {
        setItemName(name);
        setModalVisible("archive");
        setSelectedItem(id);
    };

    const showDeleteModal = (id, name) => {
        setItemName(name);
        setModalVisible("trash");
        setSelectedItem(id);
    };

    const showRecoverModal = (id, name) => {
        setItemName(name);
        setModalVisible("recover");
        setSelectedItem(id);
    };

    const showPermanentDeleteModal = (id, name) => {
        setItemName(name);
        setModalVisible("delete");
        setSelectedItem(id);
    };

    const showEditItemModal = (record) => {
        setItemName(record.internal_name);
        setModalVisible("edit");
        setSelectedItem(record);
    };

    const handleOpenSchedule = (record) => {
        setCurrentConfig(
            schedules.find((x) => x.collection_id === record._id) || {}
        );
        setSelectedCollectionName(record.internal_name);

        setSelectedItem(record);
        setShowScheduleForm(true);
    };

    const handleCloseSchedule = async () => {
        await refreshRestaurant(dispatch, identifier, current_restaurant);
        refresh();
        setSelectedItem({});
        setSelectedCollectionName("");
        setCurrentConfig({});
        setShowScheduleForm(false);
    };

    const okDeleteClick = () => {
        setModalVisible("");
        markAsTrash(selectedItem);
    };

    const okArchiveClick = () => {
        setModalVisible("");
        markAsInactive(selectedItem);
    };

    const okDuplicateClick = (newName) => {
        setModalVisible("");
        duplicateElementItem(selectedItem, newName);
        setItemName("");
    };

    const okMarkAsActiveClick = () => {
        setModalVisible("");
        markAsActive(selectedItem);
    };

    const okDeletePermanent = () => {
        setModalVisible("");
        deleteElementItem(selectedItem);
    };

    return (
        <>
            {data.length > 0 ? (
                <Root
                    style={{
                        marginTop: 15,
                        display: "block",
                        minHeight: "calc(100vh - 335px)",
                        overflowY: "auto"
                    }}
                >
                    <TableBodySortable
                        onSortEnd={onSortEnd}
                        useDragHandle
                        style={{ width: "100%" }}
                        helperClass={classes.sorting}
                        lockAxis="y"
                        helperContainer={() =>
                            document.querySelector(".sortable-tbody-collection")
                        }
                    >
                        {data
                            .slice((page - 1) * rowsPerPage, page * rowsPerPage)
                            .map((row, index) => {
                                return (
                                    <Row
                                        index={index}
                                        key={index}
                                        data={{
                                            ...row,
                                            index,
                                            showMarkAsActiveModal,
                                            showEditItemModal,
                                            showDuplicateModal,
                                            showArchiveModal,
                                            showDeleteModal,
                                            showRecoverModal,
                                            showPermanentDeleteModal,
                                            handleOpenSchedule,
                                            schedule: schedules.find(
                                                (x) =>
                                                    x.collection_id === row._id
                                            ),
                                            status,
                                            hideActions,
                                            draggable
                                        }}
                                    ></Row>
                                );
                            })}
                    </TableBodySortable>
                </Root>
            ) : (
                <NoResults text="No periods found" />
            )}

            {setPagination && (
                <CustomPagination
                    totalRows={data.length}
                    rowsPerPage={rowsPerPage}
                    handlePageChange={setPage}
                />
            )}

            <ConfirmAction
                open={modalVisible === "trash"}
                icon="trash"
                actionCancel={() => setModalVisible("")}
                actionConfirm={okDeleteClick}
                dialogText={`Are you sure want to delete the ${itemName} Service Period?`}
            />

            <ConfirmAction
                open={modalVisible === "active"}
                icon="refresh"
                actionCancel={() => setModalVisible("")}
                actionConfirm={okMarkAsActiveClick}
                dialogText={`Are you sure want to move the ${itemName} Service Period to active?`}
            />

            <ConfirmAction
                open={modalVisible === "recover"}
                icon="restore"
                actionCancel={() => setModalVisible("")}
                actionConfirm={okArchiveClick}
                dialogText={`Are you sure want to recover the ${itemName} Service Period?`}
            />

            {itemName && (
                <ConfirmAction
                    open={modalVisible === "duplicate"}
                    icon="duplicate"
                    actionCancel={() => {
                        setItemName("");
                        setModalVisible("");
                    }}
                    actionConfirm={okDuplicateClick}
                    dialogText={`Are you sure want to duplicate the ${itemName} Service Period?`}
                    params={[
                        {
                            label: "New Service Period Name",
                            value: `Copy of ${itemName}`,
                            description: "Change the Service Period Name:"
                        }
                    ]}
                />
            )}

            <ConfirmAction
                open={modalVisible === "archive"}
                icon="archive"
                actionCancel={() => setModalVisible("")}
                actionConfirm={okArchiveClick}
                dialogText={`Are you sure want to archive the ${itemName} Service Period?`}
            />

            <ConfirmAction
                open={modalVisible === "delete"}
                icon="trash"
                actionCancel={() => setModalVisible("")}
                actionConfirm={okDeletePermanent}
                dialogText={`Are you sure want to permanently delete the ${itemName} Service Period? It will be gone for good.`}
            />

            <FormScheduleCollection
                show={showScheduleForm}
                handleClose={handleCloseSchedule}
                scheduleData={currentConfig}
                collectionName={selectedCollectionName}
            />
        </>
    );
};

export default ActiveTab;
