import {
    FormControlLabel,
    Modal,
    Slide,
    Switch,
    TextField
} from "@mui/material";
import { useEffect, useState } from "react";
import { useIntercom } from "react-use-intercom";
import {
    Root,
    classes
} from "../../assets/styles/components/MenuSettingsDrawer";
import Alert from "../../components/UI/Alert";
import Button from "../../components/UI/Button";
import Icon from "../../components/UI/Icon";
import { useAlert } from "../../hooks/useAlert";

const MenuSettingsDrawer = ({ open, handleClose, values, setValues }) => {
    const [show_notations, setShowNotations] = useState(values.show_notations);
    const [show_logo, setShowLogo] = useState(values.show_logo);
    const [header_text, setHeaderText] = useState(values.header_text);
    const [footer_text, setFooterText] = useState(values.footer_text);

    useEffect(() => {
        setShowNotations(values.show_notations);
        setShowLogo(values.show_logo);
        setHeaderText(values.header_text);
        setFooterText(values.footer_text);
    }, [values]);

    const { update } = useIntercom();
    useEffect(() => {
        update({
            alignment: open ? "left" : "right"
        });
    }, [open, update]);

    const [alert, handleAlert] = useAlert();
    const saveSettings = () => {
        setValues({
            show_notations,
            show_logo,
            header_text,
            footer_text
        });
        handleAlert({
            severity: "success",
            title: "Success",
            message: "The settings were successfully updated"
        });
        handleClose();
    };

    return (
        <>
            <Alert {...alert} />
            <Modal open={open} onClose={handleClose} disablePortal>
                <Slide in={open} direction="left">
                    <Root className={classes.container}>
                        <div className={classes.top}>
                            <span className={classes.sectionClose}>
                                <span
                                    className={classes.closeButtonContainer}
                                    onClick={handleClose}
                                >
                                    <Icon
                                        name="clear"
                                        className={classes.closeButton}
                                    />
                                </span>
                            </span>
                            <h1 className={classes.title}>Menu Settings</h1>
                        </div>
                        <div className={classes.form}>
                            <p className={classes.toggleLabel}>
                                Show Dietary/Allergy Notations
                            </p>
                            <FormControlLabel
                                control={
                                    <Switch
                                        checked={show_notations}
                                        classes={{
                                            switchBase: classes.switchDisabled
                                        }}
                                        className={
                                            show_notations ? classes.switch : ""
                                        }
                                    />
                                }
                                label={show_notations ? "Active" : "Inactive"}
                                onChange={() => setShowNotations((val) => !val)}
                                className={classes.switchLabel}
                            />
                            <TextField
                                multiline
                                fullWidth
                                label="Header Text"
                                placeholder="This text that will display above your menu."
                                className={`${classes.textField} ${classes.multiline}`}
                                InputLabelProps={{ shrink: true }}
                                value={header_text}
                                onChange={(e) => setHeaderText(e.target.value)}
                            />
                            <TextField
                                multiline
                                fullWidth
                                label="Footer Text"
                                placeholder="This text that will display below yout menu."
                                className={`${classes.textField} ${classes.multiline}`}
                                InputLabelProps={{ shrink: true }}
                                value={footer_text}
                                onChange={(e) => setFooterText(e.target.value)}
                            />
                        </div>
                        <div className={classes.actions}>
                            <Button
                                type="primary"
                                color="orange"
                                innerText="Save"
                                onClick={saveSettings}
                            />
                            <Button
                                type="secondary"
                                color="dark"
                                innerText="Cancel"
                                onClick={handleClose}
                            />
                        </div>
                    </Root>
                </Slide>
            </Modal>
        </>
    );
};

export default MenuSettingsDrawer;
