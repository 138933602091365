import { styled } from "@mui/material/styles";

const classes = {
    container: `container`,
    header: `header`,
    title: `title`,
    textField: `textField`,
    switchLabel: `switchLabel`,
    switch: `switch`,
    switchDisabled: `switchDisabled`,
    toggleLabel: `toggleLabel`,
    boxLabel: `boxLabel`,
    sectionsContainer: `sectionsContainer`,
    addSectionButton: `addSectionButton`,
    dragSection: `dragSection`,
    iconContainer: `iconContainer`,
    sorting: `sorting`,
    dragIconContainer: `dragIconContainer`,
    iconCircle: `iconCircle`,
    infoMessage: `infoMessage`
};
const InfoMessage = styled("div")(({ theme }) => ({
    [`&.${classes.infoMessage}`]: {
        marginBottom: 16,
        "& p": {
            fontSize: "14px",
            fontStyle: "italic",
            lineHeight: "16px"
        }
    }
}));

const BoxLabel = styled("p")(({ theme }) => ({
    [`&.${classes.boxLabel}`]: {
        margin: "12px 48px",
        fontWeight: 500,
        fontSize: 16,
        color: theme.colors.grey[900]
    }
}));
const SectionsContainer = styled("div")(({ theme }) => ({
    [`&.${classes.sectionsContainer}`]: {
        width: "calc(100% - 96px)",
        minHeight: 200,
        flexGrow: 1,
        padding: 24,
        background: "#FAFAFA80",
        border: "1px solid #EAEAEA",
        margin: "0px 48px 48px 48px",
        borderRadius: 10
    },
    [`& .${classes.addSectionButton}`]: {
        marginTop: 10,
        marginLeft: 16,
        fontWeight: 400,
        fontStyle: "italic",
        fontSize: "14px",
        cursor: "pointer",
        width: "fit-content",
        display: "flex",
        alignItems: "center",
        "& svg": {
            width: 20,
            height: 20,
            padding: 6,
            marginRight: 10,
            borderRadius: "50%",
            backgroundColor: theme.colors.orange[700]
        }
    },
    [`& .${classes.sorting}`]: {
        border: `1px solid ${theme.colors.grey[400]}`,
        boxShadow: "0px 5px 12px rgba(34, 34, 34, 0.15)",
        display: "flex !important",
        background: "white",
        padding: "2px 8px"
    }
}));
const Root = styled("div")(({ theme }) => ({
    [`&.${classes.container}`]: {
        position: "relative"
    },
    [`& .${classes.header}`]: {
        display: "flex",
        position: "relative",
        "& > div": {
            display: "flex",
            flexDirection: "row",
            justifyContent: "right",
            alignItems: "center",
            flexGrow: 1,
            float: "right",
            height: 45,
            "& > .input-container": {
                flexGrow: 1,
                height: 36,
                paddingLeft: 40
            },
            "& > span": {
                paddingLeft: 15,
                height: 36
            }
        },
        margin: "62px 48px 25px 48px",
        height: 45
    },
    [`& .${classes.title}`]: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        lineHeight: "28px",
        fontSize: "24px",
        fontWeight: 500,
        margin: "0"
    },
    [`& .${classes.textField}`]: {
        lineHeight: "44px !important",
        // border: "1px solid #B4B3B3",
        "& div.Mui-focused": {
            "& fieldset": {
                borderColor: `${theme.colors.orange[700]} !important`
            }
        },
        "& label.Mui-focused": {
            color: `${theme.colors.orange[700]} !important`
        },
        "& fieldset": {
            borderRadius: "50px",
            "& legend": {
                margin: "4px 0px 4px 2px"
            }
        },
        "& input": {
            padding: "12px 18px !important"
        },
        "& input, div": {
            fontSize: 14
        },
        "& label": {
            transition: "all 100ms linear !important"
        },
        "& label[data-shrink=true].Mui-focused": {
            color: `${theme.colors.orange[700]} !important`
        },
        "& label[data-shrink=true]": {
            padding: `4px${theme.browser.isSafari ? " 10px" : " 8px"}`,
            color: `${theme.colors.grey[900]} !important`,
            background: "white",
            fontWeight: 500,
            fontSize: "16px",
            top: -3,
            "& .MuiFormLabel-asterisk": {
                color: theme.colors.orange[700]
            }
        },
        "& label[data-shrink=false]": {
            paddingLeft: 7
        }
    },
    [`& .${classes.switchLabel}`]: {
        "& .MuiFormControlLabel-label": {
            fontSize: 14,
            lineHeight: "16px",
            color: theme.colors.grey[500]
        },
        "&:hover .MuiFormControlLabel-label": {
            color: theme.colors.grey[900]
        }
    },
    [`& .${classes.switch}`]: {
        "& .MuiSwitch-switchBase": {
            color: `${theme.colors.orange[700]} !important`,
            "&.Mui-checked:hover": {
                backgroundColor: "rgba(250, 211, 184, 0.15) !important"
            }
        },
        "& .MuiSwitch-track": {
            backgroundColor: `${theme.colors.orange[200]} !important`
        }
    },
    [`& .${classes.switchDisabled}`]: {
        "&:hover": {
            backgroundColor: "rgba(250, 250, 250, 0.35) !important"
        }
    },
    [`& .${classes.toggleLabel}`]: {
        padding: "0",
        margin: "0",
        fontSize: 12,
        fontWeight: 500,
        lineHeight: "14px"
    },
    [`& .${classes.dragSection}`]: {
        width: "5%",
        height: "auto"
    },
    [`& .${classes.iconContainer}`]: {
        transform: "translateY(2px)"
    },
    [`& .${classes.dragIconContainer}`]: {
        cursor: "grab",
        "&:hover": {
            "& > svg > path": {
                fill: theme.colors.grey[900]
            }
        },
        "& svg": {
            transform: "translateY(2px)"
        }
    },
    [`& .${classes.iconCircle}`]: {
        width: "36px",
        height: "36px",
        borderRadius: "12px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        background: theme.colors.orange[100],
        cursor: "pointer",
        marginRight: "20px",
        alignSelf: "center"
    }
}));

export { Root, classes, BoxLabel, SectionsContainer, InfoMessage };
