import { TableRow } from "@mui/material";
import { styled } from "@mui/material/styles";

const classes = {
    container: `container`,
    itemRow: `itemRow`,
    arrowDownButton: `arrowDownButton`,
    arrowRightButton: `arrowRightButton`,
    lessRadioBorder: `lessRadioBorder`,
    buttonContainer: `buttonContainer`
};

const CollapseRoot = styled(TableRow)(({ theme }) => ({
    [`&.${classes.container}`]: {
        background: "white",
        width: "100%",
        border: `1px solid ${theme.colors.grey[100]}`,
        marginBottom: 12,
        borderRadius: "6px",
        display: "flex",
        flexDirection: "column",
        transition: "box-shadow 0.3s, border 0.3s",
        "&:hover": {
            boxShadow: "0px 5px 12px 0px #9D9D9D26",
            borderColor: `${theme.colors.grey[200]}`
        },
        "&.active": {
            borderColor: `${theme.colors.grey[200]}`,
            boxShadow: "0px 5px 6px 0px #9D9D9D1F inset"
        }
    },
    [`& .${classes.itemRow}`]: {
        display: "flex !important",
        alignItems: "center",
        marginTop: 16,
        borderRadius: 6,
        "&:hover": {
            background: theme.colors.grey[50],
            "& td:nth-of-type(1)": {
                opacity: 1,
                transform: "translateY(2px)"
            }
        }
    },
    [`& .${classes.lessRadioBorder}`]: {
        margin: "0 !important",
        "& fieldset": {
            borderRadius: "20px",
            borderColor: `${theme.colors.grey[200]}`
        }
    },
    [`& .${classes.arrowDownButton}`]: {
        margin: "0px 12px 0 20px",
        width: 26,
        height: 26,
        padding: "0px 8px",
        borderRadius: "50%",
        background: theme.colors.orange[50],
        "& path": {
            fill: theme.colors.orange[700]
        },
        "&:hover": {
            background: `${theme.colors.orange[200]}`
        }
    },
    [`& .${classes.arrowRightButton}`]: {
        margin: "0px 12px 0 20px",
        width: 26,
        height: 26,
        padding: "0px 10px",
        borderRadius: "50%",
        "&:hover": {
            background: `${theme.colors.grey[100]}80`
        }
    },
    [`& .${classes.buttonContainer}`]: {
        "&.columnsContainer": {
            marginBottom: 0,
            columnGap: "24px",
            "& .column > div": {
                margin: "0 !important"
            }
        }
    }
}));
export { CollapseRoot, classes };
