import { useContext, useState } from "react";
import AuthGlobal from "../../context/store/global";
import { Root, classes } from "../../assets/styles/PublishHeader";
import Icon from "./Icon";
import { useAlert } from "../../hooks/useAlert";
import { runPublishCompiler } from "../../utils/Menus/menu-helper";
import ConfirmAction from "../modals/ConfirmAction";
import { refreshRestaurant } from "../../utils/Restaurants/restaurant-helper";
import Alert from "./Alert";

const PublishHeader = () => {
    const {
        dispatch,
        globalState: {
            current_restaurant: { identifier, outdated },
            current_restaurant
        }
    } = useContext(AuthGlobal);
    const [hidePublishBanner, setHidePublishBanner] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [alert, handleAlert] = useAlert();
    const [disableBtn, setDisableBtn] = useState(false);

    const publish = async () => {
        setDisableBtn(true);
        const { success, response } = await runPublishCompiler(identifier);
        if (success && response) {
            await refreshRestaurant(dispatch, identifier, current_restaurant);
            handleAlert({
                severity: "success",
                title: "Success",
                message: response.message
            });
        } else {
            handleAlert({
                severity: "error",
                title: "Error",
                message: response.message
            });
        }
        setDisableBtn(false);
        setShowModal(false);
    };

    return (
        <>
            <Alert {...alert} />
            <Root
                className={classes.banner}
                style={
                    !hidePublishBanner && outdated
                        ? { opacity: 1 }
                        : { opacity: 0 }
                }
            >
                <span className={classes.publishMessage}>
                    <Icon name="error" />
                    <span>
                        You have made an update to your Active Menus. Would you
                        like to publish these changes to your live menu?
                        <span
                            className={classes.inlinePublish}
                            onClick={() => setShowModal(true)}
                        >
                            Publish
                        </span>
                    </span>
                </span>
                <span
                    className={classes.dismissBanner}
                    onClick={() => setHidePublishBanner(true)}
                >
                    <Icon name="clear" />
                </span>
            </Root>
            <ConfirmAction
                open={showModal}
                actionConfirm={publish}
                actionCancel={() => setShowModal(false)}
                icon="publish"
                dialogText={`Do you want to publish the changes in your menu?`}
                disableBtn={disableBtn}
            />
        </>
    );
};

export default PublishHeader;
