import { Slide, Snackbar } from "@mui/material";
import { useCallback } from "react";

import { Root, classes } from "../../assets/styles/Alert";
import Icon from "./Icon";

const Alert = ({ open, severity = "info", title, message, onClose }) => {
    const iconName =
        severity === "success"
            ? "check"
            : severity === "error"
            ? "error"
            : "info";
    return (
        <Snackbar
            style={{ zIndex: 2147483005 }}
            anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
            open={open}
            TransitionComponent={useCallback(
                (props) => (
                    <Slide {...props} direction="right" />
                ),
                []
            )}
        >
            <Root className={`${classes.alertContainer}`}>
                <Icon className={classes[severity]} name={iconName} />
                <div className={classes.content}>
                    <p className={classes.title}>{title}</p>
                    <p
                        className={classes.message}
                        dangerouslySetInnerHTML={{
                            __html: message
                        }}
                    />
                </div>
                <div className={classes.closeButtonContainer}>
                    <Icon
                        onClick={onClose}
                        name="clear"
                        className={classes.closeButton}
                    />
                </div>
            </Root>
        </Snackbar>
    );
};

export default Alert;
