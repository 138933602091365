import { useNavigate } from "react-router-dom";
import Icon from "./Icon";
import { Root, classes } from "../../assets/styles/components/NavigationButton";
import AuthGlobal from "../../context/store/global";
import { useContext } from "react";
import { setLocation } from "../../context/actions";
const NavigationButton = ({
    to,
    icon,
    isActive,
    label,
    hidden = false,
    decorator = null,
    onClick = () => {}
}) => {
    const { dispatch } = useContext(AuthGlobal);
    const navigate = useNavigate();

    const navigateToTarget = () => {
        if (to !== "") {
            dispatch(setLocation(to));
            navigate(to);
        }
    };

    return (
        <Root
            onClick={navigateToTarget}
            className={`${classes.navButton} ${isActive && classes.active} ${
                hidden && classes.hidden
            }`}
        >
            <div style={{ width: "100%" }} onClick={onClick}>
                <Icon name={icon} className={classes.icon} />{" "}
                <span className={classes.label}>
                    <span>{label}</span>
                    {decorator}
                </span>
            </div>
        </Root>
    );
};

export default NavigationButton;
