import { useReducer, useEffect, useState } from "react";
import authReducer from "../reducers";
import {
    setCurrentUser,
    setCurrentRestaurant,
    setCurrentUserType
} from "../actions";
import AuthGlobal from "./global";
import Cookie from "js-cookie";
import { client } from "../../utils/apiClient";
import useLocalStorage from "../../hooks/useLocalStorage";

const Auth = ({ children }) => {
    // eslint-disable-next-line no-unused-vars
    const [location, setLocation] = useState(window.location.pathname);
    const [globalState, dispatch] = useReducer(authReducer, {
        isAuthenticated: null,
        user: {},
        current_restaurant: {},
        user_type: "",
        alert: { open: false },
        location: "/"
    });
    const { getItem, removeItem } = useLocalStorage();
    const item = getItem("sm_a_i");
    let token = Cookie.get("sm_c_t");

    useEffect(() => {
        const refreshToken = Cookie.get("sm_r_t");
        const refreshSession = async (token) => {
            if (!token || token === undefined) {
                if (!refreshToken || refreshToken === undefined) {
                    removeItem("sm_a_i");
                    return window.location.replace(
                        `${process.env.REACT_APP_LOGIN}`
                    );
                } else {
                    return (async () => {
                        const { success, response } = await client(
                            "user/refresh-token",
                            null,
                            "GET",
                            {
                                Authorization: `${refreshToken}`
                            }
                        );
                        if (success && response) {
                            const oneHour = new Date(
                                new Date().getTime() + 1 * 60 * 60 * 1000
                            );

                            Cookie.set(
                                "sm_c_t",
                                response.AuthenticationResult?.AccessToken,
                                {
                                    domain: process.env.REACT_APP_COOKIE_DOMAIN,
                                    expires: oneHour
                                }
                            );
                            return response.AuthenticationResult?.AccessToken;
                        } else {
                            removeItem("sm_a_i");
                            return window.location.replace(
                                `${process.env.REACT_APP_LOGIN}`
                            );
                        }
                    })();
                }
            }
        };
        const checkToken = async (token) => {
            const { success, response } = await client(
                "user/get-user",
                null,
                "GET",
                {
                    Authorization: `${token}`
                }
            );

            if (success) {
                response.user_permissions = response.user_permissions.sort(
                    (x, y) =>
                        x.identifier.display_name.localeCompare(
                            y.identifier.display_name,
                            undefined,
                            {
                                sensitivity: "accent"
                            }
                        )
                );

                dispatch(setCurrentUser(response));
                if (globalState) {
                    if (globalState.user.status === "pending") {
                        return window.location.replace(
                            `${process.env.REACT_APP_LOGIN}`
                        );
                    }
                }

                const activeRestaurants = response.user_permissions.filter(
                    (x) => x.status === "active"
                );
                const index = activeRestaurants.findIndex(
                    (x) =>
                        x.identifier.identifier === response.selected_restaurant
                );
                const current_identifier =
                    activeRestaurants[Math.max(index, 0)]?.identifier
                        ?.identifier;
                const user_role = response?.user_permissions?.find(
                    (x) => x?.identifier?.identifier === current_identifier
                )?.user_role;
                dispatch(setCurrentUserType(user_role));
                dispatch(
                    setCurrentRestaurant({
                        ...activeRestaurants[Math.max(index, 0)].identifier
                    })
                );
            } else {
                if (response.error === "User does not exist.") {
                    window.location.replace(`${process.env.REACT_APP_LOGIN}`);
                }
            }
        };
        (async () => {
            // eslint-disable-next-line react-hooks/exhaustive-deps
            const newToken = await refreshSession(token);
            await checkToken(newToken || token);
        })();
        // eslint-disable-next-line
    }, [item, globalState.location]);

    return (
        <AuthGlobal.Provider
            value={{
                globalState,
                dispatch
            }}
        >
            {children}
        </AuthGlobal.Provider>
    );
};

export default Auth;
