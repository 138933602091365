import { useState } from "react";
import Icon from "../../../components/UI/Icon";
import { TableRow } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import NoResults from "../../../components/NoResults";
import { Root, classes } from "../../../assets/styles/pages/menus/tabs/Active";
import ConfirmAction from "../../../components/modals/ConfirmAction";
import CustomTooltip from "../../../components/UI/CustomTooltip";
import CustomPagination from "../../../components/UI/CustomPagination";
import Button from "../../../components/UI/Button";

const Row = ({ data }) => {
    const navigate = useNavigate();
    return (
        <TableRow
            className={`${classes.menuListTableRow} ${
                data.index % 2 === 0 ? classes.color1 : classes.color2
            }`}
        >
            <td className={classes.name}>{data.internal_name}</td>
            {!data.hideActions && (
                <td className={classes.actions}>
                    <CustomTooltip title="Move to Active">
                        <span className={classes.iconContainer}>
                            <Icon
                                onClick={() =>
                                    data.showMarkAsActiveModal(
                                        data._id,
                                        data.internal_name
                                    )
                                }
                                name="refresh"
                            />
                        </span>
                    </CustomTooltip>
                    <CustomTooltip title="Duplicate Menu">
                        <span className={classes.iconContainer}>
                            <Icon
                                onClick={() =>
                                    data.showDuplicateModal(
                                        data._id,
                                        data.internal_name
                                    )
                                }
                                name="duplicate"
                            />
                        </span>
                    </CustomTooltip>
                    <CustomTooltip title="Delete Menu">
                        <span className={classes.iconContainer}>
                            <Icon
                                onClick={() =>
                                    data.showDeleteModal(
                                        data._id,
                                        data.internal_name
                                    )
                                }
                                name="trash"
                            />
                        </span>
                    </CustomTooltip>
                    <Button
                        type="primary"
                        color="dark"
                        innerText="Edit"
                        className={classes.recoverMenu}
                        onClick={() => navigate(`/menu/${data._id}/update`)}
                    />
                </td>
            )}
        </TableRow>
    );
};

const DraftTab = ({
    data,
    markAsActive,
    duplicateMenuItem,
    markAsTrash,
    setPagination
}) => {
    const [deleteModalVisible, setDeleteModalVisible] = useState(false);
    const [duplicateModalVisible, setDuplicateModalVisible] = useState(false);
    const [markAsActiveModalVisible, setMarkAsActiveModalVisible] =
        useState(false);
    const [itemDelete, setItemDelete] = useState(false);
    const [itemDuplicate, setItemDuplicate] = useState(false);
    const [itemName, setItemName] = useState("");
    const [markAsActiveItem, setMarkAsActiveItem] = useState();
    const [page, setPage] = useState(1);
    const rowsPerPage = setPagination ? 10 : data.length;

    const showMarkAsActiveModal = (record, name) => {
        setItemName(name);
        setMarkAsActiveModalVisible(true);
        setMarkAsActiveItem(record);
    };

    const showDeleteModal = (id, name) => {
        setItemName(name);
        setDeleteModalVisible(true);
        setItemDelete(id);
    };

    const showDuplicateModal = (id, name) => {
        setItemName(name);
        setDuplicateModalVisible(true);
        setItemDuplicate(id);
    };

    const okDeleteClick = () => {
        setDeleteModalVisible(false);
        markAsTrash(itemDelete);
    };

    const okDuplicateClick = (itemName) => {
        setDuplicateModalVisible(false);
        duplicateMenuItem(itemDuplicate, itemName);
        setItemName("");
    };

    const okMarkAsActiveClick = () => {
        setMarkAsActiveModalVisible(false);
        markAsActive(markAsActiveItem);
    };

    return (
        <>
            {data.length > 0 ? (
                <Root
                    style={{
                        marginTop: 15,
                        display: "block",
                        minHeight: "calc(100vh - 335px)",
                        overflowY: "auto"
                    }}
                >
                    <tbody>
                        {data
                            .slice((page - 1) * rowsPerPage, page * rowsPerPage)
                            .map((row, index) => {
                                return (
                                    <Row
                                        index={index}
                                        key={index}
                                        data={{
                                            ...row,
                                            index,
                                            showDeleteModal,
                                            showDuplicateModal,
                                            showMarkAsActiveModal
                                        }}
                                    ></Row>
                                );
                            })}
                    </tbody>
                </Root>
            ) : (
                <NoResults text={"No Menus Found"} />
            )}

            {setPagination && (
                <CustomPagination
                    totalRows={data.length}
                    rowsPerPage={rowsPerPage}
                    handlePageChange={setPage}
                />
            )}

            {itemName && (
                <ConfirmAction
                    open={duplicateModalVisible}
                    actionConfirm={okDuplicateClick}
                    actionCancel={() => {
                        setItemName("");
                        setDuplicateModalVisible(false);
                    }}
                    icon="duplicate"
                    dialogText={`Are you sure want to duplicate the ${itemName} menu?`}
                    params={[
                        {
                            label: "New Menu Name",
                            value: `Copy of ${itemName}`,
                            description: "Change the Menu Name:"
                        }
                    ]}
                />
            )}

            <ConfirmAction
                open={deleteModalVisible}
                actionConfirm={okDeleteClick}
                actionCancel={() => setDeleteModalVisible(false)}
                icon="trash"
                dialogText={`Are you sure want to delete the ${itemName} menu?`}
            />

            <ConfirmAction
                open={markAsActiveModalVisible}
                actionConfirm={okMarkAsActiveClick}
                actionCancel={() => setMarkAsActiveModalVisible(false)}
                icon="refresh"
                dialogText={`Are you sure want to move the ${itemName} menu to active?`}
            />
        </>
    );
};

export default DraftTab;
