import { useContext, useEffect, useState } from "react";
import BoxLayout from "../../components/UI/BoxLayout";
import Header from "../../components/UI/Header";
import { Menu, MenuItem, Tab, Tabs } from "@mui/material";
import { Root, classes } from "../../assets/styles/pages/Alerts";
import Icon from "../../components/UI/Icon";
import AlertsList from "./AlertsList";
import { AlertsContext } from "../../context/store/Alerts";

const Alerts = () => {
    const data = useContext(AlertsContext);
    const [search, setSearch] = useState("");
    const [anchorEl, setAnchorEl] = useState(null);
    const dropdownOpen = Boolean(anchorEl);
    const tabLabel = (text) => {
        const count =
            text === "Active Alerts"
                ? data.activeAlerts.length
                : text === "Archived Alerts"
                ? data.dismissedAlerts.length
                : 0;

        return (
            <span className={classes.labelText}>
                {text}
                {text === "Archived Alerts" || count === 0 ? (
                    <span
                        style={{
                            color: "#888888",
                            marginLeft: "0.2rem",
                            fontWeight: "normal"
                        }}
                    >
                        ({count})
                    </span>
                ) : (
                    count > 0 && (
                        <span
                            style={{
                                marginLeft: 8,
                                padding: "4px 7.5px",
                                color: "#FFFFFF",
                                fontWeight: 700,
                                fontSize: 14,
                                lineHeight: "16.5px",
                                borderRadius: "50px",
                                background: "#D8000C"
                            }}
                        >
                            {count > 99 ? "+99" : count}
                        </span>
                    )
                )}
            </span>
        );
    };

    const labelProps = (index) => {
        return {
            id: `simple-tab-${index}`,
            "aria-controls": `simple-tabpanel-${index}`,
            key: index,
            value: index
        };
    };

    function TabPanel(props) {
        const { children, value, index, ...other } = props;

        return (
            <div
                role="tabpanel"
                hidden={value !== index}
                id={`simple-tabpanel-${index}`}
                aria-labelledby={`simple-tab-${index}`}
                {...other}
            >
                {value === index && <div>{children}</div>}
            </div>
        );
    }

    const handleChangeTab = (_event, newValue) => {
        data.setValue(newValue);
    };

    const handleSortOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleSort = (order) => {
        setAnchorEl(null);
        if (order) {
            data.setOrder(order);
        }
    };

    useEffect(() => {
        data.getData();
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <BoxLayout key="content">
            <Header
                title={"Alerts"}
                searchText={search}
                setSearchText={setSearch}
                clearSearchText={() => setSearch("")}
            />
            <Root className={classes.table}>
                <div className={classes.tabsRow}>
                    <Tabs
                        className={classes.tabsContainer}
                        value={data.value}
                        onChange={handleChangeTab}
                    >
                        <Tab
                            label={tabLabel("Active Alerts")}
                            {...labelProps(0)}
                        />
                        <Tab
                            label={tabLabel("Archived Alerts")}
                            {...labelProps(1)}
                        />
                    </Tabs>
                    <>
                        <div
                            className={classes.sortHandler}
                            id="sort-dropdown"
                            aria-controls={
                                dropdownOpen ? "basic-menu" : undefined
                            }
                            aria-haspopup="true"
                            aria-expanded={dropdownOpen ? "true" : undefined}
                            onClick={handleSortOpen}
                        >
                            <span>
                                {data.order === "ASC"
                                    ? "Oldest to Newest"
                                    : "Newest to Oldest"}
                            </span>
                            <Icon name="dropdown" />
                        </div>
                        <Menu
                            id="basic-menu"
                            anchorEl={anchorEl}
                            open={dropdownOpen}
                            onClose={() => handleSort()}
                            MenuListProps={{
                                "aria-labelledby": "sort-dropdown"
                            }}
                            disableScrollLock={true}
                        >
                            <MenuItem onClick={() => handleSort("DESC")}>
                                Newest to Oldest
                            </MenuItem>
                            <MenuItem onClick={() => handleSort("ASC")}>
                                Oldest to Newest
                            </MenuItem>
                        </Menu>
                    </>
                </div>

                <TabPanel value={data.value} index={0}>
                    <AlertsList
                        alerts={data.activeAlerts.filter((alert) =>
                            alert.item_id.internal_name.match(
                                new RegExp(search, "i")
                            )
                        )}
                    />
                </TabPanel>
                <TabPanel value={data.value} index={1}>
                    <AlertsList
                        alerts={data.dismissedAlerts.filter((alert) =>
                            alert.item_id.internal_name.match(
                                new RegExp(search, "i")
                            )
                        )}
                        archived={true}
                    />
                </TabPanel>
            </Root>
        </BoxLayout>
    );
};

export default Alerts;
