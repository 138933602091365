import { styled } from "@mui/material/styles";

const classes = {
    columnsContainer: `columnsContainer`,
    column: `column`,
    textField: `textField`
};
const Root = styled("div")(({ theme }) => ({
    [`&.${classes.columnsContainer}`]: {
        display: "flex",
        flexDirection: "row",
        columnGap: 32
    },
    [`& .${classes.column}`]: {
        display: "flex",
        flexDirection: "column",
        width: "100%"
    },
    [`& .${classes.textField}`]: {
        marginTop: "28px !important",
        lineHeight: "44px !important",
        // border: "1px solid #B4B3B3",
        "& div.Mui-focused": {
            "& fieldset": {
                borderColor: `${theme.colors.orange[700]} !important`
            }
        },
        "& label.Mui-focused": {
            color: `${theme.colors.orange[700]} !important`
        },
        "& fieldset": {
            borderRadius: "50px",
            "& legend": {
                margin: "4px 0px 4px 2px"
            }
        },
        "& input": {
            padding: "12px 18px !important"
        },
        "& input, div": {
            fontSize: 14
        },
        "& label": {
            transition: "all 100ms linear !important"
        },
        "& label[data-shrink=true].Mui-focused": {
            color: `${theme.colors.orange[700]} !important`
        },
        "& label[data-shrink=true]": {
            padding: `4px${theme.browser.isSafari ? " 10px" : " 8px"}`,
            color: `${theme.colors.grey[900]} !important`,
            background: "white",
            fontWeight: 500,
            fontSize: "16px",
            top: -3,
            "& .MuiFormLabel-asterisk": {
                color: theme.colors.orange[700]
            }
        },
        "& label[data-shrink=false]": {
            paddingLeft: 7
        }
    }
}));

export { Root, classes };
