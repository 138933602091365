import { useContext, useEffect, useState } from "react";
import {
    TextField,
    FormControlLabel,
    Switch,
    Collapse,
    CircularProgress
} from "@mui/material";
import {
    Root,
    classes
} from "../../../assets/styles/settings/PresenterSettings.js";
import _ from "lodash";
import Button from "../../../components/UI/Button.js";
import AuthGlobal from "../../../context/store/global";
import {
    getWebTemplate,
    refreshRestaurant,
    updateWebTemplate
} from "../../../utils/Restaurants/restaurant-helper.js";
import { useAlert } from "../../../hooks/useAlert.js";
import Alert from "../../../components/UI/Alert.js";
import Icon from "../../../components/UI/Icon.js";
import { saveImage } from "../../../utils/mediaHelper.js";

const WebSettings = () => {
    const {
        dispatch,
        globalState: {
            current_restaurant: { identifier },
            current_restaurant
        }
    } = useContext(AuthGlobal);
    const defaultFont = {
        name: "",
        family: "",
        url: "",
        weight: "",
        size: "",
        style: "",
        line_height: "",
        background: "",
        color: ""
    };
    const [formData, setFormData] = useState({
        template_id: "1",
        controls: {
            show_item_separator: true,
            item_counter: true
        },
        colors: {
            primary: "",
            secondary: "",
            tertiary: "",
            background_1: "",
            background_2: "",
            text: ""
        },
        images: {
            section_background_image: ""
        },
        fonts: {
            menu_links: { ...defaultFont },
            section_links: { ...defaultFont },
            section: { ...defaultFont },
            item_name: { ...defaultFont },
            item_description: { ...defaultFont },
            item_price: { ...defaultFont },
            notations_slider: { ...defaultFont },
            notations: { ...defaultFont },
            section_header: { ...defaultFont },
            menu_footer: { ...defaultFont }
        }
    });
    const [images, setImages] = useState({
        section_background_image: ""
    });
    const [previewImg, setPreviewImg] = useState({
        section_background_image: ""
    });

    useEffect(() => {
        const newPreviews = {};
        Object.keys(images).forEach((k) => {
            if (images[k]) {
                newPreviews[k] =
                    typeof images[k] === "string"
                        ? images[k]
                        : URL.createObjectURL(images[k]);
            } else {
                newPreviews[k] = "";
            }
        });
        setPreviewImg(newPreviews);
    }, [images]);

    const [errors, setErrors] = useState({
        "colors.primary": "",
        "colors.secondary": "",
        "colors.tertiary": "",
        "colors.background_1": "",
        "colors.background_2": "",
        "colors.text": "",
        "fonts.menu_links.color": "",
        "fonts.section_links.color": "",
        "fonts.section.color": "",
        "fonts.item_name.color": "",
        "fonts.item_description.color": "",
        "fonts.item_price.color": "",
        "fonts.notations_slider.color": "",
        "fonts.notations.color": "",
        "fonts.section_header.color": "",
        "fonts.menu_footer.color": ""
    });

    const [collapsed, setCollapsed] = useState({
        menu_links: false,
        section_links: false,
        section: false,
        item_name: false,
        item_description: false,
        item_price: false,
        notations_slider: false,
        notations: false,
        section_header: false,
        menu_footer: false
    });
    const [loading, setLoading] = useState(true);
    const [showFormError, setShowFormError] = useState(false);
    const [alert, handleAlert] = useAlert();
    let colorChangeTimeout;

    const handleChange = (target, event, type = "text") => {
        let newData = { ...formData };
        if (type !== "image") {
            newData = _.set(newData, target, event.target.value);
            setErrors((prev) => {
                return _.set(errors, target, "");
            });
            setShowFormError(false);
            setFormData(newData);
        }
        if (type === "image") {
            const path = target.split(".").at(-1);
            if (event.target.type === "file") {
                ((file) =>
                    setImages((prev) => ({
                        ...prev,
                        [path]: file
                    })))(event.target.files[0]);
                event.target.value = null;
            } else {
                newData = _.set(newData, target, event.target.value);
                setFormData(newData);
                setImages((prev) => ({ ...prev, [path]: event.target.value }));
            }
        }
    };

    const displayName = (str) => {
        if (str === "notations_slider") {
            return "Dietary Restrictions Slider";
        } else if (str === "notations") {
            return "Dietary Restrictions";
        }
        return str.split("_").map(_.startCase).join(" ");
    };

    useEffect(() => {
        (async () => {
            const { success, response } = await getWebTemplate(identifier);
            if (success && response) {
                const data = response.template;
                setFormData((prev) => _.merge(prev, data));
                response.template.images && setImages(response.template.images);
                setLoading(false);
            }
        })();
    }, [identifier]);

    const isValidColor = (val) => {
        if (typeof val !== "string") {
            return false;
        }
        const isHex = val.match("^#[0-9a-fA-F]+$");
        const isValidLength = [4, 7, 9].includes(val.length);
        return isHex && isValidLength;
    };

    const formInput = (label, name, type = "text") => {
        const value = _.get(formData, name);
        const errorState = _.get(errors, name)?.length > 0;
        const showImage =
            type === "image" && previewImg[name.split(".").at(-1)];
        return (
            <>
                <TextField
                    label={label}
                    value={value}
                    type={type !== "color" && type !== "image" ? type : "text"}
                    InputLabelProps={{ shrink: true }}
                    className={classes.textField}
                    error={errorState}
                    fullWidth
                    helperText={_.get(errors, name)}
                    onChange={(e) => handleChange(name, e, type)}
                    style={{ margin: "15px 0" + (errorState ? " -7px" : "") }}
                    InputProps={
                        type === "color"
                            ? {
                                  endAdornment: (
                                      <div
                                          style={{
                                              width: "22px",
                                              height: "22px",
                                              border: "1px solid #B4B3B3",
                                              background: value
                                          }}
                                      >
                                          <input
                                              className={
                                                  classes.invisibleColorPicker
                                              }
                                              style={{
                                                  padding: "0 !important"
                                              }}
                                              type="color"
                                              onChange={(e) => {
                                                  clearTimeout(
                                                      colorChangeTimeout
                                                  );
                                                  colorChangeTimeout =
                                                      setTimeout(() => {
                                                          handleChange(name, e);
                                                      }, 100);
                                              }}
                                          />
                                      </div>
                                  )
                              }
                            : type === "image"
                            ? {
                                  endAdornment: (
                                      <span className={classes.uploadButton}>
                                          <Icon name="publish" />
                                          <input
                                              type="file"
                                              className={
                                                  classes.invisibleColorPicker
                                              }
                                              onChange={(e) =>
                                                  handleChange(name, e, type)
                                              }
                                              style={{
                                                  position: "absolute",
                                                  padding: "0 !important"
                                              }}
                                          />
                                      </span>
                                  )
                              }
                            : {}
                    }
                />
                {showImage && (
                    <div className={classes.imageContainer}>
                        <span
                            className={classes.removeImage}
                            onClick={() =>
                                handleChange(
                                    name,
                                    { target: { value: "" } },
                                    type
                                )
                            }
                        >
                            <Icon name="clear" />
                        </span>
                        <img
                            src={previewImg[name.split(".").at(-1)]}
                            alt={displayName(name.split(".").at(-1))}
                            className={classes.image}
                        />
                    </div>
                )}
            </>
        );
    };

    const toggleSwitch = (target) => {
        let newData = { ...formData };
        newData = _.set(newData, target, !_.get(newData, target));
        setFormData(newData);
    };

    const handleSubmit = async () => {
        // Validate fields
        const newErrors = {};
        let valid = true;
        Object.keys(errors).forEach((v) => {
            const color = _.get(formData, v);
            if (color?.length > 0 && !isValidColor(color)) {
                newErrors[v] = "Invalid color format";
                valid = false;
            } else {
                newErrors[v] = "";
            }
        });
        setErrors(newErrors);
        if (!valid) {
            console.log(newErrors);
            setShowFormError(true);
            window.scrollTo({ top: 200 });
            return;
        }
        // ---- End Validate ----

        // Process images
        let payload = {
            ...formData
        };
        let successUpload, image_url;
        for (const key of Object.keys(images)) {
            if (typeof images[key] === "string") {
                payload.images[key] = images[key];
            } else {
                [successUpload, image_url] = await saveImage(
                    images[key],
                    identifier,
                    "webmenu/assets"
                );
                if (successUpload) {
                    payload.images[key] = image_url;
                } else {
                    setShowFormError(true);
                    window.scrollTo({ top: 200 });
                    return;
                }
            }
        }
        // ---- End Image Processing

        // Call API to update
        const { success, response } = await updateWebTemplate(
            identifier,
            formData
        );
        if (success && response) {
            handleAlert({
                severity: "success",
                title: "Success",
                message: "Presenter Template updated successfully"
            });
            setTimeout(() => {
                refreshRestaurant(dispatch, identifier, current_restaurant);
            }, 2000);
        }
    };

    const formSwitch = (label, name) => {
        const value = _.get(formData, name);
        return (
            <div style={{ height: 44, margin: "15px 0" }}>
                <p className={classes.toggleLabel}>{label}</p>
                <FormControlLabel
                    control={
                        <Switch
                            checked={value}
                            classes={{
                                switchBase: classes.switchDisabled
                            }}
                            className={value ? classes.switch : ""}
                        />
                    }
                    name={name}
                    onChange={() => toggleSwitch(name)}
                    label={value ? "Active" : "Inactive"}
                    className={classes.switchLabel}
                />
            </div>
        );
    };

    return !loading ? (
        <Root>
            <Alert {...alert} />
            <Collapse in={showFormError}>
                <div
                    style={{
                        fontSize: 14,
                        background: "#FAD3B8",
                        justifyContent: "center",
                        alignItems: "center",
                        display: "flex",
                        height: 40
                    }}
                >
                    Some errors were found in the form, please fix them and try
                    again
                </div>
            </Collapse>
            {/* <h2 style={{ marginTop: 15 }}>General</h2>
            <hr style={{ margin: "15px 0" }} />
            <TextField
                select
                value={formData.template_id}
                onChange={(e) => handleChange("template_id", e)}
                label="Template id"
                InputLabelProps={{ shrink: true }}
                SelectProps={{
                    MenuProps: {
                        classes: {
                            paper: classes.selectRow
                        }
                    }
                }}
                style={{ width: 200 }}
                className={`${classes.select} ${classes.textField}`}
            >
                <MenuItem value="1"> 1</MenuItem>
                <MenuItem value="2"> 2</MenuItem>
            </TextField>
            <hr style={{ marginTop: 15}} />  */}
            <h2 style={{ marginTop: 15 }}>Controls</h2>
            <hr style={{ margin: "15px 0" }} />
            <div className={classes.columnsContainer}>
                <div className={classes.column}>
                    {formSwitch(
                        "Show item separator",
                        "controls.show_item_separator"
                    )}
                    {formSwitch(
                        "Show Currency Symbol",
                        "controls.show_currency_symbol"
                    )}
                </div>
                <div className={classes.column}>
                    {formSwitch("Item counter", "controls.item_counter")}
                    {formSwitch(
                        "Show Empty Decimals",
                        "controls.show_empty_decimals"
                    )}
                </div>
                <div className={classes.column}>
                    {formSwitch("Show Item Price", "controls.show_item_price")}
                </div>
            </div>
            <hr style={{ margin: "15px 0" }} />
            <h2>Colors</h2>
            <hr style={{ margin: "15px 0" }} />
            <div className={classes.columnsContainer}>
                <div className={classes.column}>
                    {formInput("Primary", "colors.primary", "color")}
                    {formInput("Background 1", "colors.background_1", "color")}
                </div>
                <div className={classes.column}>
                    {formInput("Secondary", "colors.secondary", "color")}
                    {formInput("Background 2", "colors.background_2", "color")}
                </div>
                <div className={classes.column}>
                    {formInput("Tertiary", "colors.tertiary", "color")}
                    {formInput("Text", "colors.text", "color")}
                </div>
            </div>
            <hr style={{ margin: "15px 0" }} />
            <h2>Images</h2>
            <hr style={{ margin: "15px 0" }} />
            <div className={classes.columnsContainer}>
                <div className={classes.column} style={{ width: "50%" }}>
                    {formData.template_id === "1" &&
                        formInput(
                            "Section Image Background",
                            "images.section_background_image",
                            "image"
                        )}
                </div>
            </div>
            <h2>Fonts</h2>
            <hr style={{ marginTop: "15px" }} />
            {Object.keys(formData.fonts).map((key) => (
                <div key={key}>
                    <h4
                        style={{
                            margin: "0px",
                            padding: "10px",
                            borderBottom: "1px solid",
                            borderTop: "1px solid",
                            textAlign: "center",
                            cursor: "pointer",
                            width: "100%",
                            height: "100%"
                        }}
                        onClick={() => {
                            setCollapsed((prev) => ({
                                ...prev,
                                [key]: !prev[key]
                            }));
                        }}
                    >
                        {displayName(key)}
                    </h4>
                    <Collapse in={collapsed[key]}>
                        <div className={classes.columnsContainer}>
                            <div className={classes.column}>
                                {formInput("Name", `fonts.${key}.name`)}
                                {formInput("Weight", `fonts.${key}.weight`)}
                                {formInput(
                                    "Line height",
                                    `fonts.${key}.line_height`
                                )}
                            </div>
                            <div className={classes.column}>
                                {formInput("Family", `fonts.${key}.family`)}
                                {formInput("Size", `fonts.${key}.size`)}
                                {formInput(
                                    "Color",
                                    `fonts.${key}.color`,
                                    "color"
                                )}
                            </div>
                            <div className={classes.column}>
                                {formInput("Url", `fonts.${key}.url`)}
                                {formInput("Style", `fonts.${key}.style`)}
                                {key === "restaurant_button" &&
                                    formInput(
                                        "Background",
                                        `fonts.${key}.background`,
                                        "color"
                                    )}
                            </div>
                        </div>
                    </Collapse>
                </div>
            ))}
            <Button
                innerText="Save"
                type="primary"
                color="orange"
                style={{ marginLeft: "auto", marginBottom: 15, marginTop: 15 }}
                onClick={handleSubmit}
            />
        </Root>
    ) : (
        <div
            style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: 500
            }}
        >
            <CircularProgress
                style={{ color: "#CC5803", width: 60, height: 60 }}
            />
        </div>
    );
};

export default WebSettings;
